const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  chart: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
  chartContainer: {
  },
  buttonsPad: {
    display: 'block'
  },
  radioSelectRoot: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12
    }
  },
  radioSelectChecked: {
    color: theme.palette.primary.main + '!important'
  },
  radioSelectTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#000000'
  },
  helpIcon: {
    color: '#202a38',
    marginLeft: 3,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  legend: {
    style: 'none',
    float: 'right',
    listStyle: 'none',
    marginTop: 0,
    [theme.breakpoints.only("xs")]: {
      padding: 0
    }
  },
  legendItem: {
    display: 'inline-block',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 0
    },
    [theme.breakpoints.only("xs")]: {
      marginRight: 10,
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  description: {
    fontSize: 12,
    marginBottom: 0,
    color: '#8D8D8D',

    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    }
  },
  warning: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    color: '#000000',
    marginLeft: 30,
  },
  disabled: {
    opacity: 0.5
  },
  disabledIcon: {
    '&:hover': {
      cursor: 'default'
    }
  }
})

export default styles;