import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  guideTourCard: {
    position: 'fixed',
    top: 56,
    right: 25,
    zIndex: 9999,
    width: 500,
    maxWidth: '100%',
    padding: '19px 30px',
    borderRadius: 12,
    boxShadow: '0px 2px 15px 0px #00000029',

    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      minHeight: '100%',
      minWidth: '100%',
      borderRadius: 0,
      boxShadow: 'none',
      padding: 8
    },

  },
  guideTourCardContent: {
    padding: '0 !important',
    margin: 0
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > p': {
      fontFamily: 'Roboto-Bold',
      color: '#4D4F5C',
      fontSize: 22,
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },

    '& > i': {
      color: '#b1b1b1',
      fontSize: 20,
      
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  contentSection: {
    marginTop: 20
  },

  /**Guide tour item styles */
  guideTourItemContainer: {
    display: 'flex',
    alignItems: 'center',

    padding: '20px 0',

    borderBottom: '1px solid #E1E3E9',

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0'
    },

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      fontFamily: 'Roboto-Bold',
      fontSize: 25,
      backgroundColor: '#D8DCDF',
      color: 'white',
      width: 45,
      height: 45,

      [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
        fontSize: 18,
      },
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      color: '#4D4F5C',
      fontSize: 15,
      maxWidth: 230,
      margin: 0,
      marginLeft: 20,

      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        marginLeft: 10,
        maxWidth: 400
      },

      [theme.breakpoints.only('xs')]: {
        maxWidth: 180
      },
    },

    '& > $startGuideButton': {
      float: 'right',
      marginLeft: 'auto'
    }
  },
  startGuideButton: {
    textTransform: 'none',
    fontFamily: 'Roboto-Bold',
    boxShadow: 'none',
    borderRadius: 2,
    padding: '6px 16px',

    '& i': {
      marginLeft: 5
    },

    [theme.breakpoints.down('sm')]: {
      padding: '4px 6px'
    },
  },

  highlightedMaskClassName: {
    stroke: '#E3A600',
    strokeWidth: 6,
  }, 
  helper: {
    borderRadius: 10,
    width: '430px!important',
    maxWidth: '430px!important',
    display: 'flex',
    flexDirection: 'column-reverse',

    [theme.breakpoints.only('xs')]: {
      width: '300px!important',
      maxWidth: '300px!important',
      padding: 16
    },
    
    '& [data-tour-elem="badge"]': {
      height: 53,
      width: 53,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E3A600 !important',
      top: -28,
      right: 28,
      left: 'auto',
      fontSize: 22,
      fontFamily: 'Roboto-Bold',

      [theme.breakpoints.only('xs')]: {
        width: 35,
        height: 35,
        top: -17,
        fontSize: 16
      },
    },

    '& [data-tour-elem = "badge"]': {
      boxShadow: 'none'
    },

    '& [data-tour-elem = "controls"]': {
      display: 'none'
    }
  },

  tourContentComponentHeader: {
    fontSize: 20, 
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
    margin: 0,

    [theme.breakpoints.only('xs')]: {
      fontSize: 16,
    },
  },
  tourContentComponentBody: {
    fontSize: 16, 
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C',

    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
      marginTop: 4,
      marginBottom: 4,
    },
  },
  tourNavigationButton: {
    boxShadow: 'none!important',
    textTransform: 'none!important',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
    padding: '6px 16px',
    borderRadius: 2,
    fontFamily: 'Roboto-bold',
    fontSize: 14,

    '&:[disabled]': {
      backgroundColor: '#b1b1b1'
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 13,
      padding: 6
    },
  },
  arrowIcon: {
    fontSize: 20,
  },
  closeIcon: {
    color: '#b1b1b1',
    cursor: 'pointer'
  },

  navigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.only('xs')]: {
      display: 'block'
    },
  },
  tourSkipButton: {
    boxShadow: 'none!important',
    textTransform: 'none!important',
    textDecoration: 'underline!important',
    cursor: 'pointer',
    padding: '0!important',
    fontFamily: 'Roboto-bold',
    fontSize: 14,
    backgroundColor: 'transparent!important',

    [theme.breakpoints.only('xs')]: {
      padding: '8px 0',
      fontSize: 13
    },
  },
  nextPrevButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  prevBtn: {
    marginRight: 10
  }
}));