import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  content: {
    padding: '0 24px 16px 24px',
    marginTop: -5,
    [theme.breakpoints.only('xs')]: {
      padding: 8,
    }
  },
  modalTitle: {
    '& h2': {
      fontWeight: 'bold',
    },
  },
  dialogActions: {
    padding: '0 24px 16px 24px',
  },
  root: {
    zIndex: '1000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    margin: '0 !important',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  },
  expansionPanelRoot: {
    borderRadius: '2px',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    zIndex: 2
  },

  primaryEmail: {
    display: 'inline-flex',
    padding: '0 3px',
    minWidth: '1.125rem',
    height: '1.125rem',
    lineHeight: 0,
    borderRadius: '1.125rem',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'initial',
    border: '1px solid #78bd5d',
    color: '#78bd5d!important',
    background: 'none!important',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontSize: '.75rem !important',
    fontWeight: 700,
    marginLeft: '0.5em',
  },
  emailType: {
    marginLeft: '0.5em',
    color: '#78bd5d!important',
    fontSize: '.75rem !important',
  },
  selectedEmail: {
    '& span': {
      position: 'relative',
      top: -1,
    }
  },
  sectionHeader: {
    fontFamily: 'Roboto-Regular !important',
    fontSize: '16px !important',
    fontWeight: 'normal !important',
    marginBottom: '1rem',
    marginTop: 8,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  emailLabel: {
    marginBottom: 5,
  },
  selectedItem: {
    '& span': {
      position: 'relative',
      top: -1,
    }
  },
  listItemSelected: {
    backgroundColor: '#D8DCDF',

    '& > span': {
      color: '#4D4F5C'
    }
  },
  textEditorWrapper: {
    borderRadius: 0,
    minHeight: 330,
    marginTop: 10,
    '& .ql-editor': {
      minHeight: 277
    }
  },
  editorHelpText: {
    fontSize: '0.8rem',
    fontFamily: "Roboto-Bold"
  },
  documentsToSignBlock: {
    marginTop: 20,
  },
  headerWithToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 5,
  },
  switch: {
    transform: 'scale(0.9)',
  },
  labelRoot: {
    paddingBottom: '0 !important',
    marginBottom: 0,
  },
  editorFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  counter: {
    marginLeft: 'auto',

    '& > p': {
      color: 'rgb(94, 193, 78)',
    }
  }
}));

