import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title:{
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    borderBottom: '1px solid #DADEE1',
    padding: '16px',
    color: '#626970',
    margin: 0
  },
  container: {
    margin: 0,
    padding: 0,
  },
  firstSettingsSection: {
    borderRight: '1px solid #DADEE1',
  },
  dashboardSettingsSection: {
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DADEE1',
      '&': {
        borderBottom: '1px solid #DADEE1'
      },
      '&:last-child': {
        borderBottom: 'none'
      }
    },
  },
  reportSaveSection: {
    borderTop: '1px solid #DADEE1',
    padding: theme.spacing(2),
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#313D44',
  },
  highlightedName: {
    color: theme.palette.primary.main,
  },
  contentDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
    pointerEvents: 'none',
    '& > span': {
      color: 'rgba(0, 0, 0, 0.38) !important',
    }
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -2,
  },
  copySettingsHeader: {
    marginBottom: 0
  },
  formLabel: {
    color: '#313D44',
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 8,
    whiteSpace: 'nowrap !important',
    width: '100%'
  },
  benchmarkLabel: {
    fontFamily: "'Roboto-Bold'",
    fontSize: 13,
    color: '#4D4F5C',
    margin: 0
  },
  chartSettingsSectionWrapper: {
    marginTop: theme.spacing(2)
  },
  portfoliosSelectorContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  resetButtonLabel: {
    fontSize: 12,
  }
}));