/* React imports */
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';

/* Material UI Components */
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarIcon from '@material-ui/icons/Today';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import LinearProgress from '@material-ui/core/LinearProgress';

/* BCA Components */
import ErrorDialog from '../ErrorDialog';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';

/* BCA modules */
import { parseResponse, VirtualPortfolioHandlerResource } from '../../../../utils/api';
import { toGermanFormat } from '../../../../utils/numberFormater';
import {formatDateShouldBeGraterThenErrorMessage, getErrorMessage} from '../../../../utils/utils';
import { getInstrName } from '../../../Modelportfolios/utils';
import { MONTHLY, CONFIG } from '../../constants';
import styles from './styles';

export const getFee = (assetData, discountInPercent) => {
  let  instrumentFee = _.get(assetData, 'product_fees.entry_cost', 0)

  let fee = instrumentFee / 100
  let discount = discountInPercent / 100

  if (discount) {
    fee = fee * (1 - discount)
  }
  return fee
}

export const getAmountAndNumberOfStocksByGrossAmount = (grossAmount, fee, assetPrice) => {
  let amount = Number(grossAmount * (1 - fee)).toFixed(6)
  let numberOfStocks = Number(amount / assetPrice)

  return [amount, numberOfStocks]
}

export const getGrossAmountByAmount = (amount, fee) => {
  return Number(amount * (1 + fee))
}

const CONTRACT_TYPES = {
    SAVING_PLAN: 1,
    WITHDRAWAL_PLAN: 2,
    EXCHANGE_PLAN: 3
};

export const MODAL_LOADER_ACTION = {
  CREATE: 1,
  EDIT: 2,
  DELETE: 3
};

const MODAL_LOADER_MESSAGE = {
  [MODAL_LOADER_ACTION.CREATE]: "Die Transaktionen werden erstellt.",
  [MODAL_LOADER_ACTION.EDIT]: "Die Transaktionen werden erstellt.",
  [MODAL_LOADER_ACTION.DELETE]: "Die Transaktionen werden gelöscht.",
}

export const ModalLoader = withStyles(styles)((props) => {

  const {
    classes,
    withMsg,
    action
  } = props;

  return (
    <div className={classes.loadWrapper}>
      <CircularProgress color={'primary'}/>
      {withMsg &&
        <p className={classes.loadMessage}>
          {MODAL_LOADER_MESSAGE[action]}
          <br/>
          Bitte haben Sie Geduld, dieser Prozess kann einen Moment dauern.
        </p>
      }
    </div>
  )
});

ModalLoader.defaultProps = {
  action: MODAL_LOADER_ACTION.CREATE
}

class ModalInstruments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      assetData: props.assetData,
      assetId: props.assetId,
      assetEarliest: undefined,
      unitType: 'eur',
      assetPrice: undefined,
      assetPriceDate: moment(),
      priceLoading: true,
      priceError: undefined,
      valueInEur: null,
      valueInEurToDisplay: null,
      valueInEurError: true,
      numberOfStocks: null,
      numberOfStocksToDisplay: null,
      numberOfStocksError: false,
      discount: null,
      discountError: false,
      grossAmount: 0,
      amount: 0,
      recurrentPaymentEnabled: false,
      oneTimePaymentEnabled: true,
      recurrentPaymentStartDate: moment(),
      recurrentPaymentStartDateError: undefined,
      recurrentPaymentAmount: null,
      recurrentPaymentAmountError: false,
      recurrentPaymentPeriod: MONTHLY,
      paymentPlanPrice: undefined,
      paymentPlanPriceLoading: false,
      paymentPlanPriceError: undefined
    }
  }

  isDataReady() {

    let {recurrentPaymentEnabled, oneTimePaymentEnabled } = this.state;

    let oneTimeDataReady = false;
    let recurrentDataReady = false;

    if (recurrentPaymentEnabled) {
      recurrentDataReady =
        this.state.recurrentPaymentAmount > 0
        && !this.state.recurrentPaymentAmountError
        && this.state.paymentPlanPriceError === undefined
        && this.state.recurrentPaymentStartDateError === undefined

    }

    if (oneTimePaymentEnabled) {
      oneTimeDataReady = this.state.assetPrice > 0 && this.state.amount > 0 && this.state.grossAmount > 0;
    }

    if (oneTimePaymentEnabled && !recurrentPaymentEnabled) {
      return oneTimeDataReady;

    } else if (!oneTimePaymentEnabled && recurrentPaymentEnabled){
      return recurrentDataReady;

    } else if (oneTimePaymentEnabled && recurrentPaymentEnabled) {
      return recurrentDataReady && oneTimeDataReady;
    }

    return false
  }

  handleCheckBoxChange = (event) => {
    this.setState({
      recurrentPaymentEnabled: event.target.checked
    })
  }

  handleOneTimePaymentCheckBoxChange = (event) => {
    this.setState({
      oneTimePaymentEnabled: event.target.checked
    })
  }

  fetchPrice = (date, isPaymentPlan=false) => {

    if (isPaymentPlan) {
      date = date.subtract(1, 'days')
      this.setState({
        paymentPlanPriceLoading: true
      })
    } else {
      this.setState({
        priceLoading: true
      })
    }

    VirtualPortfolioHandlerResource.getInstrumentPrice(this.state.assetData.isin, date.format('YYYY-MM-DD'))
      .then((response) => {
        parseResponse(response, 'instrument_price',
          (_data) => {
            let data = _.get(_data, this.state.assetData.isin)
            if (isPaymentPlan) {

              let date = moment(data['price_date'])

              let delta = 1

              switch (date.isoWeekday()) {
                case 5: {
                  delta = 3;
                  break
                }
                case 6: {
                  delta = 2;
                  break
                }
              }

              this.setState({
                paymentPlanPriceDate: moment(data['price_date']).add(delta, 'days'),
                recurrentPaymentStartDate: moment(data['price_date']).add(delta, 'days'),
                recurrentPaymentStartDateError: undefined,
                paymentPlanPrice: data['price_eur'],
                paymentPlanPriceError: undefined,
                paymentPlanPriceLoading: false,
              })
            } else {
              this.setState({
                assetPriceDate: moment(data['price_date']),
                assetPrice: data['price_eur'],
                priceLoading: false
              }, this.recalculateAmounts)
            }
          },
          (error) => {
            if (isPaymentPlan) {
              this.handlePaymentPlanPriceError(date, error)
            } else {
              this.handlePriceError(date, error)
            }
          }
          )
      })
      .catch((error) => {
        if (isPaymentPlan) {
          this.handlePaymentPlanPriceError(date, error)
        } else {
          this.handlePriceError(date, error)
        }
      })
  }

  fetchAssetData() {

    this.setState({
      priceLoading: true
    })

    VirtualPortfolioHandlerResource.getInstrument(this.props.customerId, this.state.assetId)
      .then((response) => {
        parseResponse(response, 'asset',
          (data) => {

            let newState = {
              assetData: data['financial_information']['asset_info_data'],
              discount: data.discount ? data.discount * 100 : null,
            }

            if (data['one_time_payment_date']) {

              newState = {...newState, ...{
                  oneTimePaymentEnabled: true,
                  assetPriceDate: moment(data['one_time_payment_date']),
                  numberOfStocks: data['one_time_payment_quantity'],
                  numberOfStocksToDisplay: Number(data['one_time_payment_quantity']).toFixed(2),
                  valueInEur: data['requested_value'],
                  valueInEurToDisplay: Number(Number(data['requested_value']).toFixed(2)),
                  assetPrice: data['one_time_payment_price'],
                  priceLoading: false
                }
              }

            } else {
              newState = {...newState, ...{
                oneTimePaymentEnabled: false,
              }}
            }

            if (data.hasOwnProperty('payment_plan')) {
              newState['recurrentPaymentEnabled'] = true;
              newState['recurrentPaymentStartDate'] = moment(data['payment_plan']['start_date']);
              newState['recurrentPaymentPeriod'] = data['payment_plan']['period'];
              newState['recurrentPaymentAmount'] = data['payment_plan']['value'];
            }

            this.setState(newState, () => {
              this.fetchPrice(this.state.assetPriceDate)
              this.fetchAssetEarliestPriceDate()
              this.recalculateAmounts()
            })
          },
          (error) => {
            this.handleFetchDataError(error)
          }
          )
      })
      .catch((error) => {
        this.handleFetchDataError(error)
      })
  }
  fetchAssetEarliestPriceDate() {

    this.setState({
      priceLoading: true
    });

    const isin = this.state.assetData.isin;

    VirtualPortfolioHandlerResource.getInstrumentsEarliestPriceDate([isin])
      .then((response) => {
        parseResponse(response, 'earliest',
          (data) => {
            this.setState({
              assetEarliest: _.get(data, isin),
            })
          },
          (error) => {
            this.handleFetchDataError(error)
          })
      })
      .catch((error) => {
        this.handleFetchDataError(error)
      })
  }

  handleFetchDataError(error) {
    this.setState({
      showFailedAssetFetchPopup: true,
      failedAssetFetchError: getErrorMessage(error)
    })
  }

  closeFailedAssetFetch = () => {
    this.setState({
      showFailedAssetFetchPopup: false
    }, this.props.onClose())
  }

  handlePaymentPlanPriceError(date, error) {

    let delta = 1;

    switch (date.isoWeekday()) {
      case 5: {
        delta = 3;
        break
      }
      case 6: {
        delta = 2;
        break
      }
    }

    this.setState({
      paymentPlanPriceDate: moment(date).add(delta, 'days'),
      recurrentPaymentStartDate: moment(date).add(delta, 'days'),
      paymentPlanPriceLoading: false,
      paymentPlanPrice: undefined,
      paymentPlanPriceError: error !== undefined ? 'Für das ausgewählte Datum ist kein Preis vorhanden' : undefined
    })
  }

  handlePriceError(date, error) {
    this.setState({
      assetPriceDate: date,
      priceLoading: false,
      assetPrice: undefined,
      priceError: error
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {

      if (this.props.open !== prevProps.open) {

        this.setState({
          assetData: this.props.assetId ? null : this.props.assetData,
          assetId: this.props.assetId,
          assetPrice: undefined,
          assetPriceDate: moment(),
          assetEarliest: undefined,
          priceError: undefined,
          valueInEur: null,
          valueInEurToDisplay: null,
          numberOfStocks: null,
          numberOfStocksToDisplay: null,
          discount: null,
          unitType: 'eur',
          priceLoading: true,
          valueInEurError: false,
          numberOfStocksError: true,
          discountError: false,
          grossAmount: 0,
          amount: 0,
          recurrentPaymentEnabled: false,
          recurrentPaymentStartDate: moment(),
          recurrentPaymentStartDateError: undefined,
          recurrentPaymentAmount: null,
          recurrentPaymentAmountError: false,
          recurrentPaymentPeriod: MONTHLY,
          paymentPlanPrice: undefined,
          paymentPlanPriceLoading: false,
          paymentPlanPriceError: undefined
        }, () => {

          if (this.props.assetId) {
            this.fetchAssetData()
          } else if (this.props.assetData && this.props.open) {
            this.fetchPrice(moment())
            this.fetchAssetEarliestPriceDate()
          }
        })
      }
    }
  }

  recalculateAmounts() {
    let amount, grossAmount, fee = 0;

    let { numberOfStocks, valueInEur, numberOfStocksToDisplay, valueInEurToDisplay, assetData } = this.state;

    if (assetData && ! this.state.discountError) {
      fee = getFee(assetData, this.state.discount)
    }

    if (assetData && this.state.assetPrice && this.state.unitType === 'eur' && ! this.state.valueInEurError) {
      grossAmount = valueInEur;
      [amount, numberOfStocks] = getAmountAndNumberOfStocksByGrossAmount(grossAmount, fee, this.state.assetPrice)

      numberOfStocksToDisplay = numberOfStocks.toFixed(2)
      numberOfStocks = numberOfStocks.toFixed(6)

      this.setState({
        valueInEurError: false,
      })

    } else if (this.state.unitType === 'stock' && this.state.assetPrice && ! this.state.numberOfStocksError ) {
      amount = numberOfStocks * this.state.assetPrice
      grossAmount = getGrossAmountByAmount(amount, fee)

      valueInEur = grossAmount.toFixed(6)
      valueInEurToDisplay = grossAmount.toFixed(2)

      grossAmount = grossAmount.toFixed(2)

      this.setState({
        valueInEurError: false,
      })
    }

    this.setState({
      amount: amount,
      grossAmount: grossAmount,
      valueInEur: valueInEur,
      numberOfStocks: numberOfStocks,
      numberOfStocksToDisplay: numberOfStocksToDisplay,
      valueInEurToDisplay: valueInEurToDisplay
    })

  }

  formatPercentsNumber(number) {
    return number || number === 0 ? toGermanFormat(number, '', '%', 2) : '-'
  }

  formatNumber(number) {
    return number || number === 0 ? toGermanFormat(number, '', '', 2) : '-'
  }

  handleRadioChange = (event) => {

    let newState = {
      unitType: event.target.value
    }

    if (event.target.value === 'eur') {
      newState['valueInEurError'] = false;
    } else {
      newState['numberOfStocksError'] = false;
    }

    this.setState(newState, this.recalculateAmounts)
  }

  handleDateChange = (date) => {
    this.fetchPrice(date)
  }

  handlePaymentPlanChange = (date) => {
    if (date <= moment()) {
      this.fetchPrice(date, true)
    } else {
      this.setState({
        paymentPlanPriceDate: date,
        recurrentPaymentStartDate: date,
        recurrentPaymentStartDateError: undefined
      })
    }
  }

  handlePaymentPlanDateError = (e) => {
    this.setState({
      recurrentPaymentStartDateError: !e ? undefined : e
    })
  };

  checkIfNumber(value) {
    const re = /^\d+(\.\d+)?$/;
    return value === '' || re.test(value);
  }

  handleAssetValueInEurChange = (event) => {

    let value = event.target.value

    this.setState({
      valueInEur: value,
      valueInEurToDisplay: value,
      valueInEurError: !this.checkIfNumber(value) || value <= 0
    }, this.recalculateAmounts)
  }

  handleStockNumberChange = (event) => {

    let value = event.target.value

    this.setState({
      numberOfStocks: value,
      numberOfStocksToDisplay: value,
      numberOfStocksError: !this.checkIfNumber(value) || value <= 0
    }, this.recalculateAmounts)
  }

  handleDiscountChange = (event) => {

    let value = event.target.value

    this.setState({
      discount: value,
      discountError: !this.checkIfNumber(value) || value < 0 || value > 100
    }, this.recalculateAmounts)
  }

  handleRecurrentAmountChange = (event) => {

    let value = event.target.value

    this.setState({
      recurrentPaymentAmount: value,
      recurrentPaymentAmountError: !this.checkIfNumber(value) || value <= 0
    })
  }

  handlePeriodChange = (event) => {
    this.setState({
      recurrentPaymentPeriod: event.target.value,
    })
  }

  handleRecurrentDateChange = (date) => {
    this.setState({
      recurrentPaymentStartDate: date
    })
  }

  filterWeekends(date) {
    // Return false if Saturday or Sunday
    date = new Date(date);
    return date.getDay() === 0 || date.getDay() === 6;
  }

  wrapData() {
    return {
      'isin': this.state.assetData.isin,
      'discount': this.state.discount / 100,
      'asset_id': this.state.assetId
    }
  }

  wrapOneTimePaymentData() {
    if (this.state.oneTimePaymentEnabled) {
      return {
        quantity: Number(this.state.numberOfStocks),
        value: Number(this.state.grossAmount),
        total_fee: Number(this.state.grossAmount) - Number(this.state.amount),
        price: Number(this.state.assetPrice),
        order_date: this.state.assetPriceDate.format('YYYY-MM-DD'),
        discount: this.state.discount,
      }
    } else {
      return undefined
    }
  }

  wrapPaymentPlanData() {
    if (this.state.recurrentPaymentEnabled) {
      return {
        payment_plan_start_date: this.state.recurrentPaymentStartDate.format('YYYY-MM-DD'),
        payment_plan_value: this.state.recurrentPaymentAmount,
        payment_plan_period: this.state.recurrentPaymentPeriod,
        contract: CONTRACT_TYPES.SAVING_PLAN
      }
    } else {
      return undefined
    }
  }

  buttonClickSave = () => {

    if (this.state.recurrentPaymentEnabled && this.state.recurrentPaymentAmount <= 0) {
      this.setState({
        recurrentPaymentAmountError: true
      })
    } else {
      this.props.processInstrument(this.wrapData(), this.state.assetId !== undefined, this.wrapOneTimePaymentData(), this.wrapPaymentPlanData())
    }
  }

  hasInceptionDate = () => {
    return this.state.assetData && this.state.assetData.hasOwnProperty('inception_date') && this.state.assetData['inception_date']
  }

  renderHeading() {

    const { classes } = this.props;
    const asset = this.state.assetData;

    return (
      <Grid item sm={12}>
        <p className={classes.instrumentName}>
          {getInstrName(asset)}
        </p>
        <p className={classes.instrumentCodes}>
          ISIN/WKN: {asset.isin || '-'} / {asset.wkn || '-'}
        </p>
        <p className={classes.inceptionDate}>
          Auflagedatum: {this.hasInceptionDate() ? moment(asset['inception_date']).format('DD.MM.YYYY') : '-'}
        </p>
      </Grid>
    )
  }

  renderFooter() {

    const { classes } = this.props;

    return (
      <Grid item sm={12} className={classes.modalFooter}>
        <div className={classes.errorWrapper}>
          {/* TODO: need to replace English string */}
          {this.props.error ? this.props.error : ''}
        </div>
        <PrimaryButton
          classes={{button: classes.buttonSave}}
          text={'Hinzufügen'}
          disabled={
            !this.isDataReady()
            || this.state.priceLoading
            || this.state.paymentPlanPriceLoading
            || (!this.state.recurrentPaymentEnabled && !this.state.oneTimePaymentEnabled)
            || !this.state.assetEarliest}
          onButtonClick={this.buttonClickSave}
        />
      </Grid>
    )
  }

  renderOneTimePaymentForm() {

    const { classes } = this.props;
    const asset = this.state.assetData;

    return (
      <Grid item sm={12} container spacing={2}>
        <Grid item sm={12}>
          <Checkbox
            checked={this.state.oneTimePaymentEnabled}
            onChange={this.handleOneTimePaymentCheckBoxChange}
            color={'primary'}
            className={classes.radio}
          />
          <span className={!this.state.recurrentPaymentEnabled && classes.disabledLabel}> Einzelkauf </span>
        </Grid>
        <Grid item sm={3}>
          <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
            Buchungsdatum
          </span>
          <br />
          <DatePicker
            disabled={this.state.priceLoading || !this.state.oneTimePaymentEnabled}
            className={classes.inputField}
            value={this.state.assetPriceDate}
            inputVariant={'outlined'}
            format={'DD.MM.YYYY'}
            minDate={this.state.assetEarliest
              ? moment(this.state.assetEarliest)
              : undefined
            }
            maxDate={moment()}
            onChange={this.handleDateChange}
            disableFuture
            openTo="year"
            views={["year", "month", "date"]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon color={'primary'}/>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
            Preis
          </span>
          <br />
          {this.state.priceLoading ?
            <div className={classes.priceLoaderWrapper}>
              <LinearProgress color={'primary'}/>
            </div>
            :
            <span className={clsx(classes.paddedValue, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}>{this.formatNumber(this.state.assetPrice)} <span className={classes.unitsLabel}>EUR</span></span>
          }
        </Grid>
        <Grid item sm={3}>
          <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
            Rabatt
          </span>
          <br/>
          <TextField
            className={classes.inputField}
            variant={'outlined'}
            error={this.state.discountError}
            value={this.state.discount}
            onChange={this.handleDiscountChange}
            disabled={!asset.hasOwnProperty('product_fees') || !asset['product_fees'].hasOwnProperty('entry_cost') || this.state.priceLoading || !this.state.oneTimePaymentEnabled}
            InputProps={{
              placeholder: '0,00',
              endAdornment: (
                <InputAdornment position="end"> % </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={3}>
                  <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
                    Ausgabeaufschlag
                  </span>
          <br />
          <span className={clsx(classes.paddedValue, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}>{ this.formatPercentsNumber(asset['product_fees'] ? asset['product_fees']['entry_cost'] : undefined) }</span>
        </Grid>

        <Grid item sm={3}>
          <Radio
            checked={this.state.unitType === 'eur'}
            onChange={this.handleRadioChange}
            disableRipple={true}
            color="primary"
            value="eur"
            name="unit-type-switch"
            className={classes.radio}
            disabled={this.state.priceLoading || !this.state.oneTimePaymentEnabled}
          />
          <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
                    Anlagesumme*
                  </span>
          <br />
          <TextField
            className={classes.inputField}
            onChange={this.handleAssetValueInEurChange}
            variant={'outlined'}
            value={this.state.valueInEurToDisplay}
            disabled={this.state.unitType === 'stock' || this.state.priceLoading || !this.state.oneTimePaymentEnabled}
            error={this.state.valueInEurError}
            InputProps={{
              placeholder: '0,00',
              endAdornment: (
                <InputAdornment position="end"> <span className={classes.unitsLabel}>EUR</span> </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <Radio
            checked={this.state.unitType === 'stock'}
            onChange={this.handleRadioChange}
            color="primary"
            disableRipple={true}
            value="stock"
            name="unit-type-switch"
            className={classes.radio}
            disabled={this.state.priceLoading || !this.state.oneTimePaymentEnabled}
          />
          <span className={!this.state.oneTimePaymentEnabled && classes.disabledLabel}>
            Anteile*
          </span>
          <br />
          <TextField
            className={classes.inputField}
            variant={'outlined'}
            value={this.state.numberOfStocksToDisplay}
            disabled={this.state.unitType === 'eur' || this.state.priceLoading || !this.state.oneTimePaymentEnabled}
            onChange={this.handleStockNumberChange}
            error={this.state.numberOfStocksError}
            InputProps={{
              placeholder: '0,00',
              endAdornment: (
                <InputAdornment position="end"> <span className={classes.unitsLabel}>STÜCK</span> </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <span className={clsx(classes.textBold, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}>Bruttobetrag</span>
          <span className={clsx(classes.paddedValue, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}> {this.formatNumber(this.state.grossAmount)} <span className={classes.unitsLabel}>EUR</span></span>
        </Grid>
        <Grid item sm={3}>
          <span className={clsx(classes.textBold, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}>Betrag</span>
          <span className={clsx(classes.paddedValue, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}> {this.formatNumber(this.state.amount)} <span className={classes.unitsLabel}>EUR</span></span>
        </Grid>

        <Grid item sm={12} className={clsx(classes.explanation, !this.state.oneTimePaymentEnabled && classes.disabledLabel)}>*Wählen sie entweder Bruttobetrag oder Anteile als Buchungsbasis.</Grid>
      </Grid>
    )
  }

  renderPaymentPlanForm() {

    const { classes } = this.props;

    const dateError = this.state.recurrentPaymentStartDateError || this.state.paymentPlanPriceError;
    const maxDate = this.state.oneTimePaymentEnabled ? moment().add(1, 'years') : moment();

    return (
      <Grid item container spacing={2}>
        <Grid item sm={3}>
          <Checkbox
            checked={this.state.recurrentPaymentEnabled}
            onChange={this.handleCheckBoxChange}
            color={'primary'}
            className={classes.radio}
          />
          <span className={!this.state.recurrentPaymentEnabled && classes.disabledLabel}> Sparplan </span>
          <TextField
            disabled={!this.state.recurrentPaymentEnabled}
            value={this.state.recurrentPaymentAmount}
            className={classes.inputField}
            error={this.state.recurrentPaymentAmountError}
            variant={'outlined'}
            onChange={this.handleRecurrentAmountChange}
            InputProps={{
              placeholder: '0,00',
              endAdornment: (
                <InputAdornment position="end"> <span className={classes.unitsLabel}>EUR</span> </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <span className={clsx(!this.state.recurrentPaymentEnabled && classes.disabledLabel, classes.paddedLabel)}> Turnus </span>

          <Select
            id={'period-select'}
            disabled={!this.state.recurrentPaymentEnabled}
            className={classes.inputFieldSelect}
            variant={'outlined'}
            value={this.state.recurrentPaymentPeriod}
            onChange={this.handlePeriodChange}
          >
            {Object.entries(CONFIG).map(([code, config]) => <MenuItem value={code}> {config['title']} </MenuItem>)}
          </Select>

        </Grid>
        <Grid item sm={3}>
          <span className={clsx(!this.state.recurrentPaymentEnabled && classes.disabledLabel, classes.paddedLabel)}> Einzahlung ab </span>

          {this.state.paymentPlanPriceLoading
            ?
            <div className={classes.priceLoaderWrapper}>
              <LinearProgress color={'primary'}/>
            </div>
            :
            <DatePicker
              disabled={!this.state.recurrentPaymentEnabled}
              className={classes.inputField}
              value={this.state.recurrentPaymentStartDate}
              onChange={this.handlePaymentPlanChange}
              inputVariant={'outlined'}
              format={'DD.MM.YYYY'}
              minDate={this.state.assetEarliest
                ? moment(this.state.assetEarliest)
                : moment()
              }
              onError={this.handlePaymentPlanDateError}
              maxDate={maxDate}
              maxDateMessage={!!maxDate && formatDateShouldBeGraterThenErrorMessage(maxDate)}
              shouldDisableDate={this.filterWeekends}
              openTo="year"
              views={["year", "month", "date"]}
              error={!!dateError}
              helperText={dateError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon color={'primary'}/>
                  </InputAdornment>
                )
              }}
            />
          }
        </Grid>
        <Grid item sm={3} />
      </Grid>
    )
  }

  render() {

    const { classes } = this.props;
    const asset = this.state.assetData;

    return (
      <Dialog maxWidth='md'
              fullWidth
              open={this.props.open}
              onClose={(this.props.loading || this.props.instrumentCreating || this.state.priceLoading) ? null : this.props.onClose}
              scroll={'body'}>
        <IconButton aria-label="close" classes={{root: classes.closeButton}} onClick={this.props.onClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle className={classes.title}>
          Wertpapier hinzufügen (Kauf)
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          {asset &&
            <Grid container spacing={2}>
              {this.renderHeading()}
              {this.renderOneTimePaymentForm()}
              {this.renderPaymentPlanForm()}
              {this.renderFooter()}
            </Grid>
          }
        </DialogContent>
        {(this.props.loading || this.props.instrumentCreating) &&
          <ModalLoader withMsg={this.props.instrumentCreating}/>
        }
        <ErrorDialog
          open={this.state.showFailedAssetFetchPopup}
          onClose={this.closeFailedAssetFetch}
          message={
            <div className={classes.warningMessage}>
              <p>
                {this.state.failedAssetFetchError}
              </p>
            </div>
          }
        />
     </Dialog>
    )
  }
}

export default withStyles(styles)(ModalInstruments);
