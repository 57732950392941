const styles = theme => ({
  root: {
    paddingBottom: 5,
  },
  rating: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    paddingTop: 20,
    margin: 0,
  },
  item: {
    display: 'inline-block',
    width: 48,
    height: 48,
    fontSize: 24,
    lineHeight: '46px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#0092E5',
    border: '2px solid #0092E5',
    borderRadius: 5,
  },
  itemActive: {
    color: '#FFFFFF',
    backgroundColor: '#0092E5',
  },
  headline: {
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 16,
  },
  legend: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default styles;