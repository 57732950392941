import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  profileRiskContainer: {},
  profileRiskTitle: {
    fontSize: 14,
    margin: 0,
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C',
  },
  profileRiskNumber: {
    position: 'relative',
    padding: 0,
    margin: '15px 0 0',
    fontFamily: 'Roboto-Bold',
    color: '#B1B1B1 !important',
    fontSize: '28px !important',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  profileRiskValue: {
    fontSize: '44px !important',
    color: '#E1E3E9',
    marginRight: 8,

    [theme.breakpoints.down('sm')]: {
      fontSize: '60px !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px !important',
    }
  },
  infoText: {
    position: 'absolute',
    top: -6,
    left: -8,
    fontSize: '14px !important',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > span': {
      height: 30
    }
  },
  container: {
    '& > div:nth-last-child(2)': {
      paddingBottom: 15,
    },
    '& > div:last-child': {
      paddingTop: 15,
      borderTop: '2px solid #E1E3E9'
    },
  },
  tooltip: {
    fontSize: 12,
  }
})

export default makeStyles(styles)