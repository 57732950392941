import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  card: {
    width: "40%",
    height: "300px",
    maxWidth: "450px",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #D8DCDF",
    borderRadius: "5px",
  },
  title: {
    fontWeight: "bold",
    color: "#202A38",
    marginBottom: "10px"
  },
  text: {
    color: "#0D2440",
    fontSize: "16px"
  },
}));
