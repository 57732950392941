import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash'

import clsx from 'clsx';

/* Material-UI Components */
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import {setInStorage, removeFromStorage, getFromStorage} from "../../utils/storage";


/* BCA modules */
import styles from './styles';
import {
  AGGREGATED_ASSET_NAME,
  AGGREGATED_PORTFOLIO_NAME,
  formatPortfolioName
} from "../../utils/aggregated-portfolio";
import {getPortfolioMinStartOfInvestment, renderAssetsDropdownName} from "../../utils/utils";
import {renderPortfolioDropdownName} from "../../utils/utils";
import {Dialog, DialogContent, makeStyles} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Switch from "../AssetModal/components/Switch";

import {getProductName, productExists} from "../../containers/ProductComparison/utils";
import WarningTooltip from "../WarningTooltip";
import {SERVER_ERROR} from "../../utils/constants";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FORM_FIELD_HEIGHT } from '../../containers/TransactionsMonitoring/components/Filters/components/common_styles';
import SelectCaret from '../Icons/SelectCaret';

import {useDashboardPortfoliosSelectorContext} from "../../containers/CustomerDashboard/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4D4F5C',
    marginRight: 20,
    whiteSpace: 'nowrap',

    [theme.breakpoints.only('sm')]: {
      fontSize: 13
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 12
    },
  },
  expansionPannelContainer: {
    position: 'relative',
    height: FORM_FIELD_HEIGHT,
    width: '100%',
    background: 'white !important',
    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
}))


export const ListDropdown = withStyles(styles, {withTheme: true})((props) => {

  const {
    disabled,
    classes,
    theme,
    formatSelected,
    alwaysScrollableList,
    renderListItem,
    ButtonProps={},
    id,
    asDialog,
    dialogTitle
  } = props;

  const [expanded, setExpanded] = useState(props.expanded || false);

  React.useEffect(() => {
    props.onExpanded && props.onExpanded(expanded);
  }, [expanded])

  let node = React.useRef();

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClick, false);

    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  const handleClick = (event) => {

    if (asDialog) {
      return
    }

    if (!node.current || !node.current.contains((event.target))) {
      setExpanded(false);
    }
  };

  const onButtonClick = () => {
    ButtonProps.onClick && ButtonProps.onClick()
    setExpanded(false);
  }

  return (
    <>
      <ExpansionPanel
        ref={node}
        id={id}
        TransitionProps={{timeout: 0}}
        expanded={!asDialog && expanded}
        onChange={() => setExpanded(!expanded)}
        classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded
        }}
        disabled={disabled}
      >
        <ExpansionPanelSummary
          expandIcon={<SelectCaret style={{color: theme.palette.primary.main}} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          IconButtonProps={{
            disableRipple: true
          }}
          classes={{
            root: classes.expansionPanelSummaryRoot,
            expanded: classes.expansionPanelSummaryExpanded,
            content: classes.expansionPanelSummaryContent
          }}
        >
          <div className={classes.ellipsis}>
            {formatSelected()}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.scrollableList} classes={{
          root: classes.expansionPanelDetailsRoot,
        }}>
          {props.listControlsRender && props.listControlsRender()}
          <List style={{maxHeight: props.maxHeight || 'auto'}} className={clsx(classes.portfolioList, alwaysScrollableList && classes.alwaysScrollableList)}>
            {renderListItem()}
          </List>
          {ButtonProps.enabled &&
            <Button
              variant="contained"
              color="primary"
              className={classes.startButton}
              onClick={onButtonClick}
              disabled={ButtonProps.disabled}
            >
              {ButtonProps.title || 'Start'}
            </Button>
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {asDialog && (
        <Dialog
          open={expanded}
          onClose={() => setExpanded(false)}
          maxWidth={"xs"}
          fullWidth={true}
          classes={{
            paperFullWidth: classes.dialogFullWidth
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <span>{dialogTitle}</span>
            <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={() => setExpanded(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {props.listControlsRender && props.listControlsRender()}
            <List style={{maxHeight: props.maxHeight || 'auto'}} className={clsx(classes.portfolioList, classes.portfolioListDialog, alwaysScrollableList && classes.alwaysScrollableList)}>
              {renderListItem()}
            </List>
          </DialogContent>
          <DialogActions>
            {ButtonProps.enabled &&
              <Button
                variant="contained"
                color="primary"
                className={classes.startButton}
                onClick={onButtonClick}
                disabled={ButtonProps.disabled}
              >
                {ButtonProps.title || 'Start'}
              </Button>
            }
          </DialogActions>
        </Dialog>
      )}
    </>
  )
})

export const ComparisonProductsDropdown = withStyles(styles, {withTheme: true})((props) => {
  const {
    products,
    onSelectedProductsChange,
    classes,
    disabled
  } = props;


  const dropdownWrapperClasses = useStyles()

  const [selectedProductsInternal, setSelectedProductsInternal] = React.useState([]);

  React.useEffect(() => {
    setSelectedProductsInternal(_.cloneDeep(products))
  }, [products]);

  const handleSelectedProductChange = (product) => {

    return setSelectedProductsInternal((current) => current.map((_product) => {
      if (_product.product_id == product.product_id) {
        _product.configuration.active = !_product.configuration.active;
      }
      return _product;
    }))

  }
  const handleButtonClick = () => onSelectedProductsChange && onSelectedProductsChange(selectedProductsInternal)
  const handleHighlightIconClick = (event, product) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedProductsInternal((current) => {
      return current.map((currentProduct) => {
        currentProduct.configuration.highlighted = currentProduct.product_id == product.product_id
          && !currentProduct.configuration.highlighted;
        return currentProduct;
      });
    })
  }

  const renderListItem = (product) => {
    const isChecked = product.configuration.active;
    return (
      <React.Fragment key={product.product_id}>
        <ListItem
          className={clsx(classes.portfolioListItem, classes.productsComparisonListItem)}
          onClick={() => productExists(product) && handleSelectedProductChange(product)}
          style={{display: 'flex', justifyContent: 'space-between'}}
          disabled={!productExists(product)}
        >
          <span>
            <Checkbox
              checked={isChecked}
              value="checkedB"
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
              classes={{
                checked: classes.portfolioListCheckboxChecked,
                root: classes.portfolioListCheckbox
              }}
              disableRipple
              disableFocusRipple
              className={classes.portfolioListCheckbox}
              disabled={!productExists(product)}
            />
            <span className={classes.ellipsis} style={{whiteSpace: 'normal'}}>
              {getProductName(product)}
            </span>
          </span>
          <i className={`fa ${product.configuration.highlighted ? "fa-star":"fa-star-o"} fa-lg`} onClick={(event) => productExists(product) && handleHighlightIconClick(event, product)}/>
        </ListItem>
      </React.Fragment>
    )
  };

  return (
    <div
      className={clsx(dropdownWrapperClasses.container)}
    >
      <span className={dropdownWrapperClasses.label}>Produktauswahl:</span>
      <div className={
        dropdownWrapperClasses.expansionPannelContainer
      }>
        <ListDropdown
          id="assets-list"
          formatSelected={() => renderAssetsDropdownName(selectedProductsInternal.filter((product) => product.configuration.active), products, false)}
          renderListItem={() => (
            <>
              {selectedProductsInternal.map(asset => {
                return renderListItem(asset)
              })}
            </>
          )}
          alwaysScrollableList={true}
          disabled={disabled}
          ButtonProps={{
            enabled: true,
            onClick: handleButtonClick,
            disabled: _.filter(selectedProductsInternal, (product) => product.configuration.active).length == 0
          }}
        />
      </div>
    </div>
  )
})

export const AssetsListDropdown = withStyles(styles, {withTheme: true})((props) => {
  const {
    assets,
    selectedAssets,
    onSelectedAssetChange,
    classes,
    disabled
  } = props;

  const AggregatedAssetsOption = {
    id: 0,
    name: AGGREGATED_ASSET_NAME
  }

  const dropdownWrapperClasses = useStyles()

  const [selectedAssetsInternal, setSelectedAssetsInternal] = React.useState([]);

  React.useEffect(() => {
    let _selectedAssetsInternal = selectedAssets || [];
    if (selectedAssets && selectedAssets.length > 1 && selectedAssets.length === assets.length) {
      _selectedAssetsInternal = [AggregatedAssetsOption, ..._selectedAssetsInternal];
    }

    setSelectedAssetsInternal(_selectedAssetsInternal)
  }, [selectedAssets]);

  const dropdownOptions = React.useMemo(() => {
    return assets.length > 1 ? [AggregatedAssetsOption, ...assets] : assets;
  }, [JSON.stringify(assets)])

  const handleSelectedAssetChange = (asset) => {

    const isAggregatedClicked = !asset.id;
    const isSelected = selectedAssetsInternal
      .findIndex(_asset => _asset.id === asset.id) === -1;

    if (isAggregatedClicked) {
      return setSelectedAssetsInternal(isSelected ? [...dropdownOptions] : []);
    }

    if (isSelected) {
      if (selectedAssetsInternal.length === dropdownOptions.length - 2) {
        return setSelectedAssetsInternal([...dropdownOptions]);
      } else {
        return setSelectedAssetsInternal([asset, ...selectedAssetsInternal]);
      }
    } else {
      return setSelectedAssetsInternal(selectedAssetsInternal.filter(_asset => {
        return _asset.id !== asset.id && _asset.id;
      }));
    }
  }

  const handleButtonClick = () => onSelectedAssetChange && onSelectedAssetChange(selectedAssetsInternal.filter((asset) => !!asset.id))

  const renderListItem = (asset) => (
    <React.Fragment key={asset.id}>
      <ListItem
        className={classes.portfolioListItem}
        onClick={() => handleSelectedAssetChange(asset)}
      >
        <Checkbox
          checked={selectedAssetsInternal.map(
            asset => asset.id).includes(asset.id)}
          value="checkedB"
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
          classes={{
            checked: classes.portfolioListCheckboxChecked,
            root: classes.portfolioListCheckbox
          }}
          disableRipple
          disableFocusRipple
          className={classes.portfolioListCheckbox}
        />
        <span>
            {formatPortfolioName(asset.name)}
        </span>
      </ListItem>
    </React.Fragment>
  );

  return (
    <div
      className={clsx(dropdownWrapperClasses.container)}
    >
      <span className={dropdownWrapperClasses.label}>Produktauswahl:</span>
      <div className={
        dropdownWrapperClasses.expansionPannelContainer
      }>
        <ListDropdown
          id="assets-list"
          formatSelected={() => renderAssetsDropdownName(selectedAssetsInternal, assets)}
          renderListItem={() => (
            <>
              {dropdownOptions.map(asset => {
                return renderListItem(asset)
              })}
            </>
          )}
          alwaysScrollableList={true}
          disabled={disabled}
          ButtonProps={{
            enabled: true,
            onClick: handleButtonClick,
            disabled: selectedAssetsInternal.length === 0
          }}
        />
      </div>
    </div>
  )
})

export const ProductsComparisonPortfolioListDropDown = withStyles(styles, {withTheme: true})((props) => {
  const {
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    handleSelectedPortfolioChanged,
    classes,
    onExpanded,
  } = props;

  const [selectedPortfoliosInternal, setSelectedPortfoliosInternal] = React.useState([]);
  const [aggregateSelectedPortfolios, setAggregateSelectedPortfolios] = React.useState(false);
  const renderPlaceholder = () => {
    let array = [];

    for (let i = 0; i < 5; i++) {
      array.push((
        <ListItem key={i} className={classes.portfolioListItemPlaceholder}>
          <div className={classes.itemPlaceholder}>
          </div>
        </ListItem>)
      )
    }
    return array;
  };

  const aggregateDisabled = selectedPortfoliosInternal.length <= 1;

  React.useEffect(() => {
    // when aggregateDisabled -> switch OFF / when it's enabled -> switch ON by default
    setAggregateSelectedPortfolios(!aggregateDisabled)
  }, [aggregateDisabled]);

  const handlePortfolioSelected = (clickedPortfolio) => {
    const isAggregatedClicked = !clickedPortfolio.depotNumber;
    const isSelected = selectedPortfoliosInternal
      .findIndex(portfolio => portfolio.id === clickedPortfolio.id) === -1;

    if (isAggregatedClicked) {
      return handlePortfolioChanged(isSelected ? [...portfolios] : []);
    }

    if (isSelected) {
      return handlePortfolioChanged([clickedPortfolio, ...selectedPortfoliosInternal])
    } else {
      return handlePortfolioChanged(selectedPortfoliosInternal.filter(portfolio => {
        return portfolio.id !== clickedPortfolio.id && portfolio.depotNumber;
      }));
    }
  };

  const handlePortfolioChanged = (portfolios) => {
    setSelectedPortfoliosInternal(portfolios)
  }

  const handleStartClick = () => {
    if (handleSelectedPortfolioChanged) {
      handleSelectedPortfolioChanged(aggregateSelectedPortfolios ? [selectedPortfoliosInternal] : selectedPortfoliosInternal);
    }
  };

  const handleAggregateSelectedChange = () => {
    setAggregateSelectedPortfolios((current) => !current)
  }

  const renderListItem = (portfolio) => (
    <React.Fragment key={portfolio.id}>
      <ListItem
        className={classes.portfolioListItem}
        onClick={() => handlePortfolioSelected(portfolio)}
      >
        <Checkbox
          checked={selectedPortfoliosInternal.map(
            portfolio => portfolio.depotNumber).includes(portfolio.depotNumber)}
          value="checkedB"
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
          classes={{
            checked: classes.portfolioListCheckboxChecked,
            root: classes.portfolioListCheckbox
          }}
          disableRipple
          disableFocusRipple
          className={classes.portfolioListCheckbox}
        />
        <span>
            {formatPortfolioName(portfolio.name)}
        </span>
      </ListItem>
    </React.Fragment>
  );

  return (
    <ListDropdown
      asDialog
      dialogTitle="Depotauswahl"
      id="portfolio-list"
      formatSelected={() => portfoliosDataLoading ? "" : renderPortfolioDropdownName(selectedPortfoliosInternal, portfolios)}
      renderListItem={() => (
        <>
          {portfoliosDataLoading ? (
            <React.Fragment>
              {renderPlaceholder()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {portfolios && !portfoliosLoadingError ? (
                <React.Fragment>
                  {portfolios.length > 0 ? (
                    <>
                      {portfolios.map(portfolio => {
                        return renderListItem(portfolio)
                      })}
                    </>
                  ) : (
                    <ListItem
                      className={classes.portfolioListItem}
                      style={{justifyContent: 'center'}}
                    >
                      <span>
                        Kein aktives Depot verfügbar
                      </span>
                    </ListItem>
                  )}
                </React.Fragment>
              ) : (
                <ListItem
                  className={classes.portfolioListItem}
                  style={{justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}
                >
                  <i className={clsx(classes.errorIcon, 'fa fa-exclamation')} />
                  <span style={{color: '#63696F',}}>{SERVER_ERROR}</span>
                </ListItem>
              )}
            </React.Fragment>
          )}
        </>
      )}
      listControlsRender={() => (
        <>
          {!portfoliosDataLoading && !portfoliosLoadingError && portfolios && portfolios.length > 1 && (
            <ListItem
              className={classes.portfolioListItemAggregateSelectedCheckbox}
            >
              <Switch
                value={aggregateSelectedPortfolios}
                handleValueChanged={handleAggregateSelectedChange}
                disabled={aggregateDisabled}
                customClasses={{
                  container: classes.aggregatePortfoliosSwitchContainer,
                  thumb: classes.aggregatePortfoliosSwitchThumb,
                  label: classes.aggregatePortfoliosSwitchLabel
                }}
                // onChange={handleChange}
                // classes={classes}
              />
              <span>Kumuliert hinzufügen</span>
              <WarningTooltip
                title={"Setzen Sie diesen Schalter auf “Ein”, werden die ausgewählten Depots als ein kombiniertes “Gesamtdepot” dem Vergleich hinzugefügt."}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)} />}
                width={600}
              />
            </ListItem>
          )}
        </>
      )}
      onExpanded={onExpanded}
      alwaysScrollableList={true}
      maxHeight={230}
      ButtonProps={{
        enabled: true,
        onClick: handleStartClick,
        disabled: selectedPortfoliosInternal.length === 0,
        title: 'Hinzufügen'
      }}
    />
  )
});

const PortfolioListDropDown = (props) => {
  const {
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    selectedPortfolios,
    handleSelectedPortfolioChanged,
    classes,
    alwaysScrollableList,
    isVirtual,
    loading,
    isListAutoUpdate,
    onExpanded,
    placeholder
  } = props;

  const shared = useDashboardPortfoliosSelectorContext();

  const [selectedPortfoliosInternal, setSelectedPortfoliosInternal] = React.useState([]);

  let sessionCustomerId = getFromStorage('customer_id');

  let selectedPortfoliosPrefix = 'selected_portfolios_'
  let selectedTrackingDatePrefix = 'selected_tracking_date_';

  if (isVirtual) {
    selectedPortfoliosPrefix = 'selected_virtual_portfolios_'
  }

  let sessionSelectedPortfoliosKey = selectedPortfoliosPrefix.concat(sessionCustomerId);
  let sessionSelectedTrackingDateKey = selectedTrackingDatePrefix.concat(sessionCustomerId);

  React.useEffect(() => {
    const _selectedPortfolios = !!selectedPortfolios ? selectedPortfolios : [];
    setSelectedPortfoliosInternal(_selectedPortfolios);
    shared.onSelectedPortfoliosChanged && shared.onSelectedPortfoliosChanged(_selectedPortfolios)
  }, [selectedPortfolios]);

  React.useEffect(() => {
    setSelectedPortfoliosInternal(!!shared.selectedPortfolios ? shared.selectedPortfolios : []);
  }, [shared.selectedPortfolios])

  const renderPlaceholder = () => {
    let array = [];

    for (let i = 0; i < 5; i++) {
      array.push((
        <ListItem key={i} className={classes.portfolioListItemPlaceholder}>
          <div className={classes.itemPlaceholder}>
          </div>
        </ListItem>))
    }
    return array;
  };

  const handlePortfolioSelected = (clickedPortfolio) => {
    const isAggregatedClicked = !clickedPortfolio.depotNumber;
    const isSelected = selectedPortfoliosInternal
      .findIndex(portfolio => portfolio.id === clickedPortfolio.id) === -1;

    if (isAggregatedClicked) {
      return handlePortfolioChanged(isSelected ? [...portfolios] : []);
    }

    if (isSelected) {
      if (selectedPortfoliosInternal.length === portfolios.length - 2) {
        return handlePortfolioChanged([...portfolios]);
      } else {
        return handlePortfolioChanged([clickedPortfolio, ...selectedPortfoliosInternal]);
      }
    } else {
      return handlePortfolioChanged(selectedPortfoliosInternal.filter(portfolio => {
        return portfolio.id !== clickedPortfolio.id && portfolio.depotNumber;
      }));
    }
  };

  const handlePortfolioChanged = (portfolios) => {
    if(isListAutoUpdate && handleSelectedPortfolioChanged)
    {
      handleSelectedPortfolioChanged(portfolios)
    }
    setSelectedPortfoliosInternal(portfolios);
    shared.onSelectedPortfoliosChanged && shared.onSelectedPortfoliosChanged(portfolios)
  }

  const handleStartClick = () => {
    if (handleSelectedPortfolioChanged) {
      handleSelectedPortfolioChanged(selectedPortfoliosInternal);
      if (!selectedPortfoliosInternal.map(portfolio => portfolio.depotNumber).includes(0)) {
        let minTrackingDate = getPortfolioMinStartOfInvestment(selectedPortfoliosInternal);
        setInStorage(sessionSelectedPortfoliosKey, selectedPortfoliosInternal);
        setInStorage(sessionSelectedTrackingDateKey, minTrackingDate.format('YYYY-MM-DD'))

      } else {
        removeFromStorage(sessionSelectedPortfoliosKey);
        removeFromStorage(sessionSelectedTrackingDateKey);
      }
    }
  };

  const renderListItem = (portfolio) => (
    <React.Fragment key={portfolio.id}>
      <ListItem
        className={classes.portfolioListItem}
        onClick={() => handlePortfolioSelected(portfolio)}
      >
        <Checkbox
          checked={selectedPortfoliosInternal.map(
            portfolio => portfolio.depotNumber).includes(portfolio.depotNumber)}
          value="checkedB"
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
          classes={{
            checked: classes.portfolioListCheckboxChecked,
            root: classes.portfolioListCheckbox
          }}
          disableRipple
          disableFocusRipple
          className={classes.portfolioListCheckbox}
        />
        <span>
            {formatPortfolioName(portfolio.name)}
        </span>
      </ListItem>
    </React.Fragment>
  );

  return (
    <ListDropdown
      id="portfolio-list"
      formatSelected={() => portfoliosDataLoading ? placeholder || "" : renderPortfolioDropdownName(selectedPortfoliosInternal, portfolios, isVirtual, placeholder)}
      renderListItem={() => (
        <>
          {portfoliosDataLoading ? (
            <React.Fragment>
              {renderPlaceholder()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {portfolios && !portfoliosLoadingError ? (
                <React.Fragment>
                  {portfolios.map(portfolio => {
                    return renderListItem(portfolio)
                  })}
                </React.Fragment>
              ) : (
                <p>Error</p>
              )}
            </React.Fragment>
          )}
        </>
      )}
      disabled={loading}
      onExpanded={onExpanded}
      alwaysScrollableList={alwaysScrollableList}
      ButtonProps={{
        enabled: !isListAutoUpdate,
        onClick: handleStartClick,
        disabled: loading || selectedPortfoliosInternal.length === 0
      }}
    />
  )
};

export default withStyles(styles, {withTheme: true})(PortfolioListDropDown)