import React from "react";
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux'

import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";

import UserInfo from "../../../../../../components/ProfileDetails/components/UserInfo";

import useStyles from "./styles";
import clsx from "clsx";

import DashboardSettingsWindow from '../../../DashboardSettingsWindow';
import DashboardSettingsWindowV2 from '../../../DasboardSettingWindowV2';
import moment from 'moment';
import {
  BEGINNING_PERFORMANCE_TIME_TYPE_VALUE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE,
  PERFORMANCE_TIME_TYPE
} from '../../../../../../utils/constants';
import {getDashboardSettingsType} from "../../../../utils";
import {validateSelectedRangeBusinessDates} from "../../../../../../utils/utils";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

const CustomerListItem = (props) => {
  const classes = useStyles();

  const {
    customer,
    customerLoadingError,
    handleCustomerSettingsChanged,
    loading,
    updatedCustomer,
    key,
    dashboardType,
    resetSettings,
    newDesign,
    singleView,
  } = props;

  const DashboardSettingsWindowComponent = newDesign ? DashboardSettingsWindowV2 : DashboardSettingsWindow;

  let customerSettings = updatedCustomer || customer && customer.settings || {};

  const [dateRange, setDateRange] = React.useState({
    start: undefined,
    end: undefined
  });

  const [snackbarSettings, setSnackbarSettings] = React.useState({
    open: false,
    variant: 'success',
    message: ''
  });
  const handleSnackbarVisibility = (state) => {
    setSnackbarSettings({
      ...snackbarSettings,
      open: state
    })
  };

  const handleSettingUpdate = (fieldname) => (value) => {
    handleCustomerSettingsChanged(customer, fieldname, value);
  }

  const handleDateRangeChanged = (dates, datesType) => {
    handleCustomerSettingsChanged(customer, 'date_updates', {
      date_range: datesType && datesType.value,
      date_range_start_date: moment(dates.start).format('YYYY-MM-DD'),
      date_range_end_date: moment(dates.end).format('YYYY-MM-DD'),
    });

    let newRange;
    if(datesType && datesType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value){
      newRange = {
        start: moment(dates.start),
        end: moment(dates.end)
      };
    } else {
      newRange = dates;
    }
    setDateRange(newRange)
    return newRange
  }

  const prepareDates = (customer) => {
    let init_data;
    if(customer.date_range === PERFORMANCE_TIME_TYPE.CUSTOM.value.toString()) {
       init_data = {
        start: moment(customer.date_range_start_date),
        end: moment(customer.date_range_end_date),
      }
    }
    else if (customer.date_range) {
      const performanceTimeType = Object.values(PERFORMANCE_TIME_TYPE).find(item => item.value == customer.date_range);
      init_data = performanceTimeType.getDateRange();
      validateSelectedRangeBusinessDates(
        init_data, performanceTimeType.value,
        [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE]);
    }
    else {
      init_data = {
        start: undefined,
        end: undefined
      }
    }
    setDateRange(init_data)
  }

  const updateBenchmarkSwitchState = (forceShow) => {
    handleSettingUpdate('benchmark_updates')({
      benchmarks: customerSettings.benchmarks,
      benchmark_enabled: !!forceShow
    })
  };
  const handleBenchmarkUpdate = (benchmarks, enabled) => {
    const preparedBenchmarksData = benchmarks.map(benchmark => ({
      ...benchmark,
      settings_id: customer.settings.id,
      benchmark_id: benchmark.id,
      percentage: benchmark.weight
      }
    ));

    handleSettingUpdate('benchmark_updates')({
      benchmarks: preparedBenchmarksData,
      benchmark_enabled: !!enabled
    })
  }

  React.useEffect(() => {
    if(customer && customer.settings){
      prepareDates(customer.settings)
    }
  }, []);

  const settingsComponent = (
    <DashboardSettingsWindowComponent
      settings={customerSettings}
      handleSettingUpdate={handleSettingUpdate}
      handleDateRangeChanged={handleDateRangeChanged}
      dateRange={dateRange}
      loading={loading}
      snackbarSettings={snackbarSettings}
      handleSnackbarVisibility={handleSnackbarVisibility}
      benchmarks={customerSettings.benchmarks}
      showBenchmark={customerSettings.benchmark_enabled}
      updateBenchmarkSwitchState={updateBenchmarkSwitchState}
      handleBenchmarkUpdate={handleBenchmarkUpdate}
      customerFullName={customer.customer_full_name}
      settingsType={ // TODO
        getDashboardSettingsType(dashboardType)
      }
      disableCopySettings={props.disableCopySettings || !_.isNil(customer.groups)}
      resetSettings={resetSettings}
      customer_id={customer.customer_id}
      singleView={singleView}
    />
  );


  if(singleView) return settingsComponent;

  return (
    <Grid item className={classes.listItemContainer}>
      <Card className={classes.profileCard}>
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item container lg={3} md={12} sm={12} xs={12}>
              <Grid className={clsx(classes.userInfoContainer, !_.isNil(customerSettings.id) && classes.userInfoChangedContainer)}>
                <div className={clsx(loading && 'loading-line')}>
                  <UserInfo
                    customer={customer}
                    customerLoadingError={customerLoadingError}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item lg={9} md={12} sm={12} style={{borderLeft: '1px solid #DADEE1',}}>
              <div key={key}>
                {settingsComponent}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default connect(mapStateToProps)(withRouter(CustomerListItem));