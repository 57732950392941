import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {InteractService} from "../../services";
import {
  getStepAnswer,
  getUID,
  isQuestionValid,
  showQuestionRequiredNote,
  validateCustomer,
  scrollToFirstElementOfClass,
  checkMinor,
  isESGEnabled
} from "../../utils";
import {brokerLogout} from "../../../Authentication/Auth/utils";
import Snackbar from "../../../../components/Snackbar";
import Navigation from "../Navigation";
import {ESGProfileService} from "../../esg_profile_service";
import {CustomerService} from "../../customer_step_service";
import {ProductsService} from "../../products_step_service";
import {FinalizeService} from '../../finalize_step_service'
import {QuestionnairesHandlerResource} from "../../../../utils/api";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../InvestmentPlatform/utils'
import { ERROR_MESSAGES_TRANSLATIONS } from './constants'
import useStyles from './styles'

import {SERVER_ERROR} from '../../../../utils/constants'
import ServiceConceptSelection from "../ServiceConceptSelection";
import {SERVICE_CONCEPTS, SERVICE_CONCEPTS_NAMES} from "../../../Trades/constants";
import {ExAnteService} from "../../exante_constants";
import {PrimaryButton} from "../../../../components/Buttons";
import {ArrowBack} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import {displayWarningSnackBar} from "../../../../components/SnackbarProvider/actions";
import {setRefreshHeaderTrigger} from "../../../InvestmentPlatform/actions";
import {
  CONFIRM_REMOVE_MSG,
  COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS,
  OBJECT_TYPES,
  USER_CONFIRMATION_STEP_IDENTIFIER, SNACKBAR_DURATION_IN_SEC
} from "../../constants";
import ConfirmationDialog from "../../../VirtualPortfolioManager/modals/ConfirmationDialog";

let currService;

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const RiskProfilingDataProvider = WrappedComponent => {
  return withRouter(connect(mapStateToProps)(function dataProvider(props) {
    const {
      match: {
        params: {
          customer_id
        },
        url
      },
      auth: {
        user
      },
      history
    } = props;

    const classes = useStyles()

    const _getStepService = (onboarind_step_uid) => {
      return menuItems.find(menu => menu.uid === onboarind_step_uid);
    };

    const _getStepAnswer = (onboarind_step_uid, step_uid, question_uid, withOption) => {
      const menu = _getStepService(onboarind_step_uid);
      if(menu){
        return menu.getStepAnswer(step_uid, question_uid, withOption);
      }
    };

    let esgEnabled = isESGEnabled();

    var handleCreateAccountClick = (validate, raiseExceptions=true) => {
      let data = {customer_id: customer_id, onboarding_uid: currService.onboarding_uid, validate: !!validate};
      menuItems.map(menuItem => {
        const userData = menuItem.getDataForAccount() || {};
        data = {...data, ...userData}
      });

      // console.log(data);
      return QuestionnairesHandlerResource.at('create-account/')
        .post(data).then((response) => response)
        .catch((error) => {
          if (raiseExceptions) {
            throw error
          }

          return {
            error
          }
        });
    };

    const handleValidateAccountClick = (raiseExceptions=false) => {
      return handleCreateAccountClick(true, raiseExceptions);
    };

    const handleWarnings = (warnings) => {
      const warnMsg = currService.parsePageWarnings(warnings);
      if(warnMsg && _.isString(warnMsg)){
        props.dispatch(displayWarningSnackBar(warnMsg, SNACKBAR_DURATION_IN_SEC));
      }
    };

    const [menuItems, setMenuItems] = React.useState([
      new InteractService('risk_profile', 'Risikoprofilierung', esgEnabled ? "Weiter zum Nachhaltigkeitsprofil" : "Weiter zur Produktauswahl",
        null, _getStepAnswer),
      ...(
          esgEnabled ? [
              new ESGProfileService('esg_profile', 'NACHHALTIGKEITSPROFIL', "Weiter zur Produktauswahl",
                  <>
                    Das Nachhaltigkeitsprofil des Kunden wurde erfolgreich gespeichert. Sie können diese Informationen
                    auch unter Meine Kunden ansehen und bearbeiten.
                  </>, _getStepAnswer)
          ] : []
      ),
      new ProductsService('products_selection', 'Produktauswahl', "Weiter zum Ex-Ante Kostenausweis",
        <>
          Die Produktauswahl wurde erfolgreich gespeichert.
        </>,
        _getStepAnswer, handleValidateAccountClick, handleWarnings),
      new ExAnteService('ex_ante_cost', 'Ex-Ante Kosten', customer_id, _getStepService),
      new CustomerService('user_data', 'Kundendaten', "Weiter zu Aufklärung & Protokoll",
        <>
          Die Kundendaten wurden erfolgreich gespeichert. Fahren Sie fort mit Aufklärung &
          Protokoll um den Prozess erfolgreich abschließen zu können.
        </>,
        _getStepAnswer, handleValidateAccountClick),
      new FinalizeService('protocol', 'Aufklärung & Protokoll', 'Zum Kundenprofil',
        <>
          Sie haben den Prozess erfolgreich abgeschlossen.
        </>,
        _getStepAnswer, handleCreateAccountClick)
    ]);

    const [step, setStep] = React.useState(null);
    const [progress, setProgress] = React.useState(null);
    const [isLast, setIsLast] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [currMenu, setMenu] = React.useState(0);
    const [onboardingData, setOnboardingData] = React.useState(null);
    const [latestFinished, setLatestFinished] = React.useState(null);
    const [riskProfile, setRiskProfile] = React.useState(null);
    const [serviceConcept, setServiceConcept] = React.useState(null);

    const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())
    const [resetSessionModalOpen, setResetSessionModalOpen] = React.useState(false);
    const [deleteSessionModalOpen, setDeleteSessionModalOpen] = React.useState(false);

    React.useEffect(() => {
      currService = undefined;
      initData().catch(handleError);
    }, []);

    React.useEffect(() => {

      const _getStepAnswer = (onboarind_step_uid, step_uid, question_uid, withOption, asQuestion) => {

        const menu = menuItems.find(menu => menu.uid === onboarind_step_uid);
        if(menu){
          return menu.getStepAnswer(step_uid, question_uid, withOption, asQuestion);
        }
      };

      menuItems.forEach(item => {
        item._getPrevStepAnswer = _getStepAnswer
        if(item.uid === 'protocol'){
          item._createAccountFunc = handleCreateAccountClick;
        } else if(['user_data', 'products_selection', 'custody_certificate'].includes(item.uid)){
          item._validateAccountFunc = handleValidateAccountClick;
        }
      })
    }, [menuItems])

    const initStepData = async () => {
      let currentStep = currMenu;
      if (onboardingData.current_step){
        const menuIdx = menuItems.findIndex(i => i.uid === onboardingData.current_step);
        if(menuIdx !== -1){
          setMenu(menuIdx);
          currentStep = menuIdx;
          for(let i = 0; i < currentStep; i++) {
            // we need to init all prev steps with data from Onboarding
            await initService(i, onboardingData, true);
          }
        }
      }
      await _setCurrService(currentStep, onboardingData, false, onboardingData.last_finished_sub_step);
      setLoading(false);
    };

    React.useEffect(() => {
      props.dispatch(setRefreshHeaderTrigger());
    }, [currMenu]);

    React.useEffect(() => {
      if(serviceConcept){
        initStepData().then(() => {
          props.dispatch(setRefreshHeaderTrigger());
        }).catch(handleError);
      }
    }, [serviceConcept]);

    React.useEffect(() => {

      validateQuestions(true)

    }, [JSON.stringify(step)])

    React.useEffect(() => {
      menuItems.forEach(item => {
        if (['risk_profile', 'user_confirmation'].includes(item.uid)) {
          item.onLegalDocumentsButtonClick = onDownloadLegalDocumentClick
          item._syncQuestions()
        }
      })
    }, [step])

    const initService = async (idx, onboarding_data, initOnly, isBack, lastFinishedStep) => {
      let prevService = currService
      currService = menuItems[idx];
      if(isBack) {
        currService.finished = false;
        if(currService.step && currService.step.uid === 'congrats-step'){
          // as there is no back button in congrats step
          await currService.prevStep();
        }
        // store current step
        await currService.sendCurrentStep();

        return;
      }
      currService.customer_id = customer_id;
      currService.user = user;
      currService.serviceConcept = serviceConcept
      currService.customer_type = prevService && prevService.customer_type
      currService.bank = prevService && prevService.bank
      currService.members = prevService && prevService.members
      currService.current_customer = prevService && prevService.current_customer
      if(serviceConcept){
        const lastProcess = (latestFinished || []).find(l => l.service_concept == serviceConcept);
        currService.prevProcessAnswers = lastProcess && lastProcess.onboarding_answers;
      }
      if(onboarding_data){
        // TODO: store ids / answers globally
        currService.onboarding_uid = onboarding_data.session_id;
        if(currService.uid !== 'user_confirmation') {
          // User Confirmation step is stored on our side - so do not set Interact session
          currService.session_id = onboarding_data.risk_session;
        }
        if(currService.uid === 'risk_profile' && serviceConcept === SERVICE_CONCEPTS.Anlageberatung) {
          currService.srri = null;
          if (!currService.session_id && riskProfile) {
            currService.session_id = riskProfile.session_id;
            currService.srri = riskProfile.srri;
          }
        }
        // set final SRRI to answers
        if (onboarding_data.srri) {
          onboarding_data.onboarding_answers.srri = onboarding_data.srri;
        }
      }

      // TODO: Remove session id usage from onboarding/trading
      // User Confirmation step is stored on our side - so do not set Interact session
      if(!currService.session_id && currService.uid !== 'user_confirmation') {
        currService.session_id = prevService && prevService._session_id;
      }

      await currService.initQuestionnaire(onboarding_data && onboarding_data.onboarding_answers, initOnly, lastFinishedStep);
      if(currService.warning){
        props.dispatch(displayWarningSnackBar(currService.warning));
        delete currService.warning;
      }
    };

    const _setCurrService = async (idx, onboarding_data, isBack, lastFinishedStep) => {
      if(_.isNumber(idx)) {
        setLoading(true);
        try {
          await initService(idx, onboarding_data, false, isBack, lastFinishedStep);
          await updateState();
          setLoading(false);
        } catch (error) {
          handleError(error);
        }
      }
    };

    const updateState = async () => {
      if(currService.finished) {
        await _nextMenu();
      }
      setStep(currService.step);
      setIsLast(currService.isLastStep);
      setProgress(currService.progress);
    };

    async function onDownloadLegalDocumentClick() {
      if (validateQuestions()) {
        try {
          setLoading(true);
          await currService.nextStep(true);
          setLoading(false);
          return true
        } catch (error) {
          handleError(error);
          return false
        }
      } else {
        return false
      }
    }

    const updateServiceConcept = (sc) => {
      // update menu items according to service concept
      setMenuItems(prevMenuItems => {
        if (sc === SERVICE_CONCEPTS.ExecutionOnly){
          const filtered = prevMenuItems.filter(item => item.uid !== 'esg_profile');
          // replace existing service with User Confirmation
          return filtered.map(menu => {
            if (menu.uid === 'risk_profile') {
              menu = new InteractService('user_confirmation', 'Stammdaten',
                null, null, _getStepAnswer);
            }

            return menu;
          })
        } else if (sc === SERVICE_CONCEPTS.Anlagevermittlung) {
          // replace existing service with Knowledge and Expiriense
          const newMenus = prevMenuItems.map(menu => {
            if (menu.uid === 'risk_profile') {
              menu = new InteractService('risk_profile', 'Kenntnisse & Erfahrungen',
                "Weiter zur Produktauswahl", null, _getStepAnswer);
              menu.is_investment_questionnaire = true;
            }

            return menu;
          });

          // add User Confirmation menu as first step
          return [
            new InteractService('user_confirmation', 'Stammdaten',
                null, null, _getStepAnswer),
            ...newMenus.filter(item => item.uid !== 'esg_profile')
          ]
        }

        return prevMenuItems;
      });

      setServiceConcept(sc);
    };

    const initData = async () => {
      setLoading(true);
      if(!customer_id) brokerLogout();

      // get customer details - to check if customer exists
      const customer = await validateCustomer(customer_id, props.dispatch);
      const custodyService = checkMinor(customer, props.dispatch);
      if(custodyService){
        setMenuItems(prevMenuItems => {
          return [custodyService, ...prevMenuItems];
        })
      }

      const onboarding = await QuestionnairesHandlerResource.getLatestSession(customer_id);
      setOnboardingData(onboarding);
      try {
        let res = await QuestionnairesHandlerResource.at(`${customer_id}/get-last-srri/`).get();
        setRiskProfile(res);
      } catch (e) {
        // risk profile doesn't exist
      }
      try {
        let lastFinishedProcesses = await QuestionnairesHandlerResource.getLatestFinishedByServiceConcepts(customer_id, OBJECT_TYPES.ONBOARDING);
        if(lastFinishedProcesses){
          setLatestFinished(lastFinishedProcesses);
        }
      } catch (error) {
        // not critical as we use it just to pre-fill
      }
      if(onboarding.onboarding_answers && onboarding.onboarding_answers.service_concept_step){
        updateServiceConcept(onboarding.onboarding_answers.service_concept_step.service_concept);
      } else {
        setLoading(false);
      }
    };

    const _nextMenu = async () => {
      if (currMenu < menuItems.length - 1){
        setLoading(true)

        const _currMenu = currMenu + 1
        await _setCurrService(_currMenu, onboardingData)
        setMenu(_currMenu)
        return currMenu;

      } else {
        // this is last step
        console.log('You have finished Onboarding.')

        executeIfPathExist(props.investmentPlatform.routes, 'ESIGN_STATUS', path => {
          props.history.push(`/${investmentDynamicPath}${path}`)
        })
      }
    };

    const _prevMenu = async () => {
      if(currMenu > 0){
        const _currMenu = currMenu - 1;
        await _setCurrService(_currMenu, onboardingData, true);
        setMenu(_currMenu);
      }
    };

    const handleNextClick = async () => {
      if (validateQuestions()) {
        try {
          setLoading(true);
          const latestOnboardingData = await currService.nextStep();
          if (latestOnboardingData && latestOnboardingData.is_onboarding) {
            setOnboardingData(latestOnboardingData)
          }
          await updateState();
          setLoading(false)
        } catch (error) {
          handleError(error);
        }
      } else{
        scrollToFirstElementOfClass()
      }
    }

    const handleGoToStepClick = async (step_uid) => {
      try {
        setLoading(true);
        await currService.goToStep(step_uid);
        await updateState();
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }

    const deleteCurrentProcess = async () => {
      await QuestionnairesHandlerResource.deleteQuestionnaire(currService.onboarding_uid)
    }

    const deleteAndGoBackToPreviousRout = async () => {
      await deleteCurrentProcess()
      await goBackToPreviousRout()
    }

    const handlePrevClick = async () => {
      try {
        setLoading(true);
        if(currService.isFistStep){
          if (currMenu == 0 && ['risk_profile', 'user_confirmation', 'custody_certificate'].includes(currService.uid)) {
            setLoading(true)
            try {
              await deleteCurrentProcess()
              // redirect to concept selection
              history.replace({ pathname: "/empty" });
              history.replace({ pathname: url });
              currService = undefined;
            } catch (error) {
              console.error(`Error during deletion of questionnaire with uid ${currService.onboarding_uid}`)
            }
            setLoading(false)
          } else {
            await _prevMenu();
          }
        } else {
          await currService.prevStep();
          await updateState();
        }
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }

    const handleResendResultClick = async () => {
      try {
        setLoading(true);
        await currService.resendResult();
        await updateState();
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }

    const handleConfirmResultClick = async (data) => {
      try {
        // this comparios needed for cases, when menu has more then one confirmation steps
        let isDone = currService.isDone
        if (!isDone) {
          setLoading(true);
        }
        await currService.confirmResult(data);
        await updateState();
        // this comparios needed for cases, when menu has more then one confirmation steps
        if (!isDone) {
          setLoading(false);
        }
      } catch (error) {
        handleError(error);
      }
    }

    const stepRequired = step && step.question && step.question.some(showQuestionRequiredNote);

    const validateQuestions = (afterChange=false) => {

      let isValid = true//step && step.question && step.question.every(question => isQuestionValid(question, step));

      if (step && step.question) {
        let questions = [...step.question]

        questions.forEach(q =>  {

          if (afterChange && !q.validateImmediately) {
            return
          }

          let isQValid = isQuestionValid(q, step, afterChange, currService)

          isValid = !isValid ? false : isQValid
        })

        setStep({
          ...step,
          question: questions
        })
      }

      return isValid
    }

    const handleStepAnswerChange = (uid, answer) => {
      setStep((prevStep) => ({
        ...prevStep,
        question: prevStep.question.map(question => {
          if (question.uid === uid) {
            question.answer = answer;
          }

          if (question.question) {
            question.question = question.question.map(question => {
              if (question.uid === uid) {
                question.answer = answer;
              }
              return question;
            })
          }

          return question;
        })
      }));
    }

    const handleError = (error) => {
      setLoading(false);

      function parseError(errorData) {
        if(errorData.errors){
          currService.setFormErrors(errorData.errors);
        }

        if(errorData.detail){
          setError(errorData.detail)
        } else if (errorData.message) {
          setError(errorData.message);
        } else {
          setError(typeof error === 'string' ? error : SERVER_ERROR)
        }
      }

      switch (error.status) {
        case 401:
        case 403:
          brokerLogout();
          return;
        default:
          if (error.data) {
            parseError(error.data);
          } else {
            parseError(error);
          }
      }
    };

    const getErrorMessage = () => {
      if (error && _.isString(error)) {
        if (ERROR_MESSAGES_TRANSLATIONS.hasOwnProperty(error)) {
          return ERROR_MESSAGES_TRANSLATIONS[error]
        }
      }

      return error
    }

    const handleServiceConceptChanged = async (sc) => {
      await QuestionnairesHandlerResource.post({
        customer_id: customer_id,
        onboarding_uid: onboardingData.session_id,
        onboarding_step_uid: 'service_concept_step',
        step_uid: 'service_concept',
        answers: sc
      });

      updateServiceConcept(sc);
    };

    const goBackToPreviousRout = async () => {
      /** Returns you to page from which you were redirected here */

      // if you start the process, clear browser history, props.history.length would be set, but you won't be able to redirect => use window.history
      let hasHistory = window.history.length > 1

      if(!hasHistory) {
        executeIfPathExist(props.investmentPlatform.routes, 'RISK_PROFILING', path => {
          props.history.push(`/${investmentDynamicPath}${path}`)
        })
      }
      else{
        props.history.goBack()
      }
    }

    // show Service concept screen ONLY after onboarding data loaded
    if (!loading && serviceConcept === null && !!onboardingData) {
      return <ServiceConceptSelection
        onServiceConceptChange={handleServiceConceptChanged}
        footerContent={(
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 45}}>
            <PrimaryButton
              text={"Zurück"}
              icon={<ArrowBack color={'primary'}/>}
              variant={"outlined"}
              onButtonClick={goBackToPreviousRout}
              customClasses={{
                root: classes.scBackButtonRoot
              }}
            />
          </div>
        )}
      />
    }

    const title = SERVICE_CONCEPTS_NAMES[serviceConcept] || "Onboarding";

    // Jump from Anlageberatung to Anlagevermittlung functionality
    const handleJumpToAnlagevermittlung = async () => {
      // pre fill answers
      const _getStepData = (stepUID) => {
        const step = currService.getStep(stepUID);

        return step && _.get(getStepAnswer(step), 'answers');
      };

      // start from beginning
      setMenu(0);
      // add answers for Anlagevermittlung and clean "current_step"
      const data = {...onboardingData, current_step: undefined};
      [USER_CONFIRMATION_STEP_IDENTIFIER, ...COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS].map(stepUID => {
        _.set(data, `onboarding_answers.user_confirmation.${stepUID}`, _getStepData(stepUID));
      });
      _.set(data, "onboarding_answers.risk_profile.K1", _getStepData('A2'));
      _.set(data, "onboarding_answers.risk_profile.K2", _getStepData('A3'));

      setOnboardingData(data);

      await handleServiceConceptChanged(SERVICE_CONCEPTS.Anlagevermittlung)
    };

    // TODO: Check, if selected solution is correct
    const handleJumpToServiceConceptSelection = async () => {
      await QuestionnairesHandlerResource.deleteQuestionnaire(currService.onboarding_uid)
      history.push({ pathname: "/empty" });
      history.replace({ pathname: url });
      currService = undefined;
    }

    let rightButtons = [];
    if (serviceConcept === SERVICE_CONCEPTS.Anlageberatung && ['A5', 'A7'].includes(getUID(_.get(currService, 'step.uid', '')))) {
      rightButtons = [
        <PrimaryButton text={"Abbrechen und zu Anlagevermittlung wechseln"}
            onButtonClick={handleJumpToAnlagevermittlung}
        />
      ]
    } else if (serviceConcept === SERVICE_CONCEPTS.ExecutionOnly
        && getUID(_.get(currService, 'step.uid', '')) === 'product-selection-depot'
        && currService.hasPrivateInvestmentDepots) {

      rightButtons = [
        <PrimaryButton
          text={"Abbrechen & wechseln"}
          onButtonClick={() => setResetSessionModalOpen(true)}
        />
      ]

    }

    return (
      <>
        {!!currService && (
          <Navigation
            title={title}
            menuItems={menuItems}
            currMenu={currMenu}
            onDeleteProcessClick={() => setDeleteSessionModalOpen(true)}
            isFinished={!!_.get(onboardingData, 'finished')}
          />
        )}
        <WrappedComponent
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onGoToStepClick={handleGoToStepClick}
          onResendResultClick={handleResendResultClick}
          onConfirmResultClick={handleConfirmResultClick}
          onAnswerChange={handleStepAnswerChange}
          step={step}
          progress={progress}
          isFirst={currService && currService.goBackDisabled || false}
          isLast={isLast}
          loading={loading}
          required={stepRequired}
          valid={true} //TODO: Check, if this param is used
          successBody={currService && currService.success_body}
          nextStepBtnText={currService && currService.next_btn}
          dataService={currService}
          extraRightButtons={rightButtons}
        />
        <Snackbar
          open={Boolean(error)}
          variant={'error'}
          message={getErrorMessage()}
          autoHideDuration={SNACKBAR_DURATION_IN_SEC}
          handleClose={() => setError(undefined)}/>
        <ConfirmationDialog
          message={resetSessionModalOpen ? "Sind Sie sicher?" : CONFIRM_REMOVE_MSG}
          open={resetSessionModalOpen || deleteSessionModalOpen}
          onClose={() => resetSessionModalOpen ? setResetSessionModalOpen(false) : setDeleteSessionModalOpen(false)}
          confirm={resetSessionModalOpen ? handleJumpToServiceConceptSelection : deleteAndGoBackToPreviousRout}
        />
      </>
    );
  }))
};

// function Confirm

export default RiskProfilingDataProvider;