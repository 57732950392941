import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  filtersRoot: {
    padding: '20px 30px',
  },
  container: {
    overflowY: 'auto'
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '&  > div:first-child': {
      backgroundColor: 'transparent'
    },
    overflowY: 'visible'
  },
  paper: {
    borderRadius: 10,
    overflowY: 'visible'
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  modalTitle: {
    borderBottom: '2px solid #E1E3E9',
    textAlign: 'left',
    color: '#4D4F5C',
    paddingRight: 55,
    paddingLeft: 55,
    paddingTop: 24,
    '& > h2': {
      fontFamily: 'Roboto-Regular',
      fontSize: 22,
      lineHeight: 1.2,
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    }
  },
  documentItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
    position: 'relative',
    '& > *': {
      flex: '0 0 auto',
    },
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'calc(100% - 170px)',
      height: 45,
      border: '1px solid #EAEDF3',
      padding: '0 5px',
      overflowX: 'hidden',

      '& > span:first-child': {
        color: '#202A38 !important',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        whiteSpace: 'nowrap',
        display: 'inline-block',
        width: 'calc(100% - 90px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  loadDocsButton: {
    color: 'white',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: '3px 6px 10px #00000033',
  },
  loadDocsButtonText: {
    padding: '5px 8px',
    whiteSpace: 'nowrap',
  },
})

export default makeStyles(styles)