import _ from "lodash";

import {Service} from "./service";
import {
  getProductSelectionStepData,
  instrumentDataPath,
  productsStepsData,
  SAVINGS_PLANS_KEY, SINGLE_INVESTMENTS_KEY
} from "./mock_produktauswahl"

import {
  _prepareBanksFromResponse, getBankData,
  getCustodianData,
  getCustomerData,
  getTargetMarketAnswers,
  getVatCoef
} from "./utils";
import {
  BankSettingsHandlerResource,
  ModelPortfolioResource,
  parseResponse,
  QuestionnairesHandlerResource
} from '../../utils/api'
import {getInstrumentName} from "../Trades/utils";
import { PROCESS_TYPE, VALID_UNTIL_TODAY_VALUE } from './constants';

export class ProductsService extends Service {

  constructor(uid, name, next_btn, success_text, getPrevStepAnswer, validateAccountFunc, handleWarnings) {
    super(uid, name, next_btn, success_text, getPrevStepAnswer, validateAccountFunc, handleWarnings);

    this._srri = undefined;
    this._target_market = undefined;
    this._custodiansInstrumentsData = undefined;
    this._banksData = undefined;
    this._service_concept = undefined;
    this.skipStepsIfOpenOnly = [
      "product-selection-investment-recommendation",
      "product-selection-summary"
    ]
  }

  get serviceConcept () {
    return this._service_concept
  }

  set serviceConcept (value) {
    this._service_concept = value
  }

  get openOnlyProductSelectionRequired() {
    const depot = this.getDepot();
    const bankData = getBankData(this.banksData, depot.bankCompanyId);

    return bankData.onboarding_open_only_product_selection_required
  }

  async __sendStepData() {
    let isOpenOnly = this.isOpenOnly;

    if (['product-selection-investment-recommendation', 'product-selection-deposits'].includes(this._step.uid) || isOpenOnly) {
      try {
        await this._validateStepFunc();
      } catch (e) {
        // ignore errors on deposits step -> show it later
        if (this._step.uid !== 'product-selection-deposits') {
          throw e;
        }
      }
      // get instruments custodian details
      await this._getInstrumentsCustodiansDetails();
    }

    let response = await super.__sendStepData(); // store answers in our db

    if (isOpenOnly && !this.openOnlyProductSelectionRequired) {
      this.removeInstrumentsSteps()
    } else if (_.get(this._questionnaire, 'steps')) {
      this.addInstrumentsSteps()
      if (isOpenOnly && this._step.uid === 'product-selection-deposits') {
        await this.fillWithDefaultInstruments()
      }
    }

    return response
  }

  /**
   * Fill instruments question with default answers according to the depot configuration
   * @returns {Promise<void>}
   */
  async fillWithDefaultInstruments() {
    const depot = this.getDepot();

    if (!depot) {
      return
    }

    const bankData = getBankData(this.banksData, depot.bankCompanyId);
    const defaultInstrument = _.get(bankData, 'onboarding_open_only_default_instrument')
    if (!defaultInstrument) {
      return
    }

    const instrumentsResponse = await ModelPortfolioResource.searchAssets({
      name: defaultInstrument,
      custodian: [depot.bankCompanyId]
    })

    parseResponse(instrumentsResponse, 'assets', (data) => {

      const instrumentOpenOnlyInfoText = _.get(bankData, 'onboarding_open_only_default_instrument_info_text')

      this.getQuestion('product-selection-investment-recommendation', 'instruments').answer = data.map((asset) => {
        asset.weight = 0;
        asset.amount = 0;
        asset.savingsPlanAmount = 0;
        asset.infoText = instrumentOpenOnlyInfoText
        return asset
      })
    }, (errors) => {
      console.log(errors)
    });
  }

  removeInstrumentsSteps() {
    this._questionnaire.steps = this._questionnaire.steps.filter(s => {
      return !this.skipStepsIfOpenOnly.includes(s.uid)
    })
  }

  addInstrumentsSteps() {
    let stepsUIDs = this._questionnaire.steps && this._questionnaire.steps.map(item => item && item.uid);
    let indexForInsert = stepsUIDs.indexOf('product-selection-deposits') + 1;
    this.skipStepsIfOpenOnly.forEach(step => {
      if (!stepsUIDs.includes(step))
      this._questionnaire.steps.splice(indexForInsert, 0, this.__deepCopy(productsStepsData).steps.find(s => s.uid === step));
      indexForInsert += 1
    })
  }

  async _getInstrumentsCustodiansDetails() {
    const instruments = this.getStepAnswer('product-selection-investment-recommendation', 'instruments') || [];
    const assetsData = await getCustodianData(instruments);
    if(assetsData) this._custodiansInstrumentsData = assetsData;
  }

  async _getBanksData() {
    try {
      let response = await BankSettingsHandlerResource.getBanksData({
        customer_type: this.customer_type,
        usage_area: PROCESS_TYPE.ONBOARDING
      });
      return _prepareBanksFromResponse(response)

    } catch (err) {
      console.log(err);
      return undefined
    }
  }

  getDepot(withContractTypeData=false) {
    const answer = this.getStepAnswer('product-selection-depot', 'custodians');

    const depot = answer && answer.depot;
    if (depot && withContractTypeData) {
      depot.selected_contract_type = depot.contract_types.find(
        contract => contract.value === answer.contract_type);
    }

    return depot
  }

  get hasPrivateInvestmentDepots() {

    const custodiansQuestion = this.getQuestion('product-selection-depot', 'custodians');
    for(let custodian of (custodiansQuestion.options || [])) {
      if(custodian.is_private_investment) {
        return true
      }
    }

  }

  get bankCompanyId(){
    const depot = this.getDepot();
    return depot && depot.bankCompanyId;
  }

  get banksData(){
    let banksData = this._banksData || [];

    // if we have depot selected - override with config from depot type
    const depot = this.getDepot(true);
    if (depot && depot.selected_contract_type){
      // clone bankData to keep original
      banksData = banksData.map(b => {
        if(b.custodian_id == depot.bankCompanyId){
          b = {...b, ...depot.selected_contract_type.configuration}
        }

        return b;
      });
    }

    return banksData;
  }

  _getBank() {
    const depot = this.getDepot();
    return depot && depot.bank;
  }

  getBankInstrumentsInfo(bank) {
    return this._custodiansInstrumentsData && this._custodiansInstrumentsData[bank] || {};
  }

  getInvestedAmountData() {
    const savingsPlanEnabled = this.getStepAnswer("product-selection-deposits", "savings_plan['checkbox']");
    const singleInvestmentEnabled = this.getStepAnswer("product-selection-deposits", "single_investment['checkbox']");
    const vlPlanEnabled = this.getStepAnswer("product-selection-deposits", "vl_plan['checkbox']");
    const einzeltitelEnabled = this.getStepAnswer("product-selection-deposits", "einzeltitel['checkbox']");

    let savingsPlanAmount, savingsPlanPeriod;
    if(vlPlanEnabled){
      savingsPlanAmount = this.getStepAnswer("product-selection-deposits", "vl_plan['input']");
      savingsPlanPeriod = this.getStepAnswer("product-selection-deposits", "vl_plan['rotation']")
    } else if (savingsPlanEnabled){
      savingsPlanAmount = this.getStepAnswer("product-selection-deposits", "savings_plan['input']");
      savingsPlanPeriod = this.getStepAnswer("product-selection-deposits", "savings_plan['rotation']")
    }

    return {
      single_investment_enabled: singleInvestmentEnabled,
      single_investment: singleInvestmentEnabled && this.getStepAnswer("product-selection-deposits", "single_investment['input']") || 0,
      savings_plan: savingsPlanAmount || 0,
      savings_plan_enabled: savingsPlanEnabled,
      savings_plan_period: savingsPlanPeriod,
      vl_plan_enabled: vlPlanEnabled,
      einzeltitel_enabled: einzeltitelEnabled
    }
  }

  getDataForAccount(forExAnte=false) {
    const modelPortfolio = this.getStepAnswer('product-selection-investment-recommendation', 'instruments_source');
    const bank = this._getBank();
    const depot = this.getDepot();
    const availableAssetClasses = this.bank && this.bank.available_asset_classes || {};
    const savingsPlanStartDate = this.getStepAnswer("product-selection-deposits", "savings_plan['from_date']");
    const savingsPlanEndDate = this.getStepAnswer("product-selection-deposits", "savings_plan['till_date']");
    const periodicPlan = this.getStepAnswer("product-selection-deposits", "savings_plan['rotation']");
    const vlPlanStartDate = this.getStepAnswer("product-selection-deposits", "vl_plan['from_date']");
    const vlPlanPeriodicPlan = this.getStepAnswer("product-selection-deposits", "vl_plan['rotation']");

    const data = {bank: bank, bank_id: depot && depot.bankId, advisor_details: {}};

    const discountsInfo = this.getStepAnswer("product-selection-summary", "discounts") || {};

    if (modelPortfolio && !forExAnte){
      const savingsPlanEnabled = this.getStepAnswer("product-selection-deposits", "savings_plan['checkbox']");
      const singleInvestmentEnabled = this.getStepAnswer("product-selection-deposits", "single_investment['checkbox']");

      const instrument_type = modelPortfolio.isInvestmentStrategy ? 'model portfolio managed' : 'model portfolio';
      const dst_instrument = modelPortfolio.isInvestmentStrategy ? modelPortfolio.portfolio_id : modelPortfolio.agency_portfolio_id;

      function getMPDiscount(key) {
        if (discountsInfo.discountsEnabled) {
          // in case discount is not set ("", 0, etc) - return undefined as it won't be sent with axios
          return _.get(discountsInfo, key) || undefined;
        }
      }

      if(singleInvestmentEnabled || this.isOpenOnly) {
        data.order_details = [{
          instrument_type,
          dst_instrument,
          dst_instrument_name: modelPortfolio.name.slice(0, 120),
          amount_eur: this.getStepAnswer("product-selection-deposits", "single_investment['input']") || 0,
          bank: bank,
          discount: getMPDiscount(SINGLE_INVESTMENTS_KEY)
        }]
      }
      if(savingsPlanEnabled){

        const _saving_plan_structure = {
          instrument_type,
          dst_instrument,
          dst_instrument_name: modelPortfolio.name.slice(0, 120),
          amount_eur: this.getStepAnswer("product-selection-deposits", "savings_plan['input']"),
          bank: bank,
          start_date: savingsPlanStartDate,
          periodic_plan: periodicPlan,
          discount: getMPDiscount(SAVINGS_PLANS_KEY)
        };
        if (savingsPlanEndDate) {
          _saving_plan_structure.end_date = savingsPlanEndDate
        }

        data.savings_plans = [_saving_plan_structure]
      }
    } else {
      let instruments = this.getStepAnswer('product-selection-investment-recommendation', 'instruments') || [];
      const vlPlanEnabled = this.getStepAnswer("product-selection-deposits", "vl_plan['checkbox']");

      function getTotalAmount(instrument){
        return _.round(instrument.amount, 2);
      }

      function getDiscount(instrument) {
        if (discountsInfo.discountsEnabled) {
          const instrumentData = _.get(discountsInfo, instrumentDataPath(instrument), {});
          return instrumentData.discount || undefined;
        }
      }

      data.order_details = [];
      instruments.filter(i => !i.savingsPlan).forEach((instr) => {
        const order = {
          dst_instrument: instr.isin,
          dst_instrument_name: getInstrumentName(instr),
          amount_eur: getTotalAmount(instr),
          bank: bank
        };
        if (_.get(availableAssetClasses, instr.internal_asset_class)) {
          order.instrument_type = `isin ${instr.internal_asset_class}`;
          order.quantity = instr.quantity;
          order.order_type = instr.order_type !== 'no order' ? instr.order_type : undefined;
          order.limit_amount_eur = instr.limit || 0;
          if (instr.valid_until === VALID_UNTIL_TODAY_VALUE) {
            order.valid_until_today = true;
          } else {
            order.valid_until = instr.valid_until;
          }
          order.stock_exchange = instr.stock_exchange;
        } else if (order.amount_eur) {
          order.discount = getDiscount(instr)
        }
        data.order_details.push(order)
      })

      const savings_plans_key = !vlPlanEnabled ? 'savings_plans' : 'vl_plan_details';

      if (vlPlanEnabled && _.isEmpty(instruments)){
        // put dummy item to validate vl amount
        instruments = [{
          savingsPlan: true,
          isin: "DE1234567891",
          amount: this.getStepAnswer("product-selection-deposits", "vl_plan['input']")
        }]
      }
      data[savings_plans_key] = instruments.filter(i => i.savingsPlan).map(instr => {
        const _savings_plan_structure = {
          // TODO dst_insrument is depracated and can be removed(sparplans)
          dst_instrument: instr.isin,
          dst_instrument_name: getInstrumentName(instr),
          //
          instrument: instr.isin,
          instrument_name: getInstrumentName(instr),
          amount_eur: getTotalAmount(instr),
          bank: bank,
          start_date: vlPlanEnabled ? vlPlanStartDate : savingsPlanStartDate,
          periodic_plan: vlPlanEnabled ? vlPlanPeriodicPlan : periodicPlan,
          discount: getDiscount(instr)
        };
        if (savingsPlanEndDate) {
          _savings_plan_structure.end_date = savingsPlanEndDate
        }
        return _savings_plan_structure
      });

      // savings_plans is required property
      if (!data.hasOwnProperty('savings_plans')) {
        data.savings_plans = []
      }
    }

    let serviceFee = this.getStepAnswer("product-selection-summary", "serviceFee");
    const kickback = this.getStepAnswer("product-selection-summary", "kickback");
    let serviceFeeVat = this.getStepAnswer("product-selection-summary", "serviceFeeVat");
    const isMPProcess = !!modelPortfolio;

    if (_.get(modelPortfolio, 'isInvestmentStrategy')){
      serviceFee = _.sum([
        _.get(modelPortfolio, 'bfv_commission'),
        _.get(modelPortfolio, 'asset_manager_commission'),
        serviceFee
      ]);

      serviceFeeVat = serviceFee * getVatCoef()
    }

    if (discountsInfo.discountsEnabled && discountsInfo.overall) {
      data.advisor_details = {account_discount: discountsInfo.overall};
    }

    if(serviceFee){
      data.advisor_details.service_fee = serviceFee
    }
    if(kickback){
      data.advisor_details.kickback = kickback
    }
    if(serviceFeeVat){
      data.advisor_details.service_fee_vat = serviceFeeVat
    }
    if(isMPProcess){
      data.advisor_details.mp_entry_fee = parseFloat(modelPortfolio.isInvestmentStrategy ?
        modelPortfolio.cost_netto : modelPortfolio.fee_netto || 0);

      data.advisor_details.mp_entry_fee_vat = parseFloat(modelPortfolio.isInvestmentStrategy ?
        modelPortfolio.cost_brutto : modelPortfolio.fee_brutto || 0);
    }

    // CIOS require rounding to 2/5 decimals
    ['mp_entry_fee', 'mp_entry_fee_vat', 'service_fee', 'service_fee_vat'].forEach(field => {
      const advisorDetails = data.advisor_details;

      if (advisorDetails.hasOwnProperty(field)){
        advisorDetails[field] = _.round(advisorDetails[field], field.endsWith('vat') ? 5 : 2);
      }
    });

    return data;
  }

  async syncDepotTypeInfo() {

    const answer = this.getStepAnswer('product-selection-depot', 'custodians');
    if (!answer || !answer.contract_type || !answer.depot) {
      return
    }

    try {

      const response = await BankSettingsHandlerResource.getDepotTypeInfo(answer.contract_type, this.objectType);

      const contractTypes = answer.depot.contract_types || [];
      contractTypes.forEach((contractType) => {
        if (contractType.value == response.id) {
          // For now only depot type configuration synced.
          contractType.configuration = response.configuration
        }
      });
      this.getStep('product-selection-depot', 'custodians').answer = answer
      await this._getBankDetails()

    } catch (error) {
      console.error(`Unable to sync depot type info.\nError: ${error}`)
    }

  }

  async __setQuestionnaire(onboardingAnswers) {
    this.current_customer = await getCustomerData(this._customer_id);
    this._questionnaire = this.__deepCopy(productsStepsData);
    this._setDefaultAnswers(onboardingAnswers);

    this._srri = this._getPrevStepAnswer('risk_profile', 'final-step', 'final_question_uid');
    this._target_market = getTargetMarketAnswers(this.serviceConcept, this._getPrevStepAnswer);

    if(!this._custodiansInstrumentsData){
      await this._getInstrumentsCustodiansDetails();
    }

    if(!this._banksData){
      const res = await QuestionnairesHandlerResource.getBanksData();
      this._banksData = res.response;
    }

    const banksData = await this._getBanksData();
    if (banksData) {
      this.getQuestion('product-selection-depot', 'custodians').options = banksData
    }

    if (this.isOpenOnly && !this.openOnlyProductSelectionRequired) {
      this.removeInstrumentsSteps()
    } else {
      // update Product selection step name and category
      const step = this.getStep('product-selection-investment-recommendation');
      const {name, behaviour} = getProductSelectionStepData(this.serviceConcept);
      step.name = name;
      step.behaviour = behaviour;
      if (this.isOpenOnly && !this.getQuestion('product-selection-investment-recommendation', 'instruments').answer) {
        await this.fillWithDefaultInstruments()
      }
    }

    await this.syncDepotTypeInfo()
  }

  async confirmResult(data) {
    this._is_finished = true;
  }

}