import React, { useRef, useCallback } from 'react';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import { Grid, Divider } from '@material-ui/core'

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { InfoIcon } from '../../../../images'

import useStyles from './styles';

const headerRowInfoText = 'Fertig bedeutet, dass die Dokumente vollständig an den Kunden und die Depotbank gesendet wurden. Sie können die fertigen Dokumente weiterhin bei ihren erledigten Mappen anzeigen und gegebenenfalls anpassen.'

export default function DocumentsFinishModal(props) {
  const classes = useStyles();

  const refDocumentsHeader = useRef()

  const refDocumentsItems = useCallback(node => {
    // To align 'Fertig'(select all) buttton in documents header
    // to the same child documents buttom,
    // check if the documents list is overflow and has scroll bar.
    // If true then add padding-right to documents header container.
    if (node !== null) {
      const hasOverflowing = node.offsetHeight < node.scrollHeight
      if (hasOverflowing) {
        refDocumentsHeader.current.style.cssText = "padding-right: 15px"
      }
    }
  }, []);

  const renderItems = (documents, handleOnChange, header=false) => {

    return (
      <>
        {documents && documents.map(document => 
          <div key={document.id}>
            <Grid container alignItems="center" className={classes.collectionModalItem}>
              <Grid item xs={10} className={classes.documentNameText}>
                {document.name}
              </Grid>
              <Grid item xs={2} >
                <Checkbox
                  checked={Boolean(document.is_finished)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={() => handleOnChange(document.id)}
                  />
                <span style={{fontWeight: 'bold'}}>Fertig</span>
                {header && (
                  <Tooltip
                    arrow={true}
                    title={headerRowInfoText}
                    placement="bottom-end"
                    classes={{ tooltip: classes.deleteHelperTitle, arrow: classes.deleteHelperArrow }}
                  >
                    <Info className={classes.helperHeaderInfo}/>
                  </Tooltip>)
                }
              </Grid>
            </Grid>
            <Divider />
          </div>
        )}
      </>
    )
  }

  const Info = React.forwardRef(function MyComponent(props, ref) {
    return (
      <span ref={ref} {...props}>
        <InfoIcon />
      </span>
    )
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <div className={classes.modalHeaderText}>
              Bitte markieren Sie die Dokumente, die abgeschlossen sind
            </div>
            <div ref={refDocumentsHeader} className={classes.documentsHeader}>
              {renderItems([props.headerRow], props.handleOnChange, true)}
            </div>
            <div ref={refDocumentsItems} className={classes.documentsItems}>
              {renderItems(props.documents, props.handleOnChange)}
            </div>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              style={{padding: '40px 30px 10px 0px'}}
            >
              <Grid item className={classes.modalNavButtons}>
                <PrimaryButton
                  text={'Speichern'}
                  icon={<i className="far fa-save" style={{fontSize: 18}} aria-hidden="true"></i>}
                  onButtonClick={props.handleSaveClick}
                />
                <PrimaryButton
                  text={'Schließen'}
                  icon={<i className="far fa-close" style={{fontSize: 18}} aria-hidden="true"></i>}
                  onButtonClick={props.handleClose}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
