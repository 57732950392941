import React from 'react';
import moment from "moment";

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';
import KeyboardDatePicker from '../KeyboardDatePicker';
import {PrimaryButton} from '../Buttons';
import {isWeekend, shouldDisableWeekendIfRequired} from "../../utils/utils";

const DateRange = props => {
  const {
    classes,
    onChange,
    start,
    end,
    onOpen,
    onClose,
    minDate,
    minDateErrorMessage,
    businessDaysOnly
  } = props;

  const [from, setFrom] = React.useState(start || moment());
  const [to, setTo] = React.useState(end || moment());

  const [fromError, setFromError] = React.useState(false);
  const [toError, setToError] = React.useState(false);

  React.useEffect(() => {
    if(moment.isMoment(start) && from !== start){
      setFrom(start.clone())
    }
  }, [start]);

  React.useEffect(() => {
    if (moment.isMoment(end) && to !== end) {
      setTo(end.clone())
    }
  }, [end]);


  const handleClickApplyButton = () => {
    onChange({start: from, end: to})
  };

  const isApplyButtonDisabled = () => {
    return fromError || toError;
  };

  return (
    <div className={classes.dateRangeContainer}>
      <div className={classes.dateRangeItem}>
        <div className={classes.itemLabel}>Seit</div>
        <KeyboardDatePicker
          value={from}
          minDate={minDate}
          maxDate={to || moment()}
          maxError='Startdatum muss vor dem Enddatum liegen.'
          minError={minDateErrorMessage}
          onChange={(date) => setFrom(date)}
          shouldDisableDate={shouldDisableWeekendIfRequired(businessDaysOnly)}
          onOpen={onOpen}
          onClose={onClose}
          onError={setFromError}
          classes={{
            root: classes.keyboardDatePickerRoot
          }}
          inputProps={{
            classes: {
              underline: classes.textFieldUnderline,
            }
          }}
        />
      </div>
      <div className={classes.dateRangeItem}>
        <div className={classes.itemLabel}>bis</div>
        <KeyboardDatePicker
          value={to}
          minDate={from || minDate}
          maxDate={moment()}
          minError='Enddatum muss nach dem Startdatum liegen.'
          onChange={(date) => setTo(date)}
          shouldDisableDate={shouldDisableWeekendIfRequired(businessDaysOnly)}
          onOpen={onOpen}
          onClose={onClose}
          onError={setToError}
          classes={{
            root: classes.keyboardDatePickerRoot
          }}
          inputProps={{
            classes: {
              underline: classes.textFieldUnderline,
            }
          }}
        />
      </div>
      <div>
        <PrimaryButton
          text='Anwenden'
          onButtonClick={handleClickApplyButton}
          disabled={isApplyButtonDisabled()}
        />
      </div>
    </div>
  )
};

export default withStyles(styles)(DateRange);