import React from "react";
import { withStyles } from "@material-ui/core";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import { toShortGermanFormat } from "../../../../utils";
import HighChartBase from "../../../../../../components/Charts/Base";
import styles from "./styles";


class PerformanceBarChart extends React.Component {

    constructor(props) {
      super(props);

      this.table = [];
    }

  renderChart() {
    const defaultChartColor = '#0596CE';
    const { classes, data, currency } = this.props;
    let years = [], values = [];

    for (const [year, dividends_list] of Object.entries(data)) {
      years.push(year);
      let dividendYearSum = 0
      dividends_list.forEach((dividend) => {
        dividendYearSum += parseFloat(dividend.value);
      });
      this.table.push({year: year, value: dividendYearSum})
      values.push(parseFloat((dividendYearSum).toFixed(2)));
    }

    const series = [{
      name: 'dividends',
      data: values,
      pointPadding: 0.15,
      visible: true,
      color: defaultChartColor
    }];

    return <HighChartBase
      className={classes.chart}
      language={'DE'}
      options={{
        chart: {
          type: 'column',
          height: 365,
          style: {
            fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
            fontSize: 14
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: years,
          tickLength: 0,
          labels: {
            style: {
              fontWeight: 'bold',
              fontSize: 14,
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function () {
              return toShortGermanFormat(this.value, '', ' ' + currency, 2, true);
            },
            style: {
              fontSize: 14,
            }
          },
          plotLines: [{
            color: '#D8DCDF',
            width: 2,
            value: 0,
            zIndex: 10,
            fontSize: 14,
          }]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          column: {
            grouping: true,
            shadow: false,
            borderWidth: 0
          },
          tooltip: {
            enabled: false
          },
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return toShortGermanFormat(this.y, '', '', 2, false);
              },
              style: {
                fontSize: 12,
                fontWeight: 'normal',
                color: '#202A38',
              },
            },
            enableMouseTracking: true
          }
        },
        series: series
      }} />
  }

  renderTable() {
    const { classes, currency } = this.props;

    let tableSplitted = [];
    for (let i = 4; i > 0; i--) {
        tableSplitted.push(this.table.splice(0, Math.ceil(this.table.length / i)));
    }

    return <div className={classes.table}>
        {tableSplitted.map(items => (
           <ul className={classes.list}>
            <li className={[classes.item, classes.headline].join(' ')}>
              <span className={classes.title}>Jahre</span>
              <span className={classes.values}>Ausschüttung</span>
            </li>
            {items.map((item, index) => (
              <li className={classes.item} key={index}>
                <span className={classes.title}>{item.year}</span>
                <span className={classes.values}>{toShortGermanFormat(item.value, '', ' '+currency, 2, false)}</span>
              </li>
            ))}
          </ul>
        ))}
    </div>
  }

  renderError() {
    return <div>{'Fehler. Die Graphik "Jahresausschüttungen je Fondsanteil" konnte nicht geladen werden.'}</div>;
  }

  render() {
    const { classes, loading, error, data } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title="Jahresausschüttungen je Fondsanteil"
      loading={loading}
      content={
        <div className={classes.container}>
          {error
            ? this.renderError()
            : data
              ? <>
                  {this.renderChart()}
                  {this.renderTable()}
                </>
              : 'Die Graphik "Jahresausschüttungen je Fondsanteil" konnte nicht geladen werden.'
          }
        </div>
      }
    />
  }
}

export default withStyles(styles)(PerformanceBarChart);
