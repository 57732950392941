import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import clsx from "clsx";
import connect from "react-redux/lib/connect/connect";
import { withWidth } from '@material-ui/core';

import {
  Card,
  CardContent,
  Grid,
  CircularProgress
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import PrimaryButton from '../../components/Buttons/PrimaryButton';
import GroupDetails from './GroupDetails';
import ReportType from '../../containers/DashboardSettings/components/CustomersList/components/ReportType';
import ReportGenerationType from '../../containers/DashboardSettings/components/CustomersList/components/ReportGenerationType';
import ReportsOptions from '../../containers/DashboardSettings/components/CustomersList/components/AdditionalReportOptions';
import DistributionType from '../../containers/DashboardSettings/components/CustomersList/components/DistributionType';
import ReportSendingDate from '../../containers/DashboardSettings/components/CustomersList/components/ReportSendingDate';
import { REPORTS_OPTIONS } from '../../containers/DashboardSettings/components/CustomersList/components/AdditionalReportOptions/constants';
import { DISTRIBUTION_TYPES } from '../../containers/DashboardSettings/components/CustomersList/components/DistributionType/constants';
import CustomersTable from './CustomersTable';
import DeleteGroupModal from "../../containers/ReportGroupCreation/components/DeleteGroupModal/DeleteGroupModal";

import useStyles from './styles';
import { GroupResource } from '../../utils/api';
import {ROUTES} from "../../routes";
import { newDesignUsed } from '../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../containers/InvestmentPlatform/utils';
import {downloadPdf} from "../../utils/utils";
import { displayErrorSnackBar } from '../SnackbarProvider/actions';
import { REPORT_TYPES } from '../../containers/DashboardSettings/components/CustomersList/components/ReportType/constants';
import ReportsAreasVisibility from '../../containers/DashboardSettings/components/CustomersList/components/ReportsAreasVisibility';
import {
  REPORTS_AREAS
} from '../../containers/DashboardSettings/components/CustomersList/components/ReportsAreasVisibility/constants';
import {setReportingCustomer, setReportingGroup} from "../../containers/GroupReporting/actions";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const GroupSettings = props => {
  const classes = useStyles();
  const {
    group,
    history,
    handleGroupUpdated,
    onGroupDeleted,
    onEditNameClick,
    loading,
    dispatch,
    updatedGroup
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [isDeleting, setDeletingStatus] = React.useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
  const [fileDownloadingProgress, setFileDownloadingProgress] = React.useState(false);

  const redirectTo = (path) => () => {
    history.push(path);
  };

  const handleValueChanged = (fieldname) => (value) => {
    if (!_.isNil(handleGroupUpdated)) {
      handleGroupUpdated(fieldname)(value);
    }
  }

  const handleDateRangeChanged = (fieldname, value) => {
    if (!_.isNil(handleGroupUpdated)) {
      handleGroupUpdated(fieldname)(value);
    }
  }

  const deleteGroup = async () => {
    setDeletingStatus(true);
    try {
      await GroupResource.at(`${group.id}/`).delete();
      if (!_.isNil(onGroupDeleted)) {
        onGroupDeleted();
      }
    } catch (error) {
      //TODO: Add error handling
    }
    setIsDeleteModalVisible(false);
    setDeletingStatus(false);
  }

  const isDistributionTypeDisabled = () => {
    return group.customers && group.customers.some(customer => !customer.postbox);
  };

  const getGroupAdditionalReports = () => {
    let additionalReports = [];

    if (group.with_cost) {
      additionalReports.push(REPORTS_OPTIONS.COST.value);
    }
    if (group.with_transactions) {
      additionalReports.push(REPORTS_OPTIONS.TRANSACTIONS.value);
    }

    return additionalReports;
  };

  const getGroupAreasVisibility = () => {
    let areasVisibility = [];

    if (group.with_payment_plans) {
      areasVisibility.push(REPORTS_AREAS.PAYMENT_PLANS.value);
    }
    if (group.with_profit_loss) {
      areasVisibility.push(REPORTS_AREAS.PROFIT_LOSS.value);
    }
    if (group.with_other_assets) {
      areasVisibility.push(REPORTS_AREAS.OTHER_ASSETS.value);
    }

    return areasVisibility;
  };

  const onReportRedirectClick = (group) => () => {
    dispatch(setReportingGroup(group));
    dispatch(setReportingCustomer(null));

    let path = ROUTES.BROKER.REPORTING.children.GROUPS.children.COVER.path.replace(':group_id', group.id)
    let investmentDynamicPath = getInvestmentDynamicPath()
    if (newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'REPORTING', (dynamicPath) => {
        path = '/' + investmentDynamicPath + dynamicPath + ROUTES.BROKER.REPORTING.children.GROUPS.children.COVER.newDesignPath.replace(':group_id', group.id)
      })
    }

    props.history.push(path)
  };

  const handleClickExpandButton = () => {
    return setExpanded(!expanded);
  };

  const getCurrentDistributionType = () => {
    if (updatedGroup) {
      return updatedGroup.distribution_type;
    }

    return group.distribution_type;
  }

  const handleEditGroupButtonClick = () => {
    let path = ROUTES.BROKER.GROUP_UPDATE.path.replace(':group_id', group.id)
    let investmentDynamicPath = getInvestmentDynamicPath()

    if (newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'REPORT_SETTINGS', (dynamicPath) => {
        path = '/' + investmentDynamicPath + dynamicPath + ROUTES.BROKER.GROUP_UPDATE.newDesignPath.replace(':group_id', group.id)
      })
    }

    props.history.push(path)
  }

  const handleDownloadPdfClick = async () => {
    try {
      setFileDownloadingProgress(true);
      await downloadPdf('/reports/broker/group_postbox_status/', undefined, 'POST', undefined, {'group_id': group.id});

    } catch (errors) {
      props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'));
    } finally {
      setFileDownloadingProgress(false);
    }
  }

  const getCurrentReportType = () => {
    if (updatedGroup) {
      return updatedGroup.report_type;
    }

    return group.report_type;
  }

  return (
    <>
      <Card className={classes.container} id="report-settings-group-details-tour-element">
        <CardContent style={{padding: 0}}>
          <Grid container>
            <Grid className={classes.groupDetailsContainer} item lg={3} md={12} sm={12} xs={12}>
              <div className={clsx(loading && 'loading-line', classes.groupDetailSection)}>
                <GroupDetails group={group} loading={loading} />
                <div className={clsx(classes.expandButtonWrapper, loading && 'loading-line')}>
                  <PrimaryButton
                    text={expanded ? 'Zuklappen' : 'Ausklappen'}
                    onButtonClick={handleClickExpandButton}
                    icon={expanded ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                  />
                </div>
                {expanded &&
                  <div className={clsx(classes.expandButtonWrapper, loading && 'loading-line')}>
                    <PrimaryButton
                      text={'PDF Report Kundenliste'}
                      onButtonClick={handleDownloadPdfClick}
                      disabled={fileDownloadingProgress}
                    />
                  </div>
                }
              </div>
            </Grid>
            <Grid item lg={9} md={12} sm={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={classes.reportSettingsSection}>
                    <Grid item lg={4} md={4} sm={6} xs={12} id="report-settings-create-group-period-tour-element" style={{overflow: 'hidden'}}>
                      <div className={clsx(loading && 'loading-line')} >
                        <ReportSendingDate
                          selected={group.date_range}
                          handleChanged={handleValueChanged('date_range')}
                          dateRangeStartDate={group.date_range_start_date}
                          dateRangeEndDate={group.date_range_end_date}
                          handleDateRangeChanged={handleDateRangeChanged}
                          businessDaysOnly
                        />
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} className={classes.nonPadding}>
                      <Grid container className={clsx(classes.reportSettingsSectionAdditional, classes.reportSettingsSectionAdditionalOne)}>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="report-settings-create-group-report_type-tour-element" style={{overflow: 'hidden'}}>
                          <div className={clsx(loading && 'loading-line')} >
                            <ReportType
                              selected={getCurrentReportType()}
                              handleChanged={handleValueChanged('report_type')}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="report-settings-create-group-report_generation_type-tour-element" style={{overflow: 'hidden'}}>
                          <div className={clsx(loading && 'loading-line')} >
                            <ReportGenerationType
                              selected={group.report_generation_type}
                              handleChanged={handleValueChanged('report_generation_type')}
                              disabled={getCurrentDistributionType() === DISTRIBUTION_TYPES.POSTBOX.value}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="report-settings-create-group-send-type-tour-element" style={{overflow: 'hidden'}}>
                          <div className={clsx(loading && 'loading-line')}>
                            <DistributionType
                              selected={group.distribution_type}
                              handleChanged={handleValueChanged('distribution_type')}
                              disabled={isDistributionTypeDisabled()}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className={classes.nonPadding}>
                      <Grid container className={clsx(classes.reportSettingsSectionAdditional, classes.reportSettingsSectionAdditionalTwo)}>
                        <Grid item lg={12} md={12} sm={6} xs={12} id="report-settings-create-group-additional-reports-tour-element" style={{overflow: 'hidden'}}>
                          <div className={clsx(loading && 'loading-line')} >
                            <ReportsOptions
                              selected={getGroupAdditionalReports()}
                              handleChanged={handleValueChanged('additional_reports')}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={6} xs={12} id="report-settings-create-group-additional-reports-tour-element" style={{overflow: 'hidden'}}>
                          <div className={clsx(loading && 'loading-line')} >
                            <ReportsAreasVisibility
                              isDepotStatus={getCurrentReportType() === REPORT_TYPES.DEPOT_STATUS.value}
                              selected={getGroupAreasVisibility()}
                              handleChanged={handleValueChanged('areas_visibility')}
                              updateDisabled
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>                  
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.groupItemActionsWrapper} id="report-settings-group-actions-tour-element">
                  <div className={clsx(classes.groupItemActions, loading && 'loading-line')}>
                    <span onClick={handleEditGroupButtonClick}>
                      <i className="fa fa-plus" aria-hidden="true" /><span>Kunden hinzufügen/löschen</span>
                    </span>
                    <span onClick={() => onEditNameClick(group)} >
                      <i className="fa fa-pencil" aria-hidden="true" /><span>Gruppe umbenennen</span>
                    </span>
                    <span onClick={() => setIsDeleteModalVisible(true)}>
                      {isDeleting ? (
                        <CircularProgress size={15}/>
                      ) : (
                        <i className="fa fa-trash-o" aria-hidden="true" />
                      )}
                      <span>Gruppe löschen</span>
                  </span>
                    <PrimaryButton
                      id="report-settings-create-group-redirect-to-series-button-tour-element"
                      text="Report zusenden"
                      onButtonClick={onReportRedirectClick(group)}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {(group.expanded || expanded) && (
        <div id='report-settings-group-customer-list-tour-element'>
          <CustomersTable customers={group && group.customers || []}/>
        </div>
      )}
      <DeleteGroupModal
        open={isDeleteModalVisible}
        handleClose={() => setIsDeleteModalVisible(false)}
        handleDeleteClick={() => deleteGroup()}
        isDeleting={isDeleting}
        groupName={group.name}
      />
    </>
  )
};


export default connect(mapStateToProps)(withRouter(withWidth()(GroupSettings)));

