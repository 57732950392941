import { makeStyles } from '@material-ui/core'

const styles = theme => ({
  table: {
    tableLayout: 'fixed'
  },

  tableHead: {
    backgroundColor: 'rgb(249, 249, 249)',

    '& td': {
      color: 'rgb(177, 177, 177)',
      fontFamily: 'Roboto-Regular',
      fontWeight: 'bold',
      textAlign: 'right',
      '&:nth-of-type(2)': {
        textAlign: 'left',
      },
    }
  },
  modalTitle: {
    color: '#0D2440',
    '& > h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 22,
      lineHeight: 1.2,
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    }
  },
  modalAction: {
    padding: "8px 24px"
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  acceptButton: {
    fontFamily: 'Roboto-Bold',
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    verticalAlign: 'middle',
    '&:nth-of-type(1)': {
      width: 50,
      textAlign: 'center',
    },
    '&:nth-of-type(2)': {
      width: 200,
    },
    '&:nth-of-type(6)': {
      width: 80,
    },
    '&:last-child': {
      width: 95
    },
    padding: '8px 5px',

    '& .ellipsis': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '& .name': {
      cursor: 'pointer',
      '&:hover': {
        textOverflow: 'initial',
        overflow: 'initial',
        whiteSpace: 'initial',
        wordBreak: 'break-all'
      }
    },
    '& .link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& .percentagePA': {
      position: 'relative',

      '&::after': {
        content: "'p.a.'",
        position: 'absolute',
        left: '100%',
        marginLeft: 4
      }
    },
    '& .bold': {
      fontFamily: 'Roboto-Bold',
      fontWeight: 'normal,'
    },
  },

  [theme.breakpoints.down('md')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 150,
      },
    }
  },

  [theme.breakpoints.down('sm')]: {
    tableCell: {
      padding: 5,
      fontSize: 12,
      '&:nth-of-type(1)': {
        width: 100,
      },
    }
  },


  [theme.breakpoints.only('xs')]: {
    tableCell: {
      '& .hide-xs': {
        display: 'none'
      },
      '& .show-xs': {
        display: 'initial'
      }
    }
  },
  filterContainer: {
    '& h2': {
      fontSize: 20
    }
  },
  tableFooter: {
    background: 'inherit',

    '& > tr > td': {
      border: 'none',
    },

    '& .MuiTableCell-root': {
      verticalAlign: 'top',

      '&:nth-of-type(1)': {
        textAlign: 'left'
      }
    }
  }
});

export default makeStyles(styles)