import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  navContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    height: 35
  },
  navButton: {
    display: 'inline-flex',
    height: 35,
    minWidth: 'min-content',
    color: '#0092e5',
    marginLeft: 20,
    border: '2px solid #0092e5',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 4,
    opacity: 1,
    paddingTop: '4px',
    paddingBottom: '4px',
    padding: '6px 16px',
    lineHeight: 1.75,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: 'white',
      opacity: 0.5
    },
    '&:first-child': {
      marginLeft: 0,
      height: 35
    },
    '&:nth-child(2)': {
      height: 35
    },
    '& > span': {
      alignSelf: 'baseline',
      '& > span': {
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#0092E5',
        fontFamily: 'Roboto-Medium',
        alignSelf: 'center'
      },
      '& > i': {
        color: '#0092E5',
      }
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'min-content',
    },
  },
  navButtonActive: {
    '&:hover': {
      backgroundColor: '#0092e5',
      boxShadow: '0px 2px 5px grey',
      '& > span': {
        '& > span': {
          color: '#FFFFFF',
          '& > i': {
            color: '#FFFFFF',
          },
          '& > svg': {
            fill: '#FFFFFF',
          }
        }
      }
    },
  },
  dateContainer: {
      width: '100%',
      alignSelf: 'center'
  },
  handlingTime: {
      fontFamily: 'Roboto-Regular',
      fontSize: 13,
      color: '#323C42',
      margin: 0
  },
  signIcon: {
    lineHeight: '16px',
    alignItems: 'inherit',
    marginLeft: '8px',
    marginRight: '8px'
  },
  signButtonActive: {
    opacity: 1,
    pointerEvents: 'initial',
    cursor: 'pointer',
  },
  signButtonDisabled: {
    opacity: 0.5,
    pointerEvents: 'auto',
    cursor: 'not-allowed',
  }
})

export default makeStyles(styles)
