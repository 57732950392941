import React from "react";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useStyles from "./styles";

import GroupInfo from "./components/GroupInfo";
import LastSendCell from "../../../LastSendCell";
import {PrimaryButton} from "../../../../../../components/Buttons";
import {ROUTES} from "../../../../../../routes";
import {setReportingCustomer, setReportingGroup} from "../../../../actions";
import connect from "react-redux/es/connect/connect";
import CustomersTable from "../../../../../../components/Group/CustomersTable";
import clsx from "clsx";
import {getErrorMessage, newDesignUsed} from '../../../../../../utils/utils'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../../../InvestmentPlatform/utils'

import {downloadPdf} from "../../../../../../utils/utils";
import { displayErrorSnackBar } from '../../../../../../components/SnackbarProvider/actions';
import _ from 'lodash';
import {CircularProgress} from "@material-ui/core";
import AutoLastSendCell from "../../../AutoLastSendCell";
import {GroupResource} from "../../../../../../utils/api";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const CustomerGroupListItem = (props) => {
  const classes = useStyles();

  const {
    group,
    history,
    dispatch,
    loading,
    onEditNameClick,
    setGroupToDelete,
    isDeleting
  } = props;

  const [dynamicReportSettingsTabLink, setDynamicReportSettingsTabLink] = React.useState(null)
  const [dynamicAutoReportSettingsTabLink, setDynamicAutoReportSettingsTabLink] = React.useState(null)
  const [expanded, setExpanded] = React.useState(false);
  const [fileDownloadingProgress, setFileDownloadingProgress] = React.useState(false);

  const [customers, setCustomers] = React.useState(undefined)
  const [customersListLoading, setCustomersListLoading] = React.useState(false)

  React.useEffect(() => {

    if (props.investmentPlatform.routes) {
      executeIfPathExist(props.investmentPlatform.routes, 'SERIES_REPORTING', (path) => {

        let newPath = '/' + getInvestmentDynamicPath()
        let url = newPath + path;

        setDynamicReportSettingsTabLink(url + ROUTES.BROKER.SERIES_REPORTING.children.GROUP.children.REPORT.newDesignPath)
        setDynamicAutoReportSettingsTabLink(url + ROUTES.BROKER.SERIES_REPORTING.children.GROUP.children.AUTO_REPORT.newDesignPath)

      })
    }

  }, [props.investmentPlatform.routes])

  const isDynamicGroupProcess = !_.isNil(group.dynamic_filters)
  const handleClickExpandButton = () => {
    // On expand if customers of the group are not set yet - send request to get them if group is dynamic else get from goup obj
    if(_.isNil(customers) && !expanded){
      isDynamicGroupProcess ? getDynamicGroupCustomers() : setCustomers(group.customers)
    }
    return setExpanded(!expanded);
  };

  const handleClickReportButton = () => {
    dispatch(setReportingGroup(group));
    dispatch(setReportingCustomer(null));
    return history.push(dynamicReportSettingsTabLink.replace(':group_id', group.id))
    // return history.push((newDesignUsed() && dynamicCoverPagePath) ?  dynamicCoverPagePath.replace(':group_id', entity.id) : buildReportingCoverLink(entity.id));
  };

  const handleClickAutoReportButton = () => {
    dispatch(setReportingGroup(group));
    dispatch(setReportingCustomer(null));
    return history.push(dynamicAutoReportSettingsTabLink.replace(':group_id', group.id))
  }

  const handleDownloadPdfClick = async () => {
    try {
      setFileDownloadingProgress(true);
      await downloadPdf('/reports/broker/group_postbox_status/', undefined, 'POST', undefined, {'group_id': group.id});

    } catch (errors) {
      props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'));
    } finally {
      setFileDownloadingProgress(false);
    }
  }

  const handleEditGroupButtonClick = () => {
    let path = ROUTES.BROKER.GROUP_UPDATE.path.replace(':group_id', group.id)

    if (newDesignUsed()) {
      let investmentDynamicPath = getInvestmentDynamicPath()
      executeIfPathExist(props.investmentPlatform.routes, 'SERIES_REPORTING', (dynamicPath) => {
        path = '/' + investmentDynamicPath + dynamicPath + ROUTES.BROKER.NEW_GROUP_UPDATE.newDesignPath.replace(':group_id', group.id)
      })
    }

    props.history.push(path)
  }


  const getDynamicGroupCustomers = () => {
    // Function to get dynamic group customers

    setCustomersListLoading(true)
    GroupResource.at('customers/').get({dynamic_group_id: group.id})
      .then(responseCustomers => {
        setCustomers(responseCustomers)
      })
      .catch(errors => {
         props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
      })
      .finally(() => {
        setCustomersListLoading(false)
      })
  }

  return (
    <Grid item className={classes.listItemContainer} id="reporting-group-item-tour-element">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container>

            <Grid item md={4} sm={12} className={classes.item}>
              <div className={clsx(loading && 'loading-line')}>
                <GroupInfo entity={group}/>
                {/* Unfold Group members list btn */}
                <div className={clsx(classes.expandButtonWrapper, loading && 'loading-line')}>
                  <PrimaryButton
                    text={expanded ? 'Zuklappen' : 'Ausklappen'}
                    onButtonClick={handleClickExpandButton}
                    icon={expanded ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                  />
                </div>
                {expanded &&
                  <div className={clsx(loading && 'loading-line')} style={{marginTop: '5px'}}>
                    <PrimaryButton
                      text={'PDF Report Kundenliste'}
                      onButtonClick={handleDownloadPdfClick}
                      disabled={fileDownloadingProgress}
                    />
                   </div>
                }
              </div>
            </Grid>

            {/* col 2-4 upper row */}
            <Grid container md={8} sm={12} className={classes.upperRowWrapper}>
                <Grid item xs={4} className={classes.item}>
                    <div className={clsx(loading && 'loading-line')}>
                      <AutoLastSendCell
                        classes={classes}
                        next_generation_date={_.get(group, 'auto_settings.next_generation_date')}
                        periodicity={_.get(group, 'auto_settings.periodicity')}
                        handleClickAutoReportButton={handleClickAutoReportButton}
                      />
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    <div className={clsx(loading && 'loading-line')}>
                      <LastSendCell
                        date={group.last_report && group.last_report.creation_time}
                        type={group.last_report && group.last_report.report_type}
                      />
                    </div>
                  </Grid>
                <Grid item xs={4} className={classes.item} style={{alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <div className={clsx(loading && 'loading-line')}>
                      <PrimaryButton
                        text='Gruppenreport einmalig senden'
                        onButtonClick={handleClickReportButton}
                        classes={classes}
                        style={{minHeight: 100}}
                      />
                    </div>
                  </Grid>
            </Grid>

            {/* group col 2-4 lower part as raw with actions */}
            <Grid className={classes.actionsRow} container justify="flex-end">
              <Grid item md={8} sm={12} className={classes.groupItemActionsWrapper} id="report-settings-group-actions-tour-element">
                <Grid container className={clsx(loading && 'loading-line')}>
                  <Grid item xs={4} className={classes.actionItem}>
                    <span onClick={handleEditGroupButtonClick}>
                      <i className="fa fa-plus" aria-hidden="true" /><span>Kunden hinzufügen/löschen</span>
                    </span>
                  </Grid>
                  <Grid item xs={4} className={classes.actionItem}>
                    <span  onClick={() => onEditNameClick(group)}>
                      <i className="fa fa-pencil" aria-hidden="true" /><span>Gruppe umbenennen</span>
                    </span>
                  </Grid>
                  <Grid item xs={4} className={classes.actionItem}>
                    <span onClick={() => setGroupToDelete(group)}>
                      {isDeleting ? (
                        <CircularProgress size={15}/>
                      ) : (
                        <i className="fa fa-trash-o" aria-hidden="true" />
                      )}
                      <span>Gruppe löschen</span>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
      {expanded && <CustomersTable customers={customers || []} loading={customersListLoading} isDynamicGroupProcess={isDynamicGroupProcess}/>}

    </Grid>
  );
};

export default connect(mapStateToProps)(withRouter(CustomerGroupListItem));