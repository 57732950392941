import { makeStyles } from "@material-ui/core";
import { SECTION_PADDING } from '../../../containers/CustomerDashboardV2/constants';
import { ROTATE_ICON_STYLES } from '../common_styles';

const commonStyles = {
  ...ROTATE_ICON_STYLES,
  tableHead: {
    backgroundColor: '#f9f9f9',

    '& td': {
      color: '#80858C !important'
    }

  },
  tableFooter: {
    backgroundColor: '#eef0f2',
    '& > tr': {
      height: 65
    },
    '& td': {
      verticalAlign: 'middle'
    }
  },
}

export default makeStyles(theme => ({
  ...commonStyles,
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
      paddingLeft: SECTION_PADDING
    },
    '&:nth-of-type(2)': {
      width: 'auto'
    },
    '&:nth-of-type(3)': {
      width: 150
    },
    '&:nth-of-type(4)': {
      width: 150
    },
    '&:nth-of-type(5)': {
      width: 95
    },
    '&:nth-of-type(6)': {
      width: 95
    },
    '&:nth-of-type(7)': {
      width: 130
    },
  },

  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(3)': {
        width: 100
      },
      '&:nth-of-type(4)': {
        width: 100
      },
    }
  },

  [theme.breakpoints.only('xs')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 100
      },
    }
  },
}))

export const useTradingTableClasses = makeStyles(theme => ({
  ...commonStyles,
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
      paddingLeft: SECTION_PADDING
    },
    '&:nth-of-type(2)': {
      width: 'auto'
    },
    '&:nth-of-type(3)': {
      width: 150,
      verticalAlign: 'middle'
    },
    '&:nth-of-type(4)': {
      width: 150,
      verticalAlign: 'middle'
    },
    '&:nth-of-type(5)': {
      width: 95,
      verticalAlign: 'middle'
    },
    '&:nth-of-type(6)': {
      width: 95,
      verticalAlign: 'middle'
    },
    '&:nth-of-type(7)': {
      width: 130,
      verticalAlign: 'middle'
    },
    '&:nth-of-type(8)': {
      width: 130,
      verticalAlign: 'middle'
    },
  },
  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(3)': {
        width: 100
      },
      '&:nth-of-type(4)': {
        width: 100
      },
      '&:nth-of-type(7)': {
        width: 130
      },
      '&:nth-of-type(8)': {
        width: 130
      },
    }
  },

  [theme.breakpoints.only('xs')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 100
      },
    }
  },
}));