import {makeStyles} from "@material-ui/core";
import {fontSizeTable, smFontSizeTable} from "../../../../utils/constants";
import { SECTION_PADDING } from '../../constants';

const BORDER = '1px solid #d9dcdf';

export const CELL_MIN_WIDTH = 145;

const commonEllipsisStyles = {
  textAlign: 'left',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  boxOrient: 'vertical',
  display: '-webkit-box',
  wordBreak: 'break-word',
}

const commonCellContentWrapStyles = {
  display: 'flex',
  alignItems: 'center',
}

const getCommonWidth = (theme) => {
  return {
    [theme.breakpoints.only("lg")]: {
      maxWidth: 450,
      minWidth: 250
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: 300,
      minWidth: 200
    },
    [theme.breakpoints.only("sm")]: {
      maxWidth: 200,
      minWidth: 150
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: 100,
      minWidth: 50
    },
  }
}

export default makeStyles((theme) => ({
  productStructureTable: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    overflowX: 'auto',
    position: 'relative',

    '& .tr': {
      '&:not(:last-child)': {
        borderBottom: BORDER,
      },
      minWidth: CELL_MIN_WIDTH,
      '& .errorTd': {
        borderBottom: 'none!important',
      },

      flexWrap: 'nowrap',
      '& .highlightedCell': {
        background: `${theme.palette.primary.main}12`, // last two numbers is opacity
        '& div': {
          fontWeight: 'bold',
        },
        '& .background': {
          backgroundColor: 'white',
          border: '1px solid white',
        }
      }
    },

    '& .tr .td': {
      fontSize: fontSizeTable,
      [theme.breakpoints.down("sm")]: {
        fontSize: smFontSizeTable,
      },
      padding: `6px ${SECTION_PADDING}px`,
      minWidth: CELL_MIN_WIDTH,
      minHeight: 37,
      ...commonCellContentWrapStyles,
    },

    '& .thead .td': {
      backgroundColor: '#F9F9F9',
    },
    '& .tbody .td': {
      backgroundColor: '#FFFFFF',
    }
  },
  starIcon: {
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  cogIcon: {
    color: theme.palette.primary.main,
  },
  filledPercentage: {
    justifyContent: 'flex-end',
    paddingRight: '4px',
    fontSize: fontSizeTable,
    [theme.breakpoints.down("sm")]: {
      fontSize: smFontSizeTable,
    }
  },
  background: {
    boxSizing: 'border-box',
    border: '1px solid white',
    backgroundColor: '#F9F9F9',
  },

  cellContentWrapper: {
    ...commonCellContentWrapStyles,
    height: 16,
  },
  headerCellContentWrapper: {
    ...commonCellContentWrapStyles,
    height: 38,
  },

  headerCellContent: {
    ...commonEllipsisStyles,
    lineClamp: 2,
  },
  cellContent: {
    ...commonEllipsisStyles,
    lineClamp: 1,

  },
  dynamicEllipsis: {
    ...commonEllipsisStyles,
    // lineClamp: does not work, that is way '-webkit-line-clamp' is used as key
    '-webkit-line-clamp': 1,
    textAlign: 'center',
  },

  noData: {
    borderBottom: BORDER,
    borderRight: BORDER,
    padding: `6px ${SECTION_PADDING}px`,
    minHeight: 37,
    height: '100%',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },

  tbodyContent: {
    flexDirection: 'column',
    '& .tr:not(.loadingContainer):last-of-type': {
      borderRight: 'none!important',
      '& div': {
        borderRight: 'none!important',
      },
    },
    '& .loadingCol:last-of-type': {
      '& .loadingContainer': {
        '& div': {
          borderRight: 'none!important',
        }
      },
    },
  },

  firstColumn: {
    width: '50%',
    ...getCommonWidth(theme),

    '& .tr': {
      ...getCommonWidth(theme)
    },

    '& .tr .td': {
      borderRight: BORDER,
      ...getCommonWidth(theme)
    }
  }
}))