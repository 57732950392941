import React from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid/Grid";

import styles from './styles';
import {
  getCountWithName,
  paginateArray,
  UserUtils
} from "../../../../utils/utils";

import CustomerListItem from "./components/CustomerListItem";
import Pagination from "../../../../components/Pagination/Pagination";
import PaginationInput from "../../../../components/Pagination/PaginationInput";
import {SORTING_TYPES} from "../../../../components/FilteringPanel/components/SortingFilter/constants";
import {filterCustomers, sortCustomers} from "../../../RiskDashboard/utils";
import CustomerFilteringPanel from "./components/CustomerFilteringPanel";
import {getAgencyFilterOptions} from "../../../../components/FilteringPanel/components/ListSelector/constants";
import _ from "lodash";
import withCustomersData from "../../../../components/CustomersDataProvider";

import FilterHeader from '../../../DashboardSettings/components/FilterHeader';
import { hasFilterResult } from '../../../../utils/utils';

const CUSTOMERS_PER_PAGE = 10;
const START_PAGE_INDEX = 0;
const DEFAULT_LIST_FILTER = {
  search: '',
  first_letter: '',
  sortingType: SORTING_TYPES.NAME_ASC,
  triggerFilter: true
}
const DEFAULT_FILTER_RESULT = {
  count: undefined,
  word: undefined
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  unfilteredCustomers: state.get('reporting').toJS().customers,
  customersLoading: state.get('reporting').toJS().customersLoading,
  guideTourCustomers: state.get('reporting').toJS().guideTourCustomers,
});


const CustomersTabContent = (props) => {
  const {
    classes,
    customerRequestFilter,
    setCustomerRequestFilter,
    unfilteredCustomers,
    customersLoading,
    auth,
    guideTourCustomers,
    customersListRender,
    defaultListFilter,
    useFiltersOptionsLoading=true
  } = props;

  const [useExpertSearch, setUseExpertSearch] = React.useState(false);

  const getFilteredCustomers = (listFilter) =>{
    // getting unfiltered customers in proper format
    let customersJoined = [].concat.apply([], unfilteredCustomers || []);

    // filtering customers by easy filter
    let filtered = [...filterCustomers(customersJoined, listFilter || customerListFilter)];

    // if expert search is active -> get intersection of customers filtered by easy search and expert filters
    if(useExpertSearch){
      filtered = _.intersectionBy(filtered, _.flatten(props.filteredCustomersData.data), 'customer_id')
    }

    return sortCustomers(filtered, customerListFilter.sortingType);
  };

  const [filteredCustomers, setFilteredCustomers] = React.useState([]);

  const [page, setPage] = React.useState(START_PAGE_INDEX);

  const [customerListFilter, setCustomerListFilter] = React.useState({...DEFAULT_LIST_FILTER, ...defaultListFilter});

  const [filterResult, setFilterResult] = React.useState(DEFAULT_FILTER_RESULT);


  React.useEffect(() => {
    handleFilterCustomers()
  }, [unfilteredCustomers, customerRequestFilter]);

  React.useEffect(() => {
    if (customerListFilter.triggerFilter) {
      handleFilterCustomers()
    }
  }, [customerListFilter.triggerFilter]);


  const handleFilterCustomers = () => {
    let filtered = getFilteredCustomers();

    const pages = [...paginateArray(filtered, CUSTOMERS_PER_PAGE)];

    let searchText = customerListFilter.first_letter ? `Anfangszeichen ${customerListFilter.first_letter}` : customerListFilter.search;

    setFilterResult({
      word: searchText,
      count: filtered.length
    });

    setPage(0); // when pages is one dimensional to make renderList() work correctly, page should be set to 0
    setCustomerListFilter({
      ...customerListFilter,
      triggerFilter: false
    })
    setFilteredCustomers(pages);
  };

  const handleSearchClicked = (value) => {
    let searchValue = value ? value.trim() : ''

    setCustomerListFilter({
      ...customerListFilter,
      first_letter: '',
      search: searchValue,
      triggerFilter: true
    });

    let customersJoined = [].concat.apply([], unfilteredCustomers);

    setFilterResult({
      word: searchValue,
      count: getCountWithName(customersJoined, searchValue)['true']
    });
  };

  const handleSearchByLetter = (letter) => {
    let searchValue = letter ? letter.trim() : ''
    setCustomerListFilter({
      ...customerListFilter,
      first_letter: searchValue,
      search: '',
      triggerFilter: true
    });
  };


  const handleClientTypeChanged =  (event) => {
    setCustomerRequestFilter((prevState) => ({
      ...prevState,
      // in case we need to store CLIENT_TYPE insted of value
      customerType: _.find(getAgencyFilterOptions(auth), type => type.value === event.target.value),
      requestType: event.nativeEvent.target.getAttribute('data-type'),
    }));
  };

  const renderList = () => {

    let customers;

    if (guideTourCustomers && guideTourCustomers.length) {
      customers = guideTourCustomers
    } else if (customersLoading) {
      customers = [{}, {}, {}];
    } else {
      customers = [...filteredCustomers[page] || []];
    }

    if (customersListRender) {
      return customersListRender({customersLoading, filterResult, customers, paginationBlock: <PaginationBlock />})
    }

    return (
      <>
        {hasFilterResult(filterResult) &&
          <FilterHeader filterResult={filterResult}/>
        }

        <Grid item xs={12} id="reporting-customers-list-tour-element">
          {customers.map(customer =>
            <CustomerListItem key={customer.customer_id} customer={customer} loading={customersLoading} />
          )}
        </Grid>
        <PaginationBlock />
      </>
    )
  };

  // FIXME: move pagination into separate component (for better memory usage and as it's also used in other places)
  const PaginationBlock = () => {
    if (filteredCustomers.length > 1) {
      return (
        <Grid item xs={12}>
          <Grid container className={classes.paginationWrapper}>
            <Grid item className={classes.paginationItem}>
              <span className={classes.paginationInfo}>Seite {page + 1} von {filteredCustomers.length}</span>
            </Grid>
            <Grid item className={classes.paginationItem}>
              <Pagination handlePageChanged={setPage} totalPageCount={filteredCustomers.length} currentPage={page}/>
            </Grid>
            <Grid item className={classes.paginationItem}>
              <PaginationInput handlePageChanged={setPage}
                               totalPageCount={filteredCustomers.length}
                               currentPage={page}
                               label={'Seite aufrufen:'}/>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return null;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomerFilteringPanel
            customerType={customerRequestFilter.customerType.value}
            defaultSearchValue={''}
            handleSearchByLetter={handleSearchByLetter}
            handleSearchClicked={handleSearchClicked}
            handleClientTypeChanged={handleClientTypeChanged}
            isChief={UserUtils.isChief(auth)}
            user={auth.user}
            useNameAndKundenNrTooltip
            loading={(useFiltersOptionsLoading || useExpertSearch) && props.globalCustomers.loading}
            disabled={!_.isNil(props.globalCustomers.errors)}
            withExpertFilters={true}
            onFiltersUpdated={props.onFiltersUpdated}
            filtersOptions={props.filtersOptions}
            hasCustomers={!_.isEmpty(props.customers)}
            setExpertFilteringEnabled={setUseExpertSearch}
          />
        </Grid>

        {/* Customers List */}
        {renderList()}
      </Grid>

    </>
  )
};

export const ShowReportingCustomersTabData = withStyles(styles)(
  connect(mapStateToProps)(withCustomersData(CustomersTabContent, true)))

export default withStyles(styles)(
  connect(mapStateToProps)(withCustomersData(CustomersTabContent))
);

