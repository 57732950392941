export const REPORTS_AREAS = {
    PAYMENT_PLANS: {
        value: 1,
        description: 'Zahlpläne'
    },
    PROFIT_LOSS: {
        value: 2,
        isDisabledForDepotStatus: true,
        description: 'vollständig verkaufte Vermögenswerte'
    },
    INCLUDE_HISTORICAL_PORTFOLIOS: {
        value: 3,
        description: 'historische Depots'
    },
    WITH_TRANSACTION_SALDO: {
        value: 4,
        description: 'Transaktionssaldo'
    },
    OTHER_ASSETS: {
        value: 5,
        description: 'Sonstige Vermögenswerte (inkl. Verrechnungskonto)'
    },
    TRANSACTIONS_MONITOR: {
        value: 6,
        description: 'Transaktionsmonitor'
    }
};

// TODO: deprecated
export const REPORTS_AREAS_LIST = [
    REPORTS_AREAS.OTHER_ASSETS,
    REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS,
    REPORTS_AREAS.WITH_TRANSACTION_SALDO,
    REPORTS_AREAS.PAYMENT_PLANS,
    REPORTS_AREAS.PROFIT_LOSS
];

// For customers dashboard settings we moved Historical Depot to new place in BCA-8141.
// That is why we have separate constant without this option
export const CUSTOMER_RELATED_REPORTS_AREAS_LIST = REPORTS_AREAS_LIST.filter(
  (option) => option.value !== REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value);

export const REPORTS_AREAS_LIST_V2 = [
    REPORTS_AREAS.OTHER_ASSETS,
    REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS,
    REPORTS_AREAS.WITH_TRANSACTION_SALDO,
    REPORTS_AREAS.PROFIT_LOSS,
    REPORTS_AREAS.TRANSACTIONS_MONITOR,
    REPORTS_AREAS.PAYMENT_PLANS,
];

// For customers dashboard settings we moved Historical Depot to new place in BCA-8141.
// That is why we have separate constant without this option
export const CUSTOMER_RELATED_REPORTS_AREAS_LIST_V2 = REPORTS_AREAS_LIST_V2.filter(
  (option) => option.value !== REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value);