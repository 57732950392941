import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  card: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: "5px",
    height: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    opacity: 0.5,
    boxShadow: "0px 3px 15px #00000033",
    border: "1px solid #D8DCDF",
  },
  border: {
    border: "1px solid #D8DCDF",
  },
  title: {
    fontWeight: "bold",
    color: "#202A38",
    fontSize: "20px",
  },
  sub_title: {
    fontSize: "16px",
    color: "#0D2440",
  },
  label: {
    margin: 0,
    fontFamily: "Roboto-Regular",
    fontWeight: "normal"
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-start",
    marginTop: "auto",
  },
  activeStep: {
    opacity: 1,
    backgroundColor: "rgba(216,220,223,0.4)",
  },
  disabled: {
    border: "none",
    boxShadow: "none",
    background: "#D8DCDF",
  },
  selected: {
    border: "1px solid #0092E5",
    background: "none",
  },
  current: {
    backgroundColor: "#D8DCDF",
    boxShadow: "none",
  },
}));
