import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    minHeight: 500,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)'
  },
  summary: {
    borderBottom: '1px solid #DADEE1',
    padding: 10
  },
  summaryText: {
    padding: 0,
    margin: 0,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#313D44',

    '& > span:last-child': {
      marginLeft: 10
    }
  },
  summaryNumber: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#ABB6BE',
    display: 'inline-block',
    padding: '2px 15px',
    borderRadius: 12,
  },

  //#region Customers list

  customersListHead: {
    backgroundColor: '#DADEE1',

    '& th': {
      color:'#636A71',
      fontSize: 12,
      fontFamily: 'Roboto-Regular',
      fontWeight: 'bold',
      padding: '0px 10px',
    }
  },
  customersListRow: {
    borderBottom: '1px solid #DADEE1',
    '& td': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: '#313D44',
      padding: '4px 10px',

      '&:last-child': {
        padding: '4px 0px'
      }
    }
  },
  //#endregion
  inputContainer: {
    padding: '0px 10px 20px'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  textField: {
    fontFamily: 'Roboto-Regular',
    fontSize:18,
    color: '#4D4F5C',
    width: '100%'
  },
  inputLabelRoot: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    fontSize: 12
  },
  inputLabelShrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    color: '#313D44 !important',
  },
  inputLabelError: {
    color: 'red !important'
  },
  inputAdornment: {
    fontSize: 12,
    color: '#ABB6BE'
  },
  formHelperText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  },
  deleteCustomerIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))