import React from 'react';
import _ from 'lodash';
import moment from 'moment'

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import KeyboardDatePicker from '../../../../components/KeyboardDatePicker';
import {isWeekend, renderInfoIconWithLabel, shouldDisableWeekendIfRequired} from '../../../../utils/utils';

/* BCA modules*/
import useStyles from './styles';

import {DEFAULT_SENDING_DATE, SENDING_DATE} from './constants';
import clsx from "clsx";

export default (props) => {
    const classes = useStyles();
    const START_RANGE_DATE_FILED_NAME = 'date_range_start_date';

    const [value, setValue] = React.useState(props.selected || DEFAULT_SENDING_DATE.value);
    const [dateRangeStartDate, setDateRangeStartDate] = React.useState(props.dateRangeStartDate && moment(props.dateRangeStartDate ) || null);
    const [dateRangeEndDate, setDateRangeEndDate] = React.useState(props.dateRangeEndDate && moment(props.dateRangeEndDate) || undefined);

    // workaround to update inner values from outside
    React.useEffect(() => {
      if (!_.isNil(props.selected)) {
        setValue(props.selected);
      }
    }, [props.selected]);

    React.useEffect(() => {
      if (!_.isNil(props.dateRangeStartDate)) {
        setDateRangeStartDate(moment(props.dateRangeStartDate));
      }
    }, [props.dateRangeStartDate]);

    React.useEffect(() => {
      if (!_.isNil(props.dateRangeEndDate)) {
        setDateRangeEndDate(moment(props.dateRangeEndDate));
      }
    }, [props.dateRangeEndDate]);

    const MIN_WORKING_DAY_PERIOD = 10;
    const [minPeriodError, setMinPeriodError] = React.useState('');

    const validateCustomDateRange = (error) => {
      // if there are no errors make additional check for working days period
      if(!error && dateRangeStartDate){
        // set period error msg and state
        let minErrorMsg = 'Der Berichtszeitraum muss mehr als 10 Tage umfassen.';
        error = true;

        const weekends = [0, 6]; // Sunday = 0, Saturday = 6;
        let workDaysNumber = 0;
        let currentDay = dateRangeStartDate.clone();
        const endDate = dateRangeEndDate || moment(); // today by default
        // loop over days
        while (currentDay <= endDate){
          //increase number of working days if current day is not weekend
          if(!weekends.includes(currentDay.day())){
            workDaysNumber++;
            // if number of working days is enough remove error msg and state
            if(workDaysNumber >= MIN_WORKING_DAY_PERIOD){
              minErrorMsg = '';
              error = false;
              break;
            }
          }
          currentDay = currentDay.add(1, 'days');
        }
        setMinPeriodError(minErrorMsg)
      } else {
        setMinPeriodError(''); // clean error
      }

      props.onDatePickerError && !props.disabled && value === SENDING_DATE.CUSTOM.value
        ? props.onDatePickerError(error)
        : props.onDatePickerError(false) // if custom date picker is disabled or not selected, do not send error

    };

    const handleDateRangeChanged = (fieldName) => (date) => {
      if (!_.isNil(props.handleDateRangeChanged) && value === SENDING_DATE.CUSTOM.value) {
        if(!_.isNil(date)){
          props.handleDateRangeChanged(fieldName, moment(date).format('YYYY-MM-DD'));
        }
        else if(fieldName === START_RANGE_DATE_FILED_NAME){
          props.handleDateRangeChanged(fieldName, null);
        }
      }
    };

    const handleChange = (event) => {
        setValue(+event.target.value);

        if (!_.isNil(props.handleChanged)) {
          props.handleChanged(+event.target.value);
        }
    };

    const optionLabelStyles = {paddingRight:9}

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled} component="fieldset">
            <FormLabel component="legend" classes={{root: classes.formLabel, focused: classes.focusedLabel}}>Reporting-Zeitraum</FormLabel>
            {props.isAutoReporting &&
              <RadioGroup
                name="sendingDateType"
                value={`${value}`}
                onChange={handleChange}
                style={{flexWrap: 'wrap', flexDirection: props.column ? 'column': 'row'}}
              >
                <FormControlLabel
                  value={`${SENDING_DATE.LAST_PERIOD.value}`}
                  label={
                    renderInfoIconWithLabel('Mit dieser Option wird ein Report immer für die letzte abgeschlossene Periode des gewählten Zeitraums erstellt, also z.B. mit der Einstellung Quartal, für das letzte abgeschlossene Quartal. Diese Option ist relevant für automatische Serienreports, welche für Reports in regelmässigen Zeitabständen über zurückliegende Zeitperioden konfiguriert worden sind.',
                      SENDING_DATE.LAST_PERIOD.description, optionLabelStyles
                    )
                  }
                  classes={{label: classes.formControlLabel}}
                  control={
                    <Radio color="primary" classes={{root: classes.radio}}/>
                  }/>
              </RadioGroup>
              }
            <RadioGroup
              name="sendingDateType"
              value={`${value}`}
              onChange={handleChange}
              style={{flexWrap: 'wrap', flexDirection: props.column ? 'column': 'row'}}
            >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <FormControlLabel
                    value={`${SENDING_DATE.BEGIN.value}`}
                    label={renderInfoIconWithLabel('Bis zum heutigen Datum', SENDING_DATE.BEGIN.description, optionLabelStyles)}
                    classes={{label: classes.formControlLabel}}
                    control={
                      <Radio color="primary" classes={{root: classes.radio}}/>
                    }/>
                  <FormControlLabel
                    value={`${SENDING_DATE.ONE_Y.value}`}
                    label={SENDING_DATE.ONE_Y.description}
                    classes={{label: classes.formControlLabel}}
                    control={
                      <Radio color="primary" classes={{root: classes.radio}}/>
                    }/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <FormControlLabel
                    value={`${SENDING_DATE.THREE_Y.value}`}
                    label={SENDING_DATE.THREE_Y.description}
                    classes={{label: classes.formControlLabel}}
                    control={
                      <Radio color="primary" classes={{root: classes.radio}}/>
                    }/>
                  <FormControlLabel
                    value={`${SENDING_DATE.FIVE_Y.value}`}
                    label={SENDING_DATE.FIVE_Y.description}
                    classes={{label: classes.formControlLabel}}
                    control={
                      <Radio color="primary" classes={{root: classes.radio}}/>
                    }/>
                </div>
            </RadioGroup>
            <RadioGroup
              name="sendingDateType"
              value={`${value}`}
              onChange={handleChange}
              style={{flexWrap: 'wrap', flexDirection: props.column ? 'column': 'row'}}
            >
              <div style={{display: 'flex', alignItems: 'self-end', flexDirection: 'row'}}>
                  <FormControlLabel
                    label=''
                    value={`${SENDING_DATE.CUSTOM.value}`}
                    control={
                      <Radio color="primary" classes={{root: classes.radio}}/>
                    }/>
                    <div>
                      <div className={classes.datePickerLabelItem}>
                        <div className={clsx(classes.datePickerLabel, props.disabled && classes.disabledDatePickerLabel)}>
                          Von
                        </div>
                        <KeyboardDatePicker
                          value={dateRangeStartDate}
                          maxDate={dateRangeEndDate || moment()}
                          disabled={props.disabled || value !== SENDING_DATE.CUSTOM.value}
                          errorMessage={minPeriodError}
                          onError = {validateCustomDateRange}
                          onChange={(date) => {
                            setDateRangeStartDate(date);
                            handleDateRangeChanged(START_RANGE_DATE_FILED_NAME)(date)
                          }}
                          classes={{
                            root: classes.keyboardDatePickerRoot
                          }}
                          shouldDisableDate={shouldDisableWeekendIfRequired(props.businessDaysOnly)}
                        />
                        {renderInfoIconWithLabel('Ohne Eingabe wird der Investmentbeginn als Startdatum herangezogen.', undefined, optionLabelStyles)}
                      </div>
                      <div  className={classes.datePickerLabelItem}>
                        <div className={clsx(classes.datePickerLabel, props.disabled && classes.disabledDatePickerLabel)}>
                          bis
                        </div>
                        <KeyboardDatePicker
                          value={dateRangeEndDate}
                          disabled={props.disabled || value !== SENDING_DATE.CUSTOM.value}
                          maxDate={moment()}
                          onChange={(date) => {
                            setDateRangeEndDate(date);
                            handleDateRangeChanged('date_range_end_date')(date)
                          }}
                          classes={{
                            root: classes.keyboardDatePickerRoot
                          }}
                          shouldDisableDate={shouldDisableWeekendIfRequired(props.businessDaysOnly)}
                        />
                      </div>
                    </div>
                </div>   
            </RadioGroup>
          </FormControl>
        </div>
    )
}