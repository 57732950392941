import React from 'react';
import { useParams, useHistory } from 'react-router-dom'
import moment from "moment";
import { connect } from 'react-redux'
import _ from 'lodash'

// region Third party components
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
// endregion

// region Internal components
import Filters from './components/Filters/Filters'
import Transactions from './components/Transactions/Transactions'
import DownloadIcon from "../../images/DownloadIcon";
import Link from "../../components/Link";
import {PrimaryButton} from "../../components/Buttons";
import HTMLHostRelatedSharedSetting from "../../components/HTMLHostRelatedSharedSetting";
// endregion

import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";
import {parseResponse, QuestionnairesHandlerResource} from "../../utils/api";
import useFetchData from "../../hooks/useDataFetch";
import useStyles from './styles'
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import {
  getClearingAccountsOptions,
  getCustomerPortfoliosOptions,
  getInstrumentsOptions,
  getTrackingDate,
  getTransactionTypesOptions,
  updateTransactionDepot
} from "./utils";
import { paginateArray, toLocalDateTime } from '../../utils/utils';
import {HOST_RELATED_SHARED_SETTINGS_KEYS} from "../../components/SharedSettingsProvider/constants"
import {
  CUSTOMER_DATA_LOADING_ERROR,
  INSTRUMENT_TYPES_OPTIONS,
  TRANSACTIONS_DATA_LOADING_ERROR, TRANSACTIONS_PDF_GENERATION_ERROR
} from "./constants";
import { displayErrorSnackBar } from "../../components/SnackbarProvider/actions";
import useFetchDocument from "../../hooks/useFetchDocument";
import {hasPortfolios} from "../../components/CustomersSelectorProviderNew/utils";
import { PERFORMANCE_TIME_TYPE } from '../../utils/constants';


const CUSTOMER_DATA_FETCHING_PARAMS = {
  with_shared_settings: false,
  with_instruments: true,
  with_companies: true,
  with_transaction_types: true,
  with_historical_portfolios: true,
}

const DEFAULT_PERFORMANCE_TIME_TYPE = PERFORMANCE_TIME_TYPE.LAST_1_YEARS;
let defaultTimeFrameSelection = DEFAULT_PERFORMANCE_TIME_TYPE.getDateRange();
let defaultTimeFrameType = DEFAULT_PERFORMANCE_TIME_TYPE;


const DEFAULT_FILTERS = {
  depots: [],
  clearing_accounts: [],
  instruments: [],
  instrumentTypes: [],
  transactions: [],
  bookings: [],
  dates: [null, null],
  datesType: defaultTimeFrameType,
  is_canceled: true
}

export const TransactionsMonitoringContext = React.createContext()

export function useTransactionsMonitoringContext() {

  const context = React.useContext(TransactionsMonitoringContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}


TransactionsMonitoring.propTypes = {

};


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

/**
 * Component to display customer related transactions.
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function TransactionsMonitoring(props) {

  const classes = useStyles()
  const history = useHistory()
  const routeParams = useParams()

  const [customerData, fetchCustomerData] = useFetchData(
    `portfolio-handler/customer/${routeParams.customer_id}/customer-app-data/`, 'get')
  const [transactionsData, fetchTrasactionsData] = useFetchData(
    `portfolio-handler/customer/${routeParams.customer_id}/transactions/`, 'post')
  const [pdfDownloadingStatus, downloadPdf] = useFetchDocument(
    `/reports/report/transaction/${routeParams.customer_id}/`, 'post')

  // Need to store applied filters, as they should be used for pdf generation
  const filtersUsed = React.useRef(undefined)
  // Need to store applied ordering rules, as they should be used for pdf generation
  const orderingUsed = React.useRef(undefined)
  const filtersRef = React.useRef();

  React.useEffect(() => {
    fetchCustomerData(CUSTOMER_DATA_FETCHING_PARAMS)
  }, [])

  React.useEffect(() => {
    if (pdfDownloadingStatus.errors) {
      props.dispatch(displayErrorSnackBar(TRANSACTIONS_PDF_GENERATION_ERROR))
    }
  }, [pdfDownloadingStatus.updatedAt])

  const _buildRequestFilters = (filters) => {
    let start = filters.dates[0];

    if(start === undefined) {
      start = customer.data.portfolios.min_tracking_date
      //if end_date is undefined, moment will be current
    }

    const getTransactionTypeOptions = (options, allOptions) => {
      return options.length === allOptions.length ? [] : _.isEmpty(options) ? undefined : options;
    }

    return {
      start_date: moment(start).format('YYYY-MM-DD'),
      end_date: moment(filters.dates[1]).format('YYYY-MM-DD'),
      instruments: filters.instruments.length === filtersOptions.instruments.length ? [] : filters.instruments,
      portfolios: getTransactionTypeOptions(filters.depots, filtersOptions.depots),
      clearing_accounts: getTransactionTypeOptions(filters.clearing_accounts, filtersOptions.clearing_accounts),
      transaction_types: getTransactionTypeOptions(filters.transactions, filtersOptions.transactions),
      booking_types: getTransactionTypeOptions(filters.bookings, filtersOptions.bookings),
      instrument_types: filters.instrumentTypes.length === filtersOptions.instrumentTypes.length ? [] : filters.instrumentTypes,
      is_canceled: filters.is_canceled
    }
  }

  const customer = React.useMemo(() => {

    let customer = _.cloneDeep(customerData)

    if (!customer.loading && !customer.errors && customer.updatedAt) {
      parseResponse(customer.data, 'customer', (data) => {
        customer.data = data
        customer.errors = undefined
      }, (errors) => {
        console.error(errors)
        customer.data = undefined
        customer.errors = errors
      })
    }

    if (!!customer.errors) {
      props.dispatch(displayErrorSnackBar(CUSTOMER_DATA_LOADING_ERROR))
    }

    return customer

  }, [customerData.updatedAt])

  const transactions = React.useMemo(() => {

    let transactions = _.cloneDeep(transactionsData)

    if (!transactions.loading && !transactions.errors && transactions.updatedAt) {
      parseResponse(transactionsData.data, 'orders', (data) => {
        transactions.data = [...paginateArray(data, 10)]
        transactions.errors = undefined
      }, (errors) => {
        console.error(errors)
        transactions.errors = errors
        transactions.data = undefined
      })
    }

    if (!!transactions.errors) {
      props.dispatch(displayErrorSnackBar(TRANSACTIONS_DATA_LOADING_ERROR))
    }

    return transactions

  }, [transactionsData.updatedAt])

  const filtersOptions = React.useMemo(() => {

    let filters = {
      ...DEFAULT_FILTERS,
      filled: false
    }

    if (!customer.data) {
      return filters
    }

    filters.depots = getCustomerPortfoliosOptions(customer.data.portfolios.portfolios);
    filters.clearing_accounts = getClearingAccountsOptions(customer.data.portfolios.clearing_accounts);
    [filters.transactions, filters.bookings] = getTransactionTypesOptions(customer.data.transaction_types);
    filters.instruments = getInstrumentsOptions(customer.data.portfolios.portfolios);
    filters.instrumentTypes = INSTRUMENT_TYPES_OPTIONS;
    filters.filled = true;

    return filters
  }, [customer.updatedAt]);

  React.useMemo(() => {
    if(!transactions.data || !filtersOptions.depots) return false;

    transactions.data.map(item => {
      item.map(asset => updateTransactionDepot(asset, filtersOptions.depots));
    });

  }, [transactionsData.updatedAt, filtersOptions]);

  const defaultDatePickerValidation = (min_tracking_date) => {

    const isMinDateValid = !!(moment(min_tracking_date) < moment().subtract(1, 'years'));

    if (isMinDateValid) {
      defaultTimeFrameSelection = defaultTimeFrameType.getDateRange()
    }
    else {
      if(!isMinDateValid) {
        defaultTimeFrameType = PERFORMANCE_TIME_TYPE.BEGINNING
        defaultTimeFrameSelection = defaultTimeFrameType.getDateRange();
      }
    }

    return [defaultTimeFrameSelection.start, defaultTimeFrameSelection.end, min_tracking_date]
  }

  const initFilters = React.useMemo(() => {
    if (!filtersOptions.filled) {
      return {
        ...DEFAULT_FILTERS,
        initialized: false
      }
    }

    let result = {...DEFAULT_FILTERS, initialized: true}
    result.depots = filtersOptions.depots.map(option => option.id)
    result.transactions = filtersOptions.transactions.map(option => option.id)
    result.bookings = filtersOptions.bookings.map(option => option.id)
    result.instruments = filtersOptions.instruments.map(option => option.id)
    result.instrumentTypes = INSTRUMENT_TYPES_OPTIONS.map(option => option.id)

    result.dates = defaultDatePickerValidation(getTrackingDate(customer.data.portfolios.min_tracking_date))

    // Use init filters, as first applied criterias
    filtersUsed.current = result
    if (!props.hasOwnProperty('depots') && !props.hasOwnProperty('dates')) {
      fetchTrasactionsData(_buildRequestFilters(result))
    }

    return result
  }, [filtersOptions.filled]);

  React.useEffect(() => {
    if (initFilters.initialized && filtersRef.current && !!props.selectedDepots && !_.isEmpty(props.selectedDepots)) {
      filtersRef.current.setFilter('depots', initFilters.depots.filter((id) => !!_.find((props.selectedDepots), (depotId) => depotId == id)));
    }
  }, [initFilters.initialized, JSON.stringify(props.selectedDepots)]);

  React.useEffect(() => {
    if (initFilters.initialized && filtersRef.current && !_.isEmpty(props.dates)) {
      filtersRef.current.setFilter('dates', [
        !!props.dates.start ? moment(props.dates.start) : undefined,
        !!props.dates.end ? moment(props.dates.end) : undefined]);
      filtersRef.current.setFilter('datesType', props.dates.datesType);
    }
  }, [initFilters.initialized, JSON.stringify(props.dates)]);

  const renderBackLink = () => {

    return executeIfPathExist(props.investmentPlatform.routes, 'TRANSACTIONS_OVERVIEW', (path) => (
      <Link
        title="Zurück zur Kundenübersicht"
        icon={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
        underline
        onClick={() => history.push(`/${getInvestmentDynamicPath()}${path}`)}
      />
    ))

  }

  const handleFiltersSubmit = (filters) => {
    fetchTrasactionsData(_buildRequestFilters(filters))
    filtersUsed.current = filters
  }

  const handleDownloadPdfClick = () => {
    if (!!filtersUsed.current) {
      downloadPdf({
        ..._buildRequestFilters(filtersUsed.current),
        ordering_column_index: orderingUsed.current && orderingUsed.current.index,
        ordering_ascending: orderingUsed.current && orderingUsed.current.ascending
      })
    }
  }

  const handleOrderingChange = (orderingColumn) => {
    orderingUsed.current = orderingColumn
  }

  const transactionTypes = filtersOptions.transactions;
  const bookingTypes = filtersOptions.bookings;
  const ContainerComponent = props.isComponent ? React.Fragment : Container;

  return (
    <TransactionsMonitoringContext.Provider value={{
      ...filtersOptions,
      transactionTypes,
      bookingTypes,
      onDownloadPdfClick: handleDownloadPdfClick,
      onOrderingChange: handleOrderingChange,
      initOrdering: orderingUsed.current
    }}>
      <ContainerComponent className={'app-page-container'} style={{paddingBottom: 48}}>
        <Grid container spacing={4} className={classes.container}>
          {!props.isComponent && (
            <Grid item xs={12}>
              { renderBackLink() }
            </Grid>
          )}
          <Grid item xs={12}>
            <Filters
              initValue={initFilters}
              defaultValue={initFilters}
              label="Transaktionen filtern"
              onSubmit={handleFiltersSubmit}
              ref={filtersRef}
              isInline={props.isComponent}
            >
              <Filters.Dropdown
                loading={customer.loading}
                name="depots"
                label="Depot"
                placeholder="Wählen Sie ein Depot"
                emptyErrorMessage="Bitte wählen Sie ein Depot oder Konto."
                options={filtersOptions.depots}
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'clearing_accounts'
                }}
              />
              <Filters.Dropdown
                loading={customer.loading}
                name="clearing_accounts"
                label="Konto"
                placeholder="Wählen Sie ein Konto"
                emptyErrorMessage="Bitte wählen Sie ein Depot oder Konto."
                options={filtersOptions.clearing_accounts}
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'depots'
                }}
              />
              <Filters.Dropdown
                loading={customer.loading}
                name="transactions"
                label="Depottransaktion"
                placeholder="Wählen Sie eine Depottransaktion"
                emptyErrorMessage="Bitte wählen Sie eine Depottransaktion."
                options={filtersOptions.transactions}
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'depots',
                  disableIfEmpty: true
                }}
              />
              <Filters.Dropdown
                loading={customer.loading}
                name="bookings"
                label="Kontobuchung"
                placeholder="Wählen Sie eine Kontobuchung"
                emptyErrorMessage="Bitte wählen Sie eine Kontobuchung."
                options={filtersOptions.bookings}
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'clearing_accounts',
                  disableIfEmpty: true
                }}
              />
              <Filters.Dropdown
                loading={customer.loading}
                name="instruments"
                label="Instrument"
                placeholder="Wählen Sie ein Instrument"
                options={filtersOptions.instruments}
                emptyErrorMessage="Bitte wählen sie ein Instrument aus."
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'depots',
                  disableIfEmpty: true
                }}
              />
              <Filters.Switch
                name="is_canceled"
                label="Stornobuchungen anzeigen"
                loading={customer.loading}
                GridProps={{
                  md: 6,
                  xs: 12,
                }}
              />
              <Filters.Dropdown
                loading={customerData.loading}
                name="instrumentTypes"
                label="Instrumententyp"
                placeholder="Wählen Sie einen Instrumententyp"
                emptyErrorMessage="Bitte wählen sie einen Instrumententyp aus."
                options={filtersOptions.instrumentTypes}
                disabled={!!customer.errors}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
                RelatedFilterProps={{
                  name: 'depots',
                  disableIfEmpty: true
                }}
              />
              <Filters.DateRange
                name="dates"
                disabled={!!customer.errors}
                loading={customer.loading}
                maxDate={moment()}
                GridProps={{
                  md: 6,
                  xs: 12
                }}
              />
              <Filters.SubmitButton disabled={customerData.loading || !!customer.errors} />
            </Filters>
          </Grid>
          <Grid item xs={12}>
            <Transactions loading={transactions.loading} transactions={transactions.data} errors={transactions.errors} isInline={props.isComponent}>
              {!!props.navigationRender
                ? props.navigationRender({handleDownloadPdfClick, pdfLoading: pdfDownloadingStatus.loading})
                : (
                  <Transactions.Navigation>
                    <PrimaryButton
                      icon={<DownloadIcon color="white"/>}
                      text="PDF herunterladen"
                      onButtonClick={handleDownloadPdfClick}
                      disabled={pdfDownloadingStatus.loading}
                    />
                  </Transactions.Navigation>
                )}
              <Transactions.List />

              <div className={classes.disclaimer}>
                <HTMLHostRelatedSharedSetting sharedSettingKey={HOST_RELATED_SHARED_SETTINGS_KEYS.TRANSACTION_RELIABILITY_DISCLAIMER} />
              </div>

            </Transactions>
          </Grid>
        </Grid>
      </ContainerComponent>
    </TransactionsMonitoringContext.Provider>
  );
}

let onboardings = {};

const onCustomersChange = async (customers) => {
  if(customers && Array.isArray(customers) && customers.length){
    onboardings = await QuestionnairesHandlerResource.at('onboarding_list/').post({
      customers_ids: customers.map(c => c.id)
    });
  }
};

const getButtonAttributes = (customer) => {
  return {
    title: 'Transaktionen',
    disabled: !hasPortfolios(customer)
  }
}

const extraColumns = [{
  content: [{
    body: {
      content: (customer) => {
        const data = onboardings[customer.id];
        if(data){
          const updatedAt = toLocalDateTime(data.updated_at);
          return (
            <>
              <span>{updatedAt.format('DD.MM.YYYY')}</span><br/>
              <span>{updatedAt.format('HH:mm')}</span>
            </>
          )
        } else {
          return ' - ';
        }
      }
    },
    header: {
      content: () => (
        <>
          <span>Zuletzt</span><br/>
          <span>bearbeitet</span>
        </>
      )
    }
  }],
}];

export default withCustomersSelectorNew(
  'TRANSACTIONS_OVERVIEW', getButtonAttributes, onCustomersChange, extraColumns)(connect(mapStateToProps)(TransactionsMonitoring));