import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import _ from 'lodash';
import clsx from "clsx";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  Paper
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import useStyles from './styles';


const DropdownFilter = ({title, options, selected, handleSelectedChanged, dataTour, disabled}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = useState(selected);
  const classes = useStyles();

  let node = useRef();

  useEffect(() => {
    window.addEventListener('mousedown', handleClick, false);

    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  const handleClick = (event) => {
    if (!node.current || !node.current.contains((event.target))) {
      setExpanded(false);
    }
  };

  const renderItem = (item) => {
    if (!_.isNil(item)) {
      let fontWeight = item.depth === 1 ? 'bold' : 'normal';
      return (
        <span className={classes.ellipsis} style={{ fontWeight: fontWeight }}>
          {item.description}
        </span>
        )
    }
    return null;
  }

  const isSelected = (item) => !_.isNil(selected) && item.value === selected.value;

  const handleSelectedUpdated = (item) => {
    setExpanded(false);
    if (!_.isNil(handleSelectedChanged)) {
      handleSelectedChanged(item);
    }
  }

  const renderListItem = (item) => (
    <ListItem key={item.value} style={{ paddingLeft: (!_.isNil(item.depth) ? item.depth : 1) * 10 }}
      onClick={() => handleSelectedUpdated(item)}
      selected={isSelected(item)}
      className={classes.listItem}
      classes={{selected: classes.selectedListItem}}>
      {renderItem(item)}
    </ListItem>
  )

  const renderListItems = function *() {
    for (let key in options) {
      if (options.hasOwnProperty(key)) {
        yield renderListItem(options[key])
      }
    }
  }

  return (
    <div className={`${classes.container} ${disabled ? classes.disabled : ''}`} ref={node} id={dataTour || ''}>
      {title && (
        <div className={`${classes.label} ${classes.ellipsis}`}>
          <span>{title}</span>
        </div>
      )}
      <div className={classes.dropdownContainer}>
        <div className={classes.expansionPannelContainer}>
          <ExpansionPanel TransitionProps={{timeout: 0}} square expanded={expanded} onChange={() => setExpanded(!expanded)} classes={{
            root: clsx(classes.expansionPanelRoot, title ? '' : classes.expansionPanelRootWithoutLabel),
            expanded: classes.expansionPanelExpanded
          }}>
            <ExpansionPanelSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              IconButtonProps={{
                disableRipple: true
              }}
              classes={{
                root: classes.expansionPanelSummaryRoot,
                expanded: classes.expansionPanelSummaryExpanded,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expandIcon
              }}
            >
              {renderItem(selected)}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{
              root: classes.expansionPanelDetailsRoot,
            }}>
              <Paper style={{maxHeight: '70vh', overflow: 'auto'}}>
                <List className={classes.list}>
                  {
                    [...renderListItems()]
                  }
                </List>
              </Paper>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    </div>
  );
};

export default DropdownFilter;