import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './styles';

function Link(props) {

  const {
    title,
    path,
    icon,
    iconPosition,
    underline,
    onClick,
    fontWeight,
    disabled,
    customClasses,
    ...otherProps
  } = props;

  const classes = useStyles();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      onClick()
    }
  };

  return (
    <span
      className={clsx(classes.link, customClasses.link, disabled ? classes.linkDisabled : '')}
      onClick={handleClick}
      {...otherProps}
    >
      {icon && (iconPosition == 'left' || !iconPosition) && (
        <span style={{marginRight: 8}}>{icon}</span>
      )}
      <span
        style={{
          borderBottom: underline ? '2px solid' : 'none',
          textDecorationThickness: 10,
          fontWeight
        }}
        className={props.classes && props.classes.title || ''}
      >
        {title}
      </span>
      {icon && iconPosition == 'right' && (
        <span style={{marginLeft: 8}}>{icon}</span>
      )}
    </span>
  )
}

Link.propTypes = {
  /**Link text */
  title: PropTypes.any.isRequired,

  /** Icon, that should be included to the link */
  icon: PropTypes.element,

  /** Position of the icon */
  iconPosition: PropTypes.oneOf(['left', 'right']),

  /**Path to redirect */
  path: PropTypes.string,

  /** OnClick handler */
  onClick: PropTypes.func,

  /**Indicate, if 'underline' style should be used */
  underline: PropTypes.bool,

  /** Font weight */
  fontWeight: PropTypes.oneOf(['bold', 'normal'])
}

Link.defaultProps = {
  fontWeight: 'bold',
  customClasses: {},
}

export default Link

