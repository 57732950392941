import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import {DialogContent, Fade, Grid} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import DialogActions from "@material-ui/core/DialogActions";
import withStyles from "@material-ui/core/styles/withStyles";
import {ModelPortfolioBase} from "../../../../../Modelportfolios/Create/ModelportfolioBase";
import {favoriteListTableStructure} from "../../../../../Modelportfolios/components/InstrumentsList/table-data";
import {MODELPORTFOLIO_CREATE_CONTAINER_TABS} from "../../../../../Modelportfolios/Create/constants";
import {
  InstrumentsFilter, Widget, WidgetHeader, WidgetBody
} from "../../../../../Modelportfolios/components";
import Link from "../../../../../../components/Link";
import {ModelportfoliosFilters} from "../../../../../Modelportfolios";

import DashboardTable from "../../../../../../components/DashboardTable/DashboardTable";
import styles from "./styles";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton";
import FavouriteList from "../FavouriteList";
import Portfolio from "../Portfolio";
import {INSTRUMENTS_STEP_STATE} from "../../../../../RiskProfiling/constants";
import ModelPortfolioList from "../ModelPortfolioList";
import Rebalancing from "../Rebalancing";
import SwitchOutList from "../SwitchOutList";
import {Switch} from "../../../../../../components/Inputs";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import ConfirmationDialog from "../../../../../VirtualPortfolioManager/modals/ConfirmationDialog";
import {getAssetInternalId} from "../../../../../CustomerDashboard/utils";
import MusterDepotList from "../MusterDepotList";
import {
  MUSTERDEPOT_ALL_ASSETS_AVAILABLE,
  MUSTERDEPOT_NOT_ALL_ASSETS_AVAILABLE
} from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/MusterDepot/constants";
import {displaySuccessSnackBar, displayWarningSnackBar} from "../../../../../../components/SnackbarProvider/actions";


class Create extends ModelPortfolioBase {

  constructor(props) {

    super(props);

    this.state = {
      ...this.state,

      existingInstruments: [],
      activeSource: null,
      activeSourceItem: null,
      modelPortfolioData: {},
      switchOutListActive: false,
      activeAssets: [],
      rebalancingConfirmationDialogOpen: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if(this.props.instruments){
      this.setState({
        existingInstruments: this.props.instruments
      });
    }
    if (this.props.instrumentsHandler) {
      this.setState({
        instrumentsHandler: this.props.instrumentsHandler
      })
    }
    if (this.props.portfolioOnly) {
      this.setState({
        activeSource: this.props.portfolioOnly ? 'Portfolio' : null,
        activeContent: this.props.portfolioOnly ? INSTRUMENTS_STEP_STATE.ADD_FORM : null,
      });
    }
    if (this.props.action === 'rebalancing') {
      this.setState({
        activeSource: 'ModelPortfolio',
        activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.LIST,
      });
    }
  }

  getDisabledInstruments() {
    return _.concat(this.state.selectedAssets, this.state.existingInstruments, this.props.portfolioInstruments);
  }

  render() {
    const {
      classes,
      error,
      handleAddItems,
      handleAddItem,
      portfolioInstruments,
      portfolioOnly,
      handleClose,
      clearTransactions
    } = this.props;
    const isRebalancing = this.props.action === 'rebalancing';
    const isRebalancingView = this.state.activeContent === MODELPORTFOLIO_CREATE_CONTAINER_TABS.REBALANCING;
    const instrumentSteps = [
      INSTRUMENTS_STEP_STATE.LIST,
      INSTRUMENTS_STEP_STATE.ADD_FORM,
      MODELPORTFOLIO_CREATE_CONTAINER_TABS.LIST,
      MODELPORTFOLIO_CREATE_CONTAINER_TABS.REBALANCING,
    ];

    const handleSourceClick = (source, initial_step=INSTRUMENTS_STEP_STATE.LIST) => () => {
      this.setState({
        activeSource: source,
        activeContent: initial_step,
        activeAssets: [],
      });
    };

    const instrumentsSources = !this.props.isCustomerApp
      ? [{text: "Aus Favoritenlisten wählen", variant: "outlined", onButtonClick: handleSourceClick("FavouriteList")},
        {text: this.props.isVirtual ? "Anderes Musterdepot auswählen" : "Musterdepot auswählen", variant: "outlined", onButtonClick: handleSourceClick("MusterDepot")}]
      : []; // customer does not have favorite list

    if (!_.isEmpty(portfolioInstruments)){
      let text = "Aus Kundendepot wählen";
      if (this.props.isVirtual) {
        text = this.props.isCustomerApp ? "Aus Virtuellem Depot wählen" : "Aus Musterdepot wählen";
      }
      instrumentsSources.push({
        text: text, variant: "outlined",
        onButtonClick: handleSourceClick("Portfolio", INSTRUMENTS_STEP_STATE.ADD_FORM)
      })
    }

    const getComponent = () => {
      switch (this.state.activeSource){
        case "FavouriteList":
          return FavouriteList;
        case "Portfolio":
          return Portfolio;
        case "MusterDepot":
          return MusterDepotList;
        case "ModelPortfolio":
          return ModelPortfolioList;
        case "Rebalancing":
          return Rebalancing;
      }
    };

    const handleDetailsClick = (item, skipDetails, hasWarning) => {
      this.setState({
        activeSourceItem: item,
        activeContent: INSTRUMENTS_STEP_STATE.ADD_FORM,
        selectedAssets: item && skipDetails ? [...this.state.selectedAssets, ...(item.assets || [])] : this.state.selectedAssets
      });
      if (skipDetails) {
        clickNext(undefined, hasWarning)
      }
    };

    const onSelectAll = (selectedAssets) => {
      this.setState({activeAssets: selectedAssets});
    };

    const addActiveAssetClick = (asset) => {

      let selectedAssetsCopy = [...this.state.activeAssets];
      // in case asset has payment_id - it is payment plan
      const key = asset.payment_id ? 'payment_id' : getAssetInternalId
      const idx = this.findAssetIndex(selectedAssetsCopy, asset, key);
      if (idx !== -1) {
        selectedAssetsCopy.splice(idx, 1);
      } else {
        selectedAssetsCopy = [...selectedAssetsCopy, asset]
      }

      this.setState({activeAssets: selectedAssetsCopy})
    };

    const renderComponent = () => {
      const Component = getComponent();

      return (
        <Grid container className={this.props.classes.noPaddingContent}>
          <Component
            portfolioInstruments={this.props.portfolioInstruments}
            instrumentsToFilterOut={this.props.enableFilteringDisabledInstruments && this.getDisabledInstruments().map(i => i.isin)}
            transactions={this.props.transactions}
            instrumentsHandler={this.state.instrumentsHandler}
            selectedAssets={this.state.activeAssets}
            onInstrumentSelect={addActiveAssetClick}
            onSelectAll={onSelectAll}
            portfolioData={this.props.portfolioData}
            modelPortfolioData={this.state.modelPortfolioData}
            activeSourceItem={this.state.activeSourceItem}
            handleDetailsClick={handleDetailsClick}
            dataService={this.props.dataService}
            action={this.props.action}
            withSrri={this.props.withSrri}
            clientSRRI={this.props.clientSRRI}
            totalMarketValue={this.props.portfolioMarketValue || 0}
          />
        </Grid>
      )
    };

    const handleCloseRebalancingConfirmationDialog = () => {
      this.setState({
        rebalancingConfirmationDialogOpen: false
      })
    }

    const handlePrefillRebalancingData = async () => {
      if (clearTransactions && this.state.rebalancingConfirmationDialogOpen) {
        clearTransactions()
        handleCloseRebalancingConfirmationDialog()
      }
      const buy = [];
      const sell = [];
      this.state.activeAssets.forEach(asset => {
        if (asset.transactionType === 'Verkauf') {
          sell.push(asset)
        } else if (asset.transactionType === 'Kauf') {
          buy.push(asset)
        }
      })
      handleAddItem('buy')(buy);
      handleAddItem('sell')(sell);
      this.props.handlePortfolioChange && this.props.handlePortfolioChange();
      handleClose(this.props.action);
    }

    const clickBack = () => {
      let newState = {activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN};

      if (this.state.activeContent === INSTRUMENTS_STEP_STATE.ADD_FORM){
        if (!!this.state.activeSourceItem) {
          newState.activeSourceItem = undefined;
          newState.activeContent = isRebalancing 
            ? MODELPORTFOLIO_CREATE_CONTAINER_TABS.LIST 
            : INSTRUMENTS_STEP_STATE.LIST;
        }

        onSelectAll([]); // remove all selected
      }
      if (isRebalancingView) {
        newState.activeContent = MODELPORTFOLIO_CREATE_CONTAINER_TABS.LIST;
        newState.selectedAssets = [];
        newState.activeSourceItem = undefined;
        newState.activeSource = 'ModelPortfolio';
      }

      this.setState(newState);
    };

    const clickNext = (event, hasWarning) => {
      if (portfolioOnly) {
        handleAddItems(this.state.activeAssets);
      } else if (isRebalancingView) {
        if (_.flatten(Object.values((this.props.allTransactions || {}))).length) {
          this.setState({
            rebalancingConfirmationDialogOpen: true
          })
        } else {
          handlePrefillRebalancingData()
        }
      } else if (this.state.switchOutListActive){
        (this.props.transactions || [])
          .filter(item => this.state.activeAssets.map(i => i.isin).includes(item.data.isin))
          .forEach(item => handleAddItem('switch', item)(this.state.selectedAssets));
      } else if (this.state.activeContent === MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN){
        handleAddItems(this.state.selectedAssets);
      } else {
        if (isRebalancing) {
          const selectedAssets = [...(this.state.activeSourceItem.assets || [])
            .map(asset => ({...asset, weight: +asset.weight / 100}))];

          this.setState({
            modelPortfolioData: {
              name: this.state.activeSourceItem.name,
              categoryName: 'Musterdepot',
              assets: selectedAssets
            },
            activeAssets: [],
            activeSourceItem: undefined,
            activeSource: 'Rebalancing',
            activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.REBALANCING,
          });
        } else {
          this.setState({
            activeSourceItem: undefined,
            activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN,
          });

          let selectedAssetsCopy = [...this.state.selectedAssets];
          this.state.activeAssets.map(asset => {
            if (this.findAssetIndex(selectedAssetsCopy, asset) === -1) {
              selectedAssetsCopy = [...selectedAssetsCopy, asset]
            }
          });

          if(this.state.activeSource === "MusterDepot"){
            const msg = hasWarning ? MUSTERDEPOT_NOT_ALL_ASSETS_AVAILABLE : MUSTERDEPOT_ALL_ASSETS_AVAILABLE;
            const displaySnackBar = hasWarning ? displayWarningSnackBar : displaySuccessSnackBar;
            this.props.dispatch(displaySnackBar(msg))
          }

          this.setState({
            selectedAssets: selectedAssetsCopy,
            activeAssets: []
          })
        }
      }
    };

    const showBackBtn = () => {
      return !portfolioOnly && [
        INSTRUMENTS_STEP_STATE.LIST,
        INSTRUMENTS_STEP_STATE.ADD_FORM,
        MODELPORTFOLIO_CREATE_CONTAINER_TABS.REBALANCING,
      ].includes(this.state.activeContent);
    }

    const clickSwitchOut = () => {
      this.setState({
        switchOutListActive: !this.state.switchOutListActive,
      });
    }

    const showNextBtn = () => {
      return [
        INSTRUMENTS_STEP_STATE.ADD_FORM,
        MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN,
        MODELPORTFOLIO_CREATE_CONTAINER_TABS.REBALANCING
      ].includes(this.state.activeContent);
    };

    const nextButtonDisabled = () => {
      return ((this.state.activeContent === INSTRUMENTS_STEP_STATE.ADD_FORM
        || isRebalancingView) && _.isEmpty(this.state.activeAssets))
      || (this.state.switchOutListActive && this.state.activeAssets.length < 2)
      || this.props.loading;
    }

    const showSwitchOutList = () => {
      const showSwitchOut = this.props.switchInPossible
        && (this.props.transactions && this.props.transactions.length > 1)
        && this.state.activeContent === MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN
      if (showSwitchOut && !this.state.activeAssets.length && this.props.initialSwitchOutInstrument) {
        this.setState({
          activeAssets: [this.props.initialSwitchOutInstrument.data],
        });
      }
      return showSwitchOut
    };

    return (
      <>
      <DialogContent>
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN && (
          <>
            <Grid container spacing={2}>
              {/* assets search */}
              <Grid item xs={12}>
                <InstrumentsFilter
                  instruments={this.getFilterInstruments()}
                  instrumentsDisabled={this.getDisabledInstruments()}
                  loading={this.state.assetsSearch.loading}
                  errors={this.state.assetsSearch.errors}
                  onSearchActivate={this.handleSearchActivate}
                  onAddAssetClick={this.handleAddAssetClick}
                  onFiltersExtendedButtonClick={this.handleFiltersExtendedButtonClick}
                  extraActions={instrumentsSources}
                  isCustomerApp={this.props.isCustomerApp}
                />
              </Grid>
              {/* selected instruments table */}
              <Grid item xs={12}>
                <Widget>
                  <WidgetHeader>
                    <div className={classes.header}>Ausgewähltes Instrument</div>
                  </WidgetHeader>
                  <WidgetBody>
                    <DashboardTable
                      tableLayout={'auto'}
                      structure={favoriteListTableStructure}
                      dataSource={this.state.selectedAssets || []}
                      tableClasses={classes}
                      expanded={true}
                      withFooter={false}
                      options={{
                        onRemoveAssetClick: this.handleAddAssetClick,
                        itemsLength: this.state.selectedAssets && this.state.selectedAssets.length,
                      }}
                    />
                    {showSwitchOutList() && (
                      <>
                        <div className={clsx(classes.header, classes.headerInternal)}>
                          <div className={classes.switchContainer}>
                            <span style={{marginRight: 22}}>Ausgangsfonds des gewählten Tausches</span>
                            <span className={classes.switchText}>
                              Investment zu weiteren Täuschen hinzufügen&nbsp;
                              <WarningTooltip title={'Hier können Sie die ausgewählten Zielfonds in mehrere Täusche einfügen.'}/>
                            </span>
                            <Switch
                              checked={this.state.switchOutListActive}
                              onChange={clickSwitchOut}
                              disabled={!this.state.selectedAssets.length}
                            />
                          </div>
                        </div>
                        <Grid container>
                          <SwitchOutList
                            portfolioInstruments={this.props.portfolioInstruments}
                            transactions={this.state.switchOutListActive ? this.props.transactions : [this.props.initialSwitchOutInstrument]}
                            initialSwitchOutInstrument={_.get(this.props.initialSwitchOutInstrument, 'data.isin')}
                            instrumentsHandler={this.state.instrumentsHandler}
                            selectedAssets={this.state.activeAssets}
                            onInstrumentSelect={addActiveAssetClick}
                            onSelectAll={onSelectAll}
                            activeSourceItem={this.state.activeSourceItem}
                            handleDetailsClick={handleDetailsClick}
                            action={this.props.action}
                            withSrri={this.props.withSrri}
                            clientSRRI={this.props.clientSRRI}
                          />
                        </Grid>
                      </>
                    )}
                  </WidgetBody>
                </Widget>
              </Grid>
            </Grid>
          </>
        )}
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED && (
          <>
            <Link
              title="Schließen"
              icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
              underline
              onClick={() => this.setState({activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN})}
            />
            <Fade in={this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED} timeout={500}>
              <ModelportfoliosFilters
                onAddInstruments={this.handleAddInstrumentsFilters}
                instrumentsHandler={this.state.instrumentsHandler}
                addExtraFilters={this.addExtraFilters}
                useButtons
                predefinedFilter={this.props.predefinedFilter}
                customerId={this.props.customerId}
              />
            </Fade>
          </>
        )}
        {instrumentSteps.includes(this.state.activeContent) && renderComponent()}
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActions}}>
        <Grid container spacing={2} justify={"space-between"}>
          {showBackBtn() && (
            <Grid item>
              <PrimaryButton
                variant={'outlined'}
                icon={<ArrowBack color={'primary'}/>}
                text="Schließen"
                onButtonClick={clickBack}
              />
            </Grid>
          )}
          {showNextBtn() && (
            <Grid item style={{marginLeft: 'auto'}}>
              <PrimaryButton
                disabled={nextButtonDisabled()}
                icon={isRebalancingView && (<i className="far fa-dollar-sign fa-xs" style={{color: 'white'}} />)}
                text={isRebalancingView ? "ORDER" : "Speichern & fortfahren"}
                onButtonClick={clickNext}
              />
            </Grid>
          )}
        </Grid>
      </DialogActions>
        <ConfirmationDialog
          message={"Alle vorherigen Ordereingaben werden gelöscht. Sind Sie sicher?"}
          open={this.state.rebalancingConfirmationDialogOpen}
          onClose={handleCloseRebalancingConfirmationDialog}
          confirm={handlePrefillRebalancingData}
        />
      </>
    )
  }
}

export default withStyles(styles)(connect()(Create));