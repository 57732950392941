import { makeStyles } from "@material-ui/core/styles";
import { FORM_FIELD_HEIGHT } from '../common_styles';

export default makeStyles((theme) => ({
  textFieldRoot: {
    width: '100%',
  },
  inputRoot: {
    marginBottom: 0,
    border: '1px solid #D8DCDF',
    borderRadius: 4,
    height: FORM_FIELD_HEIGHT,

    padding: 6,
    '& input[inputmode="numeric"]': {
      textAlign: 'right',
    },

    '&::before': {
      display: 'none'
    },

    '&::after': {
      display: 'none'
    },
    '&.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.2)'
    },
  },
  labelRoot: {
    top: 12,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#202A38',
    transform: 'none',
    position: 'relative',
    '&.bold': {
      fontFamily: 'Roboto-Bold',
    },
    '&.regular': {
      fontFamily: 'Roboto-Regular',
    }
  }
}))