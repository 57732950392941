import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  closeButton: {
    float: 'right',
    position: 'absolute',
    top: 0,
    right: 0
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '75%',
    fontSize: '14px',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'flex',
    padding: '.3rem 0',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    borderBottom: 'solid 1px #D8DCDF',
  },
  itemMark: {
    alignItems: 'center'
  },
  headline: {
    paddingBottom: 20,
    fontWeight: 'bold',
    fontSize: 16,
  },
  values: {
    paddingRight: '.5rem',
    width: '25%',
    lineHeight: 1,
    textAlign: 'right',
  },
  modalRoot: {
    width: 300,
    maxWidth: 300
  }
})

export default makeStyles(styles)