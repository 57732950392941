import React from 'react';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import {Select, MenuItem, FormControl} from '@material-ui/core'
import {connect} from "react-redux";
import {withRouter} from "react-router";

import useStyles from './styles';
import {toGermanFormat} from "../../../../../../../../utils/numberFormater";
import InstrumentsAllocationTable from "../../../../../../../../components/Charts/InstrumentsAllocationTable";
import ClearingAccountTable from "../../../../../../../../components/Charts/ClearingAccountTable";
import DashboardCard from '../../../DashboardCard';
import { getPortfolioCategory } from '../../../../../../../../components/CustomersDataProvider/utils'
import {
  TRADING_ACTION_DEFAULT,
  TRADING_ACTIONS
} from '../../../../../../../../components/Charts/InstrumentsAllocationTable/constants'
import {isTradingEnabled} from "../../../../../../../../components/TradingStore/utils";
import WarningTooltip from "../../../../../../../../components/WarningTooltip";

import {Link} from "react-router-dom";
import { addPortfolioData } from '../../../../../../actions';
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "../../../../../../../../components/Tooltip";
import {getProViewLink} from '../../../../../../../../routes'
import {
  REPORT_TYPES
} from "../../../../../../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {buildCurrentCustomerVirtualPortfolioManager} from "../../../../../../../../routes";
import {getAssetInternalId, wholeDepotOrderHandler} from "../../../../../../utils";
import {getCustodianData} from "../../../../../../../RiskProfiling/utils";
import {displayErrorSnackBar} from "../../../../../../../../components/SnackbarProvider/actions";
import UnrealizedProfitAndLossItem from "../UnrealizedProfitAndLossItem";
import {PROF_VIEW_PORTFOLIO_DATA, setInStorage} from "../../../../../../../../utils/storage";
import Grid from "@material-ui/core/Grid";
import {PrimaryButton} from "../../../../../../../../components/Buttons";
import {
  SelectForProductsComparisonModal, AddProductsToComparison
} from "../../../../../../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {PRODUCT_TYPE} from "../../../../../../../ProductComparison/constants";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../../../../../../../utils/redaxSelectors";


const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
});

const getComponentsForComparison = (portfolio) => {
  const components = _.get(portfolio, 'components')
  if (!components) {
    return;
  }

  return _.filter(components, (component) => !!component.isin);
}

const InstrumentListItem = (props) => {
  const classes = useStyles();

  const {
    match: {
      params: {
        customer_id
      }
    },
    portfolio,
    virtualInstrumentLink,
    isLast,
    expandable,
    expanded,
    onExpanded,
    expandedSubItems,
    onExpandedSubItems,
    isCustomerApp,
    onAddTradingOption,
    onAddPortfolioTradingOption,
    tradings,
    banksMapping,
    tradeTransactions,
    reportType,
    auth,
    isVirtual,
    triggerPortfolioDeleting,
    refresh,
    customerData,
    canTradePortfolios,
    customTypeProViewEnabled,
    instrumentsGroupBy,

    withTransactionSaldo,
    unrealizedProfitAndLossData,
    expandedUnrealizedProfitAndLossItems,
    portfolioNameRender,
    showSubSectionTitle,
    footerText,
    withClearingAccount,
    addToComparisonPossible
  } = props;

  const [productsComparisonActiveItem, setProductsComparisonActiveItem] = React.useState();

  const isClearingAccountExist = !_.isEmpty(portfolio.clearing_account);
  const isDataExist = !portfolio.is_historical || ((!portfolio.is_empty || !_.isEmpty(portfolio.components)) && portfolio.hasOwnProperty('value'));
  const isEmptyCard = !isClearingAccountExist && !isDataExist;

  const dataSource = portfolio.components || [];

  let tradingEnabled = isTradingEnabled();
  const { isModelportfolio, isDisabled, bankCode, categoryName, disabledByMessage } = getPortfolioCategory(portfolio);
  const isBankAvailable = banksMapping && banksMapping.hasOwnProperty(bankCode);
  const isTradingAvailable = tradingEnabled && !portfolio.is_empty && !portfolio.is_passive && isBankAvailable;
  const isPtfTradingDisabled = (
    isDisabled ||
    (isModelportfolio && portfolio.is_empty) ||
    !!portfolio.is_passive || !isBankAvailable);

  const isPtfTradingEnabled = tradingEnabled && !isPtfTradingDisabled;

  const [custodiansInstrumentsData, setCustodiansInstrumentsData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [wholeDepotTradingAction, setWholeDepotTradingAction] = React.useState("default");

  let isProReport = reportType === REPORT_TYPES.PRO.value || (reportType === REPORT_TYPES.CUSTOM.value && customTypeProViewEnabled);
  let proViewLinkPath = isProReport && portfolio && !portfolio.is_empty && !portfolio.not_connected && getProViewLink(auth, props.investmentPlatform.routes, portfolio.id, customer_id, isVirtual);

  React.useEffect(() => {
    if(isPtfTradingEnabled){
      setLoading(true);
      getCustodianData(dataSource, [bankCode]).then(res => {
        if(res && !_.isEmpty(res[bankCode])){
          setCustodiansInstrumentsData(res[bankCode]);
        }
      }).catch(error => {
        props.dispatch(displayErrorSnackBar(error.detail || error.message || error))
      }).finally(() => setLoading(false));
    }
  }, [isPtfTradingEnabled]);

  const setPortfolioData = (event) => {

    event.stopPropagation()

    setInStorage(PROF_VIEW_PORTFOLIO_DATA, portfolio)

    window.open(proViewLinkPath, '_blank')
    window.focus()

  };

  const renderData = () => {
    return (
      <>
        {isDataExist && (
          <div className={classes.tableSection}>
            <InstrumentsAllocationTable
              resource={props.resource}
              data={portfolio}
              virtualInstrumentLink={virtualInstrumentLink}
              expanded={expanded}
              expandedSubItems={expandedSubItems}
              onExpandedSubItems={onExpandedSubItems}
              isCustomerApp={isCustomerApp}
              isVirtualOrderEnabled={props.isVirtualOrderEnabled}
              onAddTradingOption={handleAddTradingOption}
              tradings={tradings}
              tradeTransactions={tradeTransactions}
              reportType={reportType}
              isVirtual={isVirtual}
              refresh={refresh}
              isModelportfolio={isModelportfolio}
              isPtfTradingDisabled={isPtfTradingDisabled}
              custodiansInstrumentsData={custodiansInstrumentsData}
              loading={loading}
              banksMapping={banksMapping}
              bankCode={bankCode}
              customerData={customerData}
              instrumentsGroupBy={instrumentsGroupBy}
              showCell={props.showCell}
              user={auth && auth.user}
              footerText={footerText}
            />
          </div>
        )}
        {isClearingAccountExist && expanded && withClearingAccount && (
          <div className={classes.tableSection}>
            <p className={classes.tableSectionHeader}>VERRECHNUNGSKONTO</p>
            <ClearingAccountTable data={portfolio.clearing_account}/>
          </div>
        )}
        {expanded && withTransactionSaldo && renderUnrealizedProfitAndLossItem(portfolio.id)}
      </>
    );
  };

  const portfolioName = portfolio.not_connected ? portfolio.name : `${portfolio.name} (${toGermanFormat(portfolio.weight * 100, '', ' %')})`;

  const getTitle = () => {

    if (portfolio.is_group) {
      return <>{portfolio.name}</>
    }

    function getTooltipMessage(){
      return (
        <>
          <p>Klicken Sie hier, um zur professionellen Detailanalyse zu gelangen. Zusätzlich zum Expertenbericht erhalten Sie:</p>
          <ul>
            {!isCustomerApp &&
              <>
              <li>Peergroup-Quintilsranking (nur für die Berateransicht)</li>
              <li>Projektion - Mögliche Wertentwicklung 15 Jahre (nur für die Berateransicht)</li>
              </>
            }
            <li>Detaillierte Investmentkennzahlen</li>
            <li>Rendite-Risiko Diagramm</li>
            <li>Stresstests</li>
            <li>Korrelationsmatrix</li>
          </ul>
        </>
      )
    }

    return (
      <>
        Wertpapiere: {portfolioNameRender ? portfolioNameRender(portfolio) : portfolioName}
        {proViewLinkPath && (
          <>
            <br/>
            <Link className={classes.proLink} onClick={setPortfolioData}>
              Professionelle Depotanalyse
            </Link>
            <WarningTooltip title={getTooltipMessage()} width={"100%"} size={16}/>
          </>
        )}
        {!isCustomerApp && <><br/> <small>{categoryName}</small></>}
      </>
    )
  };

  const handleAddTradingOption = (instrument, tradingType) => {
    if (onAddTradingOption) {
      onAddTradingOption(portfolio, instrument, tradingType)
    }
  }

  const handleAddPortfolioTradingOption = (event) => {
    if (onAddPortfolioTradingOption) {
      onAddPortfolioTradingOption(portfolio, event.target.value)
    }
  }

  const renderRightSideNavigation = () => {

    if (isCustomerApp || portfolio.is_group) {
      return
    }

    if (isModelportfolio && isTradingAvailable) {

      let value = 'default';
      const portfolioTradeTransactions = _.get(tradeTransactions || {}, portfolio.id);
      if (!!portfolioTradeTransactions) {
        value = portfolioTradeTransactions[portfolio.id];
      } else if (!!tradings) {
        const tradingType = tradings.tradingType;
        if (!!tradingType) {
          const tradingAction = TRADING_ACTIONS.find(o => o.code === tradingType);
          if (tradingAction){
            value = tradingAction.value;
          }
        }
      }

      return (
        <div className={classes.selectSection}>
          <span>ORDER</span>
          {disabledByMessage && (
            <Tooltip title={disabledByMessage} placement={"top"}>
              <ErrorIcon className={classes.helpIcon}/>
            </Tooltip>
          )}
          <FormControl className={classes.selectFormControl}>
            <Select variant="outlined" classes={{
              root: classes.selectRoot,
            }}
            onChange={handleAddPortfolioTradingOption}
            value={value}
            disabled={isDisabled || !!tradeTransactions}
            MenuProps={{
                classes: {
                  paper: classes.selectMenuPaper
                }
              }}
            >
              <MenuItem value="default"  classes={{
            root: classes.selectMenuItemRoot
          }}>-</MenuItem>
              { TRADING_ACTIONS.filter(option => option.isTrading).map(option => (
                <MenuItem disabled={option.value == 3} value={option.value} classes={{
                  root: classes.selectMenuItemRoot
                }}> { option.text } </MenuItem>
              )) }
            </Select>
          </FormControl>
        </div>
      )
    }
  }

  const renderWholeDepotOrderNavigation = () => {

    if (isCustomerApp || portfolio.is_group) {
      return
    }

    const handleChange = (event) => {
      const tradingType = event.target.value;
      if (tradings && onAddTradingOption && tradingType !== 'default') {
        (tradings.instruments || []).forEach(item => onAddTradingOption(portfolio, item.data, 'default'))
      }
      setWholeDepotTradingAction(tradingType);
      wholeDepotOrderHandler(portfolio, onAddTradingOption, tradingType, custodiansInstrumentsData);
    }

    // TODO: refactor it: clean dupliciated code
    if (canTradePortfolios && isTradingAvailable) {
      return (
        <div className={classes.selectSection}>
          <span>ORDER</span>
          <FormControl className={classes.selectFormControl}>
            <Select variant="outlined" classes={{
              root: classes.selectRoot,
            }}
            onChange={handleChange}
            value={wholeDepotTradingAction}
            disabled={isDisabled || !!tradeTransactions}
            MenuProps={{
                classes: {
                  paper: classes.selectMenuPaper
                }
              }}
            >
              <MenuItem value={TRADING_ACTION_DEFAULT.value}  classes={{
            root: classes.selectMenuItemRoot
          }}>-</MenuItem>
              { TRADING_ACTIONS.filter(option => option.isWholeDepotOrder).map(option => (
                <MenuItem value={option.value} classes={{
                  root: classes.selectMenuItemRoot
                }}> { option.text } </MenuItem>
              )) }
            </Select>
          </FormControl>
        </div>
      )
    }
  }

  const renderVirtualDepotActions = () => {
    if(!isVirtual || portfolio.is_group) return;

    return (
      <Grid container spacing={1} alignItems={"center"} style={{marginRight: 'auto'}}>
        {/* for new virtual order the order btn is available -> do not render link for old logic */}
        {!props.isVirtualOrderEnabled &&
          <Grid item>
            <Link
              to={{
                pathname: buildCurrentCustomerVirtualPortfolioManager(portfolio.id),
                state: {
                  portfolioName: portfolio.name
                }
              }}
              className={classes.link}
            >
              <Button className={classes.linkButton}>Bearbeiten</Button>
            </Link>
          </Grid>
        }
        {triggerPortfolioDeleting && (
          <Grid item>
            <Button className={classes.linkButton}
              onClick={() => {
                triggerPortfolioDeleting(portfolio.id, portfolioName)
              }}
              disableRipple={true}
            >
              Depot Löschen
            </Button>
          </Grid>
        )}
      </Grid>
    )
  };

  const renderUnrealizedProfitAndLossItem = (portfolio_id) => {

    const expanded = _.includes(expandedUnrealizedProfitAndLossItems, portfolio_id)
    const portfolioTransactData = _.get(unrealizedProfitAndLossData, `portfolios_transactions_data.${portfolio_id}`)

    return (
      <>
      {portfolioTransactData &&
        <div style={{marginTop: 16}}>
          <UnrealizedProfitAndLossItem
            expanded={expanded}
            onExpandedItemsChange={(expanded) => onExpanded(expanded, true)}
            portfolioTransactData={portfolioTransactData}
          />
        </div>
      }
      </>
    )
  }

  const sideNavigationContent = (portfolio.is_empty || portfolio.not_connected) ? null : (
    <>
      {renderRightSideNavigation() || renderWholeDepotOrderNavigation() || renderVirtualDepotActions()}
      {addToComparisonPossible && !isVirtual && !portfolio.is_group && !isCustomerApp && (
        <>
          <PrimaryButton
            text="Vergleich öffnen"
            onButtonClick={() => setProductsComparisonActiveItem({
              data: {...portfolio, customer_id},
              productType: PRODUCT_TYPE.CUSTOMER_PORTFOLIO,
              selectable: true
            })}
            customClasses={{
              root: classes.buttonRoot
            }}
          />
          <AddProductsToComparison>
            <SelectForProductsComparisonModal
              container={productsComparisonActiveItem}
              instruments={getComponentsForComparison(_.get(productsComparisonActiveItem, 'data'))}
              onClose={() => setProductsComparisonActiveItem(undefined)}
            />
          </AddProductsToComparison>
        </>
      )}
    </>
  )

  return (
    <>
      <DashboardCard
        title={getTitle()}
        content={renderData()}
        sideNavigationContent={sideNavigationContent}
        expandable={expandable}
        expanded={expanded}
        table
        divider
        isLast={isLast}
        onExpanded={onExpanded}
        empty={isEmptyCard}
      />
    </>
  );
}

InstrumentListItem.defaultProps = {
  showSubSectionTitle: true,
  addToComparisonPossible: true
}

export default connect(mapStateToProps)(withRouter(InstrumentListItem));
