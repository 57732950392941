import React from 'react';
import { withRouter } from "react-router";
import _ from 'lodash';

import {getRiskChartTitleType, isSectionVisible} from '../../../../utils/utils'

/* Material-UI Components */
import {
  FormControl,
  Grid, MenuItem, Select,
} from "@material-ui/core";

/* BCA Components*/
import {
  HistoricalChart,
  PerformanceBarChart,
  PerformanceLineChart,
  InstrumentList,
  ProfitLossList,
  PaymentPlans,
  DashboardSection,
  PortfolioStructure,
  RiskCharts,
  DashboardFooterMessage,
  DashboardChartSection
} from './components';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import CustomerThemeProvider from '../../../../components/CustomerThemeProvider';
import useStyles from './styles'

import {isSavingsPlansEnabled, isTradingEnabled} from '../../../../components/TradingStore/utils'
import {
  isPortfolioAvailableForSwitchPlan,
  isCumulativeDisplayEnabled,
  isPortfolioAvailableForPayoutPlan,
  isPortfolioAvailableForSavingsPlan,
  tradingPossible,
  automaticallyUncollapsePaymentPlans,
} from "../../utils";
import {REPORT_TYPES} from "../../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {GROUP_ACTIONS} from "../../../../components/Charts/InstrumentsAllocationTable/constants";

/* BCA modules */

export default withRouter((props) => {
  const {
    dashboardSettings,
    selectedPortfolios,
    dashboardData,
    instrumentListData,
    instrumentListDataLoading,
    instrumentListDataLoadingError,
    historicalData,
    updateHistoricalData,
    calculationDates,
    updateRiskMetricsBenchmark,
    benchmarkConfigurationEnabled,
    profitAndLossData,
    profitAndLossSummary,
    paymentPlansData,
    paymentPlansLoading,
    paymentPlansLoadingError,
    unrealizedProfitAndLossData,
    isCustomerApp,
    expandedItems,
    chartsSettings,
    onExpandedItemsChange,
    onChartSettingsChange,
    breakdownData,
    timeWeightedReturnData,
    riskData,
    onAddTradingOption,
    onAddPortfolioTradingOption,
    onAddSavingPlanOption,
    onAddPortfolioSavingPlanOption,
    onAddPayoutPlanOption,
    onAddPortfolioPayoutPlanOption,
    onAddPortfolioSwitchPlanOption,
    onAddSwitchPlanOption,
    combinedTradings,
    onGoToTradeDetails,
    banksMapping,
    combinedTradingSession,
    virtualInstrumentLink,
    payoutPlansData,
    payoutPlansLoading,
    payoutPlansLoadingError,
    switchPlansData,
    switchPlansLoading,
    switchPlansLoadingError,
    isVirtual,
    triggerPortfolioDeleting,
    refresh,
    banksData,
    reportType,
    customerData,
    instrumentsGroupBy,
    onInstrumentsGroupByChange,
    isVirtualOrderEnabled
  } = props;

  const isCumulativeDisplaySwitchDisabled = !isCumulativeDisplayEnabled(selectedPortfolios)
  const withTransactionSaldo = chartsSettings.global.withTransactionSaldo

  const classes = useStyles()

  let tradingEnabled = false, savingsPlansEnabled = false;
  if (!isCustomerApp || isVirtualOrderEnabled){
    tradingEnabled = isTradingEnabled();
    savingsPlansEnabled = isSavingsPlansEnabled();
  }

  const customTypeSettings = React.useMemo(() => {
    return reportType === REPORT_TYPES.CUSTOM.value
      ? _.get(dashboardSettings, 'custom_report_type_settings.dashboard.subcategories', {})
      : {}
  }, [dashboardSettings, reportType])

  React.useEffect(() => {
    automaticallyUncollapsePaymentPlans(instrumentListData, onExpandedItemsChange, {
      'paymentPlansItems': paymentPlansData,
      'payoutPlansItems': payoutPlansData,
      'switchPlansItems': switchPlansData
    })
  }, [instrumentListData])

  React.useEffect(() => {
    // Use effect to expand portfolios of unrealizedProfitAndLossData that have inflows or outflows
    let portfoliosTransactionsData = _.get(unrealizedProfitAndLossData, 'portfolios_transactions_data', [])
    if(withTransactionSaldo && !_.isEmpty(portfoliosTransactionsData)){
      // keys of portfoliosTransactionsData are portfolio_id-s
      onExpandedItemsChange('unrealizedProfitAndLossItems', Object.keys(portfoliosTransactionsData).map(portfolioId => parseInt(portfolioId)));
    }
  }, [unrealizedProfitAndLossData])

  const handleOnExpandedChange = (expandedItem) => (value) => {
    onExpandedItemsChange(expandedItem, value)
  }

  const handleGroupByChange = (e) => {
    onInstrumentsGroupByChange && onInstrumentsGroupByChange(e.target.value);
  }

  const isInstrumentsDataExist = () => {
    return instrumentListData && instrumentListData.some(portfolio => {
      return !portfolio.is_historical || (_.isArray(portfolio.components) && portfolio.components.length > 0)
        || (_.isArray(portfolio.clearing_account) && portfolio.clearing_account.length > 0);
    });
  };

  const isProfitLossDataExist = () => {
    return _.get(profitAndLossData, 'data', []).length > 0;
  };

  const getSavingPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && (isVirtual || isPortfolioAvailableForSavingsPlan(savingsPlan, instrumentListData, banksMapping))){
      return {
        onAddSavingPlanOption: onAddSavingPlanOption,
        onAddPortfolioSavingPlanOption: onAddPortfolioSavingPlanOption,
        savingPlans: combinedTradings,
        savingsPlanSession: combinedTradingSession,
      }
    }
  };

  const getPayoutPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && isPortfolioAvailableForPayoutPlan(savingsPlan, instrumentListData, banksMapping, banksData)){
      return {
        onAddPayoutPlanOption,
        onAddPortfolioPayoutPlanOption,
        payoutPlans: combinedTradings,
        payoutPlanSession: combinedTradingSession,
      }
    }
  };

  const getSwitchPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && isPortfolioAvailableForSwitchPlan(savingsPlan, instrumentListData, banksMapping, banksData)){
      return {
        onAddSwitchPlanOption,
        onAddPortfolioSwitchPlanOption,
        switchPlans: combinedTradings,
        switchPlanSession: combinedTradingSession,
      }
    }
  };

  const canTradePortfolios = isVirtualOrderEnabled || tradingPossible(instrumentListData, banksMapping);

  const isExpertChartVisible = (chartName) => {
    if([REPORT_TYPES.EXPERT.value, REPORT_TYPES.PRO.value].includes(reportType)){
      return true
    } else if(reportType === REPORT_TYPES.CUSTOM.value){
      return isSectionVisible(customTypeSettings, chartName, reportType)
    }

    return false;
  };

  return (
    <Grid container spacing={2}>
      {(isSectionVisible(customTypeSettings, 'historicalChart', reportType) && reportType != REPORT_TYPES.DEPOT_STATUS.value) && (
        <Grid item xs={12} id="historical-chart">
          <CustomerThemeProvider>
            <HistoricalChart
              dashboardData={historicalData.data}
              dashboardDataLoading={historicalData.loading}
              dashboardDataLoadingError={historicalData.errors}
              dashboardDataOptions={historicalData.options}
              timestamp={historicalData.timestamp}
              onChartSettingsChange={onChartSettingsChange}
              expanded={expandedItems.historicalChart}
              onExpanded={handleOnExpandedChange("historicalChart")}
              showInvestmentCapital={chartsSettings.historical.withInvestiertesAmount}
              isCumulativeDisplaySwitchDisabled={isCumulativeDisplaySwitchDisabled}
              aggregateSelectedPortfolios={chartsSettings.historical.aggregateSelected}
            />
          </CustomerThemeProvider>
        </Grid>
      )}
      {isExpertChartVisible('performanceLineChart') && (
        <Grid item xs={12} id="performance-chart">
          <CustomerThemeProvider>
            <PerformanceLineChart
              dashboardData={timeWeightedReturnData.data}
              dashboardDataLoading={timeWeightedReturnData.loading}
              dashboardDataLoadingError={timeWeightedReturnData.errors}
              dashboardDataOptions={timeWeightedReturnData.options}
              updateHistoricalData={updateHistoricalData}
              updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
              calculationDates={calculationDates}
              benchmarkConfigurationEnabled={benchmarkConfigurationEnabled}
              timestamp={timeWeightedReturnData.timestamp}
              isCustomerApp={isCustomerApp}
              onChartSettingsChange={onChartSettingsChange}
              expanded={expandedItems.performanceLineChart}
              onExpanded={handleOnExpandedChange("performanceLineChart")}
              showBenchmark={chartsSettings.performance.withBenchmark}
              chartsSettings={chartsSettings.performance}
              isCumulativeDisplaySwitchDisabled={isCumulativeDisplaySwitchDisabled}
              aggregateSelectedPortfolios={chartsSettings.performance.aggregateSelected}
              selectedPortfolios={selectedPortfolios}
              isVirtual={isVirtual}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      {(isSectionVisible(customTypeSettings, 'performanceBarChart', reportType) && reportType != REPORT_TYPES.DEPOT_STATUS.value) && (
        <Grid item xs={12} id="performance-bar-chart">
          <CustomerThemeProvider>
            <PerformanceBarChart
              dashboardData={historicalData.data}
              dashboardDataLoading={historicalData.loading}
              dashboardDataLoadingError={historicalData.errors}
              timestamp={historicalData.timestamp}
              expanded={expandedItems.performanceBarChart}
              onExpanded={handleOnExpandedChange("performanceBarChart")}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      {isExpertChartVisible('riskChart') && (
        <Grid item xs={12}>
          <CustomerThemeProvider>
            <DashboardChartSection
              title='Risikokennzahlen'
              content={<RiskCharts dashboardData={riskData} titleType={getRiskChartTitleType(selectedPortfolios)} />}
              loading={riskData.loading}
              error={riskData.errors}
              empty={!riskData.data || (riskData.data && riskData.data.volatility && riskData.data.volatility.min_date_message)}
              emptyMessage={riskData.data && riskData.data.volatility && riskData.data.volatility.min_date_message}
              displayError={true}
              expanded={expandedItems.riskChart}
              onExpanded={handleOnExpandedChange("riskChart")}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      {isSectionVisible(customTypeSettings, 'instrumentsItems', reportType) &&
        <Grid item xs={12} id='instruments-list-tour-element'>
          <CustomerThemeProvider>
            <DashboardSection
              title='Einzeldepots'
              content={
                <InstrumentList
                  data={instrumentListData}
                  expandedItems={expandedItems.instrumentsItems}
                  expandedSubItems={expandedItems.instrumentsSubItems}
                  onExpandedItemsChange={(items) => onExpandedItemsChange('instrumentsItems', items)}
                  onExpandedSubItemsChange={(items) => onExpandedItemsChange('instrumentsSubItems', items)}
                  customReportColumnsConfig={customTypeSettings && _.get(customTypeSettings, '')}
                  showCell={(columnKey) => {
                    // returns true if column is in expanded items of the table, or it is not custom report
                    return isSectionVisible(customTypeSettings, `instrumentsItems.subcategories.${columnKey}`, reportType)
                  }}
                  isCustomerApp={isCustomerApp}
                  isVirtualOrderEnabled={isVirtualOrderEnabled}
                  onAddTradingOption={onAddTradingOption}
                  onAddPortfolioTradingOption={onAddPortfolioTradingOption}
                  banksMapping={banksMapping}
                  tradings={combinedTradings}
                  tradingSession={combinedTradingSession}
                  virtualInstrumentLink={virtualInstrumentLink}
                  reportType={reportType}
                  isVirtual={isVirtual}
                  triggerPortfolioDeleting={triggerPortfolioDeleting}
                  refresh={refresh}
                  customerData={customerData}
                  instrumentsGroupBy={instrumentsGroupBy}
                  canTradePortfolios={canTradePortfolios}
                  customTypeProViewEnabled={
                    reportType === REPORT_TYPES.CUSTOM.value && _.get(dashboardSettings, 'custom_report_type_settings.pro_view.checked', false)
                  }
                  unrealizedProfitAndLossData={unrealizedProfitAndLossData}
                  expandedUnrealizedProfitAndLossItems={expandedItems.unrealizedProfitAndLossItems}
                  onExpandedUnrealizedProfitAndLossItemsChange={(items) =>onExpandedItemsChange('unrealizedProfitAndLossItems', items)}
                  withTransactionSaldo={withTransactionSaldo}
                  withClearingAccount={dashboardSettings.with_other_assets}
                />
              }
              loading={instrumentListDataLoading}
              error={instrumentListDataLoadingError}
              empty={!isInstrumentsDataExist()}
              displayError={true}
              titleControl={
                <Grid container spacing={1} className={classes.titleControl}>
                  {(instrumentsGroupBy && !instrumentListDataLoading) && (
                    <Grid item xs className={classes.selectSection}>
                      <span>Gruppieren nach:</span>
                      <FormControl className={classes.selectFormControl}>
                        <Select
                          variant="outlined"
                          classes={{
                            root: classes.selectRoot,
                          }}
                          onChange={handleGroupByChange}
                          value={instrumentsGroupBy}
                          MenuProps={{
                            classes: {
                              paper: classes.selectMenuPaper
                            }
                          }}
                        >
                          {GROUP_ACTIONS.map(option => (
                            <MenuItem
                              key={option.code}
                              value={option.value}
                              classes={{
                                root: classes.selectMenuItemRoot
                              }}
                            >
                              {option.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {tradingEnabled && !instrumentListDataLoading && (
                    <Grid item xs={'auto'} className={classes.btnContainer}>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <PrimaryButton
                            icon={<i className="far fa-dollar-sign fa-xs"
                              style={{color: canTradePortfolios ? 'white' : 'rgba(0, 0, 0, 0.26)'}}></i>}
                            text={(isVirtual ? !!combinedTradings : !!combinedTradingSession) ? "ORDER FORTSETZEN" : "ORDER"}
                            customClasses={{
                              root: classes.buttonRoot
                            }}
                            disabled={!canTradePortfolios}
                            onButtonClick={(event) => onGoToTradeDetails(event)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              }
            />
          </CustomerThemeProvider>
        </Grid>
      }

      {dashboardSettings.with_payment_plans &&
        <Grid item xs={12}>
          <CustomerThemeProvider>
            <DashboardSection
              title={'Zahlpläne'}
              content={
                <PaymentPlans
                  paymentPlans={paymentPlansData}
                  switchPlans={switchPlansData}
                  payoutPlans={payoutPlansData}
                  paymentPlansExpandedItems={expandedItems.paymentPlansItems}
                  switchPlansExpandedItems={expandedItems.switchPlansItems}
                  payoutPlansExpandedItems={expandedItems.payoutPlansItems}
                  onExpandedItemsChange={(items, key) => onExpandedItemsChange(key, items)}
                  getSavingPlanTradeOptions={getSavingPlanTradeOptions}
                  getPayoutPlanTradeOptions={getPayoutPlanTradeOptions}
                  getSwitchPlanTradeOptions={getSwitchPlanTradeOptions}
                />
              }
              loading={paymentPlansLoading || payoutPlansLoading || switchPlansLoading}
              error={paymentPlansLoadingError || payoutPlansLoadingError || switchPlansLoadingError}
              empty={false}
              displayError={true}
            />
          </CustomerThemeProvider>
        </Grid>
      }

      {dashboardSettings.with_profit_loss &&
        <>
          {(reportType && reportType != REPORT_TYPES.DEPOT_STATUS.value) ? (
            <Grid item xs={12}>
              <CustomerThemeProvider>
                <DashboardSection
                  title='Vollständig verkaufte Vermögenswerte'
                  content={
                    <ProfitLossList
                      data={profitAndLossData}
                      expandedItems={expandedItems.profitAndLossItems}
                      expandedSubItems={expandedItems.profitAndLossSubItems}
                      onExpandedItemsChange={(items) => onExpandedItemsChange('profitAndLossItems', items)}
                      onExpandedSubItemsChange={(items) => onExpandedItemsChange('profitAndLossSubItems', items)}
                    />
                  }
                  loading={profitAndLossData.loading}
                  error={profitAndLossData.errors}
                  empty={!isProfitLossDataExist()}
                  displayError={true}
                />
              </CustomerThemeProvider>
            </Grid>
          ) : null}
        </>
      }

      {isExpertChartVisible('structureChart') && (
        <Grid item xs={12} id="portfolio-structure">
          <CustomerThemeProvider>
            <DashboardChartSection
              title={'Depotstruktur'}
              content={
                <PortfolioStructure
                  dashboardData={breakdownData}
                  customTypeSettings={customTypeSettings && _.get(customTypeSettings, `structureChart.subcategories`, {})}
                  reportType={reportType}
                />
              }
              loading={breakdownData.loading}
              error={breakdownData.errors}
              empty={false}
              displayError={true}
              expanded={expandedItems.structureChart}
              onExpanded={handleOnExpandedChange("structureChart")}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      <Grid item xs={12}>
        <DashboardFooterMessage />
      </Grid>

      <Grid item xs={12} id={'sticky-nav-stoper'} />

    </Grid>
  );
})