import React from 'react';

import Grid from '@material-ui/core/Grid';

import { ChartSectionWrapper } from '../components_v2/ChartSectionBordered/ChartSectionBordered';
import EsgScoreCharts from '../components_v2/charts/ESGScoreCharts';
import SustainabilityMetricsCharts from '../components_v2/charts/SustainabilityMetricsCharts';


function SustainabilityTab(props) {

  return (
    <ChartSectionWrapper
      dataId={"sustainability-section"}
      expanded={props.expandedItems.sustainabilityMetrics}
      title={(<b>Nachhaltigkeitsanalyse (ESG)</b>)}
      onExpanded={(newState) => props.onExpandedItemsChange('sustainabilityMetrics', newState)}
      content={
        <Grid container spacing={2}>
          <EsgScoreCharts {...props} />
          <SustainabilityMetricsCharts {...props} />
        </Grid>
      }
    />
  )
}

SustainabilityTab.propTypes = {}

SustainabilityTab.propDefs = {}

SustainabilityTab.defaultProps = {}

export default SustainabilityTab;