import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  wrapper: {
    margin: '1rem 0',
  },
  userName: {
    marginRight: '1rem',
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    color: '#0D2440',

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },

  userId: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    color: '#202A38',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
});

export default makeStyles(styles);