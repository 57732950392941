import {getSortedObjects} from '../../utils/utils'

export const INSTRUMENT_TYPES_OPTIONS = getSortedObjects([{
  id: 'Equity',
  value: 'Aktien'
}, {
  id: 'Fixed Income',
  value: 'Renten'
}, {
  id: 'Equity Fund',
  value: 'Aktienfonds'
}, {
  id: 'Fixed Income Fund',
  value: 'Rentenfonds'
}, {
  id: 'Mixed Fund',
  value: 'Mischfonds'
}, {
  id: 'Commodity Fund',
  value: 'Rohstoffonds'
}, {
  id: 'Other',
  value: 'Andere'
}])

export const CUSTOMER_DATA_LOADING_ERROR = 'Fehler beim Laden der Kundendaten!'
export const TRANSACTIONS_DATA_LOADING_ERROR = 'Fehler beim Laden der Transaktionen!'
export const TRANSACTIONS_PDF_GENERATION_ERROR = 'Fehler bei Erzeugung des Transaktionsdokumentes (PDF)'

export const TRANSACTION_ORDER_TYPE = {
  TRANSACTION: 1,
  BOOKING: 2
}