import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },

  tableHead: {
    backgroundColor: 'rgb(249, 249, 249)',

    '& > tr > td': {
       verticalAlign: 'top !important',
    },

    '& td': {
      color: 'rgb(177, 177, 177)',
      fontFamily: 'Roboto-Regular',
      fontWeight: 'bold',
    }
  },

  tableRowAsHead: {
    backgroundColor: 'rgb(249, 249, 249)',
  },

  stickyHeader: {
    position: 'sticky',
    top: 0
  },

  tableCellHeaderOrdering: {
    '&:hover': {
      cursor: 'pointer'
    },

    '&.MuiTableCell-alignRight > div': {
      justifyContent: 'flex-end'
    }
  },

  orderingIcon: {
    height: 17,
    width: 17,
    // marginLeft: 5,
    position: 'relative',
    top: 2
  },

  tableBodyRow: {
    '&:hover': {
      backgroundColor: '#efeff2'
    }
  },

  tableFooter: {
    backgroundColor: 'rgb(216, 220, 223)',
    '& > td': {
      color: '#4D4F5C'
    },
  },

  tableCell: {
    padding: '8px 5px',
    verticalAlign: 'top',

    '& .ellipsis': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '& .name': {
      '&:hover': {
        textOverflow: 'initial',
        overflow: 'initial',
        whiteSpace: 'initial',
        wordBreak: 'break-all'
      }
    },
    '& .link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& .normal-whitespace': {
      whiteSpace: 'normal'
    },
    '& .percentagePA': {
      position: 'relative',

      '&::after': {
        content: "'p.a.'",
        position: 'absolute',
        left: '100%',
        marginLeft: 4
      }
    },
    '& .bold': {
      fontFamily: 'Roboto-Bold',
      fontWeight: 'normal,'
    },
  },

  emptyCell: {
    padding: 0,
    margin: 0,
    border: 'none'
  },

  [theme.breakpoints.down('sm')]: {
    tableCell: {
      padding: 5,
      fontSize: 12
    }
  },


  [theme.breakpoints.only('xs')]: {
    tableCell: {
      '& .hide-xs': {
        display: 'none'
      },
      '& .show-xs': {
        display: 'initial'
      }
    }
  },

  paginationContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  paginationItem: {
    padding: 4
  },

  recordsOnPage: {
    fontSize: 16,
    fontWeight: 'Roboto-Regular',
    color: '#80858C'
  },
  paginationInputLabel: {
    fontSize: 14,
    color: '#202A38'
  },
  // inputRoot is needed to override select styles
  inputRoot: {
    height: '36px!important',
    marginBottom: '0!important',
  },
  rotateIcon: {
    fontSize: 20,
    color: 'white',
    width: 30,
    height: 30,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '2px !important'
  },
  arrowContainer: {
    width: 30,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  alignCellTop: {
    verticalAlign: 'top',
  },
  displayNone:{
    display: 'none',
  },
    searchButton: {
    width: '100%',
    height: 48,
    // fontSize: 15,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
    },
    fontFamily: 'Roboto-Bold',
    textTransform: 'none!important',
    boxShadow: 'none!important',
    borderRadius: 4,
    '& .fa': {
      marginRight: '0.5rem',
      position: 'relative',
      top: -1,
    }
  },

}))