import React from "react";
import _ from "lodash";
import { connect } from 'react-redux'

import {ModelPortfolioBase} from "../../../../../../../../../../Modelportfolios/Create/ModelportfolioBase";
import {MODELPORTFOLIO_CREATE_CONTAINER_TABS} from "../../../../../../../../../../Modelportfolios/Create/constants";
import {Fade, Grid, withStyles} from "@material-ui/core";
import {InstrumentsFilter} from "../../../../../../../../../../Modelportfolios/components";
import Link from "../../../../../../../../../../../components/Link";
import {ModelportfoliosFilters} from "../../../../../../../../../../Modelportfolios";

import {tableStructureWithSavingsPlan} from "./table-data";
import styles from "../../styles";
import InstrumenstList from "../../../../../../../../../../Modelportfolios/components/InstrumentsList";
import {
  calculateInstrumentsAverageSRRI,
  CustodianAndRiskProfileInstrumentsHandler, instrumentsSourceSrriValidationRequired, splitEinzeltitelTradingInstruments
} from '../../../../../../../../../utils'
import { instrCustodianData } from '../../../../../../../../../../Trades/utils';
import {SERVICE_CONCEPTS} from "../../../../../../../../../../Trades/constants";
import RiskScoreConfirmationModal from "../RiskScoreConfirmationModal/RiskScoreConfimrationModal";
import {RiskScoreConfirmationModalContext} from "../../InvestmentRecommendationStep";
import {
  calculateTotalAmount,
  tableStructureWithEinzeltitel
} from "../../../../../../../../../../Trades/components/TradeStep/components/PortfolioTrade/table-data";
import useInternalTableStyles from '../InternalTable/styles'
import DashboardTable from "../../../../../../../../../../../components/DashboardTable/DashboardTable";
import {
  TableTooltip,
  USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT
} from "../../../../../../../../../../Trades/components/TradeStep/components/PortfolioTrade/utils";


const mapStateToProps = (state) => ({
  onboarding: state.get('onboarding').toJS(),
});

class Portfolio extends ModelPortfolioBase {

  constructor(props) {

    super(props);

    this.state = {
      ...this.state,

      portfolioAmount: props.singleBuyAmount || 0,
      bankCompanyId: props.dataService && this.props.dataService.bankCompanyId,
      confirmRiskModalOpen: false,
      confirmRiskModalActiveAsset: undefined
    }
  }

  componentDidMount() {
    super.componentDidMount();
    if(this.props.instrumentsSource){
      const items = [...this.props.instrumentsSource.assets];
      items.map(asset => {
        if(+asset.weight){
          const singleBuyAmount = this.props.singleBuyAmount || 0;
          const savingsPlanAmount = this.props.savingsPlan || 0;
          asset.weight = +asset.weight;
          asset.amount = singleBuyAmount * (asset.weight / 100);
          asset.savingsPlanAmount = savingsPlanAmount * (asset.weight / 100);
        }
      });

      this.setState({
        sourceAssets: items
      });
      this.handleSelectedAssetsChange(items, true, false)
    } else if(this.props.instruments){
      let instrumentsGrouped = {};
      this.props.instruments.forEach(i => {
        const data = {...i};
        if(i.savingsPlan) {
          data.savingsPlanAmount = i.amount;
          delete data.amount;
        }
        instrumentsGrouped[i.isin] = {...instrumentsGrouped[i.isin], ...data}
      });

      instrumentsGrouped = Object.values(instrumentsGrouped);
      this.setSelectedAssets(instrumentsGrouped);
    }

    if (this.props.dataService) {
      const dataService = this.props.dataService;
      this.setState({
        bankCompanyId: this.props.dataService.bankCompanyId,
        instrumentsHandler: this.props.instrumentsHandler || new CustodianAndRiskProfileInstrumentsHandler(dataService.bankCompanyId, undefined, dataService._target_market, undefined, this.props.vlPlanEnabled)
      })
    }
  }

  initWeightAndAmount = (assets, portfolioAmount) => {
    assets.forEach(a => this._initWeightAmountForAsset(a, portfolioAmount));
  };

  _initWeightAmountForAsset = (asset, portfolioAmount) => {
    portfolioAmount = portfolioAmount || this.state.portfolioAmount;
    const custodianData = instrCustodianData(asset, this.state.bankCompanyId);

    if(!_.isNaN(+asset.amount ) || !_.isNaN(+asset.savingsPlanAmount)){
      asset.amount = custodianData.c_single_payment_possible && +asset.amount || 0;
      asset.savingsPlanAmount = custodianData.c_savings_plan_possible && +asset.savingsPlanAmount || 0;
      asset.weight = portfolioAmount !== 0 ? (asset.amount / portfolioAmount) * 100 : 0;
    } else if(+asset.weight && custodianData.c_single_payment_possible){
      asset.weight = +asset.weight;
      asset.amount = portfolioAmount * (asset.weight / 100);
      asset.savingsPlanAmount = 0;
    } else {
      asset.weight = 0;
      asset.amount = 0;
      asset.savingsPlanAmount = 0;
    }
  };

  addInstrumentsFilters = async (instruments) => {
    this.initWeightAndAmount(instruments);
    if(!this.props.confirmChange || await this.props.confirmChange(instruments)){
      this.handleAddInstrumentsFilters(instruments);
    }
  };

  addAssetClick = async (asset) => {
    this._initWeightAmountForAsset(asset);
    if(!this.props.confirmChange || await this.props.confirmChange(asset)) {
      this.handleAddAssetClick(asset);
    }
  }

  removeAssetClick = (asset) => this.handleAddAssetClick(asset);

  setSelectedAssets = (instruments, cleanSource=true) => {
    let items = [...instruments];
    this.initWeightAndAmount(items);
    const assetsStateKey = !!this.props.instrumentsSource ? 'sourceAssets' : 'selectedAssets'
    this.setState({
      [assetsStateKey]: items
    });

    this.handleSelectedAssetsChange(items, false, cleanSource)
  };

  handleSelectedAssetsChange = (selectedAssets, init=false, cleanSource=true) => {
    const splittedAssets = [];

    selectedAssets.forEach(i => {
      if(!i.amount && !i.savingsPlanAmount){
        splittedAssets.push({
          ...i,
          savingsPlan: false
        })
      } else {
        if(i.amount){
          splittedAssets.push({
            ...i,
            savingsPlan: false
          })
        }
        if(i.savingsPlanAmount){
          splittedAssets.push({
            ...i,
            amount: i.savingsPlanAmount,
            savingsPlan: true
          })
        }
      }
    });

    this.props.onAssetsUpdate && this.props.onAssetsUpdate(splittedAssets, init, cleanSource);
  };

  componentDidUpdate(preProps) {
    let element = document.getElementById('step_footer_buttons')
    if (this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED) {
      element.style.display = 'none'
    } else {
      element.style.display = ''
    }

    const assetsStateKey = !!this.props.instrumentsSource ? 'sourceAssets' : 'selectedAssets'

    if (!_.isEmpty(this.props.instruments)) {
      const selectedAssets = _.cloneDeep(this.state[assetsStateKey])
      let syncRequired = false
      selectedAssets.forEach((asset) => {
        const assetData = _.find(this.props.instruments, (instrument) => instrument.isin == asset.isin)
        if (!assetData) return

        const errorsKey = assetData.hasOwnProperty('errors') ? 'errors' : 'error'

        if (JSON.stringify(assetData[errorsKey]) != JSON.stringify(asset[errorsKey])) {
          asset[errorsKey] = assetData[errorsKey]
          syncRequired = true
        }
      })

      syncRequired && this.setState({
        [assetsStateKey]: selectedAssets
      })
    }
  }

  handleConfirmRiskModalClose = () => {
    this.setState({
      ...this.state,
      confirmRiskModalOpen: false,
      confirmRiskModalActiveAsset: undefined
    })
  }

  handleSelectAsset = (asset) => {
    this.setState({
      ...this.state,
      confirmRiskModalOpen: true,
      confirmRiskModalActiveAsset: asset
    })
  }

  render() {
    const {
      classes,
      error,
      fundsError,
      sharesError,
      singleBuyAmount,
      savingsPlan,
      instrumentsSources,
      instrumentsSource,
      depot,
      vlPlanEnabled,
      instrumentsSearchDisabled,
      savingsPlanEnabled,
      singleInvestmentEnabled,
      einzeltitelEnabled
    } = this.props;

    const showFundsTable = vlPlanEnabled || savingsPlanEnabled || singleInvestmentEnabled || this.props.dataService.isOpenOnly;

    const getInstruments = () => {
      return !!instrumentsSource ? this.state.sourceAssets : this.state.selectedAssets;
    };

    const TotalPortfolioAmount = (instruments) => {
      let savingsPlanAmount = 0, acceptedAmount = 0;
      (instruments || []).forEach(i => {
        savingsPlanAmount += i.savingsPlanAmount;
        acceptedAmount += i.amount || i.transaction_value || 0;
      });
      acceptedAmount = _.round(acceptedAmount, 2);
      savingsPlanAmount = _.round(savingsPlanAmount, 2);

      return {acceptedAmount, savingsPlanAmount};
    };

    const handleInstrumentChange = (instrument, data, cleanSource=true) => {
      const assetsStateKey = !!this.props.instrumentsSource ? 'sourceAssets' : 'selectedAssets'
      let items = [...this.state[assetsStateKey]];
      const idx = this.findAssetIndex(items, instrument);

      if(idx !== -1){
        items[idx] = {...items[idx], dirty: true, ...data};
        this.setSelectedAssets(items, cleanSource)
      }
    };

    const validateAmount = (item, value, field) => {
      field = field || 'amount';
      const bankPaymentPlanMinAmountField = this.props.vlPlanEnabled
        ? 'onboarding_vl_plan_min_amount_payment'
        : 'onboarding_savings_plan_min_amount_payment'
      const instrumentPaymentPlanMinAmountField = this.props.vlPlanEnabled
        ? 'c_min_amount_vl_plan' // NOTE: We assume that vl min amount will be under this field
        : 'c_min_amount_savings_plan'
      const banksMinAmount = parseFloat(_.get(depot,
        field === 'amount' ? 'onboarding_min_amount_payment' : bankPaymentPlanMinAmountField,
        0));
      let minOrderAmount = instrCustodianData(item, this.state.bankCompanyId)[field === 'amount' ? 'c_min_amount_single_payment' : instrumentPaymentPlanMinAmountField] || 0;
      minOrderAmount = _.max([banksMinAmount, minOrderAmount]);
      value = value || 0;
      if(value < 0){
        return 'Bitte geben Sie einen Wert größer als 0 € ein.';
      } else if(minOrderAmount && value && value < minOrderAmount){
        return `Mindestbetrag: ${minOrderAmount} €`;
      }
      return null;
    };

    const handleWeightChange = (item, value) => {};

    const handleAmountChange = (item, value) => {
      handleInstrumentChange(item, {
        amount: value,
        error: {...item.error, amount: validateAmount(item, value)}
      });
    };

    const handleSavingsPlanChange = (item, value) => {
      handleInstrumentChange(item, {
        savingsPlanAmount: value,
        error: {...item.error, savingsPlanAmount: validateAmount(item, value, 'savingsPlanAmount')}
      });
    };

    const handleOrderVariationChange = (item, value) => {
      handleInstrumentChange(item, {order_type: value, errors: {...item.errors, order_type: null, limit: null}});
    };

    const handleLimitValueChange = (item, value, amount) => {
      handleInstrumentChange(item, {
        limit: value,
        transaction_value: amount, amount, errors: {
          ...item.errors,
          limit: null
        }});
    };

    const handleQuantityChange = (item, value) => {
      let quantity = value
      if (_.get(item, 'data.quantity')) {
        quantity = value <= item.data.quantity ? value : item.data.quantity
      }
      const amount = calculateTotalAmount(item, item.limit, quantity);
      handleInstrumentChange(item, {quantity: quantity, transaction_value: amount, amount, errors: {...item.errors, quantity: null}});
    };

    const handleValidUntilChange = (item, value, option) => {
      handleInstrumentChange(item, {valid_until: value, valid_until_option: option, errors: {...item.errors, valid_until: null}});
    };

    const handleStockExchangeChange = (item, value, option) => {
      handleInstrumentChange(item, {stock_exchange: value, stock_exchange_option: option, errors: {...item.errors, stock_exchange: null}});
    };

    const handleConfirmRiskModalSave = (item, value) => {
      if (!item.isInvestmentStrategy) {
        handleInstrumentChange(item, {
          risk_score_explanation: value,
          error: {...item.error, risk_score_explanation: undefined}
        }, false)
      } else {
        const instrumentSourceCopy = {...item};
        _.set(instrumentSourceCopy, 'error.risk_score_explanation', undefined);
        _.set(instrumentSourceCopy, 'risk_score_explanation', value);
        this.props.onInstrumentsSourceAnswerChange(instrumentSourceCopy);
      }
      this.setState({
        confirmRiskModalOpen: false,
        confirmRiskModalActiveAsset: undefined
      })
    }

    const isAnlageberatung = this.props.dataService._service_concept == SERVICE_CONCEPTS.Anlageberatung
    const averageSrriValue = isAnlageberatung && calculateInstrumentsAverageSRRI(getInstruments(), (instrument) => (instrument.amount || 0))

    const onConfirmHighRiskScore = (investmentStrategy) => {
      this.handleSelectAsset(investmentStrategy)
    }

    const sourceSriConfirmationRequired = !!this.props.instrumentsSource
      && (this.props.instrumentsSource.risk_score_confirmation_required
        || this.props.instrumentsSource.experience_confirmation_required);

    const getInstrumenstList = () => {

      const renderTables = () => {

        const internalTableClasses = useInternalTableStyles()

        let mainTable, internalTable, internalInstruments = [], mainInstruments = [];
        [internalInstruments, mainInstruments] = splitEinzeltitelTradingInstruments(getInstruments(), {}, internalInstruments, mainInstruments);

        if (showFundsTable) {
          mainTable = (
            <DashboardTable
              structure={tableStructureWithSavingsPlan}
              dataSource={mainInstruments || []}
              expanded={true}
              loadingIndicator={false}
              tableClasses={classes}
              withFooter={true}
              withInputs={true}
              withHeader={true}
              options={{
                onEditWeightsClick: this.handleOpenInstrumentsWeightsModalClick,
                itemsLength: mainInstruments && mainInstruments.length,
                bankCompanyId: this.state.bankCompanyId,
                clientSRRI: (!instrumentsSource || instrumentsSourceSrriValidationRequired(instrumentsSource)) && isAnlageberatung && this.props.dataService._srri,
                readOnly: !!instrumentsSource,
                handleWeightChange: handleWeightChange,
                handleAmountChange: handleAmountChange,
                handleSavingsPlanChange: handleSavingsPlanChange,
                onRemoveAssetClick: this.removeAssetClick,
                error: fundsError,
                savingsPlan,
                singleBuyAmount,
                isOpenOnly: this.props.dataService.isOpenOnly,
                hideAcceptanceCriteriasUnderName: true,
                ...TotalPortfolioAmount(mainInstruments)
              }}
            />
          )
        }

        if (einzeltitelEnabled) {
          internalTable = (
            <DashboardTable
              structure={tableStructureWithEinzeltitel}
              dataSource={internalInstruments || []}
              expanded={true}
              loadingIndicator={false}
              tableClasses={internalTableClasses}
              withFooter={true}
              withInputs={true}
              options={{
                bankCompanyId: this.state.bankCompanyId,
                clientSRRI: (!instrumentsSource || instrumentsSourceSrriValidationRequired(instrumentsSource)) && isAnlageberatung && this.props.dataService._srri,
                readOnly: !!instrumentsSource,
                error: sharesError,
                savingsPlan,
                singleBuyAmount,
                action: 'buy',
                stockExchangeOptions: this.props.dataService.bank && this.props.dataService.bank.stock_exchange_options,
                orderTypeOptions: this.props.dataService.bank && this.props.dataService.bank.einzeltitel_order_type_buy,
                handleOrderVariationChange: handleOrderVariationChange,
                handleLimitValueChange: handleLimitValueChange,
                handleQuantityChange: handleQuantityChange,
                handleValidUntilChange: handleValidUntilChange,
                handleStockExchangeChange: handleStockExchangeChange,
                handleRemove: this.removeAssetClick,
                totalAmount: TotalPortfolioAmount(internalInstruments).acceptedAmount,
                isOpenOnly: this.props.dataService.isOpenOnly,
                hideAcceptanceCriteriasUnderName: true
              }}
            />
          )
        }

        return (
          <Grid container spacing={2}>
            {internalTable && (
              <Grid item xs={12}>
                {internalTable}
                <TableTooltip text={USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT} />
              </Grid>
            )}
            <Grid item xs={12}>
              {mainTable}
            </Grid>
          </Grid>
        )
      }

      return (
        <Grid item xs={12}>
          <InstrumenstList
            instruments={getInstruments()}
            onRemoveAssetClick={this.addAssetClick}
            modelPortfolio={instrumentsSource}
            withModelPortfolioData={!!instrumentsSource}
            withPortfolioAverageSRRI={isAnlageberatung}
            averageSrriValue={averageSrriValue}
            onEditWeightsClick={this.handleOpenInstrumentsWeightsModalClick}
            onDeleteModelPortfolioClick={this.props.onDeleteModelPortfolioClick}
            onConfirmHighRiskScore={sourceSriConfirmationRequired && onConfirmHighRiskScore}
            modelPortfolioType={instrumentsSource && instrumentsSource.sourceType}
            tableData={{
              tableScrollable: false,
              withInputs: true,
              withHeader: !!instrumentsSource || isAnlageberatung || this.props.infoText, // Display header also in case first service concept to display instruments average SRRI
              withFooter: true,
              classes: classes,
            }}
            renderTables={renderTables}
            infoText={this.props.infoText}
          />
        </Grid>
      )
    }

    return (
      <RiskScoreConfirmationModalContext.Provider
        value={{
          open: this.state.confirmRiskModalOpen,
          activeAsset: this.state.confirmRiskModalActiveAsset,
          onClose: this.handleConfirmRiskModalClose,
          onSave: handleConfirmRiskModalSave,
          onSelectAsset: this.handleSelectAsset,
          singleInvestmentEnabled: this.props.singleInvestmentEnabled
        }}
      >
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.filterContainer}>
                <InstrumentsFilter
                  title={"Investment hinzufügen"}
                  instruments={this.getFilterInstruments()}
                  instrumentsDisabled={getInstruments()}
                  loading={this.state.assetsSearch.loading}
                  errors={this.state.assetsSearch.errors}
                  onSearchActivate={this.handleSearchActivate}
                  onAddAssetClick={this.addAssetClick}
                  onFiltersExtendedButtonClick={this.handleFiltersExtendedButtonClick}
                  extraActions={instrumentsSources}
                  disabled={instrumentsSearchDisabled}
                />
              </Grid>
              {getInstrumenstList()}
            </Grid>
          </>
        )}
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED && (
          <>
            <Link
              title="Schließen"
              icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
              underline
              onClick={() => this.setState({activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN})}
            />
            <Fade in={this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED} timeout={500}>
              <ModelportfoliosFilters
                onAddInstruments={this.addInstrumentsFilters}
                instrumentsHandler={this.state.instrumentsHandler}
                addExtraFilters={this.addExtraFilters}
                predefinedFilter={{cCustodian: [{label: depot.depot, id: depot.bankCompanyId, value: depot.bankCompanyId}]}}
              />
            </Fade>
          </>
        )}
        <RiskScoreConfirmationModal />
      </RiskScoreConfirmationModalContext.Provider>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Portfolio));