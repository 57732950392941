import React from 'react';
import {Grid} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { isProSectionVisible, withPercentOrDash } from '../../../utils/utils';
import _ from "lodash";
import WarningTooltip from "../../../components/WarningTooltip";
import clsx from "clsx";
import useStyles from './styles';
import {useRollingVolatilityData} from "../hooks/useRollingVolatilityData";
import {PerformanceChart} from "../../ProductComparison/components/charts";
import Legend from "../components_v2/Legend/Legend";
import {useRollingSharpeRatioData} from "../hooks/useRollingSharpeRatio";
import AggregatedDepotsSwitch from "../components_v2/AggregatedDepotsSwitch/AggregatedDepotsSwitch";
import ChartSectionBordered from "../components_v2/ChartSectionBordered/ChartSectionBordered";
import ExplanationTooltip from '../../../components/ExplanationTooltip';
import { noBenchmarkValue } from '../../../utils/constants';
import { toGermanFormat } from '../../../utils/numberFormater';
import {useKeyIndicatorsData} from "../hooks/useKeyIndicatorsData";
import {getDataRetrievingParamsFromProps} from "../utils";


const RollingVolatilitySection  = React.memo((props) => {

  const classes = useStyles();

  const {
    mainDataLoading, expanded, onExpanded, customerId,
    portfolios, benchmarks, startDate, endDate, dataProvider,
    aggregatedPortfolioName, investmentStrategyId,
  } = props;

  const [rollingVolatilityAggregated, setRollingVolatilityAggregated] = React.useState(true);
  const [rollingVolatilityData, fetchRollingVolatilityData] = useRollingVolatilityData(
    customerId, portfolios, benchmarks, rollingVolatilityAggregated, startDate, endDate, dataProvider,
    aggregatedPortfolioName, investmentStrategyId);

  React.useEffect(() => {
    if (!mainDataLoading && !_.isEmpty(portfolios) && !!customerId) {
      fetchRollingVolatilityData();
    }
  }, [mainDataLoading, customerId, JSON.stringify(portfolios), startDate,
    endDate, JSON.stringify(benchmarks), rollingVolatilityAggregated]);

  return (
    <ChartSectionBordered
      dataId={'rolling-volatility'}
      title={(
        <>
          <b>Rollierende 1-Jahres Volatilität-Entwicklung</b>
          <WarningTooltip
            title={'Die "Rollierende 1-Jahres-Volatilität" ist eine Finanzkennzahl, die die Schwankungen der Renditen des Portfolios über einen Zeitraum von einem Jahr misst und dient zur Risikobewertung.'}
            icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
            width={600}
          />
        </>
      )}
      titleControl={Object.keys(portfolios).length > 1 && (
        <AggregatedDepotsSwitch value={rollingVolatilityAggregated} onChange={(value) => setRollingVolatilityAggregated(value)}/>
      )}
      loading={mainDataLoading || rollingVolatilityData.loading}
      error={rollingVolatilityData.errors}
      displayError
      expanded={expanded}
      onExpanded={(newState) => onExpanded('rollingVolatilityChart', newState)}
      content={(
        <Grid container>
          <Grid item xs={12} md={8}>
            <PerformanceChart
              updatedAt={rollingVolatilityData.updatedAt}
              loading={rollingVolatilityData.loading}
              errors={rollingVolatilityData.errors}
              data={Object.values(rollingVolatilityData.data || {})}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {rollingVolatilityData.data && (
              <Legend data={rollingVolatilityData.data}/>
            )}
          </Grid>
        </Grid>
      )}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.mainDataLoading == nextProps.mainDataLoading
    && prevProps.expanded == nextProps.expanded
    && prevProps.customerId == nextProps.customerId
    && prevProps.startDate == nextProps.startDate
    && prevProps.endDate == nextProps.endDate
    && prevProps.investmentStrategyId == nextProps.investmentStrategyId
    && JSON.stringify(prevProps.portfolios) == JSON.stringify(nextProps.portfolios)
    && JSON.stringify(prevProps.benchmarks) == JSON.stringify(nextProps.benchmarks)
});


const RollingSharpeRatioSection  = React.memo((props) => {

  const classes = useStyles();

  const {
    mainDataLoading, expanded, onExpanded,
    customerId, portfolios, benchmarks, startDate,
    endDate, dataProvider, aggregatedPortfolioName, investmentStrategyId,
  } = props;

  const [rollingSharpeAggregated, setRollingSharpeAggregated] = React.useState(true);
  const [rollingSharpeRatioData, fetchRollingSharpeRatioData] = useRollingSharpeRatioData(
    customerId, portfolios, benchmarks, rollingSharpeAggregated, startDate, endDate, dataProvider,
    aggregatedPortfolioName, investmentStrategyId);

  React.useEffect(() => {
    if (!mainDataLoading && !_.isEmpty(portfolios) && !!customerId) {
      fetchRollingSharpeRatioData();
    }
  }, [mainDataLoading, customerId, JSON.stringify(portfolios), startDate,
    endDate, JSON.stringify(benchmarks), rollingSharpeAggregated]);

  return (
    <ChartSectionBordered
      dataId={'rolling-sharpe-ratio'}
      title={(
        <>
          <b>Rollierendes 1-Jahres Sharpe ratio</b>
          <WarningTooltip
            title={'Die "rollierende 1-Jahres-Sharpe Ratio" ist eine Finanzkennzahl, die das Verhältnis von Rendite zu Risiko des Portfolios misst. Es wird verwendet, um zu beurteilen, ob die erzielte Rendite eines Portfolios die damit verbundenen Risiken angemessen kompensiert. Eine höhere Sharpe Ratio deutet darauf hin, dass das Portfolio eine bessere Rendite pro Einheit Risiko erzielt.'}
            icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
            width={600}
          />
        </>
      )}
      titleControl={Object.keys(portfolios).length > 1 && (
        <AggregatedDepotsSwitch value={rollingSharpeAggregated} onChange={(value) => setRollingSharpeAggregated(value)}/>
      )}
      loading={mainDataLoading || rollingSharpeRatioData.loading}
      error={rollingSharpeRatioData.errors}
      displayError
      expanded={expanded}
      onExpanded={(newState) => onExpanded('rollingSharpeRatioChart', newState)}
      content={(
        <Grid container>
          <Grid item xs={12} md={8}>
            <PerformanceChart
              updatedAt={rollingSharpeRatioData.updatedAt}
              loading={rollingSharpeRatioData.loading}
              errors={rollingSharpeRatioData.errors}
              data={Object.values(rollingSharpeRatioData.data || {})}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {rollingSharpeRatioData.data && (
              <Legend data={rollingSharpeRatioData.data}/>
            )}
          </Grid>
        </Grid>
      )}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.mainDataLoading == nextProps.mainDataLoading
    && prevProps.expanded == nextProps.expanded
    && prevProps.customerId == nextProps.customerId
    && prevProps.startDate == nextProps.startDate
    && prevProps.endDate == nextProps.endDate
    && prevProps.investmentStrategyId == nextProps.investmentStrategyId
    && JSON.stringify(prevProps.portfolios) == JSON.stringify(nextProps.portfolios)
    && JSON.stringify(prevProps.benchmarks) == JSON.stringify(nextProps.benchmarks)
});


function KeyFiguresTab(props) {
  const {customSettings, reportType, aggregatedPortfolioName} = props;
  const classes = useStyles();

  // region Dashboard V2
  const portfolios = _.filter(
    props.selectedPortfolios || [], (portfolio) => !!portfolio.depotNumber)
    .reduce((result, portfolio) => {
      result[portfolio.depotNumber] = {
        assets: [], // For now we retrieve data for whole depot,
        id: portfolio.depotNumber,
        name: portfolio.name,
        color: portfolio.color
      };
      return result;
    }, {});
  const {
    customerId,
    dates,
    dataProvider,
    withHistorical,
    benchmarks,
    showBenchmark,
    assets,
    investmentStrategyId,
  } = getDataRetrievingParamsFromProps(props);

  const [keyFiguresData, fetchKeyIndicators] = useKeyIndicatorsData(
    customerId, portfolios, benchmarks, withHistorical,
    dates.start, dates.end, dataProvider, assets, investmentStrategyId);

  const dataFetchingPossible = () => props.isMounted && !_.isEmpty(props.selectedPortfolios) && !!customerId;
  const dataFetchingCommonDeps = [
    props.isMounted, customerId, props.selectedPortfolios, dates.start,
    dates.end, JSON.stringify(benchmarks)
  ];

  React.useEffect(() => {
    if (dataFetchingPossible()) {
      fetchKeyIndicators();
    }
  }, [...dataFetchingCommonDeps, withHistorical]);

  // endregion

  const portfolioLevelOnlyInfoText = undefined,
    rendite = _.get(props.investmentData, 'data.ydt.percentage'),
    return_pa = _.get(props.investmentData, 'data.ydt.percentage_pa');

  const formatNumber = (number) => {
    return number || number === 0 ? toGermanFormat(number, '', '', 2): '-'
  };

  const IndicatorCard = ({title, value, tooltipKey, additionalText, className}) => {
    return (
      <Grid item xs md={4} data-id={`key-indicators-${tooltipKey}`} className={classes.indicatorGridItem}>
        <Paper className={clsx(classes.indicatorCard, className)} elevation={0}>
          <div className={classes.hugeNumber}>
            {value}
          </div>
          <h5 className={classes.smallTitle}>
            {title}<ExplanationTooltip tooltipKey={tooltipKey} additionalText={additionalText}/>
          </h5>
        </Paper>
      </Grid>
    )
  };

  const getIndicatorCardVariant = (value) => value === 0 ? undefined : value < 0 ? 'red' : 'green';

  const keyIndicatorsSettings = _.get(customSettings, 'keyIndicatorsChart.subcategories');

  return (
    <Grid container spacing={2}>
      {isProSectionVisible('keyIndicatorsChart', reportType, customSettings) && (
        <Grid item xs={12}>
          <ChartSectionBordered
            dataId={'key-indicators'}
            title={(
              <b>Kennzahlen</b>
            )}
            loading={!props.isMounted || keyFiguresData.loading || props.investmentData.loading}
            error={keyFiguresData.errors}
            displayError
            expanded={props.expandedItems.keyIndicatorsChart}
            onExpanded={(newState) => props.onExpandedItemsChange('keyIndicatorsChart', newState)}
            content={(
              <Grid container spacing={1}>
                {isProSectionVisible('shortFall', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={"Expected Shortfall"}
                    tooltipKey={'expected_shortfall'}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={keyFiguresData.data.expectedShortfall ? formatNumber(Math.abs(keyFiguresData.data.expectedShortfall) * 100) + ' %' :
                            <span className={classes.empty}>{noBenchmarkValue}</span>}
                  />
                )}
                {isProSectionVisible('sharpRatio', reportType, keyIndicatorsSettings) && (
                <IndicatorCard
                  title={`Sharpe Ratio (Benchmark: ${formatNumber(keyFiguresData.data.bm_sharpe_ratio)})`}
                  tooltipKey={'sharpe_ratio'}
                  value={keyFiguresData.data.pf_sharpe_ratio ? formatNumber(keyFiguresData.data.pf_sharpe_ratio) :
                          <span className={classes.empty}>{noBenchmarkValue}</span>}
                />
                )}
                {isProSectionVisible('return', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Rendite'}
                    tooltipKey={'return'}
                    className={getIndicatorCardVariant(rendite)}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={withPercentOrDash(rendite, true)}
                  />
                )}
                {isProSectionVisible('returnPA', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Rendite p.a.'}
                    tooltipKey={'return_pa'}
                    className={getIndicatorCardVariant(return_pa)}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={withPercentOrDash(return_pa, true)}
                  />
                )}
                {isProSectionVisible('trackingError', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Tracking Error'}
                    tooltipKey={'tracking_error'}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={keyFiguresData.data.tracking_error ? formatNumber(keyFiguresData.data.tracking_error) + ' %' :
                            <span className={classes.empty}>{noBenchmarkValue}</span>}
                  />
                )}
                {isProSectionVisible('infoRatio', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Information Ratio'}
                    tooltipKey={'information_ratio'}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={showBenchmark && keyFiguresData.data.information_ratio ? formatNumber(keyFiguresData.data.information_ratio) :
                           <span className={classes.empty}>{noBenchmarkValue}</span>}
                  />
                )}
                {isProSectionVisible('alpha', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Alpha'}
                    tooltipKey={'alpha'}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={showBenchmark && keyFiguresData.data.alpha ? formatNumber(keyFiguresData.data.alpha) :
                           <span className={classes.empty}>{noBenchmarkValue}</span>}
                  />
                )}
                {isProSectionVisible('beta', reportType, keyIndicatorsSettings) && (
                  <IndicatorCard
                    title={'Beta'}
                    tooltipKey={'beta'}
                    additionalText={portfolioLevelOnlyInfoText}
                    value={showBenchmark && keyFiguresData.data.beta ? formatNumber(keyFiguresData.data.beta) :
                           <span className={classes.empty}>{noBenchmarkValue}</span>}
                  />
                )}
              </Grid>
            )}
          />
        </Grid>
      )}
      {isProSectionVisible('rollingVolatilityChart', reportType, customSettings) && (
        <Grid item xs={12}>
          <RollingVolatilitySection
            mainDataLoading={!props.isMounted}
            expanded={props.expandedItems.rollingVolatilityChart}
            onExpanded={props.onExpandedItemsChange}
            customerId={customerId}
            portfolios={portfolios}
            benchmarks={benchmarks}
            startDate={dates.start}
            endDate={dates.end}
            dataProvider={dataProvider}
            aggregatedPortfolioName={aggregatedPortfolioName}
            investmentStrategyId={investmentStrategyId}
          />
        </Grid>
      )}
      {isProSectionVisible('rollingSharpeRatioChart', reportType, customSettings) && (
        <Grid item xs={12}>
          <RollingSharpeRatioSection
            mainDataLoading={!props.isMounted}
            expanded={props.expandedItems.rollingSharpeRatioChart}
            onExpanded={props.onExpandedItemsChange}
            customerId={customerId}
            portfolios={portfolios}
            benchmarks={benchmarks}
            startDate={dates.start}
            endDate={dates.end}
            dataProvider={dataProvider}
            aggregatedPortfolioName={aggregatedPortfolioName}
            investmentStrategyId={investmentStrategyId}
          />
        </Grid>
      )}
    </Grid>
  )
}

KeyFiguresTab.propTypes = {};

KeyFiguresTab.propDefs = {};

KeyFiguresTab.defaultProps = {};

export default KeyFiguresTab;