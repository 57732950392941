import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import clsx from 'clsx';
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles, {useSwitchInTableStyles, useSwitchOutTableStyles} from "./styles"
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DashboardTable from "../../../../../../components/DashboardTable";
import {
  calculateTotalAmount, getPortfolioTypeTransactionTypeTableStructure,
  tableStructureForEinzeltitelBuyTrading,
  tableStructureForEinzeltitelSellTrading,
} from "./table-data";
import moment from "moment";
import {
  getErrorMessage,
  getSubSystemConfigItem,
  QTY_DECIMALS,
  withEuroOrDash
} from "../../../../../../utils/utils";
import {
  getModelPortfolioTransactionTypeOptions,
  ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES,
  ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES,
  SERVICE_CONCEPTS,
  TRANSACTION_INSTR,
  TRANSACTION_INSTR_ORDERING_FIELD_NAMES,
  TRANSACTION_START_DATE,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_VALUES
} from "../../../../constants";
import {toGermanFormat} from "../../../../../../utils/numberFormater";
import {
  calculatePortfolioInstrumentsAverageSrri,
  calculateSwitchTransactionSaldo, FundsInstrumentsHandler, setEarliestPriceDates,
  getTransactionItemSRRI,
  getTransactionType,
  mergeSwitchItems,
  percentageToQuantity,
  portfolioTransactionAmount,
  setInstrumentBankData,
  setMPInstrumentBankData,
  transactionValueToEuro,
  transactionValueToPercentage,
  transactionValueToQuantity, instrCustodianData,
  isHideTradingSection
} from "../../../../utils";
import {default as IconBtn} from "../../../../../../components/Buttons/IconButton";
import PlusBackgroundIcon from "../../../../../../images/PlusBackgroundIcon";
import {Dialog, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import Create from "../Create";
import {
  extendAssetsWithDataAttribute,
  getRiskScoreConfirmation,
  TradingSellInstrumentsHandler,
  TradingSwitchOutInstrumentsHandler,
  EinzeltitelTradingSwitchInInstrumentsHandler,
  getRiskProfilingAnswers,
  splitEinzeltitelTradingInstruments,
  EinzeltitelTradingInstrumentsHandler,
  getPortfolioBuilderExcludedMsg,
  PORTFOLIO_BUILDER_NO_ASSETS_MSG,
} from '../../../../../RiskProfiling/utils';
import {
  addPortfolioCombinedTradingOption,
  updatePortfolioCombinedTradingOption,
  removeCombinedTradingOption,
  removePortfolioCombinedTradingOption,
  addCombinedTradingOption
} from "../../../../../../components/TradingStore/actions";
import FormHelperText from "@material-ui/core/FormHelperText";
import SelectFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import { RiskScoreConfirmationModalContext } from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/InvestmentRecommendationStep";
import RiskScoreConfirmationModal
  from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/RiskScoreConfirmationModal/RiskScoreConfimrationModal";
import {useConfirmationModalContext} from "../../../../../../components/ConfirmationModalContextProvider";
import {
  TRADING_ACTION_SELL, TRADING_ACTION_SWITCH,
  TRADING_ACTIONS
} from '../../../../../../components/Charts/InstrumentsAllocationTable/constants'
import {ModelPortfolioResource, parseResponse, VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import {
  PAYMENT_PLANS_PORTFOLIO_ONLY_ADD_NEW_POSITION_MSG,
} from "../../../../../RiskProfiling/constants";
import {
  getAssetInternalId,
  paymentPlanHasPosition,
  portfolioIsPaymentPlansOnly
} from "../../../../../CustomerDashboard/utils";
import { displayErrorSnackBar, displayWarningSnackBar } from '../../../../../../components/SnackbarProvider/actions';
import {getPortfolioInstrumentsData, portfolioHasTransactions} from "../../CombinedTradeStep";
import {TableTooltip, USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT} from "./utils";
import {PortfolioWrapper} from "./PortfolioWrapper";
import InputFormElement, {
  CurrencyNumberFormat
} from "../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import {MODEL_PORTFOLIO_WEIGHT} from "../../../../../Modelportfolios/Create/constants";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import PortfolioBuilder
  from '../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/PortfolioBuilder';


export const buildTransactionDateSensitiveData = (transaction, data) => {
  const historyDate = moment(data.date, 'YYYY-MM-DD');

  let hasValue = !_.isNull(data.market_value);
  let quantity = data.quantity;
  let marketValue =  hasValue ? data.market_value : undefined;

  // In case transaction has original data and selected date is after or same as original
  // transaction date - we should "remove" this transaction from asset history,
  // so we put transaction values back to history.
  if (!!transaction.transactionOriginalData && historyDate.isSameOrAfter(transaction.transactionOriginalData.executionDate)) {
    quantity = (quantity || 0) + transaction.transactionOriginalData.executionQuantity;
    marketValue = (marketValue || 0) + transaction.transactionOriginalData.executionValue;
    hasValue = true;
  }

  let unitPrice = hasValue ? _.round(marketValue / quantity, 6) : undefined;

  return {
    price_eur: unitPrice,
    quantity: quantity,
    market_value: marketValue
  }
}


export const fetchAssetPrice = (customer_id, transaction, date, dispatch, updateTransaction, isSwitch, resource=VirtualPortfolioHandlerResource) => {
  // function to fetch asset price for transaction when date is changed

  if ([TRADING_ACTION_SELL, TRADING_ACTION_SWITCH].includes(transaction.tradingType)) {
    // get asset qty/amount/price
    resource.getInstrumentDataForDate(customer_id, transaction.data.id, date.format('YYYY-MM-DD'))
    .then((response) => {
      parseResponse(response, 'asset', (asset_data) => {
        const asset = asset_data[transaction.data.id].history[0];

        let transactionUpdateData = {
          data: {
            ...transaction.data,
            ...buildTransactionDateSensitiveData(transaction, asset)
          },
          errors: {...transaction.errors, 'asset_price': null}
        };

        updateTransaction(transaction, transactionUpdateData);
      }, (error) => {
        let transactionUpdateData = {
          data: {...transaction.data, price_eur: undefined, quantity: undefined, market_value: undefined},
        }

        updateTransaction(transaction, transactionUpdateData);
      })
    })
    .catch((error) => {
      dispatch(displayErrorSnackBar(getErrorMessage(error)))
    })
    .finally(() => {
      updateTransaction(transaction, {loading: false})
    })

    // proceed further for switch in instruments
    if (transaction.tradingType === TRADING_ACTION_SELL) return;
  }

  function setPriceToNestedTransactions(transactionUpdateData, data) {
    transactionUpdateData.buy = transaction.buy.map(
      buyTransaction => {
        const price_eur = _.get(data, `${buyTransaction.data.isin}.price_eur`);
        buyTransaction.data.price_eur = price_eur;
        if (price_eur) _.unset(buyTransaction, 'errors.asset_price');
        return buyTransaction
      }
    )
  }

  // for switch transaction there are nested buy transactions for which prices must be fetched too
  let isinsToGetPricesFor = isSwitch
    ? [transaction.data.isin, ..._.get(transaction, TRANSACTION_TYPE_VALUES.BUY, []).map(buyTransaction => buyTransaction.data.isin)]
    : transaction.data.isin

  let hasBuyTransactions = isSwitch && !_.isEmpty(transaction.buy || [])

  VirtualPortfolioHandlerResource.getInstrumentPrice(isinsToGetPricesFor, date.format('YYYY-MM-DD'), true)
  .then((response) => {
    parseResponse(response, 'instrument_price',
      (data) => {
      let transactionUpdateData = {
        data: {...transaction.data, 'price_eur': data[transaction.data.isin].price_eur},
        errors: {...transaction.errors, 'asset_price': null}
      }

      // sets prices for nested buy transactions. If there is no price in data for an asset - undefined is set
      hasBuyTransactions && setPriceToNestedTransactions(transactionUpdateData, data)
      updateTransaction(transaction, transactionUpdateData);
      },
      (error) => {
        let transactionUpdateData = {
          data: {...transaction.data, 'price_eur': undefined},
        }

        // if there was an error - undefined is passed as there is no valid price data, so nested prices are set to undefined
        hasBuyTransactions && setPriceToNestedTransactions(transactionUpdateData, error)

        updateTransaction(transaction, transactionUpdateData);
      }
    )
  })
  .catch((error) => {
    dispatch(displayErrorSnackBar(getErrorMessage(error)))
  })
  .finally(() => {
    // as switch has nested buy transactions set their loading to false too
    if(hasBuyTransactions){
      transaction.buy = transaction.buy.map(buyTransaction => {return{...buyTransaction, loading: false}})
    }
    updateTransaction(transaction, {loading: false})
  })
}

const CHANGE_EVENT_FIELD_TYPE = {
  AMOUNT: 1,
  WEIGHT: 2,
  TOTAL: 3
}

const PortfolioTrade = props => {
  const {
    classes,
    portfolio,
    handlePortfolioChange,
    minDepositDate,
    customer_id,
    dataService,
    isVirtual,  // flag that shows that Portfolio trade is used for virtual portfolio
    isNewVirtual, // flag that shows that virtual trading is for new portfolio creation
    defaultExpanded,
    showSectionsWithoutTransactions,
    showTotal,
    sectionsCollapsable,
    showTradingActions
  } = props;

  const allowedTradeOptions = (() => {
    const defaultOptions = () => {
      const o = [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH, 'rebalancing'];
      if(getSubSystemConfigItem('trading', 'portfolio_builder_enabled')){
        const index = o.indexOf('rebalancing');
        if(index !== -1){
          o.splice(index, 0, 'portfolio_builder');
        }
      }

      return o;
    };

    const options = props.allowedTradeOptions || defaultOptions();

    if (showSectionsWithoutTransactions) {
      return options;
    }

    return _.filter(options, (option) => portfolioHasTransactions(portfolio, [option]))
  })();

  const switchOutClasses = useSwitchOutTableStyles();
  const switchInClasses = useSwitchInTableStyles();
  const modalContext = useConfirmationModalContext();

  const [activeTradingFlowForModal, setActiveTradingFlowForModal] = React.useState(undefined);
  const [switchModalData, setSwitchModalData] = React.useState(undefined);
  const [expanded, setExpanded] = React.useState([]);
  const [portfolioBuilderActive, setPortfolioBuilderActive] = React.useState(false);

  React.useEffect(() => {
    setExpanded(
      _.flatten([
        // adds portfolio to list if it should be expanded
        (_.isUndefined(props.expanded) || props.expanded) ? ['portfolio'] : [],
        // if transactions section (buy, sell...) has transactions it will be opened
        [...Object.keys(portfolio.transactions)
          .filter(k => !_.isEmpty(portfolio.transactions[k]))
          .map(key => `transaction-${key}`),
          ...(defaultExpanded || [])
        ]
      ])
    )
  }, [props.triggerAccordionExpansionCheck])


  const [confirmRiskModalState, setConfirmRiskModalState] = React.useState({
    confirmRiskModalOpen: false,
    confirmRiskModalActiveAsset: undefined
  })

  const [instrumentsHandlers, setInstrumentsHandlers] = React.useState({});

  const [isWeightLoading, setIsWeightLoading] = React.useState(false);

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    if (!sectionsCollapsable) {
      return;
    }
    setExpanded(prevState => {
      return isExpanded ? [...prevState, panel] : prevState.filter(i => i !== panel);
    });
  };

  React.useEffect(() => {
    if(portfolio.trading_errors){
      let expandedDefaults = ['portfolio', ...Object.keys(portfolio.trading_errors).map(key => `transaction-${key}`)];

      setExpanded(prevState => {
        return [...prevState, ...expandedDefaults]
      })
    }
  }, [portfolio.trading_errors]);

  const handleCloseTradingFlowModal = () => {
    setActiveTradingFlowForModal(undefined);
  };

  const handleCloseSwitchModal = () => {
    setSwitchModalData(undefined);
  };

  /**
   * Average SRRI value of portfolio according to existing instruments and new transactions
   * @type {unknown}
   */
  const averageSrriValue = React.useMemo(() => {
    return !isVirtual && calculatePortfolioInstrumentsAverageSrri(portfolio)
  }, [JSON.stringify(portfolio.transactions)])

  const quotationFieldsSource = _.get(portfolio, 'data.is_model_portfolio')
    ? ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES : ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES;

  const findAssetIndex = (assets, asset) => _.findIndex(assets, a => getAssetInternalId(a.data) == getAssetInternalId(asset));

  const handleChange = (item, data, isUpdatePortfolio=true) => {
    Object.keys(data).forEach(key => item[key] = data[key]);

    if (item.buy && !_.isEmpty(item.buy)) {
      item.buy.forEach(buyItem => handleTransactionValueChange(buyItem, buyItem.transaction_value, item, false))
    }

    if (isUpdatePortfolio) {
      handlePortfolioChange && handlePortfolioChange();
    }
  };

  const handleTransactionValueChange = (item, value, switchOutItem=undefined, withEuro=true) => {

    const transactionValue = _.floor(value, portfolio.maxQtyDecimals || QTY_DECIMALS)
    const transactionValueEuro = transactionValueToEuro(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item)
    const transactionValuePercentage = transactionValueToPercentage(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item)

    const calculated = {
      ..._.get(item, 'calculated', {}),
      transaction_value_percentage: transactionValuePercentage,
    }
    // Recalculate euro value only in case transaction changed itself.
    // In case switch out item changed - only percent value should be recalculated.
    if (withEuro) {
      calculated.transaction_value_euro = transactionValueEuro
    }

    if (isVirtual && isNewVirtual) {
      item.transaction_value = value;
      portfolio.portfolioValueTotal = portfolioTransactionAmount(portfolio, TRANSACTION_TYPE_VALUES.BUY);
      newVirtualRecalculateTransactions(portfolio);
      calculated.transaction_value_weight = item.calculated.transaction_value_weight;
    }

    handleChange(item, {
      transaction_value: value,
      calculated,
      errors: {...item.errors, transaction_value: null, transaction_value_euro: null}
    });
  };

  const handleTransactionTypeChange = (item, value) => {
    handleChange(item, {
      transaction_type: value,
      transaction_value: null,
      errors: {
        ...item.errors,
        transaction_value: null,
        transaction_type: null
      }
    });
  };

  const handleDepositDateChange = (item, value, action) => {
    // changing selected date. For virtual portfolio set loading to disable inputs until second request is finished
    let isSwitch = action === TRANSACTION_TYPE_VALUES.SWITCH;
    let fieldsToUpdate = {deposit_from_date: value, errors: {...item.errors, deposit_from_date: null}, loading: isVirtual}

    if(isSwitch && isVirtual && !_.isEmpty(item.buy)) {
      fieldsToUpdate.buy = item.buy.map(buyTransaction => {return {...buyTransaction, loading: true} })
    }

    handleChange(item, fieldsToUpdate);

    // for virtual portfolio when date is changed fetch price of asset at that date
    if(isVirtual && value instanceof moment && value.isValid()){

      fetchAssetPrice(customer_id, item, value, props.dispatch, handleChange, isSwitch, props.resource);

      // Clean all entered data for switch transactions (only virtual) if booking date changed
      if (isSwitch) {
        const getCleanedData = (t) => ({
          calculated: {},
          transaction_value: null,
          sell_all: false,
          errors: {
            ...t.errors,
            transaction_value: null,
            transaction_value_euro: null,
            transaction_value_percentage: null
          }
        });

        _.flatten([item, item.buy || []]).forEach((t) => handleChange(t, getCleanedData(t)))
      }

    }
  };

  const handleDepositDateTypeChange = (item, value) => {
    handleChange(item, {deposit_from_type: value, errors: {...item.errors, deposit_from_type: null}});
  };

  const handleSellAllChanged = (item, value, sellAllAvailable, isUpdatePortfolio=true, totalValue=null) => {
    // in case "sell all" selected but not available - use QTY
    if(value && !sellAllAvailable) item.transaction_type = TRANSACTION_INSTR.qty;

    const isAmount = item.transaction_type === TRANSACTION_INSTR.amount;

    const transactionValue = value ? (isAmount ? item.data.market_value : item.data.quantity) : totalValue

    handleChange(item, {
      sell_all: value,
      transaction_value: transactionValue,
      calculated: {
        transaction_value_percentage:transactionValue && transactionValueToPercentage(transactionValue, item),
        transaction_value_euro: transactionValue && transactionValueToEuro(transactionValue, item)
      },
      errors: {...item.errors, transaction_value: null, sell_all: null, transaction_value_euro: null}
    }, isUpdatePortfolio);
  };

  const handleSellAllQuantityChanged = (item, value, sellAllAvailable, calculateTotalValue=null) => {
    let totalValue;
    item.quantity = value && (sellAllAvailable ? item.data.quantity : item.quantity) || 0;
    if (value && calculateTotalValue) totalValue = calculateTotalValue(item, item.limit, item.quantity)

    handleChange(item, {
      sell_all: value,
      transaction_value: totalValue,
      calculated: {
        transaction_value_percentage:totalValue && transactionValueToPercentage(totalValue, item),
        transaction_value_euro: totalValue && transactionValueToEuro(totalValue, item)
      },
      errors: {...item.errors, transaction_value: null, sell_all: null, transaction_value_euro: null}
    });
  };

  const transactions = portfolio.transactions;
  [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH].map(transaction => {
    const availableOrderingFields = portfolio[quotationFieldsSource[transaction]]
    const sellAllAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES.is_all);
    const qtyAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.qty]);
    (transactions[transaction] || []).map(instr => {
      if(typeof instr.transaction_type === 'undefined'){
        instr.transaction_type = getTransactionType(portfolio, transaction)
      }
      if(typeof instr.deposit_from_type === 'undefined'){
        if (isVirtual) {
          // for existing assets use price_date, for instruments from search use calculated
          instr.deposit_from_date = moment(instr.data.price_date || instr.data.calculated.last_price_date, 'YYYY-MM-DD');
          instr.deposit_from_type = TRANSACTION_START_DATE.date
        } else { instr.deposit_from_type = TRANSACTION_START_DATE.rightAway}
      }
      if(typeof instr.sell_all === 'undefined' && instr.data.wholeDepotOrder && (sellAllAvailable || qtyAvailable)){
        handleSellAllChanged(instr, true, sellAllAvailable);
      }
    })
  });

  const handleDiscountChange = (item, value) => {
    handleChange(item, {discount: value, errors: {...item.errors, discount: null}});
  };

  const handleOngoingFeeChange = (item, value) => {
    handleChange(item, {ongoing_fee: value, errors: {...item.errors, ongoing_fee: null}});
  };

  const handleEntryFeeChange = (item, value) => {
    handleChange(item, {entry_fee: value, errors: {...item.errors, entry_fee: null}});
  };

  const handleDepotTypeChange = (value) => {
    portfolio.depotType = _.find(portfolio.availableDepotTypes, (depotType) => depotType.value == value)
    handlePortfolioChange && handlePortfolioChange();
  }

  const handleOrderVariationChange = (item, value) => {
    handleChange(item, {order_type: value, errors: {...item.errors, order_type: null, limit: null}});
  };

  const handleLimitValueChange = (item, value, amount) => {
    handleChange(item, {limit: value, transaction_value: amount, errors: {...item.errors, limit: null}});
  };

  const handleQuantityChange = (item, value, action) => {
    let quantity = value
    if (quantity && _.get(item, 'data.quantity') && action === TRANSACTION_TYPE_VALUES.SELL) {
      quantity = value <= item.data.quantity ? value : item.data.quantity
    }

    const _amount = calculateTotalAmount(item, item.limit, quantity);

    handleChange(item, {quantity: quantity, transaction_value: _amount, errors: {...item.errors, quantity: null}});
  };

  const handleValidUntilChange = (item, value, option) => {
    handleChange(item, {valid_until: value, valid_until_option: option, errors: {...item.errors, valid_until: null}});
  };

  const handleStockExchangeChange = (item, value, option) => {
    handleChange(item, {stock_exchange: value, stock_exchange_option: option, errors: {...item.errors, stock_exchange: null}});
  };

  const handleConfirmRiskModalSave = (item, value) => {
    handleChange(item, {
      risk_score_explanation: value,
      errors: {...item.errors, risk_score_explanation: null}});
    setConfirmRiskModalState({
      confirmRiskModalOpen: false,
      confirmRiskModalActiveAsset: undefined
    })
  }

  const handleModelPortfolioTransactionTypeChange = (value) => {
    portfolio.modelPortfolioTransactionType = value
    handlePortfolioChange && handlePortfolioChange();
  }

  const handleDepotFeeChanged = (value) => {
    portfolio.depot_fee = value;
    handlePortfolioChange && handlePortfolioChange();
  };

  const handleSwitchPercentageChanged = (item, value, switchOutItem=undefined) => {

    const transactionValuePercentage = _.round(value, 2)
    let transactionValue = percentageToQuantity(value, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item, false)
    transactionValue = transactionValue && _.floor(transactionValue, portfolio.maxQtyDecimals || QTY_DECIMALS)
    const transactionValueEuro = transactionValueToEuro(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item)

    handleChange(item, {
      transaction_value: transactionValue,
      calculated: {
        ..._.get(item, 'calculated', {}),
        transaction_value_percentage: transactionValuePercentage,
        transaction_value_euro: transactionValueEuro
      },
      errors: {...item.errors, transaction_value: null, transaction_value_euro: null}
    });

  }

  const handleSwitchAmountEuroChanged = (item, value, switchOutItem=undefined) => {

    const transactionValueEuro = _.round(value, 2)
    let transactionValue = transactionValueToQuantity(transactionValueEuro, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item, false)
    transactionValue = transactionValue && _.floor(transactionValue, portfolio.maxQtyDecimals || QTY_DECIMALS)
    const transactionValuePercentage = transactionValueToPercentage(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item)

    handleChange(item, {
      transaction_value: transactionValue,
      calculated: {
        ..._.get(item, 'calculated', {}),
        transaction_value_euro: transactionValueEuro,
        transaction_value_percentage: transactionValuePercentage
      },
      errors: {...item.errors, transaction_value: null, transaction_value_euro: null}
    });
  }

  /**
   * In case saldo value != 0 - distribute saldo quantity between Switch In transactions.
   *
   * @return {(function(): void)|*}
   */
  const handleDistributeQuantitiesClick = (switchOutItem) => () => {

    const precision = portfolio.maxQtyDecimals || QTY_DECIMALS

    const saldo = _.round(calculateSwitchTransactionSaldo(switchOutItem, portfolio.maxQtyDecimals), precision)

    if (saldo == 0 || _.isEmpty(switchOutItem.buy || [])) {
      return
    }


    switchOutItem.buy.reduce((currentSaldoValue, transaction) => {

      // Continue in case all quantities were distributed
      if (currentSaldoValue == 0) {
        return currentSaldoValue
      }

      let transactionValue = _.round(
        (transaction.transaction_value || 0) + currentSaldoValue, precision)

      if (transactionValue < 0) {
        // If Transaction value is negative after distribution
        // (saldo is big negative number) recalculate saldo value
        // and continue distributing for next transaction
        transactionValue = 0
        currentSaldoValue = _.round( currentSaldoValue + transaction.transaction_value || 0, precision)
      } else {
        currentSaldoValue = 0
      }

      handleTransactionValueChange(transaction, transactionValue, switchOutItem)

      return currentSaldoValue

    }, saldo)

  }

  const getTransactionAmount = (transaction_key) => {
    return withEuroOrDash(portfolioTransactionAmount(portfolio, transaction_key));
  };

  const portfolioAmount = () => {
    const ptfAmount = [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL].reduce((total, key) => {
      return total + portfolioTransactionAmount(portfolio, key);
    }, 0);

    return withEuroOrDash(ptfAmount);
  };

  const removeItem = (items, item) => {
    const idx = findAssetIndex(items, item.data);

    if(idx !== -1){
      items.splice(idx, 1); // remove item from array
      handlePortfolioChange && handlePortfolioChange();
    }
  };

  const handleRemoveClick = (transaction, item) => {
    let items = transactions[transaction];
    removeItem(items, item);
    handleNewVirtualTransactionWeightTypeChanged(portfolio.transactionsWeightType);
    if(portfolio.isModelportfolio){
      props.dispatch(removePortfolioCombinedTradingOption(customer_id, portfolio.portfolioId));
    } else {
      props.dispatch(removeCombinedTradingOption(customer_id, portfolio.portfolioId, getAssetInternalId(item.data)));
    }
  };

  const clearTransactions = () => {
    transactions.buy = [];
    transactions.sell = [];
    transactions.switch = [];
    props.dispatch(removePortfolioCombinedTradingOption(customer_id, portfolio.portfolioId))
  }

  const handleAddClick = (key) => {
    if(key === 'portfolio_builder'){
      setPortfolioBuilderActive(true);
      return;
    }
    if(!portfolio.isModelportfolio){
      setActiveTradingFlowForModal(key);

      return
    }
    handleAddItems(key)([{...portfolio.data}]);
  };

  const setPortfolioBuilderRecommendations = async (assets) => {
    if(_.isEmpty(_.flatten(Object.values((transactions)))) || await modalContext.confirm("Alle vorherigen Ordereingaben werden gelöscht. Sind Sie sicher?")){
      clearTransactions();
      let buy = [], buyExcluded;
      let sell = [], sellExcluded;
      const currentAssetsForBuy = getPortfolioInstruments(TRANSACTION_TYPE_VALUES.BUY);
      const currentAssetsForSell = getPortfolioInstruments(TRANSACTION_TYPE_VALUES.SELL);

      assets.forEach(assetInfo => {
        switch (assetInfo.recommendationType) {
          case 'BUY':
            const currentAsset = _.find(currentAssetsForBuy, i => i.isin === assetInfo.isin);
            buy.push({...currentAsset, ...assetInfo, newMarketValue: assetInfo.amount});
            break;
          // as PB do not support sub depots - combined line should be distributed by sub depots manually
          case 'SELL':
            let amountRemains = assetInfo.amount, amountToSell;

            currentAssetsForSell.forEach(i => {
              if (i.isin === assetInfo.isin && amountRemains > 0){
                amountToSell = _.min([i.market_value, amountRemains]);

                sell.push({
                  ...i,
                  ...assetInfo,
                  newMarketValue: amountToSell
                });

                amountRemains -= amountToSell;
              }
            });

            break;
          case 'COMPLETE_SELL':
            currentAssetsForSell.forEach(i => {
              if (i.isin === assetInfo.isin) {
                sell.push({
                  ...i,
                  ...assetInfo,
                  newMarketValue: i.market_value
                });
              }
            });

            break;
        }
      });

      const buyHandler = getInstrumentsHandler(TRANSACTION_TYPE_VALUES.BUY);
      const sellHandler = getInstrumentsHandler(TRANSACTION_TYPE_VALUES.SELL);
      // extend instruments with custodian data and validate
      [buy, buyExcluded] = await buyHandler.getCustodianAndFilterInstruments(buy, true);
      [sell, sellExcluded] = await sellHandler.getCustodianAndFilterInstruments(sell, true);

      if(_.isEmpty(buy) && _.isEmpty(sell)){
        props.dispatch(displayWarningSnackBar(PORTFOLIO_BUILDER_NO_ASSETS_MSG));
      } else {
        handleAddItems(TRANSACTION_TYPE_VALUES.BUY)(buy, false);
        handleAddItems(TRANSACTION_TYPE_VALUES.SELL)(sell);
        const excluded = _.concat(buyExcluded, sellExcluded);
        if (excluded.length) {
          props.dispatch(displayWarningSnackBar(getPortfolioBuilderExcludedMsg(excluded)))
        }
      }
    }

    setPortfolioBuilderActive(false);
  };

  const setPortfolioBuilderCase = (data) => {
    portfolio.portfolioBuilderData = data;
    handlePortfolioChange && handlePortfolioChange();
  };

  // region BCA-8464 New MP creation
  const newVirtualRecalculateTransaction = (portfolio, transaction, eventType=CHANGE_EVENT_FIELD_TYPE.AMOUNT, weight=undefined) => {

    const totalValue = portfolio.portfolioValueTotal;
    let transactionValue = transaction.transaction_value || 0;
    let transactionWeight = weight || _.get(transaction, 'calculated.transaction_value_weight');

    if (eventType === CHANGE_EVENT_FIELD_TYPE.AMOUNT) {
      transactionWeight = !!totalValue ? _.round(transactionValue / totalValue * 100, 2) : 0;
    } else if (eventType == CHANGE_EVENT_FIELD_TYPE.TOTAL) {
      transactionValue = _.round(transactionWeight * totalValue / 100, 2);
    } else {
      transactionValue = totalValue * transactionWeight / 100;
    }

    _.set(portfolio, 'trading_errors.portfolio', undefined);

    handleChange(transaction, {
      transaction_value: transactionValue,
      calculated: {
        ..._.get(transaction, 'calculated', {}),
        transaction_value_weight: transactionWeight
      },
      errors: {...transaction.errors, transaction_value: null, transaction_value_weight: null}
    });
  }

  const newVirtualRecalculateTransactions = (portfolio, eventType=CHANGE_EVENT_FIELD_TYPE.AMOUNT, weight=undefined) => {
    // This recalculation works only fo new virtual portfolio creation.
    // In case weight change triggered - do nothing as it only affect one transaction.
    if (!isVirtual || !isNewVirtual) {
      return;
    }

    portfolio.transactions.buy.forEach((transaction) => {
      newVirtualRecalculateTransaction(portfolio, transaction, eventType, weight);
    });
    handleNewVirtualMissingTransactionAmount(portfolio);
  }
  const handleNewVirtualPortfolioValueTotalChange = (value) => {
    portfolio.portfolioValueTotal = value;
    newVirtualRecalculateTransactions(portfolio, CHANGE_EVENT_FIELD_TYPE.TOTAL);
  }

  const handleNewVirtualTransactionWeightChanged = (item, value) => {
    _.set(item, 'calculated.transaction_value_weight', value);
    newVirtualRecalculateTransaction(portfolio, item, CHANGE_EVENT_FIELD_TYPE.WEIGHT);
  }

  const handleNewVirtualMissingTransactionAmount = (portfolio) => {

    if (![MODEL_PORTFOLIO_WEIGHT.EQUAL, MODEL_PORTFOLIO_WEIGHT.RISK_BASED].includes(portfolio.transactionsWeightType)) {
      return;
    }

    const totalTransactionsAmount = _.round(portfolioTransactionAmount(portfolio, TRANSACTION_TYPE_VALUES.BUY), 2);
    const totalDiff = _.round(portfolio.portfolioValueTotal - totalTransactionsAmount, 2);

    if (totalDiff) {
      let transactionAmount = _.get(portfolio, 'transactions.buy.0.transaction_value');
      transactionAmount = _.round(transactionAmount + totalDiff, 2);
      _.set(portfolio, 'transactions.buy.0.transaction_value', transactionAmount);
    }

  }

  const handleNewVirtualTransactionWeightTypeChanged = async (value) => {
    portfolio.transactionsWeightType = value;

    if (!_.isEmpty(portfolio.transactions.buy) && [MODEL_PORTFOLIO_WEIGHT.EQUAL, MODEL_PORTFOLIO_WEIGHT.RISK_BASED].includes(value)) {
      if (value === MODEL_PORTFOLIO_WEIGHT.EQUAL) {
        const weight = 100 / portfolio.transactions.buy.length;
        newVirtualRecalculateTransactions(portfolio, CHANGE_EVENT_FIELD_TYPE.WEIGHT, weight);
      } else {
        setIsWeightLoading(true);
        try {

          const isins = portfolio.transactions.buy.map((transaction) => transaction.data.isin);
          const response = await ModelPortfolioResource.getRiskParityWeights({instruments: isins});

          parseResponse(response, 'weights', (data) => {
            const weights = (data.components || []).reduce((result, component) => {
              // Do not round weights, that comes from external resource.
              result[component.isin] = (component.weight || 0) * 100;
              return result
            }, {});
            portfolio.transactions.buy.forEach((transaction) => {
              const weight = weights[transaction.data.isin] || 0;
              _.set(transaction, 'calculated.transaction_value_weight', weight);
              newVirtualRecalculateTransaction(portfolio, transaction, CHANGE_EVENT_FIELD_TYPE.WEIGHT)
            });
            handleNewVirtualMissingTransactionAmount(portfolio);
          }, (error) => {
            props.dispatch(displayErrorSnackBar('Gewichtung konnte nicht berechnet werden.'))
          })

        } catch (error) {
          props.dispatch(displayErrorSnackBar('Gewichtung konnte nicht berechnet werden.'))
        } finally {
          setIsWeightLoading(false);
        }
      }
    }

    _.set(portfolio, 'trading_errors.portfolio', undefined);

    handlePortfolioChange && handlePortfolioChange();
  }
  // endregion


  /**
   * Initializing and updating instruments handles.
   */
  React.useEffect(() => {

    let addInstrumentHandler, sellInstrumentHandler, switchOutInstrumentHandler;
    if (isVirtual) {
      addInstrumentHandler = new FundsInstrumentsHandler()
    } else {
      let onlyFunds = undefined;
      let disableFunds = undefined;
      if (portfolio.depotType) {
        portfolio.available_asset_classes = portfolio.depotType.configuration.hasOwnProperty('available_asset_classes')
          ? portfolio.depotType.configuration.available_asset_classes
          : portfolio.depot_available_asset_classes;
        onlyFunds = _.isEmpty(portfolio.available_asset_classes);
        disableFunds = portfolio.depotType.configuration.trading_funds_disabled;
      }

      addInstrumentHandler = new EinzeltitelTradingInstrumentsHandler(
        portfolio.companyId,
        _.get(dataService, 'current_customer.last_srri.srri'),
        dataService.targetMarket,
        onlyFunds,
        undefined,
        portfolio,
        dataService.user && dataService.user.bca_level_id,
        dataService.serviceConcept,
        getRiskProfilingAnswers(dataService, portfolio.available_asset_classes),
        disableFunds);

      sellInstrumentHandler = new TradingSellInstrumentsHandler(
        portfolio.companyId,
        undefined,
        dataService.targetMarket,
        onlyFunds,
        undefined,
        undefined,
        disableFunds,
        portfolio,
        dataService.user && dataService.user.bca_level_id);

      switchOutInstrumentHandler = new TradingSwitchOutInstrumentsHandler(
        portfolio.companyId,
        undefined,
        dataService.targetMarket,
        undefined, // For switch out we should use only funds
        undefined,
        undefined,
        disableFunds)
    }

    const handlers = {
      [TRANSACTION_TYPE_VALUES.BUY]: addInstrumentHandler,
      [TRANSACTION_TYPE_VALUES.SELL]: sellInstrumentHandler,
      [TRANSACTION_TYPE_VALUES.SWITCH]: switchOutInstrumentHandler,
      'rebalancing': {
        buy: addInstrumentHandler,
        sell: sellInstrumentHandler
      }
    };

    if (!portfolio.isModelportfolio) {
      (async() => {
        // Validation for payment plans will be done under bug ticket about einzeltitels in payment plans
        for (let transactionType of [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH]) {
          try {
            const handler = handlers[transactionType];
            if (!handler || !portfolio.transactions || _.isEmpty(portfolio.transactions[transactionType])) {
              continue
            }

            const instruments = getPortfolioInstrumentsData(portfolio.transactions[transactionType], true)
            await handler.getCustodianAndFilterInstruments(instruments, true);

          } catch (error) {
            console.log('Error during instruments validation.')
            console.log(error)
          }
        }

        handlePortfolioChange && handlePortfolioChange();
      })()
    }

    setInstrumentsHandlers(handlers)

  }, [dataService.hasRiskProfileConfigured, dataService.current_customer, portfolio.depotType])

  const switchInstrumentHandler = React.useMemo(() => {
    return !isVirtual
      ? new EinzeltitelTradingSwitchInInstrumentsHandler(portfolio.companyId, portfolio.available_asset_classes, undefined, dataService.targetMarket)
      : new FundsInstrumentsHandler()
  }, [dataService.hasRiskProfileConfigured]);

  const getFieldNames = (key) => {
    return {
      [TRANSACTION_TYPE_VALUES.BUY]: ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.BUY],
      [TRANSACTION_TYPE_VALUES.SELL]: ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SELL],
      [TRANSACTION_TYPE_VALUES.SWITCH]: ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SWITCH],
    }[key]
  };

  const handleAddItems = (key, switchItem) => async (selectedAssets, triggerChange=true) => {

    const riskScoreConfirmation = getRiskScoreConfirmation(
      dataService, extendAssetsWithDataAttribute(selectedAssets), key === TRANSACTION_TYPE_VALUES.BUY,
      getTransactionItemSRRI, _.get(selectedAssets, '0.isModelportfolio'), true)
    const serviceConcept = dataService.serviceConcept || dataService._service_concept;

    const isSwitch = !!switchItem;

    if (isSwitch) {
      key = TRANSACTION_TYPE_VALUES.BUY
      _.unset(switchItem, 'errors.buy');
    }

    if (key === TRANSACTION_TYPE_VALUES.BUY) {

      const paymentPlanOnlyConfirmationRequired = serviceConcept == SERVICE_CONCEPTS.Anlageberatung
        && portfolioIsPaymentPlansOnly(portfolio.data.components, portfolio.data.savings_plan)
        && _.some(selectedAssets.map((asset) => !paymentPlanHasPosition(asset, portfolio.data.savings_plan)))

      if (paymentPlanOnlyConfirmationRequired) {
        if (!await modalContext.confirm(PAYMENT_PLANS_PORTFOLIO_ONLY_ADD_NEW_POSITION_MSG, 'Bitte bestätigen Sie hier')) {
          return false
        }
      } else if (riskScoreConfirmation && !await modalContext.confirm(riskScoreConfirmation, 'Bitte bestätigen Sie hier')) {
        return false
      }

    }

    const parentObj = isSwitch ? switchItem : transactions;
    const tradingAction = TRADING_ACTIONS.find(o => o.code === key).value;
    const instrumentsData = [];
    const newInstruments = []
    selectedAssets.map(item => {
      if(findAssetIndex(parentObj[key], item) === -1){
        const instrument = {
          data: item,
          // Prepare available transaction type according to the custodian settings.
          // In case it is 'switch' process - use qty as only available option
          transaction_type: ((key == TRANSACTION_TYPE_VALUES.SWITCH || isSwitch) && TRANSACTION_INSTR.qty) || getTransactionType(portfolio, getFieldNames(key)),
          tradingType: tradingAction,
          // Prefill transaction value
          transaction_value: item.hasOwnProperty('newMarketValue') ? item.newMarketValue : null
        };
        if (key === TRANSACTION_TYPE_VALUES.SWITCH && !switchItem) instrument.buy = []
        setInstrumentBankData(instrument, instrCustodianData(item, portfolio.companyId), true);
        parentObj[key].push(instrument);
        newInstruments.push(instrument);
        if (portfolio.isModelportfolio) {
          setMPInstrumentBankData(instrument, portfolio);
          if ([TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL].includes(key)) {
            props.dispatch(addPortfolioCombinedTradingOption({customer_id: customer_id}, portfolio.data, tradingAction))
            props.dispatch(updatePortfolioCombinedTradingOption(customer_id, portfolio.portfolioId, {transactions: parentObj}));
          }
          portfolio.tradingType = tradingAction
        } else {
          instrumentsData.push(instrument.data)
        }

        if(isVirtual && isSwitch){
          // fetch asset price for nested buy transaction inside of switch
          fetchAssetPrice(customer_id, instrument, switchItem.deposit_from_date, props.dispatch, handleChange, false, props.resource)
        }

      }
    });
    if (!!newInstruments.length && isVirtual){
      await setEarliestPriceDates(newInstruments, props.dispatch)
    }
    if (!!instrumentsData.length) {
      props.dispatch(addCombinedTradingOption({customer_id: customer_id}, portfolio.data, instrumentsData, tradingAction))
    }

    isSwitch ? handleCloseSwitchModal() : handleCloseTradingFlowModal();
    triggerChange && handlePortfolioChange && handlePortfolioChange();
    handleNewVirtualTransactionWeightTypeChanged(portfolio.transactionsWeightType);
  };

  const parseTransactionTitle = (key) => {
    if (portfolio.isModelportfolio) {
      return `${transactions[key].length} Portfolio${transactions[key].length === 1 ? '' : 's'}`
    }
    return `${transactions[key].length} Instrument${transactions[key].length === 1 ? '' : 'e'}`
  };

  const handleConfirmRiskModalClose = () => {
    setConfirmRiskModalState({
      confirmRiskModalOpen: false,
      confirmRiskModalActiveAsset: undefined
    })
  }

  const handleSelectAsset = (asset) => {
    setConfirmRiskModalState({
      confirmRiskModalOpen: true,
      confirmRiskModalActiveAsset: asset
    })
  }

  const _getFilteredPortfolioInstruments = (key, alreadyTraded) => {
    let components = _.get(portfolio, 'data.components', []);
    if(key === TRANSACTION_TYPE_VALUES.BUY){
      components = _.concat(components, _.get(portfolio, 'data.soldAssets') || []);
    }
    // for virtual portfolios we allow buy/sell/switch same instrument
    let instruments = getPortfolioComponentsWithSubDepots(components)
      .filter(i => !!getAssetInternalId(i) && (isVirtual || !alreadyTraded.map(i => getAssetInternalId(i.data)).includes(getAssetInternalId(i))))
    // disable switch and sell actions for sold out instruments
    return [TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH].includes(key) ? instruments.filter(i => i.quantity > 0) : instruments
  };

  const getPortfolioInstruments = (key) => {
    if (!isAssetsTrading(key)) {
      return portfolio.data.components || []
    }

    let alreadyTraded = _.flatten([TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH]
      .map((transactionType) => _.get(transactions, transactionType) || [] ))
    if (portfolio.isModelportfolio) {
      return _.isEmpty(alreadyTraded) ? [{...portfolio.data}] : []
    }

    return _getFilteredPortfolioInstruments(key, alreadyTraded);
  };

  const getAddIconTitle = (key) => {
    return {
      [TRANSACTION_TYPE_VALUES.BUY]: portfolio.isModelportfolio
        ? 'Portfoliokauf hinzufügen'
        : 'Neues Investment hinzufügen',
      [TRANSACTION_TYPE_VALUES.SELL]: portfolio.isModelportfolio
        ? 'Portfolioverkauf hinzufügen'
        : 'Investment zum Verkauf hinzufügen',
      [TRANSACTION_TYPE_VALUES.SWITCH]: 'Investment zum Tauschen hinzufügen',
      'rebalancing': 'Musterdepot auswählen',
      'portfolio_builder': 'Portfolio-Builder starten'
    }[key]
  };

  const isEnableAdding = (key) => {
    if (!showTradingActions) {
      return false;
    }
    let isEmpty = _.isEmpty(transactions[key])
    let isAvalableInstruments = !_.isEmpty(getPortfolioInstruments(key))
    return portfolio.isModelportfolio ? (isEmpty && isAvalableInstruments) : (key === TRANSACTION_TYPE_VALUES.BUY || (isEmpty || isAvalableInstruments))
  }

  const isAnlageberatung = dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung

  const getInstrumentsHandler = (key) => {
    return instrumentsHandlers[key]
  }

  const portfolioType = (() => {
    if (portfolio.isModelportfolio) return 'model_portfolio';
    if (!isVirtual) return 'real';
    return isNewVirtual ? 'virtual_create' : 'virtual'
  })()

  const getTransactionsTable = (key) => {
    let fundsTable, nonFundsTable, nonFunds = [], funds = [];
    [nonFunds, funds] = splitEinzeltitelTradingInstruments(transactions[key], portfolio.available_asset_classes, nonFunds, funds);

    if (funds && funds.length) {
      fundsTable = (
        <DashboardTable
          tableLayout={"auto"}
          structure={getPortfolioTypeTransactionTypeTableStructure(isAnlageberatung)[portfolioType][key]}
          dataSource={funds}
          expanded={true}
          tableClasses={classes}
          withFooter={false}
          withInputs={true}
          options={{
            isModelportfolio: portfolio.isModelportfolio,
            isVirtual: isVirtual,
            isNewVirtual,
            hideAcceptanceCriteriasUnderName: isVirtual, // for virtual portfolio do not display info for acceptance criteria there is no banks data
            action: key,
            itemsLength: funds.length,
            minDepositDate: minDepositDate,
            discountsDisabled: !_.get(portfolio, 'ordering_discounts_allowed', []).includes('trading'),
            availableDiscounts: portfolio.availableDiscounts,
            maxQtyDecimals: portfolio.maxQtyDecimals,
            transactionsWeightType: portfolio.transactionsWeightType,
            isWeightLoading,
            [quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]],
            [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]],
            [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]],
            handleTransactionValueChange: handleTransactionValueChange,
            handleTransactionTypeChange: handleTransactionTypeChange,
            handleDepositDateChange: handleDepositDateChange,
            handleDepositDateTypeChange: handleDepositDateTypeChange,
            handleDiscountChange: handleDiscountChange,
            handleTransactionWeightChanged: handleNewVirtualTransactionWeightChanged,
            handleTransactionWeightTypeChanged: handleNewVirtualTransactionWeightTypeChanged,
            handleRemoveClick: showTradingActions && handleRemoveClick,
            handleSellAllChanged: handleSellAllChanged,
            handleOngoingFeeChange: handleOngoingFeeChange,
            handleEntryFeeChange: handleEntryFeeChange,
            withSrri: isAnlageberatung,
            clientSRRI: dataService.srri,
          }}
        />
      )
    }

    if (nonFunds && nonFunds.length) {
      nonFundsTable = (
        <>
          <DashboardTable
            tableLayout={"auto"}
            structure={key === TRANSACTION_TYPE_VALUES.BUY ? tableStructureForEinzeltitelBuyTrading : tableStructureForEinzeltitelSellTrading}
            dataSource={nonFunds}
            expanded={true}
            tableClasses={classes}
            withFooter={false}
            withInputs={true}
            options={{
              [quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]],
              [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]],
              [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]],
              action: key,
              itemsLength: nonFunds.length,
              minDepositDate: minDepositDate,
              stockExchangeOptions: portfolio.stock_exchange_options,
              orderTypeOptions: key === TRANSACTION_TYPE_VALUES.BUY ? portfolio.einzeltitel_order_type_buy : portfolio.einzeltitel_order_type_sell,
              maxQtyDecimals: portfolio.maxQtyDecimals,
              handleOrderVariationChange: handleOrderVariationChange,
              handleLimitValueChange: handleLimitValueChange,
              handleQuantityChange: handleQuantityChange,
              handleValidUntilChange: handleValidUntilChange,
              handleStockExchangeChange: handleStockExchangeChange,
              handleRemoveClick: showTradingActions && handleRemoveClick,
              handleSellAllChanged: handleSellAllQuantityChanged,
              clientSRRI: dataService.srri,
              totalAmount: nonFunds.reduce((total, inst) => (inst.transaction_value || 0) + total, 0),
            }}
          />
          <TableTooltip text={USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT} />
        </>
      )
    }
    return (
      <>
        {fundsTable}
        {nonFundsTable}
      </>
    )
  };

  const getAddProductsSection = (key) => {
    if(!isEnableAdding(key)) return null;

    let disableTooltip;

    if(key === 'portfolio_builder'){
      let prtBuilderDisableCriteria = [];

      if(!isAnlageberatung) {
        prtBuilderDisableCriteria.push('Servicekonzept: Anlageberatung')
      } else if(!dataService.hasInvestmentFundsKnowledge(dataService.serviceConcept)) {
        prtBuilderDisableCriteria.push('fehlende Kenntnisse: Offene Investmentfonds')
      }

      if(!_.isEmpty(prtBuilderDisableCriteria)){
        disableTooltip = <>{prtBuilderDisableCriteria.map((c, i) => <li key={i}>{c}</li>)}</>
      }
    }

    return (
      <div className={classes.addBtnContainer}>
        <IconBtn
          label={getAddIconTitle(key)}
          disabled={isWeightLoading || !!disableTooltip}
          tooltip={disableTooltip}
          component={PlusBackgroundIcon}
          onClick={() => handleAddClick(key)}
          size={36}
        />
      </div>
    )
  };

  const getPortfolioComponentsWithSubDepots = (instruments) => {
    // Function to get list with assets and their sub depots in one list IF sub depot trade is enabled, ELSE returns instruments

    let portfolioInstrumentsWithSubDepots = []
    // push instrument or its nested sub depots to portfolioInstrumentsWithSubDepots
    if (getSubSystemConfigItem('trading', 'sub_depots_trade_enabled')){
      instruments.forEach(instrument => {
        portfolioInstrumentsWithSubDepots.push(
          ...(!_.isEmpty(instrument.component_sub_depots) ? instrument.component_sub_depots : [instrument])
        )
      })
    }

    return !_.isEmpty(portfolioInstrumentsWithSubDepots) ? portfolioInstrumentsWithSubDepots : instruments
  };

  const predefinedFilter = isVirtual ? {} : {cCustodian: [{label: portfolio.custodianName, id: portfolio.companyId, value: portfolio.companyId}]};
  const isAssetsTrading = (key) => !['rebalancing', 'portfolio_builder'].includes(key);

  return (
    <RiskScoreConfirmationModalContext.Provider
      value={{
        open: confirmRiskModalState.confirmRiskModalOpen,
        activeAsset: confirmRiskModalState.confirmRiskModalActiveAsset,
        onClose: handleConfirmRiskModalClose,
        onSave: handleConfirmRiskModalSave,
        onSelectAsset: handleSelectAsset,
        singleInvestmentEnabled: !dataService.isSellOnlyFlow
      }}
    >
      <Grid container className={classes.portfolioContainer}>
        <PortfolioWrapper isVirtual={isVirtual} portfolio={portfolio} sectionsCollapsable={sectionsCollapsable} classes={classes} expanded={expanded} handleExpandedChange={handleExpandedChange}>
          {allowedTradeOptions.map(key =>
            !((portfolio.isModelportfolio || portfolio.isETF) && [TRANSACTION_TYPE_VALUES.SWITCH, 'portfolio_builder'].includes(key))
            && transactions.hasOwnProperty(key)
            && (key !== 'portfolio_builder' || !!portfolio.portfolioBuilderAvailable)
            && !isHideTradingSection(key, getPortfolioInstruments(key), transactions[key]) ? (
            <Accordion
              key={key}
              expanded={expanded.includes(`transaction-${key}`)}
              onChange={handleExpandedChange(`transaction-${key}`)}
              classes={{root: classes.accordionRoot}}
            >
              <AccordionSummary
                classes={{root: classes.accordionHeader}}
                expandIcon={sectionsCollapsable && <ExpandMoreIcon />}
              >
                <div>{TRANSACTION_TYPE[key]} {isAssetsTrading(key) && `(${parseTransactionTitle(key)})`}</div>
                {showTotal && (
                  <>
                    {(isVirtual && isNewVirtual) ? (
                      <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'baseline'}}>
                        <span style={{marginRight: 10, display: 'contents'}}>Musterdepotwert: <WarningTooltip title={"Definieren Sie hier das gesamte initiale Anlagevolumen Ihres Musterdepots. Verteilen Sie dies anschließend über die Gewichtung oder geben Sie die konkreten Bruttobeträge für die einzelnen Produkte ein."}/></span>
                        <InputFormElement
                          inputComponent={CurrencyNumberFormat}
                          value={portfolio.portfolioValueTotal}
                          onChange={handleNewVirtualPortfolioValueTotalChange}
                          disabled={portfolio.transactionsWeightType == MODEL_PORTFOLIO_WEIGHT.AUTO}
                          custom_classes={{
                            inputRoot: classes.totalAmountInputRoot
                          }}
                        />

                      </div>
                    ) : (
                      <div style={{marginLeft: 'auto'}}>
                        {isAssetsTrading(key) && `Gesamt: ${getTransactionAmount(key)}`}
                      </div>
                    )}
                  </>

                )}
              </AccordionSummary>

              {/* table */}
              <AccordionDetails classes={{root: classes.accordionDetails}}>
                {![TRANSACTION_TYPE_VALUES.SWITCH, 'rebalancing', 'portfolio_builder'].includes(key) && getTransactionsTable(key)}
                {key === TRANSACTION_TYPE_VALUES.SWITCH &&
                  (_.isEmpty(transactions[key]) ? [{}] : transactions[key]).map(item => (
                    <>
                      {/* table of instruments that are sold */}
                      <DashboardTable
                        tableLayout={"auto"}
                        structure={getPortfolioTypeTransactionTypeTableStructure(isAnlageberatung)[portfolioType].switch_out}
                        dataSource={_.isEmpty(item) ? [] : [item]}
                        expanded={true}
                        tableClasses={{...classes, ...switchOutClasses}}
                        withFooter={false}
                        withInputs={true}
                        options={{
                          isVirtual: isVirtual,
                          action: key,
                          itemsLength: 1,
                          maxQtyDecimals: portfolio.maxQtyDecimals,
                          handleTransactionValueChange: (instr, value) => {
                            item.errors = {...item.errors, buy: null}; // clean buy error
                            handleTransactionValueChange(instr, value)
                          },
                          handleSwitchAmountEuroChanged: handleSwitchAmountEuroChanged,
                          handleSwitchPercentageChanged: handleSwitchPercentageChanged,
                          handleTransactionTypeChange: handleTransactionTypeChange,
                          handleDepositDateChange: handleDepositDateChange,
                          handleRemoveClick: showTradingActions && handleRemoveClick,
                          handleSellAllChanged: handleSellAllChanged,
                          [quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.BUY]],
                          [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SELL]],
                          [quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]]: portfolio[quotationFieldsSource[TRANSACTION_TYPE_VALUES.SWITCH]],
                          withSrri: isAnlageberatung,
                          clientSRRI: dataService.srri,
                        }}
                      />
                      {!_.isEmpty(item) && (
                        <>
                          {/* For virtual portfolio message is not displayed as it is not attached to bank */}
                          {!isVirtual &&
                            <TableTooltip text={'Bitte beachten Sie, dass Anteile automatisch anhand der Anforderungen der Depotbanken gerundet werden.'}/>
                          }
                          {/* table of instruments that are bought */}
                          <DashboardTable
                            tableLayout={"auto"}
                            structure={getPortfolioTypeTransactionTypeTableStructure(isAnlageberatung)[portfolioType].switch_in}
                            dataSource={item.buy}
                            expanded={true}
                            tableClasses={{...classes, ...switchInClasses}}
                            withFooter={false}
                            withInputs={true}
                            options={{
                              isVirtual: isVirtual,
                              isModelportfolio: portfolio.isModelportfolio,
                              action: key,
                              switchOutItem: item,
                              sellAll: item.sell_all,
                              itemsLength: item.buy.length,
                              minDepositDate: minDepositDate,
                              discountsDisabled: !_.get(portfolio, 'ordering_discounts_allowed', []).includes('trading'),
                              availableDiscounts: portfolio.availableDiscounts,
                              hasError: !!(item.errors && item.errors.buy),
                              maxQtyDecimals: portfolio.maxQtyDecimals,
                              handleTransactionValueChange: (instr, value, switchOutItem=undefined) => {
                                item.errors = {...item.errors, buy: null, buy_items: null}; // clean buy error
                                handleTransactionValueChange(instr, value, switchOutItem)
                              },
                              handleTransactionTypeChange: handleTransactionTypeChange,
                              handleDiscountChange: handleDiscountChange,
                              handleRemoveClick: showTradingActions && function (t, i) {removeItem(item.buy, i)},
                              handleSwitchAmountEuroChanged: handleSwitchAmountEuroChanged,
                              handleSwitchPercentageChanged: handleSwitchPercentageChanged,
                              onDistributeQuantitiesClick: handleDistributeQuantitiesClick(item),
                              transactionSaldo: calculateSwitchTransactionSaldo(item, portfolio.maxQtyDecimals),
                              withSrri: isAnlageberatung,
                              clientSRRI: dataService.srri,
                            }}
                          />
                          {/* FIXME: add tooltip for Einzeltitel {<TableTooltip text={USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT} />}*/}
                          <Grid className={classes.addBtnContainer} container alignItems={"center"}>
                            {showTradingActions && (
                              <Grid item>
                                <IconBtn
                                  label={'Zielfonds hinzufügen'}  // Add target fund
                                  component={PlusBackgroundIcon}
                                  onClick={() => setSwitchModalData({item, key})}
                                  size={36}
                                />
                              </Grid>
                            )}
                            <Grid item>
                              {item.errors && item.errors.buy && (
                                <FormHelperText error={true}>{item.errors.buy}</FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ))
                }
                {key === 'portfolio_builder' && portfolioBuilderActive && (
                  <PortfolioBuilder
                    dataService={{
                      session_id: `${dataService.session_id}-${portfolio.data.id}`,
                      getPortfolioBuilderInfo: async function(){
                        const info = await dataService.getPortfolioBuilderInfo();

                        return {
                          ...info,
                          holdings: getPortfolioInstruments(key)
                        }
                      }
                    }}
                    caseData={portfolio.portfolioBuilderData || {}}
                    setCaseData={setPortfolioBuilderCase}
                    setRecommendations={setPortfolioBuilderRecommendations}
                    closeHandler={() => setPortfolioBuilderActive(false)}
                  />
                )}

                {/* plus icon to add asset/portfolio */}
                {getAddProductsSection(key)}
              </AccordionDetails>
            </Accordion>
          ) : null )}
        </PortfolioWrapper>

        {!!switchModalData && (
          <Dialog maxWidth="lg" fullWidth disableBackdropClick={true} open={true} onClose={handleCloseSwitchModal}>
            <DialogTitle>
              <div className={classes.header}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseSwitchModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <Create
              portfolioInstruments={
                // filter by isin (not by isin and subdepots) to hide asset from portfolio modal action btn
                getPortfolioComponentsWithSubDepots(
                  _.concat(portfolio.data.components || [], _.get(portfolio, 'data.soldAssets') || [])).filter(i => !!i.isin && i.isin !== switchModalData.item.data.isin)
              }
              instruments={[switchModalData.item, ...switchModalData.item.buy].map(i => i.data)}
              initialSwitchOutInstrument={switchModalData.item}
              transactions={transactions[switchModalData.key]}
              instrumentsHandler={switchInstrumentHandler}
              switchInPossible
              extraFilters={{custodian: portfolio.companyId}}
              handleAddItems={handleAddItems(TRANSACTION_TYPE_VALUES.SWITCH, switchModalData.item)}
              handleAddItem={handleAddItems}
              predefinedFilter={predefinedFilter}
              customerId={props.customer_id}
              isCustomerApp={props.isCustomerApp}
              isVirtual={props.isVirtual}
              enableFilteringDisabledInstruments={true}
            />
          </Dialog>
        )}

        {/*// dialog with table of assets to perform order on*/}
        {!!activeTradingFlowForModal && (
          <Dialog maxWidth="lg" fullWidth disableBackdropClick={true} open={true} onClose={handleCloseTradingFlowModal}>
            <DialogTitle>
              <div className={classes.header}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseTradingFlowModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <Create
              portfolioInstruments={getPortfolioInstruments(activeTradingFlowForModal)}
              clearTransactions={clearTransactions}
              allTransactions={transactions}
              instruments={transactions[activeTradingFlowForModal].map(i => i.data)}
              extraFilters={{custodian: portfolio.companyId}}
              customerId={props.customer_id}
              instrumentsHandler={getInstrumentsHandler(activeTradingFlowForModal)}
              handleAddItems={handleAddItems(activeTradingFlowForModal)}
              action={activeTradingFlowForModal}
              handleClose={handleCloseTradingFlowModal}
              handleAddItem={handleAddItems}
              handlePortfolioChange={handlePortfolioChange}
              dataService={dataService}
              portfolioData={{name: portfolio.data.name, categoryName: portfolio.data.categoryName}}
              portfolioOnly={[TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH].includes(activeTradingFlowForModal)}
              predefinedFilter={predefinedFilter}
              withSrri={isAnlageberatung}
              clientSRRI={dataService.srri}
              portfolioMarketValue={portfolio.data.market_value}
              isCustomerApp={props.isCustomerApp}
              isVirtual={props.isVirtual}
            />
          </Dialog>
        )}

        {/* footer */}
        <Grid item container className={classes.totalAmount} alignItems={"center"} style={isVirtual && {flexWrap: 'nowrap'}}>
          {portfolio.trading_errors && portfolio.trading_errors.portfolio && (
            <Grid item className={clsx(classes.error, 'Mui-error')} style={{width: '100%'}}>
              {portfolio.trading_errors.portfolio}
            </Grid>
          )}
          {/*<Grid item>Servicegebühr / Vermögensverwaltungsgebühr</Grid>*/}
          {/*<Grid item xs={2} md={1}>*/}
          {/*  <InputFormElement*/}
          {/*    value={getNumberValue(portfolio.depot_fee)}*/}
          {/*    inputComponent={PercentageNumberFormat}*/}
          {/*    onChange={handleDepotFeeChanged}*/}
          {/*    type={'text'}*/}
          {/*    custom_classes={classes}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item>Depotdiscount</Grid>*/}
          {/*<Grid item xs={2} md={1}>*/}
          {/*  <InputFormElement*/}
          {/*    value={getNumberValue(portfolio.discount)}*/}
          {/*    inputComponent={PercentageNumberFormat}*/}
          {/*    onChange={handleDepotDiscountChanged}*/}
          {/*    type={'text'}*/}
          {/*    custom_classes={classes}*/}
          {/*  />*/}
          {/*</Grid>*/}

          {/* selcet */}
          <Grid className={classes.depotTypeSection} container alignItems={"center"}>
            {/* virtual portfolio is not attached to certain bank -> there are no depot types to select */}
            {!isVirtual &&
              <Grid item xs={12} md={8} container spacing={3} alignItems={"center"}>
                <Grid md={3} className={classes.depotSelection}>
                  <SelectFormElement
                    value={_.get(portfolio, 'depotType.value')}
                    options={portfolio.availableDepotTypes || []}
                    onChange={handleDepotTypeChange}
                    placeholder='Vertragsart'
                    custom_classes={{
                      inputRoot: classes.depotSelectionInput
                    }}
                  />
                </Grid>
                {portfolio.isModelportfolio && (
                  <Grid md={3} className={classes.depotSelection}>
                    <SelectFormElement
                      value={_.get(portfolio, 'modelPortfolioTransactionType')}
                      options={getModelPortfolioTransactionTypeOptions(portfolio.tradingType)}
                      onChange={handleModelPortfolioTransactionTypeChange}
                      placeholder='Anlagevariante'
                      custom_classes={{
                        inputRoot: classes.depotSelectionInput
                      }}
                    />
                  </Grid>
                )}
                {portfolio.depotType ? (
                  <Grid item md={9}>
                    <p className={classes.depotTypeDescriptionLine}>Depotkosten: {_.get(portfolio, 'depotType.depot_cost_display', '')}</p>
                    <p className={classes.depotTypeDescriptionLine}>Transaktionskosten: {_.get(portfolio, 'depotType.transaction_cost_display', '')}</p>
                    <p className={classes.depotTypeDescriptionLine}>Sonstige Kosten: {_.get(portfolio, 'depotType.others_cost_display', '')}</p>
                  </Grid>
                ) : null}
              </Grid>
            }

            {showTotal && (
              <Grid item className={classes.depotOverall} md={4} alignItems={"center"}>
                {isAnlageberatung && (
                  <p>Gewichtetes Portfoliorisiko: {toGermanFormat(averageSrriValue)}</p>
                )}
                <p>Gesamt: {portfolioAmount()}</p>
              </Grid>
            )}

          </Grid>
        </Grid>
      </Grid>
      <RiskScoreConfirmationModal />
    </RiskScoreConfirmationModalContext.Provider>
  )
};

PortfolioTrade.defaultProps = {
  showSectionsWithoutTransactions: true,
  showTotal: true,
  sectionsCollapsable: true,
  showTradingActions: true
}

export default connect()(withStyles(styles)(PortfolioTrade));
