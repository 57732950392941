import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {},
  formLabel: {
    color: '#313D44',
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 8,
    whiteSpace: 'nowrap !important',
  },
  focusedLabel: {
    color: '#313D44 !important'
  },
  formControlLabel: {
    fontSize: 14,
  },
  radio: {
    padding: 5,
    marginLeft: 4
  },
  reportSettingsButton: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '-15px 0 5px 2px',

    '& > span:first-child': {
      marginRight: 2,
      fontSize: 20
    },
    '& > span:last-child': {
      textDecoration: 'underline'
    },
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
}))