import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '70%',
        width: '952px',
        padding: '30px 30px',
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius: '4px',
        boxShadow: theme.shadows[5]
    },
    collectionModalItem: {
        flexWrap: 'nowrap'
    },
    modalHeaderText: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    documentNameText: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginLeft: '8px'
    },
    modalNavButtons: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 'min-content',
        marginLeft: 'auto',
        '& > button': {
            width: 'max-content',
            maxWidth: 'min-content',
            color: '#0092e5',
            marginLeft: 20,
            border: '2px solid #0092e5',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: 4,
            opacity: 1,
            '&:hover': {
            backgroundColor: '#0092e5',
            '& > span': {
                '& > span, & > i': {
                    color: '#FFFFFF',
                },
            }
            },
            '&:first-child': {
                marginLeft: 0,
                height: 35
            },
            '&:nth-child(2)': {
                height: 35
            },
            '& > span': {
                alignSelf: 'baseline',
                '& > span': {
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: '#0092E5',
                },
                '& > i': {
                    color: '#0092E5',
                }
            }
        }
    },
    deleteHelperTitle: {
        fontSize: '13px',
        textAlign: 'left',
        padding: '15px 15px 15px 15px',
        backgroundColor: '#394352',
        left: '25px',
        width: '250px'
    },
    deleteHelperArrow: {
        color: '#394352 !important',
        marginLeft: '-45px !important'
    },
    helperHeaderInfo: {
        marginLeft: '20px',
        '& > svg': {
            verticalAlign: 'middle'
        }
    },
    documentsHeader: {
        fontWeight: 'normal',
        backgroundColor: '#F5F6F7',
        opacity: '0.5',
        paddingRight: '0px'
    },
    documentsItems: {
        height: '70%',
        overflow: 'auto',
        overflowX: 'hidden'
    }
});

export default makeStyles(styles);