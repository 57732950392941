import React from "react";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import leaf from '../../../../../../../../../images/leaf.png'

highchartsMore(Highcharts);
solidGauge(Highcharts);

const style = {
    wrapper: {
        position: "relative"
    },
    img: {
        position: "absolute",
        width: 115,
        height: 115,
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 5
    },
    data: {
        position: "absolute",
        bottom: 10,
        left: "50%",
        backgroundColor: "rgba(255,255,255,0.7)",
        padding: 10,
        width: 300,
        percent: {
            fontSize: 24,
            fontWeight: "bold",
            color: "#202A38",
            paddingRight: 6
        },
        title: {
            color: "#202A38",
            fontSize: 16,
            fontWeight: "normal",
            marginBottom: 12
        }
    }
}

const Chart = ({ sustainablePercent, paiPercent, esgPercent, notitle }) => {

    const options = {
        chart: {
            type: 'solidgauge',
            height: '100%',

        },
        title: {
            text: notitle ? null : 'Activity',
            style: {
                fontSize: '24px'
            }
        },
        credits: {
            enabled: false
        },

        tooltip: {
            enabled: false,
            borderWidth: 0,
            backgroundColor: 'transparent',
            shadow: false,
            style: {
                fontSize: '15px'
            },
            valueSuffix: '%',
            pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
            positioner: function (labelWidth) {
                return {
                    x: (this.chart.chartWidth - labelWidth) / 2,
                    y: (this.chart.plotHeight / 2) + 15
                };
            }
        },

        pane: {
            startAngle: -180,
            endAngle: 180,
            background: [{ // Track for Move
                outerRadius: '107%',
                innerRadius: '88%',
                backgroundColor: "#EBEBEB",
                borderWidth: 0,
            }, { // Track for Exercise
                outerRadius: '82%',
                innerRadius: '63%',
                backgroundColor: "#EBEBEB",
                borderWidth: 0,
            }, { // Track for Stand
                outerRadius: '57%',
                innerRadius: '38%',
                backgroundColor: "#EBEBEB",
                borderWidth: 0
            }]
        },

        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            }
        },

        series: [
            {
                name: 'Ökologisch Nachhaltig',
                data: [{
                    color: "#BFE77E",
                    radius: '57%',
                    innerRadius: '38%',
                    y: sustainablePercent,
                }],
            }, {
                name: 'ESG',
                data: [{
                    color: "#80858C",
                    radius: '82%',
                    innerRadius: '63%',
                    y: esgPercent
                }]
            }, {
                name: 'PAI',
                data: [{
                    color: "#0092E5",
                    radius: '107%',
                    innerRadius: '88%',
                    y: paiPercent
                }]
            }]
    };

    return (
        <div style={style.wrapper}>
            <img src={leaf} style={style.img} alt="" />
            <div style={style.chat}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div style={style.data}>
                <h4 style={style.data.title}><span style={style.data.percent}>{sustainablePercent}%</span>Ökologisch Nachhaltig</h4>
                <h4 style={style.data.title}><span style={style.data.percent}>{esgPercent}%</span>ESG</h4>
                <h4 style={style.data.title}><span style={style.data.percent}>{paiPercent}%</span>PAI</h4>
            </div>
        </div>
    );
};


export default Chart;
