const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#202A38'
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '25%'
  },
  list: {
    listStyle: 'none',
    marginTop: 60,
    padding: 0,
    '&:first-of-type': {
      marginTop: 10
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '10px 7px',
    borderBottom: 'solid 1px #D8DCDF',
  },
  headline: {
    fontWeight: 'bold',
    backgroundColor: '#F5F6F7',
    minHeight: 46,
    padding: '4px 7px 0 7px',
    borderBottom: 'none',
    '& > span': {
      color: '#80858C',
      fontWeight: 'bold',
    }
  },
  values: {
    width: '15%',
    textAlign: 'right'
  },
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  },
  subline: {
    marginTop: 'auto',
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
})

export default styles;