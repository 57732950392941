import React from 'react';
import {connect} from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';
import {customerWithoutGroupMessage} from "../../../ReportGroupCreation/constants";

import GroupListItem from './components/GroupListItem'
import {PrimaryButton} from "../../../../components/Buttons";
import AddIcon from "@material-ui/icons/Add";
import {ROUTES} from "../../../../routes";
import {newDesignUsed} from "../../../../utils/utils";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../InvestmentPlatform/utils";
import {withRouter} from "react-router-dom";
import EditGroupNameModal from "../../../DashboardSettings/components/EditGroupNameModal";
import {GroupResource} from "../../../../utils/api";
import withNotification from "../../../../components/NotificationProvider";
import _ from "lodash";
import DeleteGroupModal from "../../../ReportGroupCreation/components/DeleteGroupModal";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import CustomerFilteringPanel from "../../../../components/CustomersSelectorProviderNew/components/CustomerFilteringPanel";
import {getSearchCustomerType} from "../../../../components/FilteringPanel/components/ListSelector/constants";
import {SORTING_TYPES} from "../../../../components/FilteringPanel/components/SortingFilter/constants";
import {UserUtils, hasFilterResult} from '../../../../utils/utils';
import {filterGroups, sortGroups} from '../../../DashboardSettings/utils';
import FilterHeader from '../../../DashboardSettings/components/FilterHeader';
import {addCustomersToStore} from "../../../../components/CustomersDataProvider/actions";
import {setReportingGroups} from "../../actions";


const mapStateToProps = (state) => ({
  initialGroups: state.get('reporting').toJS().groups,
  groupsLoading: state.get('reporting').toJS().groupsLoading,
  guideTourGroups: state.get('reporting').toJS().guideTourGroups,
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
  customers: state.get('customersDataProvider').toJS().customers,
  storeCustomersErrors: state.get('customersDataProvider').toJS().errors,
});

const CustomerGroupList = (props) => {

  const {
    classes,
    initialGroups,
    guideTourGroups,
    groupsLoading,
    auth
  } = props;

  const[groups, setGroups] = React.useState([])

  const withoutGroupCount = React.useMemo(() => {
    // Use memo to get number of customers without groups
    return !_.isEmpty(props.customers) ? props.customers.filter(c => _.isEmpty(c.groups)).length : undefined;
  }, [props.customers])

  React.useEffect(() => {
    setGroups([...initialGroups])
  }, [initialGroups])
  const [filters, setFilters] = React.useState({
    search: '',
    first_letter: '',
    sortingType: SORTING_TYPES.NAME_ASC,
    triggerFilter: true
  });

  const [filterResult, setFilterResult] = React.useState({
    count: undefined,
    word: undefined
  });
  const [filteredGroups, setFilteredGroups] = React.useState([]);

  React.useEffect(() => {
    handleFilterGroups();
  }, [groups]);

  React.useEffect(() => {
    if (filters.triggerFilter) {
      handleFilterGroups();
    }
  }, [filters.triggerFilter]);

  const onNewGroupButtonClick = () => {
    let path = ROUTES.BROKER.GROUP_CREATE.path

    if (newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'SERIES_REPORTING', (dynamicPath) => {
        path = '/' + getInvestmentDynamicPath() + dynamicPath + ROUTES.BROKER.GROUP_CREATE.newDesignPath
      })
    }

    props.history.push(path)
  }

  const[editNameGroup, setEditNameGroup] = React.useState()

  const handleEditNameClicked = (group) => {
    setEditNameGroup(group)
  }

  const handleEditNameClose = () => {
    setEditNameGroup(undefined)
  }

  const handleDeleteGroupClose = () => {
    setGroupToDelete({});
    setDeletingStatus(false);
  }

  async function handleEditNameSave(groupToUpdate, newGroupName) {
    try {
      await GroupResource.at(`${groupToUpdate.id}/`).patch({name: newGroupName});

      handleEditNameClose()
      props.displayNotification('success', `Ihre Gruppe "${newGroupName}" wurde gespeichert.`)

      // set group name
      let groupsWithUpdated = [...groups].map(g => {
        if(g.id == groupToUpdate.id){
          g.name = newGroupName
        }
        return g
      })

      setGroups(groupsWithUpdated)
      props.dispatch(setReportingGroups(groupsWithUpdated))

    } catch (error) {

      let errorMessage = 'Error!';
      if (_.isObject(error)  && 'name' in error) {
        errorMessage = error.name[0];
      }

      props.displayNotification('error', errorMessage);
    }
  }

  const [isDeleting, setDeletingStatus] = React.useState(false);
  const [groupToDelete, setGroupToDelete] = React.useState({})

  const removeGroupFromStorageCustomers = (customersToUpd) => {
    // Function to update groups key of customers in storage if group was deleted

    const storeCustomers = props.customers;
    // if customers are loaded without any errors
    if(!props.storeCustomersErrors && storeCustomers){
      // loop over list of all customers
      for (let index = 0; index < storeCustomers.length; index++) {
        // check if customer is in list of customers that needs to be updated
        customersToUpd.forEach(customerData => {
          if (storeCustomers[index].id == customerData.id) {
            // if group was just created customer has 'dummy' group id which equals to customer.id ->
            // if deleted group is not in groups of customer - remove dummy index
            let deletedGrouopId = storeCustomers[index].groups.includes(groupToDelete.id) ? groupToDelete.id : storeCustomers[index].id
            // filter group that was deleted
            storeCustomers[index].groups = storeCustomers[index].groups.filter(g => g != deletedGrouopId)
          }
        });
      }

      // update customers in store
      props.dispatch(addCustomersToStore(storeCustomers))
    }
  }

  const deleteGroup = async () => {
    setDeletingStatus(true);
    try {
      await GroupResource.at(`${groupToDelete.id}/`).delete();

      let groupsWithoutDeleted = _.filter([...groups], group => group.id !== groupToDelete.id);
      setGroups(groupsWithoutDeleted);
      props.dispatch(setReportingGroups(groupsWithoutDeleted))
      removeGroupFromStorageCustomers(groupToDelete.customers)

      props.displayNotification('success', `Gruppe "${groupToDelete.name}" wurde erfolgreich gelöscht.`);

    } catch (error) {
      props.dispatch(displayErrorSnackBar(error));
    }

    handleDeleteGroupClose();
  }

  const renderList = () => {
    if (guideTourGroups && guideTourGroups.length) {
      return guideTourGroups.map((group) => {
        return (
          <GroupListItem key={group.id} group={group} />
        )
      })
    }

    if (groupsLoading) {
      return (
        <>
          <GroupListItem group={{}} loading={true} />
          <GroupListItem group={{}} loading={true} />
          <GroupListItem group={{}} loading={true} />
        </>
      )
    }

    return [...filteredGroups || []].map((group) => {
      return (
        <GroupListItem key={group.id} group={group} onEditNameClick={handleEditNameClicked} setGroupToDelete={setGroupToDelete} isDeleting={isDeleting} />
      )
    })
  };

  const handleSearchByLetter = (letter) => {
    let searchValue = letter ? letter.trim() : ''
    setFilters({
      ...filters,
      first_letter: searchValue,
      search: '',
      triggerFilter: true
    });
  };

  const handleSearchClicked = (value) => {
    let searchValue = value ? value.trim() : ''
    setFilters({
      ...filters,
      first_letter: '',
      search: searchValue,
      triggerFilter: true
    });
  };

  const handleFilterGroups = () => {

    if (Object.entries(filters).length !== 0) {
      let groupsJoined = [].concat.apply([], groups || []);
      let filtered = [...filterGroups(groupsJoined, filters)];

      filtered = sortGroups(filtered, filters.sortingType);

      setFilters(({
        ...filters,
        triggerFilter: false
      }));

      let searchText = filters.first_letter ? `Anfangszeichen ${filters.first_letter}` : filters.search;

      setFilterResult({
        word: searchText,
        count: filtered.length
      })
      setFilteredGroups(filtered, 10);
    }
  }


  return (
    <div className={classes.container}>

      <div className={classes.listHeader}>
        IHRE KUNDENGRUPPEN
        {withoutGroupCount >= 0 && <span className={classes.withoutGroupCount}>{customerWithoutGroupMessage(withoutGroupCount)}</span>}
      </div>

      <div className={classes.addGroupSection}>
        {(groups && groups.length === 0 && !(guideTourGroups && guideTourGroups.length)) && (
          <p>Sie haben noch keine Kundengruppe erstellt.</p>
        )}
        <PrimaryButton
          id='report-settings-add-group-btn-tour-element'
          text='Kunden selektieren'  // Neue Gruppe erstellen
          onButtonClick={onNewGroupButtonClick}
          icon={<AddIcon />}
        />
      </div>
      <div className={classes.filterHeader}>
        Gruppenübersicht
      </div>
      <CustomerFilteringPanel
        customerType={getSearchCustomerType(auth).value}
        defaultSearchValue={''}
        handleSearchByLetter={handleSearchByLetter}
        handleSearchClicked={handleSearchClicked}
        isChief={UserUtils.isChief(auth)}
        user={auth.user}
        useGroupSearchTooltip
        loading={groupsLoading}
      />
      {hasFilterResult(filterResult) &&
        <FilterHeader filterResult={filterResult}/>
      }
      {renderList()}

      <EditGroupNameModal
        group={editNameGroup}
        open={!!editNameGroup}
        onSaveClick={handleEditNameSave}
        onModalClose={handleEditNameClose}
      />

      <DeleteGroupModal
        open={!_.isEmpty(groupToDelete)}
        handleClose={handleDeleteGroupClose}
        handleDeleteClick={deleteGroup}
        isDeleting={isDeleting}
        groupName={groupToDelete.name}
      />
    </div>
  )
};


export default withStyles(styles)(
  connect(mapStateToProps)(withNotification(withRouter(CustomerGroupList)))
);