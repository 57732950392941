import React from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

import Container from "@material-ui/core/Container/Container";
import { Grid } from '@material-ui/core'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { executeIfPathExist, getInvestmentDynamicPath } from '../InvestmentPlatform/utils';
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import {
  buildCurrentCustomerDashboardPath,
  buildCustomerDashboardLink,
  buildCurrentCustomerDashboardVirtualPath
} from "../../routes";
  import {UserUtils, newDesignUsed, validateSelectedRangeBusinessDates} from "../../utils/utils";

import useStyles from './styles';
import PerformanceTab from "./Tabs/PerformanceTab";
import PortfolioStructureTab from "./Tabs/PortfolioStructureTab";
import KeyFiguresTab from "./Tabs/KeyFiguresTab";
import RiskAnalysisTab from "./Tabs/RiskAnalysisTab";
import {
  CUSTOMER_ID_KEY,
  getFromStorage,
  PROF_VIEW_PORTFOLIO_DATA,
  PROF_VIEW_REPORT_TYPE,
  removeFromStorage
} from "../../utils/storage";
import { useParams } from "react-router-dom";
import withDashboardData from '../../components/DashboardDataProvider';
import withTradingsData from '../../components/TradingsDataProvider';
import TimeFramePicker from '../CustomerDashboard/components/InvestmentDetailsNavigation/PortfolioTimeSelector';
import {
  DEFAULT_PERFORMANCE_TIME_TYPE,
  PERMISSION_ERROR_MODAL_MESSAGE,
} from '../../utils/constants';
import BenchmarkWithConfiguration from "../CustomerDashboard/components/Widgets/components/PerformanceLineChart/benchmark";
import BenchmarkModal from '../CustomerDashboard/components/InvestmentDetailsNavigation/BenchmarkSettingsModal';
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {CustomerReportSettingResource} from "../../utils/api";
import withNotification from "../../components/NotificationProvider";
import {
  PortfolioHandlerResource,
  VirtualPortfolioHandlerResource
} from "../../utils/api";
import { isSectionVisible } from '../../utils/utils';
import {getReportGenerationSettings} from "../../utils/utils";
import {
  getCustomerDashboardSelector,
  getSelectedAssetsIds
} from "../../components/DashboardDataProvider/DashboardDataProvider";
import {
  getBenchmarkConfigurationSelector
} from "../CustomerDashboard/components/Widgets/components/PerformanceLineChart";
import PortfolioList from "../../components/ProfileDetails/components/PortfolioList";
import {AssetsListDropdown} from "../../components/PortfolioListDropdown/PortfolioListDropdown";
import {isProfessionalViewAssetsEnabled} from "../../components/TradingStore/utils";
import {AGGREGATED_ASSET_NAME} from "../../utils/aggregated-portfolio";
import {getAssetsWithInactiveIds} from "./utils";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";


const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state),
  auth: getAuthSelector(state),
  customerDashboard: getCustomerDashboardSelector(state),
  benchmarkConfiguration: getBenchmarkConfigurationSelector(state),
});

const mapStateToPropsProfView = (state) => ({
  isProfView: true
});

const VIEW_TABS = [
    { id: 'performance_tab', name: 'Wertentwicklung', component: PerformanceTab },
    { id: 'portfolio_structure', name: 'Portfoliostruktur', component: PortfolioStructureTab },
    { id: 'key_figures', name: 'Kennzahlen', component: KeyFiguresTab },
    { id: 'risk_analysis', name: 'Risikoanalyse', component: RiskAnalysisTab },
  ];

const ProfessionalView = props => {

  const {
    customer,
    investmentPlatform,
    auth,
    banksMappingData,
    combinedTradingSession,
    goToTradeDetails,
    handleAddTradingOption,
    handleAddSavingPlanOption,
    handleAddPortfolioTradingOption,
    handleAddPortfolioSavingPlanOption,
    handleAddPayoutPlanOption,
    handleAddSwitchPlanOption,
    handleAddPortfolioPayoutPlanOption,
    handleAddPortfolioSwitchPlanOption,
    instrumentList,
    paymentPlans,
    payoutPlansData,
    switchPlansData,
    payoutPlansTrading,
    switchPlansTrading,
    profitAndLoss,
    customerDashboard,
    combinedTradings,
    benchmarkConfiguration,
    onSelectedDatesChanged,
    timeWeightedReturnData,
    updateHistoricalData,
    investmentData,
    isCustomerApp,
    breakdownData,
    chartsSettings,
    handleChartSettingsChange,
    portfolios,
    isVirtual,
    banksData,
    reportType,
    unrealizedProfitAndLoss,
    selectedAssets,
    onSelectedAssetsChanged,
    assets,
    isAllAssetsSelected,
    inactiveAssets,
    selectedDates
  } = props;

  let { portfolio_id } = useParams();

  const portfolio_data = React.useMemo(() => {
    return getFromStorage(PROF_VIEW_PORTFOLIO_DATA)
  }, [portfolio_id])

  const validateIfDataExists = () => {
    return !!(portfolio_data && parseInt(portfolio_data.id) === parseInt(portfolio_id));
  };

  const buildDashboardLink = () => {
    let sessionCustomerId = getFromStorage(CUSTOMER_ID_KEY);
    if(isVirtual){
      return buildCurrentCustomerDashboardVirtualPath(sessionCustomerId)
    }

    let path =  UserUtils.isBroker(auth)
      ? buildCustomerDashboardLink(sessionCustomerId)
      : buildCurrentCustomerDashboardPath();

    if (UserUtils.isBroker(auth) && newDesignUsed()) {

        executeIfPathExist(investmentPlatform.routes, 'DASHBOARD', (dynamicPath) => {
            path = '/' + getInvestmentDynamicPath() + dynamicPath.replace(':customer_id', sessionCustomerId)
        }, ':customer_id');

    }
    return path
  };

  const emptyDataHandler = () => {
    //redirect to dashboard if portfolio_data in redux empty
    return !validateIfDataExists() && props.history.push(buildDashboardLink());
  };

  emptyDataHandler();

  // scroll top on render
  React.useEffect(() => {
    document.getElementById("app-main").scrollTo(0,0)

    return () => {
      removeFromStorage(PROF_VIEW_PORTFOLIO_DATA)
      removeFromStorage(PROF_VIEW_REPORT_TYPE)
    }

  }, []);

  const customSettings = React.useMemo(() => {
    return _.get(customer, 'data.dashboard_settings.custom_report_type_settings.pro_view.subcategories', {})
  }, [customer]);

  const handleShowChartSection = (customSettings, fieldId) => {
    return isSectionVisible(customSettings, fieldId, reportType)
  };

  const proTabs = React.useMemo(() => {
    return VIEW_TABS.filter(tab => handleShowChartSection(customSettings, tab.id))
  }, [customSettings, reportType]);

  let dataProvider;
  if (isVirtual) {
    dataProvider = VirtualPortfolioHandlerResource
  } else {
    dataProvider = PortfolioHandlerResource
  }

  const classes = useStyles();
  const [selectedTabId, setSelectedTabId] = React.useState(0);
  const [expandedItems, setExpandedItems] = React.useState({
    historicalChart: true,
    performanceLineChart: true,
    performanceBarChart: true,
    unrealizedProfitAndLossItems: [],
    profitAndLossItems: [],
    profitAndLossSubItems: {},
    paymentPlansItems: [],
    payoutPlansItems: [],
    switchPlansItems: [],
    instrumentsItems: [],
    instrumentsSubItems: {},
    cumulativeReturnChart: true,
    forecastChart: true,
    performanceTable: true,
    singlePerformance: true,

    // charts KeyFiguresTab
    keyIndicatorsChart: true,
    rollingVolatilityChart: true,
    rollingSharpeRatioChart: true,
    esgScoreChart: true,
    // charts from RiskAnalysisTab
    riskReturnChart: true,
    stressTestChart: true,
    correlationMatrix: true
  });

  const [lastBenchmarkUpdateTime, setLastBenchmarkUpdateTime] = React.useState(undefined);

  const [exportPdfModalVisible, setExportPdfModalVisible] = React.useState(false);

  const professionalViewAssetsEnabled = isProfessionalViewAssetsEnabled()

  const fetchPdfFile = async(skip_expanded, skip_sub_depot_expanded, cover_text_enabled, cover_message_content, extra_files) =>{
    setExportPdfModalVisible(false);

    let customer_id = props.match.params.customer_id;
    // to align structure
    let selectedDepots = [{'depotNumber': props.match.params.portfolio_id}];

    try{
      let formData = getReportGenerationSettings(customer_id, selectedDepots, portfolios,
        reportType, selectedDates, skip_expanded, skip_sub_depot_expanded, expandedItems, cover_text_enabled, cover_message_content, chartsSettings.global.withHistoricalPortfolios, extra_files, chartsSettings);

      formData.append('pro_only', true);  // flag. shows if expert part should be included in pro report
      formData.append('use_assets_approach', professionalViewAssetsEnabled)
      formData.append('all_assets_selected', professionalViewAssetsEnabled && isAllAssetsSelected);
      let assets = getAssetsWithInactiveIds(
        getSelectedAssetsIds(selectedAssets), inactiveAssets,
        isAllAssetsSelected, chartsSettings.historical.withEmptyAssets);
      formData.append('assets', JSON.stringify(assets))
      const inactiveAssetsIds = chartsSettings.historical.withEmptyAssets
        ? (inactiveAssets || []).map((asset) => asset.id) : undefined;
      formData.append('inactive_assets', JSON.stringify(inactiveAssetsIds))

      await CustomerReportSettingResource.at(`report/ad-hoc/`).post(formData);
    } catch (e) {
      let errorMsg = _.isString(e) ? e : PERMISSION_ERROR_MODAL_MESSAGE;
      props.displayNotification('error', errorMsg);
    }
  };

  const handlePdfExportClick = () => {
    setExportPdfModalVisible(true)
  };

  const getCustomerData = (customer) => {
    if(!_.isEmpty(customer)){
      return {
        report_settings: {
          ...customer.user.settings,
        },
        customer_id: customer.customer_id
      }
    }
  };

  React.useEffect(() => {

    let start_date = selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined;

    const trackingStartDate = portfolio_data && portfolio_data.tracking_start_date;

    // check for portfolio_data because redirect in emptyDataHandler may take long time and rendering still continues
    if(trackingStartDate > start_date){
      handleCalculationDatesChanged(DEFAULT_PERFORMANCE_TIME_TYPE.getDateRange(), DEFAULT_PERFORMANCE_TIME_TYPE)
    }
  }, [selectedDates])

  const handleCalculationDatesChanged = (dates, datesType) => {
    onSelectedDatesChanged(dates, datesType);
  };

  const handleChange = (event, newValue) => {
    if (newValue !== undefined) {
        setSelectedTabId(newValue);
    }
  };

  const a11yProps = (index) => {
    return ({
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    })
  };

  const renderTabsCaptions = () => {
    {/* disable tabs in case benchmarks data not loaded to avoid case when benchmarks not loaded but requests sent */}
    return proTabs && proTabs.map((tab, index) => (
        <Tab label={tab.name} {...a11yProps(index)}
          classes={{ root: classes.tab, selected: classes.selected }}
          key={'tab' + index}
          disabled={customer.data === undefined}
        />
      )
    )
  };

  const handleExpandedItemsChange = (expandedItem, value) => {
    setExpandedItems(prevState => ({
        ...prevState,
        [expandedItem]: value
    }));
  };

  const generateSelectedAssetsName = () => {
    if (_.isEmpty(selectedAssets) || (assets.length == selectedAssets.length && assets.length > 1)) {
      return AGGREGATED_ASSET_NAME
    }

    if (selectedAssets.length === 1) {
      return selectedAssets[0].name
    }

    return 'Mehrere ausgewählte Produkte'
  }

  const renderTabsPanels = () =>  {
    return proTabs && proTabs.map((tab, index) => (
        <div
          className={classes.tabContent}
          key={'tab' + index}
          role="tabpanel"
          hidden={selectedTabId !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          {selectedTabId === index && (
            <tab.component
              customerId={getFromStorage(CUSTOMER_ID_KEY)}
              portfolioId={portfolio_data.id}
              onExpandedItemsChange={handleExpandedItemsChange}
              expandedItems={expandedItems}
              portfolioName={portfolio_data.name}
              selectedAssetsName={professionalViewAssetsEnabled && generateSelectedAssetsName()}
              banksMappingData={banksMappingData}
              combinedTradingSession={combinedTradingSession.data}
              goToTradeDetails={goToTradeDetails}
              handleAddTradingOption={handleAddTradingOption}
              handleAddSavingPlanOption={handleAddSavingPlanOption}
              handleAddPortfolioTradingOption={handleAddPortfolioTradingOption}
              handleAddPortfolioSavingPlanOption={handleAddPortfolioSavingPlanOption}
              onAddPayoutPlanOption={handleAddPayoutPlanOption}
              onAddSwitchPlanOption={handleAddSwitchPlanOption}
              onAddPortfolioPayoutPlanOption={handleAddPortfolioPayoutPlanOption}
              onAddPortfolioSwitchPlanOption={handleAddPortfolioSwitchPlanOption}
              combinedTradings={combinedTradings}
              payoutPlans={payoutPlansTrading}
              payoutPlansData={payoutPlansData}
              switchPlansData={switchPlansData}
              switchPlans={switchPlansTrading}
              instrumentList={instrumentList}
              paymentPlans={paymentPlans}
              profitAndLoss={profitAndLoss}
              selectedDates={selectedDates}
              handleChartSettingsChange={handleChartSettingsChange}
              chartsSettings={chartsSettings}
              twrData={timeWeightedReturnData}
              investmentData={investmentData}
              lastBenchmarkUpdateTime={lastBenchmarkUpdateTime}
              userData={auth && auth.user}
              isCustomerApp={isCustomerApp}
              breakdownData={breakdownData}
              isVirtual={isVirtual}
              dataProvider={dataProvider}
              banksData={banksData}
              customerData={customer && customer.data}
              customSettings={customSettings && _.get(customSettings, `${tab.id}.subcategories`)}
              handleShowChartSection={handleShowChartSection}
              unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
              selectedAssets={getSelectedAssetsIds(selectedAssets)}
              inactiveAssets={inactiveAssets}
              isAllAssetsSelected={isAllAssetsSelected}
              isProfessionalViewAssetsEnabled={professionalViewAssetsEnabled}
              showEmptyAssetsCheckbox
              // TODO update to pass props only to required tab
            />
          )}
        </div>
      ));
  };
  const updateRiskMetricsBenchmark = (data, portfolioToUpdate) => {
    //used for charts reload as it do not have enough benchmark data for Keyindicators and other charts
    setLastBenchmarkUpdateTime(new Date().getTime())
  };

  const handleBenchmarkConfigured = (configured) => {
    if (configured && benchmarkConfiguration.configuration_required) {
      return updateBenchmarkSwitchState(true);
    }
  };

  const updateBenchmarkSwitchState = (forceShow) => {
    if(!timeWeightedReturnData.loading){
      const isDataExist = chartsSettings.performance.currently_selected_benchmarks.length > 0;
      handleChartSettingsChange('performance', 'withBenchmark', isDataExist || !!forceShow);
    }
  };

  const requiredDataExist = () => {
    if (_.isArray(timeWeightedReturnData.data && timeWeightedReturnData.data.portfolios)) {
      return timeWeightedReturnData.data.portfolios.every(portfolio => !!portfolio.timeseries);
    }
    return false;
  };

  const isBenchmarkSwitchVisible = () => {

    if (!isCustomerApp) { return true; }  // for broker app benchmark is visible

    // for customer app switch is visible if benchmarks are attached to portfolio as there is no way to configure them from customer dashboard
    if (isCustomerApp && _.isObject(timeWeightedReturnData)) {
      let portfolios = _.get(timeWeightedReturnData, 'data.portfolios', []);
      let hasBenchmark = portfolios.some(p => {
        return !_.isEmpty(_.get(p, 'benchmark_performance_detailed.portfolio', []))
      });
      return hasBenchmark;
    }

    return false;
  };

  return (
    <>
     <Container className={`app-page-container`} style={{paddingBottom: 16}}>
        <Link to={buildDashboardLink()} className={classes.link}>
            <i className="chevron-icon fa fa-chevron-left" />
            Zurück zu Vermögensübersicht
        </Link>
          <div className={classes.navigationContainer} style={{marginBottom: 10}}>
          <h1 className={classes.header}>{portfolio_data && portfolio_data.name}</h1>
          </div>
          <Grid container spacing={2} justify='space-between' alignItems='center'>
            <Grid item>
              <div className={classes.settingsContainer}>
                <div className={classes.timeFrameContainer}>
                  <TimeFramePicker
                    calculationDates={selectedDates}
                    calculationDatesType={props.selectedDatesType}
                    handleCalculationDatesChanged={handleCalculationDatesChanged}
                    startOfInvestment={portfolio_data && portfolio_data.tracking_start_date}
                    loading={props.dataLoading}
                    dispatch={props.dispatch}
                    timeSelectorDateChange={customerDashboard.time_selector_date_change}
                    businessDaysOnly
                  />
                </div>
                {professionalViewAssetsEnabled && (
                  <div className={classes.timeFrameContainer}>
                    <AssetsListDropdown
                      assets={assets}
                      selectedAssets={selectedAssets}
                      onSelectedAssetChange={onSelectedAssetsChanged}
                      disabled={_.isEmpty(assets) || props.dataLoading}
                    />
                  </div>
                )}
                {isBenchmarkSwitchVisible() &&
                  <BenchmarkWithConfiguration
                    portfolio={portfolio_data}
                    calculationDates={selectedDates}
                    dispatch={props.dispatch}
                    benchmarkConfigurationEnabled={!isCustomerApp}
                    onChartSettingsChange={handleChartSettingsChange}
                    isBenchmarkConfigurationEnabled={requiredDataExist()}
                    requiredDataExist={requiredDataExist()}
                    showInvestment={chartsSettings.performance.withBenchmark}
                    selectedBenchmarks={chartsSettings.performance.currently_selected_benchmarks}
                    updateBenchmarkSwitchState={updateBenchmarkSwitchState}
                    customerDashboard={customerDashboard}
                    benchmarkConfiguration={benchmarkConfiguration}
                  />
                }
              </div>
            </Grid>
            {!isCustomerApp &&
              <Grid item xs={"auto"}>
                <PrimaryButton
                  id='export-buttons'
                  text='Depot drucken'
                  onButtonClick={handlePdfExportClick}
                  icon={<ArrowDownwardIcon/>}
                  pdfExportBtnDisabled={!portfolio_data}
                />
              </Grid>
            }
          </Grid>
        </Container>
        <Container className={`app-page-container`}>
          <>
          <AppBar position="static" className={classes.tabsHeader}>
            <Grid container>
              <Tabs value={selectedTabId} onChange={handleChange} aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    classes={{root: classes.tabsRoot, indicator: classes.indicator}}
                    TabIndicatorProps={{ children: <div /> }}
              >
                {renderTabsCaptions()}
              </Tabs>
            </Grid>
          </AppBar>
          {validateIfDataExists() && renderTabsPanels()}
          </>
        </Container>
        {!isCustomerApp && benchmarkConfiguration && benchmarkConfiguration.modal_opened && (
          <BenchmarkModal
            onBenchmarkConfigured={handleBenchmarkConfigured}
            updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
            updateHistoricalData={updateHistoricalData}
            calculationDates={selectedDates}
            benchmarks={chartsSettings.performance.currently_selected_benchmarks}
            selectedPortfolio={portfolio_data}
            onChartSettingsChange={handleChartSettingsChange}
          />
        )}
        <DownloadPdfDialog
          open={exportPdfModalVisible}
          onClose={() => setExportPdfModalVisible(false)}
          onExport={fetchPdfFile}
          exportBtnDisabled={!portfolio_data}
          customer={getCustomerData(customer.data)}
        >
          <DownloadPdfDialog.CoverPage />
          <DownloadPdfDialog.Documents />
          <DownloadPdfDialog.Expanded />
        </DownloadPdfDialog>
    </>
  );
};

export default connect(mapStateToPropsProfView)(withDashboardData(withTradingsData(connect(mapStateToProps)(withNotification(withRouter(ProfessionalView)))), true));