import {makeStyles} from "@material-ui/core/styles";
import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4D4F5C',
    marginRight: 20,
    whiteSpace: 'nowrap',

    [theme.breakpoints.only('sm')]: {
      fontSize: 13
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 12
    },
  },
  expansionPannelContainer: {
    position: 'relative',
    height: FORM_FIELD_HEIGHT,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: 'transparent !important',
    zIndex: 2
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    minHeight: '45px !important',
    height: '45px !important',
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 10,
    backgroundColor: 'white',

    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
      minHeight: '35px !important',
    },
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px !important',
    height: '45px !important',

    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
      minHeight: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontWidth: 'bold',
    fontSize: 18,
    padding: 0,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  },
  expansionPanelDetailsRoot: {
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'transparent !important'
  },
  portfolioListWrap: {
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  portfolioList: {
    backgroundColor: 'white',
    padding: 0,
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    width: 150,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '65vh',
      overflowY: 'auto'
    },
    '& $portfolioListItem': {
      transition: 'all',
      transitionDuration: '0.2s',

      [theme.breakpoints.down('sm')]: {
        padding: 8,
      },

    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    },

    '& $portfolioListItemSelected:hover': {
      cursor: 'pointer',
      backgroundColor: '#D8DCDF'
    },

    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Bold',
      color: theme.palette.primary.main,
      fontSize: 14,
    }
  },
  portfolioListItemSelected: {
    backgroundColor: '#D8DCDF',

    '& > span, & > i': {
      color: '#4D4F5C !important'
    }
  },
  portfolioListCheckbox: {

  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
  timePickerContainer: {
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    position: 'absolute',
    top: -2,
    left: 'calc(100% - 2px)',
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
      left: 'auto',
      right: 0
    },
  },
  calendarIcon: {
    fontSize: 18,
    marginRight: 10
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expandIcon: {
    color: theme.palette.primary.main
  },
  textFieldUnderline: {
    '&::before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover::before': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    }
  },
}))

export const useFilterClasses = makeStyles(theme => ({
  // Date picker filter classes
  container: {
    minHeight: '72px !important',
    height: '72px !important',
    position: 'relative',
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  label: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    fontWeight: 'normal'
  },

  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: 'transparent !important',
    zIndex: 2,
    marginTop: '1px !important'
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryRoot: {
    border: `1px solid #D8DCDF`,
    minHeight: '45px !important',
    height: '45px !important',
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
    boxSizing: 'border-box',
    borderRadius: '4px !important',
    padding: 10,
    backgroundColor: 'white',
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px!important',
    height: '45px!important',
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    color: '#202A38',
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: 16,
    padding: '0px',
  },
  expansionPanelDetailsRoot: {
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'transparent !important'
  },
  portfolioListWrap: {
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  portfolioList: {
    backgroundColor: 'white',
    padding: 0,
    border: `1px solid #D8DCDF`,
    borderTop: 'none',
    width: 150,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '65vh',
      overflowY: 'auto'
    },
    '& $portfolioListItem': {
      transition: 'all',
      transitionDuration: '0.2s',

      [theme.breakpoints.down('sm')]: {
        padding: 8,
      },

    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    },

    '& $portfolioListItemSelected:hover': {
      cursor: 'pointer',
      backgroundColor: '#D8DCDF'
    },

    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#202A38',
    }
  },
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Regular',
      color: '#202A38',
      fontSize: 14,
    }
  },
  portfolioListItemSelected: {
    backgroundColor: '#D8DCDF',

    '& > span': {
      color: '#4D4F5C'
    }
  },
  portfolioListCheckbox: {

  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `#202A38 !important`
    }
  },
  timePickerContainer: {
    backgroundColor: 'white',
    border: `1px solid #D8DCDF !important`,
    position: 'absolute',
    top: -1,
    left: 'calc(100% - 1px)',
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
      left: 'auto',
      right: 0
    },
  },
  expandIcon: {
    color: '#202A38 !important',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    marginRight: '-2px',
  },
  textFieldUnderline: {
    '&::before': {
      borderBottom: `1px solid #D8DCDF`,
    },
    '&:hover::before': {
      borderBottom: `1px solid #D8DCDF !important`,
    }
  },

}))
