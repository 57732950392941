import React from 'react';

import useStyles from './styles';
import useModalStyles from '../ProductsSourceSelectionModal/styles';
import {Dialog, DialogContent, Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import DialogActions from "@material-ui/core/DialogActions";
import InputFormElement from "../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import MultilineInputFormElement
  from "../../../RiskProfiling/components/StepContent/components/formElement/MultilineInputFormElement";
import RadioFormElement from "../../../RiskProfiling/components/StepContent/components/formElement/RadioFormElement";
import {PortfolioHandlerResource} from "../../../../utils/api";
import {displayErrorSnackBar, displaySuccessSnackBar} from "../../../../components/SnackbarProvider/actions";
import {connect} from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import {isValidString, UserUtils} from "../../../../utils/utils";
import {MODEL_PORTFOLIO_TYPES} from "../../../Modelportfolios/constants";

import {getAuthSelector} from "../../../../utils/redaxSelectors";

const COMPARISON_TYPE_VALUES = {
  PRIVATE: '1',
  AGENCY: '2'
};

export const COMPARISON_TYPE_LABEL = {
  [MODEL_PORTFOLIO_TYPES.PERSONAL]: 'Privater Vergleich',
  [MODEL_PORTFOLIO_TYPES.AGENCY]: 'Öffentlicher (Meine Agentur) Vergleich',
  [MODEL_PORTFOLIO_TYPES.PARENT_AGENCY]: 'Öffentlicher (Agentur) Vergleich',
  [MODEL_PORTFOLIO_TYPES.SUB_AGENCY]: 'Öffentlicher (Unteragentur) Vergleich'
};

export const COMPARISON_TYPE_OPTIONS = [
  {
    id: 1,
    value: COMPARISON_TYPE_VALUES.PRIVATE,
    label: COMPARISON_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.PERSONAL]
  }, {
    id: 2,
    value: COMPARISON_TYPE_VALUES.AGENCY,
    label: COMPARISON_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.AGENCY]
  }
];

export const getPreparedComparisonProducts = (comparison, filterNotActive=false) => {
  return (filterNotActive ? comparison.products.filter(p => p.configuration.active) : comparison.products ).map((product, index) => ({
    type: product.type,
    product_id: product.product_id,
    configuration: {...product.configuration, order: index},
  }))
}

function SaveComparisonModal({open, onClose, onSave, comparison, dispatch, comparisonConfiguration, selectedBenchmarks, auth}) {

  const modalClasses = useModalStyles();
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    type: COMPARISON_TYPE_VALUES.PRIVATE,
    name: '',
    description: ''
  });
  const [errors, setErrors] = React.useState({});
  const [savingInProgress, setSavingInProgress] = React.useState(false);

  React.useEffect(() => {
    setFormState({
      type: (comparison.type || COMPARISON_TYPE_VALUES.PRIVATE).toString(),
      name: comparison.name || '',
      description: comparison.description || ''
    })
  }, [JSON.stringify(comparison)])

  const setFormStateField = (fieldName) => (value) => {
    setFormState((current) => ({
      ...current,
      [fieldName]: value
    }));
    setErrors((current) => {
      delete current[fieldName];
      return current;
    })
  }

  const handleCreateComparisonButtonClick = async (redirectToComparisonsList) => {
    try {
      setErrors({});
      setSavingInProgress(true);

      const request = {
        name: formState.name,
        description: formState.description,
        type: formState.type,
        configuration: comparisonConfiguration || {},
        benchmarks: selectedBenchmarks
      }
      if (comparison.id) {
        request.id = comparison.id
      }
      if (!_.isEmpty(comparison.products)){
        request.products = getPreparedComparisonProducts(comparison)
      }

      // products of comparison has 'data' wich is used to fetch performance data
      // after saving it on back-end - update products in response with data
      let comparisonProductIdDataMapping = {};
      (comparison.products || []).map(p => {
        comparisonProductIdDataMapping[`${p.product_id}_${p.type}`] = p.data
      })
      const response = await PortfolioHandlerResource.postCreateProductsComparison(request);
      onSave && onSave({
        ...response,
        products: response.products.map(p => ({
          ...p,
          data: _.get(comparisonProductIdDataMapping, `${p.product_id}_${p.type}`),
        }))
      }, redirectToComparisonsList);
      dispatch(displaySuccessSnackBar('Der Produktvergleich wurde erfolgreich gespeichert.'));
      onClose();
    } catch (error) {
      dispatch(displayErrorSnackBar('Fehler beim Speichern des Produktvergleichs.'));
      setErrors(error.hasOwnProperty('data') ? error.data : error);
    } finally {
      setSavingInProgress(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <div className={modalClasses.header}>
          <span style={{fontWeight: 'bold'}}>Vergleich speichern</span>
          <IconButton disableRipple aria-label="close" className={modalClasses.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: modalClasses.dialogContentRoot}}>
        <Grid container>
          {UserUtils.isChief(auth) && (
            <Grid item xs={12} style={{marginBottom: -20}}>
              <RadioFormElement
                value={formState.type}
                label="Zugriff"
                onChange={setFormStateField('type')}
                options={COMPARISON_TYPE_OPTIONS}
                custom_classes={{
                  labelRoot: classes.formElementLabel
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputFormElement
              value={formState.name}
              required
              label="Name"
              onChange={setFormStateField('name')}
              placeholder={"Bitte geben Sie einen Namen für den Vergleich ein"}
              custom_classes={{
                labelRoot: classes.formElementLabel
              }}
              error={errors.hasOwnProperty('name') && errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineInputFormElement
              value={formState.description}
              label="Beischreibung"
              rows={4}
              onChange={setFormStateField('description')}
              placeholder={"Bitte geben Sie eine Beschreibung ein"}
              customClasses={{
                labelRoot: classes.formElementLabel
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions classes={{root: clsx(modalClasses.dialogActionsRoot, classes.dialogActionsRoot)}}>
        <PrimaryButton
          text={"Abbrechen"}
          variant={"outlined"}
          onButtonClick={onClose}
        />
        {[
          {text: 'Speichern & fortfahren', redirectToComparisonsList: false},
          {text: 'Speichern', redirectToComparisonsList: true}
         ].map(btnConfig => {
          return (
            <PrimaryButton
              text={btnConfig.text}
              onButtonClick={() => handleCreateComparisonButtonClick(btnConfig.redirectToComparisonsList)}
              disabled={savingInProgress || !isValidString(formState.name)}
            />
          )
        })}
      </DialogActions>
    </Dialog>
  )
}


const mapStateToProps = (state) => ({
  auth: getAuthSelector(state)
});

export default connect(mapStateToProps)(SaveComparisonModal);