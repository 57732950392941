import React from 'react';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import _ from 'lodash';
import { Checkbox, Grid, makeStyles } from '@material-ui/core';
import Link from '../../../../components/Link';
import { DetailsIcon, EditIcon, InfoIcon } from '../../../../images';
import {
  getSubSystemConfigItem,
  isSubDepotItem,
  openMorningStartIsin,
  valueOrDash,
  valueOrDashInt,
  withPercentOrDash,
} from '../../../../utils/utils';
import {
  getModelPortfolioType,
  modelPortfolioAccessible,
  acceptanceCriteriasExists,
  getModelPortfolioOwner,
  getInstrName,
} from '../../utils';
import { FilteringAcceptanceCriteriasModal } from '../index';
import { StateCol } from '../../../InvestmentStrategy/List/table-structure';
import { getAssetInternalId, getKeyFieldValue } from '../../../CustomerDashboard/utils';
import {getTableOptionsActionName} from "../../../../components/DashboardTable/utils";
import {SustainabilityCell} from "../../../../utils/commonTableColumns";
import {BcaDeleteIconButton} from "../../../../components/Buttons/BcaIconButton/BcaIconButton";
import ComparisonButton from '../../../CustomerDashboardV2/components_v2/ActionButtons/ComparisonButton';
import IconButton from '@material-ui/core/IconButton';
import WarningTooltip from '../../../../components/WarningTooltip';
import DeleteIcon from '../../../../images/DeleteIcon';
import CompareIcon from '../../../../images/CompareIcon';
import PortfolioBuilder
  from '../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/PortfolioBuilder';
import { avgSRIValue } from '../../Overview/components/ModelPortfolioDetails/utils';
import { ModelPortfolioResource } from '../../../../utils/api';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { PB_KNOWLEDGE_MAPPING } from '../../../RiskProfiling/constants';
import { PB_NO_ASSETS_MSG } from '../../constants';

export const useInstrumentNameCellStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  disabled: {
    cursor: 'inherit',
    pointerEvents: 'none',
    opacity: 0.7
  },
  linkDisabled: {
    color: 'initial',
    textDecoration: 'none',
    cursor: 'initial',
    pointerEvents: 'none'
  }
}));

const useActionsStyles = makeStyles(() => ({
  actionButtonItem: {
    '& .MuiIconButton-root': {
      fontSize: '1em'
    }
  }
}));


export const InstrumentNameCell = {
  header: {
    content: (item, options) => (
      <div style={{display: 'flex', height: 'auto'}}>
        {options && options.onAllSelect && (
          <div>
            <Checkbox
              color="primary" style={{padding:0, marginRight: 8, marginBottom: -16}}
              checked={!!(options.selected.length === options.totalInstrumentsCount && options.totalInstrumentsCount)}
              onChange={() => options.onAllSelect()}
            />
          </div>
        )}
        <div>
          <span>Instrumentenname</span><br/>
          <span style={{fontWeight: 'normal'}}>ISIN/WKN</span>
        </div>
      </div>
    )
  },
  body: {
    content: (item, options) => {

      const classes = useInstrumentNameCellStyles()

      const itemName = getInstrName(item);

      const handleLinkClick = () => {
        openMorningStartIsin(item.isin)
      }

      if (options.loading) {
        return <Skeleton />
      }

      const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false);

      let srri = options.withSrri && valueOrDashInt(item.sri)
      srri = (typeof srri == 'number' && options.clientSRRI)
        ? (<span style={{color: getInstrumentRiskColor(srri, options.clientSRRI)}}>SRI {srri}</span>)
        : srri

      const isCheckedKeyField = options.isCheckedKeyField || getAssetInternalId;
      const isInitialSwitchOutInstrument = options.initialSwitchOutInstrument === item.isin;

      return (
        <div style={{display: 'flex', height: 'auto', width: '100%'}}>
          {options.onInstrumentSelect && (
            <div>
              {!item.disabledByFilter && (
                <Checkbox
                  onChange={() => options.onInstrumentSelect(item)}
                  disabled={isInitialSwitchOutInstrument || (options.rebalancing && !item.weightDifference)}
                  className={isInitialSwitchOutInstrument && classes.disabled}
                  color="primary"
                  style={{padding:0, marginRight: 8, marginBottom: -16}}
                  checked={!!(options.selected && options.selected.find(asset => getKeyFieldValue(asset, isCheckedKeyField) == getKeyFieldValue(item, isCheckedKeyField)))}
                />
              )}
            </div>
          )}
          {!options.hideAcceptanceCriteriasUnderName && acceptanceCriteriasExists(item) && (
            <div>
              <div style={{marginLeft: 3, marginRight: 9, position: 'relative', top: 14}}>
                <InfoIcon color="#80858c" onClick={() => setAcceptanceVisibility(true)}/>
              </div>
              <FilteringAcceptanceCriteriasModal
                open={acceptanceVisible}
                onClose={() => setAcceptanceVisibility(false)}
                instrumentName={itemName || item.isin}
                criterias={item.disabledByAcceptanceCriterias} />
            </div>
          )}
          {/* set min-width to truncate works as expected */}
          <div style={{minWidth: 0}}>
            <p className={classes.title} style={{margin: 0, padding: 0}} title={itemName}>
              <Link title={itemName} onClick={handleLinkClick}/></p>
            <p style={{margin: 0}}>
              <span style={{whiteSpace: 'nowrap'}}>{(item.isin || item.wkn) && <>{item.isin} / <span>{ item.wkn }</span></>} {options.withSrri && (<>/ {srri}</>)}</span>
              {!_.isNil(item.depot_sub_number) && <small><br />Unterdepotnummer: {item.depot_sub_number}</small>}
            </p>
          </div>
        </div>
      )
    }
  },
  key: 'InstrumentNameCell'
}

const ModelportfolioNameCell = {
  header: "Name",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      const itemName = getInstrName(item);

      return (
        <span style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden'}}>
          {item.disabledByFilter ? itemName : <Link title={itemName} onClick={() => options.onEdit(item)} />}
        </span>
      )
    }
  },
  key: 'ModelportfolioNameCell'
}

const TypeCell = {
  header: "Typ/Unternehmen",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return getModelPortfolioType(item)
    }
  },
  key: 'TypeCell'
};

export const OwnerCell = {
  header: "Agentur",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return getModelPortfolioOwner(item)
    }
  },
  key: 'OwnerCell'
};

export const MDDCell = {
  header: {
    content: () => (
      <>
        <span>Maximaler</span>
        <br />
        <span>Wertverlust</span>
      </>
    ),
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />;
      }
      return withPercentOrDash(item.calculated && item.calculated.mdd_3y);
    },
  },
  key: 'MDDCell',
};

const AssetClassCell = {
  header: "Assetklasse",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return item.asset_class
    }
  },
  key: "AssetClassCell"
}

export const SharpeRatioCell = {
  header: {
    content: () => (
      <>
        <span>Sharpe</span><br />
        <span>Ratio</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return valueOrDash(item.calculated && item.calculated.sharpe_ratio_a_3y)
    }
  },
  key: 'SharpeRatioCell'
}

const PAReturnCell = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>p.A.</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return "-"
    }
  },
  key: 'PAReturnCell'
}

export const getInstrumentRiskColor = (instrumentSRRI, clientSRRI) => instrumentSRRI <= clientSRRI ? '#5EC14E' : '#F44638'

export const SRRIColumn = {
  header: 'SRI',
  body: {
    content: (item, options) => {
      if (isSubDepotItem(item)) {
        return ''
      }
      if (options.loading) {
        return <Skeleton />
      }
      const srri = valueOrDashInt(item.sri)
      return (typeof srri == 'number' && options.clientSRRI)
        ? (<span style={{color: getInstrumentRiskColor(srri, options.clientSRRI)}}>{srri}</span>)
        : srri
    }
  },
  key: 'SRRIColumn'
}

export const ESGColumn = {
  header: 'ESG Score',
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (
        <>
          <span>{item.esg_risk_score && valueOrDash(item.esg_risk_score.portfolio_sustainability_score)}</span>
          <span style={{color: '#ADB6BD'}}> / 50</span>
        </>
      )
    }
  },
  key: 'ESGColumn'
}

export const ESGColumnU = {
  header: {
    content: () => (
      <>
        <span>ESG</span><br />
        <span>Score U</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (
        <>
          <span>{item.esg_risk_score && valueOrDash(item.esg_risk_score.historical_corporate_sustainability_score)}</span>
          <span style={{color: '#ADB6BD'}}> / 50</span>
        </>
      )
    }
  },
  key: 'ESGColumnU'
}

export const ESGColumnS = {
  header: {
    content: () => (
      <>
        <span>ESG</span><br />
        <span>Score S</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (
        <>
          <span>{item.esg_risk_score && valueOrDash(item.esg_risk_score.historical_sovereign_sustainability_score)}</span>
          <span style={{color: '#ADB6BD'}}> / 50</span>
        </>
      )
    }
  },
  key: 'ESGColumnS'
}

export const PriceColumn = {
  header: {
    content: () => (
      <>
        <span>Kurs</span><br />
        <span>(EUR)</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (
        valueOrDash(item.calculated && item.calculated.last_price_value)
      )
    }
  },
  key: 'PriceColumn'
}

export const ReturnJahr = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>lfd. Jahr</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (
        withPercentOrDash(item.return_data && item.return_data.return_ytd, false)
      )
    }
  },
  key: 'ReturnJahr'
}

export const ReturnAuflage = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>seit Aufl.</span>
      </>
    )
  },
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }
      return (
        <>
          <span>{ withPercentOrDash(item.return_data && item.return_data.return_inception, false) }</span><br/>
          <span>{ item.inception_date && moment(item.inception_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
        </>
      )
    }
  },
  key: 'ReturnAuflage'
}

export const Return1J = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>1 Jahr</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return withPercentOrDash(item.return_data && item.return_data.return_1y, false)
    }
  },
  key: 'Return1J'
}

export const Return3J = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>3 Jahre</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return withPercentOrDash(item.return_data && item.return_data.return_3y, false)
    }
  },
  key: 'Return3J'
}

export const Return5J = {
  header: {
    content: () => (
      <>
        <span>Rendite</span><br />
        <span>5 Jahre</span>
      </>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return withPercentOrDash(item.return_data && item.return_data.return_5y, false)
    },
  },
  key: 'Return5J'
}

const WeightColumn = {
  header: 'Gewichtung',
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      return withPercentOrDash(parseFloat(item.weight), false)
    },
  },
  key: 'WeightColumn'
}

const ActionsColumn = {
  header: {
    content: (item, options) => {
      const linkRender = () => {
        if (options.itemsLength) {
          return (
            <Link title={"Bearbeiten"} icon={<EditIcon/>}
                  onClick={() => options.onEditWeightsClick && options.onEditWeightsClick()}
            />
          )
        } else {
          return (
            <>
              <EditIcon color={"#ADB6BD"}/> {"ändern"}
            </>
          )
        }
      };

      return (
        <>
          <span>Gewichtung</span>
          {!!options.onEditWeightsClick && (
            <>
              <br />
              <span style={{whiteSpace: 'nowrap'}}>
                {linkRender()}
              </span>
            </>
          )}
        </>
      )
    }
  },
  body: {
    content: (item, options) => (
      <>
        {item.weight && (
          <span>{withPercentOrDash(parseFloat(item.weight), false)}</span>
        )}
        <Link title="Löschen" fontWeight="normal" onClick={() => options.onRemoveAssetClick && options.onRemoveAssetClick(item)}/>
      </>
    )
  },
  key: 'ActionsColumn'
}

const FavoriteListActionColumn = {
  header: '',
  body: {
    content: (item, options) => (
      <BcaDeleteIconButton tooltip="Löschen" onClick={() => options.onRemoveAssetClick && options.onRemoveAssetClick(item)}/>
    )
  },
  key: 'FavoriteListActionColumn'
}

const InstrumentsCountCell = {
  header: {content: <>Anzahl Instrumente</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.assets && item.assets.length;
    }
  },
  key: 'InstrumentsCountCell'
}

export const StartPortfolioBuilderButton = ({item, onlyIcon=true, disabled=false, tooltip, customClasses}) => {
  const [open, setOpen] = React.useState(false);

  if(!item || !getSubSystemConfigItem('extended_model_portfolio', 'portfolio_builder_enabled')) return null;

  const dataService = {
    session_id: `musterdepot-${item.id}`,
    exampleSession: true,
    getPortfolioBuilderInfo: async function(){
      const res = await ModelPortfolioResource.getPortfolioData(item.id, undefined, moment().subtract(1, 'day').format('YYYY-MM-DD'));

      const ptfData = _.get(res, 'instruments_table.data.0.instruments.0');
      const assets = _.get(ptfData, 'components', []);
      if(_.get(ptfData, 'is_empty') || _.isEmpty(assets)) {
        throw Error(PB_NO_ASSETS_MSG);
      }

      return {
        displayFirstName: item.name,
        displayLastName: '',
        knowledgeInAssetGroups: Object.keys(PB_KNOWLEDGE_MAPPING).map((key) => ({
          knowledgeInAsset: key,
          knowledge: true,
        })),
        sri: _.round(avgSRIValue(assets)),
        lossCapacity: "3", // ADDITIONAL (Max)
        esg: { oko: 100, esg: 100, pai: 100 },
        investmentMotive: item.investment_goal,
        investmentDuration: item.time_horizon,
        holdings: assets,
      }
    }
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const openBuilder = () => {
    setOpen(true )
  };

  const _disabled = disabled || open;

  const Icon = () => <CompareIcon color={_disabled && 'rgba(0, 0, 0, 0.26)'} />;

  return (
    <>
      {onlyIcon ? (
        <WarningTooltip
          title={"Portfolio-Builder öffnen"}
          icon={
            <IconButton color={"primary"} onClick={openBuilder} disabled={_disabled}>
              <Icon />
            </IconButton>
          }
        />
      ) : (
        <PrimaryButton
          text={"Portfolio-Builder"}
          variant={"text"}
          onButtonClick={openBuilder}
          icon={<Icon />}
          disabled={_disabled}
          tooltip={tooltip}
          customClasses={customClasses}
        />
      )}
      {open && (
        <PortfolioBuilder
          caseData={{}}
          setCaseData={() => {}}
          closeHandler={closeHandler}
          dataService={dataService}
        />
      )}
    </>
  )
};

const ModelportfolioOverviewActionColumn = {
  header: "",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      const classes = useActionsStyles();
      const itemName = getInstrName(item);
      const detailsTitle = getTableOptionsActionName(options, 'details');
      return (
        <Grid container style={{justifyContent: 'flex-start'}} spacing={1}>
          {!options.viewOnly && options.onExtend && (
            <Grid item className={classes.actionButtonItem}>
              <ComparisonButton
                text="Vergleich öffnen"
                variant={"icon"}
                onButtonClick={() => options.onExtend(item)}
              />
            </Grid>
          )}
          {!options.viewOnly && options.withPortfolioBuilder && (
            <Grid item className={classes.actionButtonItem}>
              <StartPortfolioBuilderButton item={item} />
            </Grid>
          )}
          <Grid item>
            {detailsTitle ? (
              <Link
                title={detailsTitle}
                fontWeight={'normal'}
                onClick={() => options.onEdit(item)}
                disabled={item.disabledByFilter}
              />
            ) : (
              <WarningTooltip
                title={"Musterdepot öffnen"}
                icon={
                  <IconButton color={'primary'} onClick={() => options.onEdit(item)} disabled={item.disabledByFilter}>
                    <DetailsIcon color={item.disabledByFilter ? '#ADB6BD' : '#0092E5'}/>
                  </IconButton>
                }
              />
            )}
          </Grid>
          {options.viewOnly && options.directAddingPossible && (
            <Grid item>
              <Link
                title="Hinzufügen"
                fontWeight={'normal'}
                onClick={() => options.onEdit(item, true)}
                disabled={item.disabledByFilter}
              />
            </Grid>
          )}
          {options.onDelete && modelPortfolioAccessible(item, options.auth) && (
            <Grid item>
              <WarningTooltip
                title={"Musterdepot löschen"}
                icon={
                  <IconButton color={'primary'} onClick={() => options.onDelete(item.id, itemName)}>
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </Grid>
          )}
        </Grid>
      )
    }
  },
  key: 'ModelportfolioOverviewActionColumn'
}

const SelectInstrumentColumn = {
  header: {
    content: (item, options) => {
      return (
        <>
          <Checkbox
            checked={options.isAllChecked && options.isAllChecked() || false}
            onChange={() => options.handleCheckAll && options.handleCheckAll()}
            color="primary" />
        </>
      )
    }
  },
  body: {
    content: (item, options) => (
      <>
        <Checkbox
          checked={options.isItemChecked && options.isItemChecked(item) || false}
          onChange={() => options.handleCheckItem && options.handleCheckItem(item)}
          color="primary" />
      </>
    )
  },
  key: 'SelectInstrumentColumn'
}

export const VaRCell = {
  header: "V@R",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      return (
        <span>{ withPercentOrDash(item.calculated && item.calculated.var_95_3y, false) }</span>
      )
    }
  },
  key: 'VaRCell'
}

export const musterPortfolioInstrumentsTableStructure = {
  default: [{
    content: [InstrumentNameCell],
    orderingFunction: (item) => (getInstrName(item) || '').toLowerCase()
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right',
    orderingFunction: (item) => item.sri || 0
  }, {
    content: [ReturnJahr],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_ytd) || 0
  }, {
    content: [ReturnAuflage],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_inception) || 0
  }, {
    content: [Return1J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_1y) || 0
  }, {
    content: [Return3J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_3y) || 0
  }, {
    content: [Return5J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_5y) || 0
  }, {
    content: [ESGColumnU],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_corporate_sustainability_score) || 0
  }, {
    content: [ESGColumnS],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_sovereign_sustainability_score) || 0
  }, {
    content: [SharpeRatioCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.sharpe_ratio_a_3y) || 0
  }, {
    content: [MDDCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.mdd_3y) || 0
  }, {
    content: [WeightColumn],
    align: 'right',
    orderingFunction: (item) => (parseFloat(item.weight)) || 0
  }]
}

export const favoriteListDetailsTableStructure = {
  default: [{
    content: [InstrumentNameCell],
    orderingFunction: (item) => (getInstrName(item) || '').toLowerCase()
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right',
    orderingFunction: (item) => item.sri || 0
  }, {
    content: [ReturnJahr],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_ytd) || 0
  }, {
    content: [ReturnAuflage],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_inception) || 0
  }, {
    content: [Return1J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_1y) || 0
  }, {
    content: [Return3J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_3y) || 0
  }, {
    content: [Return5J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_5y) || 0
  }, {
    content: [ESGColumnU],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_corporate_sustainability_score) || 0
  }, {
    content: [ESGColumnS],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_sovereign_sustainability_score) || 0
  }, {
    content: [SharpeRatioCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.sharpe_ratio_a_3y) || 0
  }, {
    content: [MDDCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.mdd_3y) || 0
  }]
}

export const modelportfoliosOverviewTableStructure = {
  default: [{
    content: [StateCol]
  }, {
    content: [ModelportfolioNameCell],
    orderingFunction: (item) => (getInstrName(item) || '').toLowerCase()
  }, {
    content: [TypeCell],
    orderingFunction: (item) => getModelPortfolioType(item)
  }, {
    content: [OwnerCell],
    orderingFunction: (item) => getModelPortfolioOwner(item)
  }, {
    content: [InstrumentsCountCell],
    align: 'center',
    orderingFunction: (item) => item.assets && item.assets.length
  },
  {
    content: [ModelportfolioOverviewActionColumn]
  }]
}

export const tableStructure = {
  default: [{
    content: [InstrumentNameCell]
  }, {
    content: [SRRIColumn],
    align: 'right'
  }, {
    content: [ReturnJahr],
    align: 'right'
  }, {
    content: [ReturnAuflage],
    align: 'right'
  }, {
    content: [Return1J],
    align: 'right'
  }, {
    content: [Return3J],
    align: 'right'
  }, {
    content: [Return5J],
    align: 'right'
  }, {
    content: [ESGColumnU],
    align: 'right',
  }, {
    content: [ESGColumnS],
    align: 'right',
  }, {
    content: [SharpeRatioCell],
    align: 'right'
  }, {
    content: [MDDCell],
    align: 'right'
  }, {
    content: [ActionsColumn],
    align: 'right'
  }]
}

export const favoriteListTableStructure = {
  default: [{
    content: [InstrumentNameCell]
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right'
  }, {
    content: [ReturnJahr],
    align: 'right'
  }, {
    content: [ReturnAuflage],
    align: 'right'
  }, {
    content: [Return1J],
    align: 'right'
  }, {
    content: [Return3J],
    align: 'right'
  }, {
    content: [Return5J],
    align: 'right'
  }, {
    content: [ESGColumnU],
    align: 'right',
  }, {
    content: [ESGColumnS],
    align: 'right',
  }, {
    content: [SharpeRatioCell],
    align: 'right'
  }, {
    content: [MDDCell],
    align: 'right'
  }, {
    content: [FavoriteListActionColumn],
    align: 'right'
  }]
}

export default tableStructure