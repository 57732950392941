import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  customerListSection: {
    paddingLeft: 15,
    paddingTop: 0,

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 15
    }
  },
  backLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    lineHeight: '52px',

    '& > i': {
      fontSize: 12,
      marginRight: 5
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  notFoundContainer: {
    backgroundColor: 'transparent',
    minHeight: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  notFoundStatusCode: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto-Bold',
    fontSize: 200,
    color: '#00000033'
  },
  notFoundHelperText: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto-Medium',
    fontSize: 20,
    color: '#4D4F5C'
  },
  radioGroup: {
    flexDirection: 'row!important',
    justifyContent: 'space-between',
    color: '#636A7',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'bold'
  },
  zeroMarginBottom: {
    marginBottom: 0
  }
}));