import {makeStyles} from '@material-ui/core'

const styles = (theme) => ({
  controlsWrapper: {
    padding: 15
  },
  inputLabelRoot: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 15
    }
  }
})

export default makeStyles(styles)