import { FORM_FIELD_HEIGHT } from '../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default (theme) => ({
    expansionPannelContainer: {
      position: 'relative',
      height: FORM_FIELD_HEIGHT,
      width: '100%',
  
      [theme.breakpoints.down('sm')]: {
        height: 35,
      },
    },
    expansionPanelRoot: {
      borderRadius: '2px !important',
      height: FORM_FIELD_HEIGHT,
      width: '100%',
      boxSizing: 'border-box',
      boxShadow: 'none',
      zIndex: 2,
      backgroundColor: 'transparent !important',
  
      [theme.breakpoints.down('sm')]: {
        height: 35,
      },
    },
    expansionPanelExpanded: {
      height: 'auto',
      zIndex: 1000,
      transform: 'translate3d(0,0,1px)'
    },
    expansionPanelSummaryRoot: {
      border: `2px solid ${theme.palette.primary.main}`,
      minHeight: '45px !important',
      height: '45px !important',
      boxSizing: 'border-box',
      borderRadius: '2px !important',
      padding: 10,
  
      [theme.breakpoints.down('sm')]: {
        minHeight: '35px !important',
        height: '35px !important',
      },
    },
    expansionPanelSummaryExpanded: {
      minHeight: '45px !important',
      height: '45px !important',
  
      [theme.breakpoints.down('sm')]: {
        minHeight: '35px !important',
        height: '35px !important',
      },
    },
    expansionPanelSummaryContent: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Bold',
      fontWaight: 'bold',
      fontSize: 18,
      padding: '0px',
      overflow: 'hidden',
  
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
  
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      },
    },
    expansionPanelDetailsRoot: {
      border: `2px solid ${theme.palette.primary.main}`,
      boxSizing: 'border-box',
      borderRadius: '2px !important',
      padding: 0,
      backgroundColor: 'white',
      borderTop: 'none',
      flexDirection: 'column'
    },
    portfolioList: {
      padding: 0,
      width: '100%',
  
      '& ul li': {
        transition: 'all',
        transitionDuration: '0.2s',
        borderBottom: '1px solid #E1E3E9',
        padding: '0'
      },
      '& ul li:hover': {
        cursor: 'pointer',
        backgroundColor: '#EFEFF2'
      },
      '&::-webkit-scrollbar': {
        width: 3
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    scrollableList: {
    },
    portfolioListItem: {
      '& span': {
        fontSize: 16
      }
    },
    '@keyframes placeholderShimmer': {
      from: {
        backgroundPosition: '-468px 0',
      },
      to: {
        backgroundPosition: '468px 0',
      }
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nowrap: {
      whiteSpace: 'nowrap',
    }
  });