import React from "react";
import _ from "lodash";
import {Switch, withRouter} from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';

import ProtectedRoute from "../../components/PrivateRoute";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";

import {PERMISSION_TYPE} from "../../utils/constants";
import {FinciteResource, PortfolioHandlerResource} from "../../utils/api";
import CustomerDashboard from "../CustomerDashboard/CustomerDashboard";
import CustomerDashboardV2 from "../CustomerDashboardV2/CustomerDashboard";
import {
  ModelportfolioList,
  ModelportfolioOverview
} from '../Modelportfolios';

import ModelportfolioOverviewV2 from '../Modelportfolios/Overview/OverviewV2';

import ModelPortfolioNewCreate from '../ModelPortfolioNewCreate'

import SnackbarProvider from '../../components/SnackbarProvider'
import {
  FavoriteList,
  FavoriteListCreate,
  FavoriteListOverview
} from '../FavoriteList';
import TransactionsOverview from '../TransactionsMonitoring/TransactionsMonitoring';
import {OtherAssetsBrokerView} from "../OtherAssets";
import {RiskProfiling} from "../RiskProfiling";
import AssetSearch from '../AssetSearch';
import FormCenter from '../DocumentCenter'
import styles from "./styles";
import { getSubSystemConfigItem } from '../../utils/utils';
import connect from "react-redux/lib/connect/connect";
import {addRoutes, setNewAlertingEventsCount} from './actions'
import { NEW_ORIGIN_KEY, setInStorage, getFromStorage } from '../../utils/storage'
import { DivaManager, DivaHelper } from '../../utils/diva'
import withCustomersSelector from '../../components/CustomersSelectorProvider'
import ErrorModal from '../../components/ErrorModal'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../containers/InvestmentPlatform/utils'
import {getMenuItems, ROUTES} from "../../routes";
import { GroupCreatePage, GroupEditPage } from '../Groups';
import { CustomersDataProvider } from '../../components/CustomersDataProvider';
import Landing from '../Landing'
import LegalDocumentsSigningStatus from '../LegalDocumentsSigningStatus'
import {brokerLogout} from "../Authentication/Auth/utils";
import Trades from "../Trades";
import InvestmentStrategiesList from '../InvestmentStrategy/List/List';
import InvestmentStrategiesOverview from '../InvestmentStrategy/Overview/Overview';
import InvestmentStrategiesOverviewV2 from '../InvestmentStrategy/Overview/OverviewV2';
import ProfessionalView from "../ProfessionalView/ProfessionalView";
import RiskDashboard from '../RiskDashboard';
import RiskDashboardDetails from '../RiskDashboard/RiskDashboardDetails';
import SingleReporting from '../SingleReporting';
import GroupReporting from '../GroupReporting';
import DashboardSettings from '../DashboardSettings';
import BrokerDashboardSettings from '../DashboardSettings/BrokerDashboardSettings';
import CustomerDashboardSettings from '../DashboardSettings/CustomerDashboardSettings';
import {DASHBOARD_SETTINGS_TYPE} from "../DashboardSettings/constants";
import SignedDocumentsApproval from "../SignedDocumentsApproval";
import AlarmEvents from "../AlarmEvents/AlarmEvents";
import SeriesReporting from '../SeriesReporting';
import {useAppContext} from "../App";
import RiskDashboardAllCustomersDetails from "../RiskDashboard/RiskDashboardAllCustomersDetails";
import useFetchData from "../../hooks/useDataFetch";
import ProductComparison from "../ProductComparison/ProductComparison";
import ProductComparisonNew from "../ProductComparison/ProductComparisonNew";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import {DIVA_URL_TYPE} from "./constants";

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
});

const getComponentClass = (componentId) => {
  switch (componentId) {
    case 'DASHBOARD':
      return getSubSystemConfigItem('reporting', 'use_v2') ? CustomerDashboardV2 : CustomerDashboard;
    case 'OTHER_ASSETS':
      return OtherAssetsBrokerView;
    case 'MODELPORTFOLIO_CREATE':
    case 'MODELPORTFOLIO_EDIT':
      return ModelPortfolioNewCreate;
    case 'MODELPORTFOLIO_LIST':
      return ModelportfolioList;
    case 'MODELPORTFOLIO_LIST_OLD':
      return [ModelportfolioList, {detailsComponentId: 'MODELPORTFOLIO_OVERVIEW_OLD'}];
    case 'TRANSACTIONS_OVERVIEW':
      return TransactionsOverview;
    case 'RISK_PROFILING':
      return RiskProfiling;
    case 'FAVORITE_LIST_CREATE':
      return FavoriteListCreate;
    case 'FAVORITE_LIST_EDIT':
         return FavoriteListCreate;
    case 'FAVORITE_LIST':
      return FavoriteList;
    case 'FAVORITE_LIST_OVERVIEW':
      return FavoriteListOverview;
    case 'MODELPORTFOLIO_OVERVIEW':
      return getSubSystemConfigItem('reporting', 'use_v2') ? ModelportfolioOverviewV2 : ModelportfolioOverview;
    case 'MODELPORTFOLIO_OVERVIEW_OLD':
      return ModelportfolioOverview;
    case 'FORM_CENTER':
      return FormCenter;
    case 'ASSET_SEARCH':
      return AssetSearch;
    case 'ESIGN_STATUS':
      return LegalDocumentsSigningStatus;
    case 'TRADE_DETAILS':
      return Trades;
    case 'INVESTMENT_STRATEGIES_LIST':
      return InvestmentStrategiesList;
    case 'INVESTMENT_STRATEGIES_OVERVIEW':
      return getSubSystemConfigItem('reporting', 'use_v2') ? InvestmentStrategiesOverviewV2 : InvestmentStrategiesOverview
    case 'INVESTMENT_STRATEGIES_LIST_OLD':
      return [InvestmentStrategiesList, {detailsComponentId: 'INVESTMENT_STRATEGIES_OVERVIEW_OLD'}];
    case 'INVESTMENT_STRATEGIES_OVERVIEW_OLD':
      return InvestmentStrategiesOverview;
    case 'PRO_VIEW':
      return ProfessionalView;
    case 'RISK_INDICATOR':
      return RiskDashboard;
    case 'RISK_INDICATOR_OVERVIEW':
      return RiskDashboardDetails;
    case 'RISK_INDICATOR_ALL_CUSTOMERS_OVERVIEW':
      return RiskDashboardAllCustomersDetails;
   //  deprecated in BCA-5734
    case 'SINGLE_REPORTING':
      return SingleReporting;
    case 'GROUP_REPORTING':
      return GroupReporting;
    //
    case 'SERIES_REPORTING':
      return SeriesReporting;
    case 'BROKER_DASHBOARD_SETTINGS':
      return [BrokerDashboardSettings, {newDesign: getSubSystemConfigItem('reporting', 'use_v2')}];
    case 'CUSTOMER_DASHBOARD_SETTINGS':
      return [CustomerDashboardSettings, {newDesign: getSubSystemConfigItem('reporting', 'use_v2')}];
    case 'DEFAULT_DASHBOARD_SETTINGS':
      return [DashboardSettings, {newDesign: getSubSystemConfigItem('reporting', 'use_v2')}];
    case 'ESIGN_APPROVAL_LIST':
      return SignedDocumentsApproval;
    case 'ESIGN_APPROVAL_LIST_APPROVER':
      return [SignedDocumentsApproval, {approverView: true}];
    case 'ASSETS_ALERTING_EVENTS_OVERVIEW':
      return AlarmEvents;
    case 'PRODUCTS_COMPARISON':
      return ProductComparison;
    case 'PRODUCTS_COMPARISON_NEW':
      return ProductComparisonNew;
    case 'DASHBOARD_V2':  // TODO: now used for "old" dashboard -> remove after new design confirmed
      return CustomerDashboard;
    case 'BROKER_DASHBOARD_SETTINGS_V2':
      return [BrokerDashboardSettings, {newDesign: false}];
    case 'CUSTOMER_DASHBOARD_SETTINGS_V2':
      return [CustomerDashboardSettings, {newDesign: false}];
    case 'DEFAULT_DASHBOARD_SETTINGS_V2':
      return [DashboardSettings, {newDesign: false}];
    default:
      return () => null;
  }
};

export function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: href,
  }).click();
}

function handleUrlAsFormSubmit(href) {
  const form = document.createElement('form');
  form.action = href;
  form.method = 'post';
  form.target = '_blank';
  document.body.appendChild(form);
  form.submit();
}

const getCustomersSelector = (buttonTitle, onButtonClick) => {
  return withCustomersSelector(undefined, buttonTitle, onButtonClick)(() => (<span></span>))
}

const ALERT_TYPE = {
  ASSET: 1,
  DEPOT: 2
}

const InvestmentPlatform = (props) => {
  const {
    classes,
    match: {
      path
    },
    auth,
    investmentPlatform
  } = props;

  const [menuConfig, setMenuConfig] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = React.useState(auth.user);
  const [userLogo, setUserLogo] = React.useState(null);
  const [divaRoutesConfig, setDivaRoutesConfig] = React.useState();
  const [customersListConfigs, setCustomersListConfigs] = React.useState({
    buttonTitle: '',
    visible: false,
    route: undefined
  });
  const [errorModalMessage, setErrorModalMessage] = React.useState()
  const [activeItem, setActiveItem] = React.useState();

  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())

  /** Routes, that are not configurable through Django Admin */
  const [createGroupRoute, setCreateGroupRoute] = React.useState()
  const [editGroupRoute, setEditGroupRoute] = React.useState()

  const [createBrokerDashboardGroupRoute, setCreateBrokerDashboardGroupRoute] = React.useState()
  const [editBrokerDashboardGroupRoute, setEditBrokerDashboardGroupRoute] = React.useState()

  const [createCustomerDashboardGroupRoute, setCreateCustomerDashboardGroupRoute] = React.useState()
  const [editCustomerDashboardGroupRoute, setEditCustomerDashboardGroupRoute] = React.useState()

  const [alertingNotifications, fetchAlertingNotifications] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/asset/alert-configuration/events/notifications/`, 'get', undefined, true)

  const { onMenuItemChange } = useAppContext();

  React.useEffect(() => {
    fetchAlertingNotifications()
    fetchMenuConfiguration();
    fetchDivaData();
    fetchDivaRoutes();
    fetchBrokerLogo();
    handleRedirectUrl();
    return () => {}
  }, []);

  React.useEffect(() => {

    if (alertingNotifications.loading || alertingNotifications.errors || !_.get(alertingNotifications, 'data.notifications')) {
      return
    }

    let depot_notifications_count = 0;
    let asset_notifications_count = 0;
    alertingNotifications.data.notifications.forEach((notification) => {
      if (notification.type_value == ALERT_TYPE.DEPOT) {
        depot_notifications_count = notification.count_new;
      } else {
        asset_notifications_count = notification.count_new;
      }
    })

    alertingNotifications.data && props.dispatch(setNewAlertingEventsCount({
      depot_notifications_count,
      asset_notifications_count,
      total: depot_notifications_count + asset_notifications_count
    }))

  }, [alertingNotifications.updatedAt])

  React.useEffect(() => {
    let _routes = [];

    menuConfig.forEach(menuItem => {
      if (menuItem.internal) {

        if (menuItem.component && !menuItem.children) {
          _routes.push(menuItem);
        }

        menuItem.children && menuItem.children.forEach(item => {
          if (item.component && !item.children) {
            _routes.push(item);
          }

          item.children && item.children.forEach(subItem => {
            _routes.push(subItem);
          })
        })
      }
    })

    setRoutes(_routes);
  }, [menuConfig]);

  /** Set internal routes configuration */
  React.useEffect(() => {
    // for groups
    executeIfPathExist(investmentPlatform.routes, 'SERIES_REPORTING', (dynamicPath) => {
      let path = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.GROUP_CREATE.newDesignPath
      let editGroupPath = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.GROUP_UPDATE.newDesignPath
      setCreateGroupRoute(path)
      setEditGroupRoute(editGroupPath)
    })
    // dashboard groups for broker dashboard settings menu itme
    executeIfPathExist(investmentPlatform.routes, 'BROKER_DASHBOARD_SETTINGS', (dynamicPath) => {
      let path = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_CREATE.newDesignPath
      let editGroupPath = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_UPDATE.newDesignPath
      setCreateBrokerDashboardGroupRoute(path)
      setEditBrokerDashboardGroupRoute(editGroupPath)
    })
    // dashboard groups for customer dashboard settings menu itme
    executeIfPathExist(investmentPlatform.routes, 'CUSTOMER_DASHBOARD_SETTINGS', (dynamicPath) => {
      let path = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_CREATE.newDesignPath
      let editGroupPath = `/${investmentDynamicPath}` + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_UPDATE.newDesignPath
      setCreateCustomerDashboardGroupRoute(path)
      setEditCustomerDashboardGroupRoute(editGroupPath)
    })
  }, [investmentPlatform])

  /** DIVA integration prototype */
  const fetchDivaData = () => {

    let origin = getOrigin()

    let url = `${origin}/Mitteilungen?seite=1&zeilen=1`

    fetch(url, {
      'credentials': 'include'}).then(response => response.json()).then(
        data => {
          setUserData({...userData, ...data});
        }).catch(error => console.error(error));
  }

  const getOrigin = () => {
    let origin = 'http://dev10.diva.dev.bcaag.de'
    let newOrigin = getFromStorage(NEW_ORIGIN_KEY)
    if (newOrigin) {
      origin = newOrigin
    } else {
      let search = window.location.search;
      search = search.substring(1)
      let searchParam = new URLSearchParams(search);

      let urlOrigin = searchParam.get('origin')
      if (urlOrigin) {
        origin = urlOrigin
      }
    }

    return origin
  }

  const fetchDivaRoutes = async () => {

    let origin = getOrigin()


    let divaManager = new DivaManager(origin)
    let divaHelper = new DivaHelper()

    let routesTree = await divaManager.getSidebarConfiguration()
    let routesArray = divaHelper.prepareListOfRoutes(routesTree)

    setDivaRoutesConfig(routesArray)

  }

  const fetchBrokerLogo = () => {
    FinciteResource.at('get-broker-logo/').get().then(logo_src => setUserLogo(logo_src));
  };

  const fetchMenuConfiguration = async () => {
    let response = await getMenuItems(auth)
    setMenuConfig(response);
    props.dispatch(addRoutes(response))
  }

  const handleTriggerMenuClick = () => {
    setOpen(!open);
    var resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  };

  const handleRootItemClick = () => {
    setOpen(true);
  };

  const handleItemSelected = (item) => {
    setCustomersListConfigs({
      buttonTitle: '',
      visible: false,
      route: undefined
    })
    setActiveItem(item)
  }

  React.useEffect(() => {
    if (activeItem) onMenuItemChange && onMenuItemChange(activeItem)
  }, [activeItem])

  const handleRedirectUrl = () => {
    let search = window.location.search;
    search = search.substring(1)
    let searchParam = new URLSearchParams(search);

    let origin = searchParam.get('origin')
    if (origin) {
      setInStorage(NEW_ORIGIN_KEY, origin)
    }
  }

  /**
   * Handler, that will be triggered, when link with diva conf will be clicked.
   *
   * @param {object} item
   */
  const handleDivaLinkClick = async (item) => {
    if ((item.diva_id || item.diva_id == 0) && item.diva_label && divaRoutesConfig) {
      let itemKey = `${item.diva_id}-${item.diva_label}`

      let itemConf = divaRoutesConfig.find(conf => {
        let confKey = `${conf.id}-${conf.label}`
        return confKey == itemKey
      })

      if (itemConf) {

        if (itemConf.typ == 0 && itemConf.url) {
          openInNewTab(itemConf.url);
        } else if (itemConf.typ == 2) {
          if (itemConf.kundendatenAnfordern == 0) {
            await _openDivaRouteURL(itemConf);
          } else if (itemConf.kundendatenAnfordern == 2) {
            setCustomersListConfigs({
              visible: true,
              buttonTitle: itemConf.label,
              route: itemConf
            })
          }
        }

      } else {
        setErrorModalMessage(`Keine Route für die definierte Menüpunkt "${item.diva_id}-${item.diva_label}" gefunden`)
      }
    }
  }

  const _openDivaRouteURL = async (itemConf) => {

    let origin = getOrigin();
    let divaManager = new DivaManager(origin);
    let route = await divaManager.getRouteUrl(itemConf);

    if (route.url) {
      if (itemConf.urlType == DIVA_URL_TYPE.GET) {
        openInNewTab(route.url);
      } else if (itemConf.urlType == DIVA_URL_TYPE.POST_FORM) {
        handleUrlAsFormSubmit(route.url);
      }
    } else {
      setErrorModalMessage(route.message)
    }
  }

  const handleCustomerSelected = async (customerId) => {

    let itemConf = customersListConfigs.route;
    itemConf.kundenId = customerId;
    await _openDivaRouteURL(itemConf);
  }

  const handleLogoutClick = () => {
    let origin = getOrigin();
    let divaManager = new DivaManager(origin);
    brokerLogout(divaManager.buildLogoutUrl());
  };

  const renderCustomersList = () => {
    let CustomerSelectorClass = getCustomersSelector(customersListConfigs.buttonTitle, handleCustomerSelected)

    return <CustomerSelectorClass />
  }

  const handleErrorModalClose = () => {
    setErrorModalMessage(undefined)
  }

  return (
    <CustomersDataProvider>
      <SnackbarProvider />
      <div className={classes.container}>
        <Header routes={routes} handleLogoutClick={handleLogoutClick} onTriggerMenuClick={handleTriggerMenuClick} userData={userData} userLogo={userLogo} open={open && activeItem}/>
        <div className={classes.main}>
          <SideMenu
            menuConfig={menuConfig}
            onRootItemClick={handleRootItemClick}
            open={open}
            onDivaLinkClick={handleDivaLinkClick}
            onItemSelected={handleItemSelected}
          />
          <div className={classes.menuModal} id="side-menu-modal"/>
          {customersListConfigs.visible ? (
            <div className={classes.content} id="app-main">
              { renderCustomersList() }
            </div>
          ) : (
             <div className={classes.content} id="app-main">
               <div className={classes.contentWrapper}>
                <Switch>
                {createGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={createGroupRoute}
                    component={GroupCreatePage}
                    exact
                  />
                )}
                {createBrokerDashboardGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={createBrokerDashboardGroupRoute}
                    component={GroupCreatePage}
                    dashboardGroupType={DASHBOARD_SETTINGS_TYPE.BROKER_GROUP}
                    exact
                  />
                )}
                {createCustomerDashboardGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={createCustomerDashboardGroupRoute}
                    component={GroupCreatePage}
                    dashboardGroupType={DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP}
                    exact
                  />
                )}
                {editGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={editGroupRoute}
                    component={GroupEditPage}
                    exact
                  />
                )}
                {editBrokerDashboardGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={editBrokerDashboardGroupRoute}
                    component={GroupEditPage}
                    dashboardGroupType={DASHBOARD_SETTINGS_TYPE.BROKER_GROUP}
                    exact
                  />
                )}
                {editCustomerDashboardGroupRoute && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={editCustomerDashboardGroupRoute}
                    component={GroupEditPage}
                    dashboardGroupType={DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP}
                    exact
                  />
                )}
                {routes.map(item => {
                  let props = {}
                  let component = getComponentClass(item.component)
                  // We assume, that in case array is returned - first
                  // element is component and second is additional props
                  if (_.isArray(component)) {
                    [component, props] = component
                  }
                  return (
                    <ProtectedRoute
                      key={item.id}
                      authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                      path={path + item.full_path}
                      component={component}
                      {...props}
                    />
                  )
                })}
                {routes.length && (
                  <ProtectedRoute
                    authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                    path={'/'}
                    component={Landing}
                  />
                )}

                </Switch>
               </div>
               <div id="sticky_navigation_portal" className={classes.stickyNavigation} />
            </div>
          )}
        </div>
      </div>
      {errorModalMessage && (
        <ErrorModal
          message={errorModalMessage}
          handleClose={handleErrorModalClose}
        />
      )}
    </CustomersDataProvider>
  )
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(InvestmentPlatform)));