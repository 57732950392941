import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clsx from "clsx";

/* Material-UI Components */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {withStyles} from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

/* BCA modules */
import styles from './styles';
import {
  PERFORMANCE_TIME_TYPE,
  REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE
} from '../../../../utils/constants';
import DateRange from "../../../../components/DateRange/DateRange";
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import {datesEqual, getNextBusinessDateForRange} from "../../../../utils/utils";
import WarningTooltip from "../../../../components/WarningTooltip";
import SelectCaret from '../../../../components/Icons/SelectCaret';


require('moment/locale/de');
moment.locale('de');


const SettingsTimeSelector = React.memo((props) => {
  const {
    calculationDates,
    handleCalculationDatesChanged,
    classes,
    loading,
    stickyContainer,
    iconComponent,
    minDateErrorMessage,
    datePickerHeader,
    selectedDefaultTimeType,
    businessDaysOnly
  } = props;

  const selectedPerformanceType = PERFORMANCE_TIME_TYPE[REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING[selectedDefaultTimeType]];
  const [expanded, setExpanded] = useState(false);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [performanceTimeType, setPerformanceTimeType] = useState(selectedPerformanceType);
  const [businessDatesUsed, setBusinessDatesUsed] = useState(false);

  const node = React.useRef();

  useOnClickOutside(node, () => {
    if (expanded && !datePickerOpened) {
      setExpanded(false)
    }
  });

  useEffect(() => {
    if(performanceTimeType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value && expanded){
      setDatePickerOpened(true)
    }
  }, [performanceTimeType.value, expanded])


  React.useEffect(() => {
    if (!businessDaysOnly || !performanceTimeType
      || [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE].includes(performanceTimeType.value)
      || (!calculationDates.start && !calculationDates.end)) {
      setBusinessDatesUsed(false)
      return
    }

    const performanceTimeTypeRange = performanceTimeType.getDateRange();
    setBusinessDatesUsed(!datesEqual(performanceTimeTypeRange.start, calculationDates.start) || !datesEqual(performanceTimeTypeRange.end, calculationDates.end))

  }, [calculationDates, performanceTimeType, businessDaysOnly])

  const handleClickListItem = (key) => {

    if (performanceTimeType.value !== PERFORMANCE_TIME_TYPE[key].value) {
      setPerformanceTimeType(PERFORMANCE_TIME_TYPE[key]);

      if (PERFORMANCE_TIME_TYPE[key].value !== PERFORMANCE_TIME_TYPE.CUSTOM.value) {
        setExpanded(false);
        setDates(PERFORMANCE_TIME_TYPE[key].getDateRange(), PERFORMANCE_TIME_TYPE[key]);
      }
    }
  };

  const setDates = (dates, selectedTimeType) => {
    if (dates.start && dates.end && selectedTimeType.value !== CUSTOM_PERFORMANCE_TIME_TYPE_VALUE && businessDaysOnly) {
      getNextBusinessDateForRange(dates);
    }
    handleCalculationDatesChanged(dates, selectedTimeType);
  };

 
  const renderPerformanceTimeTypesList = () => {
    
    const startDate = undefined; //to show all avaliable date ranges
    const selectedDateRange = performanceTimeType.getDateRange();
    const selectedStartDateRange = selectedDateRange && selectedDateRange.start;

    if (startDate && selectedStartDateRange && startDate.isAfter(selectedStartDateRange)) {
      setPerformanceTimeType(PERFORMANCE_TIME_TYPE.BEGINNING);
      return setDates(PERFORMANCE_TIME_TYPE.BEGINNING.getDateRange(), PERFORMANCE_TIME_TYPE.BEGINNING);
    }

    return Object.keys(PERFORMANCE_TIME_TYPE)
    .filter(key => {
      if (startDate) {
        const dateRange = PERFORMANCE_TIME_TYPE[key].getDateRange();
        const startDateRange = dateRange && dateRange.start || startDate;
        
        return startDateRange.isSameOrAfter(startDate);
      }
      return true;
    })
    .map(key => {
    const isSelected = performanceTimeType.value === PERFORMANCE_TIME_TYPE[key].value;
      return (
        <ListItem
          key={PERFORMANCE_TIME_TYPE[key].value}
          className={clsx(classes.portfolioListItem, isSelected && classes.portfolioListItemSelected)}
          onClick={() => handleClickListItem(key)}
        >
          <span>{PERFORMANCE_TIME_TYPE[key].description}</span>
        </ListItem>
      );
    });
  };

  return (
    <div className={classes.container} ref={node} id={stickyContainer ? "date-picker-dropdown-sticky" : "date-picker-dropdown"}>
      <span className={classes.label}>{datePickerHeader}</span>
      <div className={classes.expansionPannelContainer}>
        <Accordion id="date-picker" TransitionProps={{timeout: 0}} expanded={expanded} onChange={() => setExpanded(!expanded)} classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded
        }} disabled={loading}>
          <AccordionSummary
            expandIcon={
              <span className={classes.expandIcon}>
                {iconComponent || <SelectCaret />}
              </span>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            IconButtonProps={{
              disableRipple: true
            }}
            classes={{
              root: classes.expansionPanelSummaryRoot,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent
            }}
          >
            <CalendarTodayIcon className={classes.calendarIcon} />
            <span className={classes.ellipsis}>
              {calculationDates.start && calculationDates.end ? (
                `${calculationDates.start.format('DD.MM.YYYY')} - ${calculationDates.end.format('DD.MM.YYYY')}`
              ) : (
                'Seit Investmentbeginn'
              )}
            </span>
            {businessDatesUsed && (
              <WarningTooltip title={"Da für handelsfreie Tage keine Preisdaten zur Verfügung stehen, wird automatisch der nächstmögliche Handelstag herangezogen, falls der gewählte Zeitraum an einem handelsfreien Tag beginnt oder endet."}/>
            )}
          </AccordionSummary>
          <AccordionDetails classes={{
            root: classes.expansionPanelDetailsRoot,
          }}>
            <div className={classes.portfolioListWrap}>
              <List className={classes.portfolioList}>
                {renderPerformanceTimeTypesList()}
              </List>
              {performanceTimeType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value && (
                <div className={classes.timePickerContainer}>
                  <DateRange
                    start={(calculationDates && calculationDates.start)}
                    end={(calculationDates && calculationDates.end)}
                    onChange={(dateRange) => {
                      setExpanded(false);
                      setDates(dateRange, performanceTimeType);
                    }}
                    minDateErrorMessage={minDateErrorMessage}
                    onOpen={() => setDatePickerOpened(true)}
                    onClose={() => setDatePickerOpened(false)}
                    classes={{
                      textFieldUnderline: classes.textFieldUnderline
                    }}
                    businessDaysOnly={businessDaysOnly}
                  />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
});


export default withStyles(styles, {withTheme: true})(SettingsTimeSelector);