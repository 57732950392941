import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import moment from 'moment';


import {parseResponse, PortfolioHandlerResource, VirtualPortfolioHandlerResource} from "../../utils/api";
import {
  CUSTOMER_ID_KEY,
  getFromStorage,
  PROF_VIEW_PORTFOLIO_DATA,
  PROF_VIEW_REPORT_TYPE,
  SHARED_SETTINGS_KEY,
} from '../../utils/storage';
import {
  isCumulativeDisplayEnabled,
  saveCustomerId,
  saveCustomerIdByPathName
} from "../../containers/CustomerDashboard/utils";
import {buildCustomerDashboardLink} from '../../routes'
import {reportTypeChanged} from "../../containers/CustomerDashboard/actions";
import {
  BEGINNING,
  BEGINNING_PERFORMANCE_TIME_TYPE_VALUE,
  CUSTOM,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE,
  DEFAULT_PERFORMANCE_TIME_TYPE,
  LONGER_PORTFOLIO_COUNTS,
  LONGER_TIME_FRAME_YEARS,
  PERFORMANCE_TIME_TYPE,
  REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING
} from '../../utils/constants';
import {
  AGGREGATED_PORTFOLIO_ID,
  getPortfolioMinStartOfInvestment,
  PortfolioUtils,
  validateSelectedRangeBusinessDates,
} from "../../utils/utils";
import {brokerLogout, customerLogout} from '../../containers/Authentication/Auth/utils';
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import {setSharedSettings} from '../SharedSettingsProvider/actions';
import {createSelector} from "reselect";
import {GROUP_ACTION_DEPOT} from "../Charts/InstrumentsAllocationTable/constants";
import {isProfessionalViewAssetsEnabled} from "../TradingStore/utils";
import {defaultDataBody, showErrors} from "../../utils/dataProviderUtils";
import {updateDataAndLoadingAfter} from "./utils";
import {
  convertSettingsToSelectedPortfolios,
  filterOutPortfoliosToExclude
} from "../../containers/DashboardSettings/components/DashboardSettingsWindow";
import {getInvestmentPlatform, getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import {
  REPORT_TYPES
} from "../../containers/DashboardSettings/components/CustomersList/components/ReportType/constants";
// TODO move to one state together with dates and depots
const defaultChartsSettings = {
  historical: {
    withInvestiertesAmount: false,
    aggregateSelected: false,
    withEmptyAssets: true
  },
  performance: {
    withBenchmark: false,
    currently_selected_benchmarks: [],
    aggregateSelected: false
  },
  global: {
    withHistoricalPortfolios: undefined,
    withProfitLoss: undefined,
    withPaymentPlans: undefined,
    withTransactionSaldo: undefined
  }
}


const getCustomerDashboard = (state) => state.get('customerDashboard');
const getSharedSettings = (state) => state.get('sharedSettings');

export const getCustomerDashboardSelector = createSelector(
  [getCustomerDashboard],
  (customerDashboard) => customerDashboard.toJS()
);
export const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
);
export const getAlertingNotificationsCount = createSelector(
  [getInvestmentPlatform],
  (investmentPlatform) => (investmentPlatform.toJS().new_alerting_events_count || {}).total
);
export const getAlertingNotificationsCountSplitted = createSelector(
  [getInvestmentPlatform],
  (investmentPlatform) => {
    const data = investmentPlatform.toJS().new_alerting_events_count || {};
    return {
      depot_notifications_count: data.depot_notifications_count,
      asset_notifications_count: data.asset_notifications_count
    }
  }
);


const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  sharedSettings: getSharedSettingsSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state)
});

export const getSelectedAssetsIds = (assets) => _.flatten(assets.filter((asset) => asset.ids).map((asset) => asset.ids))

export const getUpdatedTwrData = (oldTwrData, data, portfolioToUpdate, additionalPortfoliosToUpdate) => {
  let portfolioToUpdateIds = [portfolioToUpdate.id];
  additionalPortfoliosToUpdate.forEach(pf => portfolioToUpdateIds.push(pf.id));

  const updatedPortfolios = oldTwrData.data.portfolios.map(portfolio => {
    // in case customer has only 1 portfolio set benchmark data into Aggregated
    if (portfolioToUpdate && [...portfolioToUpdateIds, AGGREGATED_PORTFOLIO_ID].includes(portfolio.id)) {
      let updatedPortfolio = data.find(item => portfolioToUpdateIds.includes(item.id));
      let updatedPortfolioBenchmarkName = updatedPortfolio && updatedPortfolio.benchmark_name;
      updatedPortfolio = updatedPortfolio && updatedPortfolio.time_series && updatedPortfolio.time_series.portfolio || [];

      try {
        const benchmark_first_date = updatedPortfolio[0]['date'];
        portfolio.benchmark_performance_detailed.tracking_start_date = benchmark_first_date;
        const time_series_first_date = portfolio.timeseries.portfolio[0]['date'];
        if (benchmark_first_date > time_series_first_date) {
          const zero_item = {
            date: time_series_first_date,
            value: 0.0,
            cum_ret_g: 0
          };
          updatedPortfolio = [zero_item, ...updatedPortfolio]
        }
      } catch (e) {

      }

      portfolio.benchmark_performance_detailed.portfolio = updatedPortfolio;

      if (updatedPortfolioBenchmarkName) {
        portfolio.benchmark_name = updatedPortfolioBenchmarkName
      }

    }
    return {...portfolio};
  });

  return {
    ...oldTwrData,
    data: {...oldTwrData.data, portfolios: updatedPortfolios},
    timestamp: new Date().getTime()
  };
}


/**
 * HOC for retrieving dashboard data
 */
const withDashboardData = (WrappedComponent, useAssetsApproach=false, isV2=false) => {
  return connect(mapStateToProps)((props) => {
    const {
      dispatch,
      computedMatch: {
        params
      },
      auth: {
        user
      },
      investmentPlatform,
      isCurrentCustomerData,
      isVirtual,
      isProfView, // pro view passes this prop to fetch data for charts that are present in pro view
    } = props;

    saveCustomerIdByPathName(props.location.pathname)

    let dataProvider;

    if (isVirtual) {
      dataProvider = VirtualPortfolioHandlerResource
    } else {
      dataProvider = PortfolioHandlerResource
    }

    const [isMounted, setMountingStatus] = useState(false);

    const [customer, setCustomer] = useState({...defaultDataBody});
    const [portfolios, setPortfolios] = useState({...defaultDataBody});
    const [investmentData, setInvestmentData] = useState({...defaultDataBody});
    const [dashboardData, setDashboardData] = useState({...defaultDataBody});
    const [instrumentData, setInstrumentData] = useState({...defaultDataBody});
    const [instrumentList, setInstrumentList] = useState({...defaultDataBody});
    const [historicalData, setHistoricalData] = useState({...defaultDataBody, timestamp: null});
    const [profitAndLoss, setProfitAndLoss] = useState({...defaultDataBody});
    const [profitAndLossSummary, setProfitAndLossSummary] = useState({...defaultDataBody});
    const [paymentPlans, setPaymentPlans] = useState({...defaultDataBody});
    const [payoutPlans, setPayoutPlans] = useState({...defaultDataBody});
    const [switchPlans, setSwitchPlans] = useState({...defaultDataBody});
    const [unrealizedProfitAndLoss, setUnrealizedProfitAndLoss] = useState({...defaultDataBody});
    const [breakdownData, setBreakdownData] = useState({...defaultDataBody});
    const [twrData, setTwrData] = useState({...defaultDataBody, timestamp: null});
    const [riskData,setRiskData] = useState({...defaultDataBody, timestamp: null});
    const [dataLoading, setDataLoading] = useState(false);
    const [instrumentsGroupBy, setInstrumentsGroupBy] = useState(GROUP_ACTION_DEPOT);

    const externalDepotData = React.useMemo(() => {
      return isProfView && getFromStorage(PROF_VIEW_PORTFOLIO_DATA)
    }, [])

    const externalDepot = externalDepotData && externalDepotData.id

    const dashboardReportType = React.useMemo(() => {
      return isProfView && getFromStorage(PROF_VIEW_REPORT_TYPE)
    }, [])

    React.useEffect(() => {
      if (instrumentData && instrumentData[instrumentsGroupBy]) {
        setInstrumentList({
          ...instrumentList,
          data: instrumentData[instrumentsGroupBy]
        })
      }
    }, [instrumentsGroupBy])

    const [chartsSettings, setChartsSettings] = useState({...defaultChartsSettings});
    const [reportType, setReportType] = useState(dashboardReportType || REPORT_TYPES.BASIC.value);
    React.useEffect(() =>{
      /** useEffect to fetch data when report Type is changed
       * (does not fetchData on initial render, when triggered by setting value form dashboard settings) */

      // isMounted indicates that it is not the initial rendering
      if(isMounted){
        fetchCustomer().then(() => { setDataLoading(false); });
      }

      props.dispatch(reportTypeChanged(reportType));
    }, [reportType])

    const handleChartSettingsChange = (chart, field, data) => {
      const updatedChartsSettings = {
        ...chartsSettings
      };
      if(!updatedChartsSettings[chart]) updatedChartsSettings[chart] = {};

      updatedChartsSettings[chart][field] = data;
      setChartsSettings(updatedChartsSettings);
    };

    useEffect(() => {
      if(!isMounted) {
        // set default chart settings with values from dashboard settings
        let customerData = customer.data;

        // report_type
        let report_type = dashboardReportType || _.get(customerData, 'dashboard_settings.report_type', REPORT_TYPES.BASIC.value);
        setReportType(report_type);

        // historical
        let withInvestiertesAmount = _.get(customerData, 'dashboard_settings.with_invested_capital', false)
        let aggregateSelectedHistorical = _.get(customerData, 'dashboard_settings.aggregate_historical_chart_portfolios', false)
        // performance
        let withBenchmark = _.get(customerData, 'dashboard_settings.benchmark_enabled', false)
        let aggregateSelectedPerformance = _.get(customerData, 'dashboard_settings.aggregate_performance_chart_portfolios', false)
        let preSelectedBenchmarks = _.get(customerData, 'dashboard_settings.benchmarks', [])
        if (!_.isEmpty(preSelectedBenchmarks)) {
          preSelectedBenchmarks = preSelectedBenchmarks.map(benchmark_data => {
            let percentage = parseInt(benchmark_data.percentage)
            return {
              ...benchmark_data,
              benchmark: benchmark_data.id,
              percentage: percentage,
              weight: percentage / 100,
            }
          })
        }
        // global firstLoad
        let withPaymentPlans = _.get(customerData, 'dashboard_settings.with_payment_plans', true)
        let withProfitLoss = _.get(customerData, 'dashboard_settings.with_profit_loss', true)
        let withHistoricalPortfolios = _.get(customerData, 'dashboard_settings.include_historical_portfolios', true)
        let withTransactionSaldo = _.get(customerData, 'dashboard_settings.with_transaction_saldo', true)

        // if only one portfolio is selected, and it is not aggregated -> aggregateSelected should be turned off
        // selectedPortfolios is undefined on init render => either aggregated or single is selected, so there is no need check if cumulative display enabled
        if(selectedPortfolios && !isCumulativeDisplayEnabled(selectedPortfolios)){
          aggregateSelectedHistorical = false
          aggregateSelectedPerformance = false
        }

        let updatedChartSettings = {
          historical: {
            withInvestiertesAmount: withInvestiertesAmount,
            aggregateSelected: aggregateSelectedHistorical,
            withEmptyAssets: true
          },
          performance: {
            withBenchmark: withBenchmark,
            aggregateSelected: aggregateSelectedPerformance,
            currently_selected_benchmarks: preSelectedBenchmarks
          },
          global: {
            withHistoricalPortfolios: withHistoricalPortfolios,
            withProfitLoss: withProfitLoss,
            withPaymentPlans: withPaymentPlans,
            withTransactionSaldo: withTransactionSaldo
          }
        }
        setChartsSettings(updatedChartSettings)

        // fetch data and track load time to google analytics on first render
        if (customerData && !customer.loading) {
          fetchData(updatedChartSettings, report_type)  // pass data in case state was not quick enough to update
          .then(() => {
            setDataLoading(false);
            setMountingStatus(true);
            const end_time = new Date().getTime();
            const value = end_time - startLoadTime;
            GoogleAnalytics.trackDashboardPageLoadTime(value, user.is_broker, customer.customer_id)
          });
        }
      }
    }, [customer])

    // data fetching options with default values for first data fetching

    let defaultTimeFrameSelection = DEFAULT_PERFORMANCE_TIME_TYPE.getDateRange();
    let defaultTimeFrameType = DEFAULT_PERFORMANCE_TIME_TYPE

    let defaultSelectedDates = defaultTimeFrameSelection;

    validateSelectedRangeBusinessDates(
      defaultSelectedDates, defaultTimeFrameType.value,
      [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE])

    const [selectedDates, setSelectedDates] = useState(defaultSelectedDates);
    const [selectedDatesType, setSelectedDatesType] = useState(defaultTimeFrameType)

    const [selectedPortfolios, setSelectedPortfolios] = React.useState(undefined);
    const [excludedPortfolios, setExcludedPortfolios] = React.useState(undefined);

    const [assets, setAssets] = React.useState([]);
    const [assetsLoading, setAssetsLoading] = React.useState(false);
    const [inactiveAssets, setInactiveAssets] = React.useState([]);
    const [selectedAssets, setSelectedAssets] = React.useState([]);

    let appSettings = {}
    if (getFromStorage(SHARED_SETTINGS_KEY)) {
      appSettings = getFromStorage(SHARED_SETTINGS_KEY).app_settings || {}
    }

    const calculateSelectedRange = (customerPortfolios=[]) => {
      let date_limit = appSettings.date_limit || LONGER_TIME_FRAME_YEARS
      let date_scale = appSettings.date_scale || 'years'
      let portfolios_limit = appSettings.portfolios_limit || LONGER_PORTFOLIO_COUNTS
      let isBig = false
      var id = moment().unix();
      if (selectedDates.end && selectedDates.start) {
        isBig = selectedDates.end.diff(selectedDates.start, date_scale) > date_limit
      } else if (selectedDatesType === DEFAULT_PERFORMANCE_TIME_TYPE) {
        isBig = true
      }
      if (selectedPortfolios) {
        if (selectedPortfolios.length > portfolios_limit)
        isBig = true
      } else if (customerPortfolios.length > portfolios_limit) {
        isBig = true
      }
      return {id, isBig}
    }

    const [selectedRange, setSelectedRange] = useState(calculateSelectedRange())
    const [dataFetchingOptions, setDataFetchingOptions] = useState({
      depot_id: selectedPortfolios ? PortfolioUtils.getSelectedPortfoliosNumbers(selectedPortfolios) : undefined,
      excluded_depot_id: excludedPortfolios ? PortfolioUtils.getSelectedPortfoliosNumbers(excludedPortfolios) : undefined,
      start_date : selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined,
      end_date : selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined
    });

    const [loadingPerformanceOnly, setLoadingPerformanceOnly] = React.useState(false);

    const [startLoadTime, setStartLoadTime] = React.useState(null);

    const getRequestedCustomerId = () => {
      if (isCurrentCustomerData) {
        return user && user.customer_id;
      }
      return params.customer_id;
    };

    const isExpertMode = (report_type) => {
      return [REPORT_TYPES.EXPERT.value, REPORT_TYPES.PRO.value, REPORT_TYPES.CUSTOM.value].includes(report_type);
    };

    //#region Life hooks

    /**
     * Call, when component did mount
     */
    useEffect(() => {
      const customer_id = getRequestedCustomerId();
      if(customer_id) saveCustomerId(customer_id);
    }, []);


    // when switch is triggered we need to change customer settings
    const handleIncludeHistoricalPortfoliosChange = (newValue) => {
      if (customer.data) {
        handleChartSettingsChange('global', 'withHistoricalPortfolios', newValue)
        !isV2 && fetchCustomer()
      }
    }

    /*
     * Call, when data fetching options were updated, to download new data
     */

    useEffect(() => {
      const start_time = new Date().getTime();
      setStartLoadTime(start_time);
      fetchCustomer();
    }, [props.computedMatch.params.customer_id]);

    useEffect(() => {

      if (externalDepot && isProfessionalViewAssetsEnabled()) {

        setAssetsLoading(true);

        const startDate = selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined;
        const endDate = selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined;

        const isAggregatedSelected = !assets.length || assets.length === selectedAssets.length;

        dataProvider.getBasicPortfolioData(getRequestedCustomerId(), externalDepot, false, startDate, endDate, true)
          .then((response) => {
            parseResponse(response, 'portfolio_data', (data) => {

              let activeComponents = data.components;
              const inactiveComponents = data.sold_before_period_components || [];

              activeComponents = activeComponents.reduce((result, component) => {
                const isin = _.get(component, 'financial_information.asset_info_data.isin');

                if (!result.hasOwnProperty(isin)) {
                  result[isin] = {
                    ...component,
                    ids: [component.id]
                  }
                } else {
                  result[isin].ids.push(component.id)
                }

                return result;
              }, []);

              activeComponents = Object.values(activeComponents);

              setAssets(activeComponents);
              setInactiveAssets(inactiveComponents);
              setSelectedAssets((currentlySelected) => {
                if (!currentlySelected.length || isAggregatedSelected) {
                  return activeComponents;
                }

                return _.intersectionBy(activeComponents, currentlySelected, (component) => component.id);
              })
            }, (errors) => {
            })
          }).finally(() => setAssetsLoading(false))
      }

    }, [selectedDates])

    useEffect(() => {

      if (!dataFetchingOptions.preventFetchData && dataFetchingOptions.depot_id && isMounted && customer.data && !customer.loading && !customer.errors) {

        fetchData().then(() => {
          setDataLoading(false);
        });
      }
    }, [dataFetchingOptions]);

    useEffect(() => {
      if (isMounted) {

        const start_date = selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined;
        const end_date = selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined;

        setSelectedRange(calculateSelectedRange())

        setLoadingPerformanceOnly(true);

        setDataFetchingOptions({
          ...dataFetchingOptions,
          start_date,
          end_date,
          preventFetchData: false
        });
      }
    }, [selectedDates]);

    useEffect(() => {
      if(selectedPortfolios){
        // deselect aggregated in case single depot
        if(selectedPortfolios.length === 1) {
          handleChartSettingsChange('historical', 'aggregateSelected', false)
          handleChartSettingsChange('performance', 'aggregateSelected', false)
        }

        if (isMounted) { setSelectedRange(calculateSelectedRange()) } // leave only this line in if
        const customerPortfoliosCount = portfolios && portfolios.data && portfolios.data.length || 0;
        updateDataFetchOptionsWithPortfolios(selectedPortfolios, customerPortfoliosCount);
      }
      setDataFetchingOptions((current) => ({
        ...current,
        excluded_depot_id: excludedPortfolios ? PortfolioUtils.getSelectedPortfoliosNumbers(excludedPortfolios) : undefined
      }))
    }, [selectedPortfolios, excludedPortfolios]);

    useEffect(() => {
      if (selectedAssets) {
        setDataFetchingOptions((options) => ({
          ...options,
          preventFetchData: false,
          assets: assets.length === selectedAssets.length && assets.length > 1 ? [] : getSelectedAssetsIds(selectedAssets)
        }))
      }
    }, [JSON.stringify(selectedAssets)])
    //#endregion

    const updateDataFetchOptionsWithPortfolios = (portfolios, customerPortfoliosCount) => {

      let hasOnlyOnePortfolio = customerPortfoliosCount === 1
      const depot_id = hasOnlyOnePortfolio ? [0] : portfolios ? PortfolioUtils.getSelectedPortfoliosNumbers(portfolios) : [0];

      // if youngest of selected portfolios is younger than start date of the selected period - do not send requests.
      // PortfolioTimeSelector/index.js renderPerformanceTimeTypesList function will trigger rerender of dashboard by setting proper period
      let preventFetchData = false
      if(dataFetchingOptions.start_date){
        const customerPortfolios = customer.data && customer.data.portfolios && customer.data.portfolios.portfolios || [];
        let portfoliosToUse = hasOnlyOnePortfolio
          ? customerPortfolios  // if customer has only one portfolio - no aggregated in customerPortfolios => all customer portfolios are used
          : customerPortfolios.filter( portfolio => depot_id.includes(portfolio.depotNumber))
        let minStartOfInvestment = getPortfolioMinStartOfInvestment(portfoliosToUse) // get earliest start of investment among selected portfolios
        preventFetchData = minStartOfInvestment.isAfter(moment(dataFetchingOptions.start_date))
      }

      setDataFetchingOptions({
        ...dataFetchingOptions,
        depot_id,
        preventFetchData
      });

      // A workaround to prevent date resetting (BCA-8297) on initial
      // setSelectedPortfolios before list of portfolios was set to state.
      if(customerPortfoliosCount && preventFetchData){
        handleSelectedDatesChanged(PERFORMANCE_TIME_TYPE.BEGINNING.getDateRange(), 'BEGINNING')
      }
    }

    const fetchData = async (updatedChartSettings, report_type) => {
      updatedChartSettings = updatedChartSettings || chartsSettings

      let fetchMethods = []

      if(isProfView) {
        fetchMethods = [
          fetchPortfolioData,
          fetchTwrData,
          fetchUnrealizedProfitAndLoss
        ];
      } else {
        fetchMethods = [
          fetchHistoricalData,
          fetchUnrealizedProfitAndLoss,
          fetchPortfolioData
        ];

        if (isExpertMode(report_type || reportType)) {
          fetchMethods.push(fetchTwrData);
          fetchMethods.push(fetchRiskData)
        }
      }
      setDataLoading(true);

      //fetchRiskData()

      return Promise.all(fetchMethods.map(method => method(updatedChartSettings)));
    };

    const togleLoadingStatus = (callbacks, status=false) => {
      callbacks.forEach(callback => callback({
        data: undefined,
        errorors: true,
        loading: status
      }));
    };

    const allDataSourcesCallbacks = [
      setInvestmentData,
      setInstrumentList,
      setHistoricalData,
      setProfitAndLoss,
      setProfitAndLoss,
      setProfitAndLossSummary,
      setPaymentPlans,
      setUnrealizedProfitAndLoss,
      setBreakdownData,
      setTwrData,
      setRiskData,
      setPayoutPlans,
      setSwitchPlans
    ];

    //#region Data fetching

    const fetchCustomer = async () => {
      const id = getRequestedCustomerId();

      setCustomer({
        ...customer,
        loading: true
      });

      try {
        // if withHistoricalPortfolios is not undefined, we pass its value to request to forсe settings depending on with historical switch state
        let includeHistoricalPortfolios = isV2 || _.isNil(chartsSettings.global.withHistoricalPortfolios) ? undefined : chartsSettings.global.withHistoricalPortfolios

        const customerAppData = await dataProvider.getCustomerAppData(id, true, false, false, false, false, includeHistoricalPortfolios);
        /** Parse shared settings data */
        parseResponse(customerAppData, 'shared_settings', (data) => {
          props.dispatch(setSharedSettings(data || {}));
        }, () => props.dispatch(setSharedSettings({})))

        /** Parse general customer data, portfolios data */
        parseResponse(customerAppData, 'customer', (data) => {

          let portfoliosData = data && _.get(data, 'portfolios', [])

          let customerPortfolios = data && data.portfolios && data.portfolios.portfolios || [];
          const defaultExcludedPortfolios = convertSettingsToSelectedPortfolios(
            customerPortfolios, portfoliosData.default_excluded_portfolios, data.dashboard_settings, 'excluded_portfolios');
          customerPortfolios = filterOutPortfoliosToExclude(customerPortfolios, defaultExcludedPortfolios);

          let trackingDate;

          if(!_.isEmpty(portfoliosData)){
            // externalDepot - id of portfolio selected to be displayed in pro view. If passed -> use its start_tracking_date instead of the aggregated portfolio
            trackingDate = externalDepot
              ? _.find(customerPortfolios, p => p.depotNumber == externalDepot).start_tracking_date
              : portfoliosData.min_tracking_date;
          }

          let timeFrameCode;

          // getting type of selected time frame
          timeFrameCode = REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING[data.dashboard_settings.date_range];

          // getting selected dates that are set by default when dashboard is loaded
          defaultSelectedDates = PERFORMANCE_TIME_TYPE[timeFrameCode].getDateRange();

          if(timeFrameCode === CUSTOM){
            if (!isMounted && (data.dashboard_settings.date_range_start_date && data.dashboard_settings.date_range_end_date)) {
              defaultSelectedDates.start = moment(data.dashboard_settings.date_range_start_date);
              defaultSelectedDates.end = moment(data.dashboard_settings.date_range_end_date);
            }
          }

          let start_date = defaultSelectedDates.start && defaultSelectedDates.start.format('YYYY-MM-DD') || undefined;
          // if start date of time period is older than portfolios, set time period to beginning
          if (trackingDate > start_date) {
            timeFrameCode = BEGINNING;
            defaultSelectedDates = PERFORMANCE_TIME_TYPE[timeFrameCode].getDateRange();
          }

          const defaultSelectedPortfolios = convertSettingsToSelectedPortfolios(
            customerPortfolios, [], data.dashboard_settings);
          // check !isMounted to prevent resetting dates when fetchCustomer is triggered by 'include historical' or report type switch
          if (!isMounted) {

            // dates and period that are selected in time selector
            validateSelectedRangeBusinessDates(defaultSelectedDates, timeFrameCode, [BEGINNING, CUSTOM])

            setSelectedDates(defaultSelectedDates);
            setSelectedDatesType(timeFrameCode);

            // dates that are used in fetch-es
            start_date = defaultSelectedDates.start && defaultSelectedDates.start.format('YYYY-MM-DD') || undefined;
            let end_date = defaultSelectedDates.end && defaultSelectedDates.end.format('YYYY-MM-DD') || undefined;

            //todo check if fetchData can be moved to useEffect that depends on [dataFetchingOptions]
            setDataFetchingOptions((current) => ({
              ...current,
              start_date,
              end_date
            }));

            setSelectedPortfolios(defaultSelectedPortfolios);
            setExcludedPortfolios(defaultExcludedPortfolios);
          }

          setCustomer({
            data: data,
            loading: false,
            errors: undefined
          });
          setPortfolios({
            data: [...customerPortfolios],
            loading: false,
            errors: undefined
          });

          if (selectedPortfolios) {
            let updatedSelectedPortfolios = [...selectedPortfolios]

            if (updatedSelectedPortfolios.some(p => p.depotNumber === 0)){
              // if aggregated in portfolios, then set all possible options
              updatedSelectedPortfolios = [...customerPortfolios]

            } else{
              // when fetch is triggered by switch some of selected portfolios may not be in options anymore
              updatedSelectedPortfolios = _.intersectionBy(customerPortfolios, selectedPortfolios, 'depotNumber')
            }

            setSelectedPortfolios(updatedSelectedPortfolios);

          } else {
            setSelectedPortfolios(defaultSelectedPortfolios);
          }
          setSelectedRange(calculateSelectedRange(selectedPortfolios ? [...selectedPortfolios] : [...customerPortfolios]))

        }, (error) => {

          setCustomer({
            data: undefined,
            loading: false,
            errors: error
          });

          togleLoadingStatus(allDataSourcesCallbacks);

        })

      }
      catch(errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        } else {
          setCustomer({
            data: undefined,
            loading: false,
            errors
          });

          togleLoadingStatus(allDataSourcesCallbacks);
        }
      }
    };

    const fetchPortfolioData = async (chartsSettings) => {
      const id = getRequestedCustomerId();

      setInstrumentsGroupBy(GROUP_ACTION_DEPOT);

      setInstrumentList({
        ...instrumentList,
        loading: true
      });

      setProfitAndLoss({
        ...profitAndLoss,
        loading: true
      });

      setProfitAndLossSummary({
        ...profitAndLossSummary,
        loading: true
      });

      setPaymentPlans({
        ...paymentPlans,
        loading: true
      });
      setPayoutPlans({
        ...paymentPlans,
        loading: true
      });
      setSwitchPlans({
        ...paymentPlans,
        loading: true
      });

      setInvestmentData({
        ...investmentData,
        loading: !loadingPerformanceOnly,
        loadingPerformanceOnly: loadingPerformanceOnly,
      });

      setBreakdownData({
        data: undefined,
        loading: true,
        errors: undefined
      });

      try {

        let {
          depot_id,
          excluded_depot_id,
          start_date,
          end_date,
          assets
        } = dataFetchingOptions;

        if(externalDepot){ depot_id = externalDepot; }

        let response = await dataProvider.getPortfolioData(id, depot_id, start_date, end_date, chartsSettings.global.withHistoricalPortfolios, assets, undefined, excluded_depot_id);
        /** Parse payment plans data */
        parseResponse(response, 'payment_plans', (data) => {
          setPaymentPlans({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setPaymentPlans({
            data: undefined,
            loading: false,
            errors: showErrors(errors)
          });
        });

        parseResponse(response, 'payout_plans', (data) => {
          setPayoutPlans({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setPayoutPlans({
            data: undefined,
            loading: false,
            errors: showErrors(errors)
          });
        });

        parseResponse(response, 'switch_plans', (data) => {
          setSwitchPlans({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setSwitchPlans({
            data: undefined,
            loading: false,
            errors: showErrors(errors)
          });
        });

        /** Parse financial data (header 2) */
        parseResponse(response, 'financial', (data) => {

          if (data.data.start_date) {
            let startInvestment = moment(data.data.start_date);
            if (startInvestment > selectedDates.start) {
              setSelectedDates(DEFAULT_PERFORMANCE_TIME_TYPE.getDateRange());
              setSelectedDatesType(DEFAULT_PERFORMANCE_TIME_TYPE);
            }
          }

          setLoadingPerformanceOnly(false);
          setInvestmentData({
            data: data && data.data,
            loading: false,
            loadingPerformanceOnly: false,
            errors: undefined
          });
        }, (errors) => {
          setLoadingPerformanceOnly(false);
          setInvestmentData({
            data: undefined,
            loading: false,
            loadingPerformanceOnly: false,
            errors: showErrors(errors)
          });
        });


        /** Parse portfolios breakdown */
        parseResponse(response, 'breakdown', (data) => {
          setBreakdownData({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setBreakdownData({
            data: undefined,
            loading: false,
            errors: showErrors(errors)
          });
        });

        /** Parse instruments table data */
        parseResponse(response, 'instruments_table', (data) => {
          setInstrumentData(data)
          // setState executed immediately so each set will cause re-render
          // in VP ordering we build transactions after setInstrumentList - so put profitLoss first
          setProfitAndLoss({
            data: data.profit_loss || [],
            loading: false,
            errors: undefined
          });

          setInstrumentList({
            data: data.instruments || [],
            loading: false,
            errors: undefined,
            timestamp: new Date().getTime()
          });

          setProfitAndLossSummary({
            data: data.profit_loss_summary || {},
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setInstrumentData({})

          setInstrumentList({
            data: [],
            loading: false,
            errors: showErrors(errors)
          });

          setProfitAndLoss({
            data: [],
            loading: false,
            errors: showErrors(errors)
          });

          setProfitAndLossSummary({
            data: {},
            loading: false,
            errors: showErrors(errors)
          });
        });

      } catch (errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        }

        setInstrumentData({})

        setInstrumentList({
          data: [],
          loading: false,
          errors
        });

        setProfitAndLoss({
          data: [],
          loading: false,
          errors: undefined
        });

        setProfitAndLossSummary({
          data: {},
          loading: false,
          errors: undefined
        });

        setPaymentPlans({
          data: undefined,
          loading: false,
          errors
        });

        setPayoutPlans({
          data: undefined,
          loading: false,
          errors
        });

        setSwitchPlans({
          data: undefined,
          loading: false,
          errors
        });

        setLoadingPerformanceOnly(false);
        setInvestmentData({
          data: undefined,
          loading: false,
          loadingPerformanceOnly: false,
          errors
        });

        setBreakdownData({
          data: undefined,
          loading: false,
          errors
        })
      }
    };

    const fetchHistoricalData = async (chartsSettings) => {
      const id = getRequestedCustomerId();

      setHistoricalData({
        ...historicalData,
        loading: true
      });

      try {

        let {
          depot_id,
          start_date,
          end_date,
          excluded_depot_id,
        } = dataFetchingOptions;

        let response = await dataProvider.getHistoricalData(
          id, depot_id, start_date, end_date, chartsSettings.global.withHistoricalPortfolios,
          undefined, undefined, undefined, false, true, true, true, undefined, excluded_depot_id);

        parseResponse(response, 'historical', (data) => {
          const _data = {
            data: {
              portfolios: data || []
            },
            loading: true,
            errors: undefined,
            timestamp: new Date().getTime()
          };
          updateDataAndLoadingAfter(_data, setHistoricalData);
        }, (errors) => {
          setHistoricalData({
            data: undefined,
            loading: false,
            errors: showErrors(errors),
            timestamp: null
          });
        });

      }
      catch(errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        }
        setHistoricalData({
          data: undefined,
          loading: false,
          errors,
          timestamp: null
        })
      }
    };


    const fetchRiskData = async (chartsSettings) => {
      const id = getRequestedCustomerId();

      setRiskData({
        ...riskData,
        loading: true
      });

      try {

        let {
          depot_id,
          start_date,
          end_date,
          excluded_depot_id
        } = dataFetchingOptions;

        let response = await dataProvider.getRiskData(id, depot_id, start_date, end_date,
           chartsSettings.global.withHistoricalPortfolios,
           chartsSettings.performance.currently_selected_benchmarks,
          undefined, undefined, excluded_depot_id
        );

        parseResponse(response, 'risk', (data) => {
          setRiskData({
            data,
            loading: false,
            errors: undefined,
            timestamp: new Date().getTime()
          });
        }, (errors) => {
          setRiskData({
            data: undefined,
            loading: false,
            errors: showErrors(errors),
            timestamp: null
          });
        });

      }
      catch(errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        }
        setRiskData({
          data: undefined,
          loading: false,
          errors,
          timestamp: null
        });
      }
    };

    const fetchTwrData = async (chartsSettings) => {
      const id = getRequestedCustomerId();

      setTwrData({
        ...twrData,
        loading: true
      });

      try {

        let {
          depot_id,
          start_date,
          end_date,
          excluded_depot_id
        } = dataFetchingOptions;

        if(externalDepot){ depot_id = externalDepot; }

        // if one portfolio is selected, and selected time period is beginning
        if (depot_id && depot_id.length == 1 && selectedDatesType.value == PERFORMANCE_TIME_TYPE[BEGINNING].value) {
          const customerPortfolios = customer.data && customer.data.portfolios && customer.data.portfolios.portfolios || [];
          let dataFetchingPortfolio = customerPortfolios.find(p => p.depotNumber == depot_id[0])
          if (dataFetchingPortfolio) {
            start_date = dataFetchingPortfolio.start_tracking_date
          }
        }

        let response = await dataProvider.getTimeWeightedReturnData(id, depot_id, start_date, end_date,
          chartsSettings.global.withHistoricalPortfolios,
          chartsSettings.performance.currently_selected_benchmarks,
          undefined, excluded_depot_id
        );

        parseResponse(response, 'time_weighted_return', (data) => {
          const _data = {
            data: {
              portfolios: data || []
            },
            loading: false,
            errors: undefined,
            timestamp: new Date().getTime()
          };
          updateDataAndLoadingAfter(_data, setTwrData);
        }, (errors) => {
          setTwrData({
            data: undefined,
            loading: false,
            errors: showErrors(errors),
            timestamp: null
          });
        });
      }
      catch(errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        }
        setTwrData({
          data: undefined,
          loading: false,
          errors,
          timestamp: null
        });
      }
    }

    const fetchUnrealizedProfitAndLoss = async (chartsSettings) => {
      const id = getRequestedCustomerId();

      setUnrealizedProfitAndLoss({
        ...unrealizedProfitAndLoss,
        loading: true
      });

      try {

        let {
          depot_id,
          start_date,
          end_date,
          assets
        } = dataFetchingOptions;

        let response = await dataProvider.getTransactionsData(id, depot_id, start_date, end_date, chartsSettings.global.withHistoricalPortfolios, assets);

        parseResponse(response, 'transactions', (data) => {
          setUnrealizedProfitAndLoss({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setUnrealizedProfitAndLoss({
            data: undefined,
            loading: false,
            errors: showErrors(errors),
          });
        });

      } catch(errors) {
        if (errors.status === 403) {
          handleUnauthorized();
        }
        setUnrealizedProfitAndLoss({
          data: undefined,
          loading: false,
          errors
        });

      }
    };

    //#endregion
    const handleUnauthorized = () => {
      if (!isCurrentCustomerData) {
          const initialCustomerId = getFromStorage(CUSTOMER_ID_KEY);
          const currentCustomerId = params.customer_id;
          const isSameRequested = +initialCustomerId === +currentCustomerId;

          if (!_.isNil(initialCustomerId) && !isSameRequested) {
            props.history.push(buildCustomerDashboardLink(initialCustomerId));
          } else {
            brokerLogout();
          }
        } else {
          customerLogout();
        }
    }

    const updateRiskMetricsBenchmark = (data, portfolioToUpdate, isVirtual) => {
      // In case it's virtual ignore validation
      if (data) {
        let dashboard = {...riskData};

        const isAggregatedSelected = _.find(selectedPortfolios, portfolio => portfolio.depotNumber == 0);

        if (dashboard.data && (selectedPortfolios.length == 1 || isAggregatedSelected || isVirtual)) {

          if ((data.portfolio == 0 && isAggregatedSelected) || (selectedPortfolios[0].depotNumber == data.portfolio) || isVirtual)
            dashboard.data.benchmark_volatility = data;

            setRiskData(dashboard)
        }
      }
    }

    /**
     * Update historical data benchmarks for portfolio
     * @param {Object} data
     */
    const updateHistoricalData = (data=[], portfolioToUpdate=undefined, additionalPortfoliosToUpdate=[]) => {
      setTwrData(getUpdatedTwrData(twrData, data, portfolioToUpdate, additionalPortfoliosToUpdate));
    };

    const refresh = (deletedPortfolioId= undefined) => {
      if (selectedPortfolios.length === 1 && selectedPortfolios[0]['depotNumber'] === deletedPortfolioId) {
        fetchCustomer().then(() => {
          setDataLoading(false);
        });
      } else {
        fetchData().then(() => {
          setDataLoading(false);
        });
      }
    }

    const handleSelectedDatesChanged = (dates, datesType) => {
      setSelectedDates(dates)
      setSelectedDatesType(datesType)
    }

    // scroll top on refresh
    React.useEffect(() => {
      document.getElementById("app-main").scrollTo(0,0)
    }, [dataLoading]);

    return (
      <WrappedComponent
        customer={customer}
        portfolios={portfolios}
        investmentData={investmentData}
        dashboardData={dashboardData}
        instrumentList={instrumentList}
        profitAndLoss={profitAndLoss}
        profitAndLossSummary={profitAndLossSummary}
        paymentPlans={paymentPlans}
        payoutPlansData={payoutPlans}
        switchPlansData={switchPlans}
        historicalData={historicalData}
        updateHistoricalData={updateHistoricalData}
        updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
        unrealizedProfitAndLoss={unrealizedProfitAndLoss}
        selectedDates={selectedDates}
        selectedDatesType={selectedDatesType}
        onSelectedDatesChanged={handleSelectedDatesChanged}
        selectedPortfolios={selectedPortfolios}
        excludedPortfolios={excludedPortfolios}
        breakdownData={breakdownData}
        timeWeightedReturnData={twrData}
        riskData={riskData}
        assets={assets}
        inactiveAssets={inactiveAssets}
        selectedAssets={selectedAssets}
        isAllAssetsSelected={assets.length > 1 && assets.length === selectedAssets.length}
        onSelectedAssetsChanged={setSelectedAssets}
        onSelectedPortfoliosChanged={setSelectedPortfolios}
        isVirtual={isVirtual}
        dataLoading={dataLoading || assetsLoading}
        refresh={refresh}
        selectedRange={selectedRange}
        includeHistoricalPortfolios={chartsSettings.global.withHistoricalPortfolios} // todo refactor, there is no sense passing this prop as we already pass chartsSettings
        setIncludeHistoricalPortfolios={handleIncludeHistoricalPortfoliosChange}
        chartsSettings={chartsSettings}
        handleChartSettingsChange={handleChartSettingsChange}
        reportType={reportType}
        setReportType={setReportType}
        instrumentsGroupBy={instrumentsGroupBy}
        handleInstrumentsGroupByChange={setInstrumentsGroupBy}
        isMounted={isMounted}
        {...props}
      />
    )
  })
};

export default withDashboardData;