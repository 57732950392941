import React from "react";
import _ from 'lodash'

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import {useStyles} from "../styles";
import clsx from "clsx";
import Tooltip from "../../../../../../../components/Tooltip";
import {InfoIcon} from "../../../../../../../images";


const SelectFormElement = props => {
  const {
    value,
    label,
    required,
    options,
    onChange,
    disabled,
    custom_classes,
    error,
    placeholder,
    tooltip,
    withColorsIndicators,
    onChangeGetEvent,
    minimisedStyles
  } = props;
  const classes = useStyles();

  React.useEffect(() => {
    if (!!value && !options.find(o => o.value == value)){
      onChange(undefined); // clean answer as it's not in the options
    }
  }, [value, options]);

  const handleChange = (event) => {
    if (onChangeGetEvent) {
      onChange(event);
    } else {
      onChange(event.target.value);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation()
  };

  const renderOption = (option) => {
    let fontWeight = (option.depth === 1) ? 'bold' : 'normal';
    let styling = {};
    if (!_.isNil(option.depth)) {
      styling.paddingLeft = option.depth * 10;
    }
    return (
      <MenuItem
        key={option.id}
        value={option.value}
        classes={{
          root: classes.selectMenuItemRoot
        }}
        style={styling}
        data-type={option.type}
        disabled={option.disabled}
      >
        <span style={{ fontWeight: fontWeight }} data-type={option.type}>
          {withColorsIndicators && option.color && <span className={classes.colorIndicator} style={{ backgroundColor: option.color }} />}
          {option.label}
          {option.info && (
            <Tooltip title={option.info} style={{pointerEvents: 'all', position: 'relative', top: 4, marginLeft: 10}} onClick={(event) => {event.preventDefault(); event.stopPropagation()}}>
              <span><InfoIcon /></span>
            </Tooltip>
          )}
        </span>
      </MenuItem>
    )
  }

  const renderValue = React.useCallback((value) => {

    const _getValueLabel = () => {
      const option = _.find(options, (option) => option.value === value);
      if (withColorsIndicators && option) {
        return <><span className={classes.colorIndicator} style={{ backgroundColor: option.color }} />{option.label}</>
      }
      return option && option.label
    };

    const _getPlaceholder = () => {
      return placeholder && (<span style={{color: 'rgb(177, 177, 177)'}}>{ placeholder }</span>)
    };

    return (value && _getValueLabel()) || _getPlaceholder() || ''
  }, [options, placeholder]);

  return (
    <TextField
      select
      style={props.style || {}}
      disabled={disabled}
      label={label && <>{label} {tooltip && (<Tooltip title={tooltip}><span className='far fa-info-circle'/></Tooltip>)}</>}
      required={required}
      value={value || ''}
      onChange={handleChange}
      onClick={handleClick}
      error={!!error}
      helperText={error}
      classes={{
        root: clsx(classes.textFieldRoot, custom_classes && custom_classes.textFieldRoot, minimisedStyles && classes.minimisedSelect),
      }}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, custom_classes && custom_classes.inputRoot),
          focused: classes.inputFocused,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: clsx(classes.labelRoot, custom_classes && custom_classes.labelRoot),
          focused: classes.labelFocused,
          asterisk: classes.labelAsterisk
        }
      }}
      SelectProps={{
        variant: 'outlined',
        displayEmpty: !!placeholder,
        renderValue: renderValue,
        classes: {
          select: classes.select
        },
        MenuProps: {
          classes: {
            paper: classes.selectMenuPaper
          }
        }
      }}
    >
      {options.map(option => (renderOption(option)))}
    </TextField>
  );
};

export default SelectFormElement;
