import React from "react";
import { withStyles } from "@material-ui/core";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import { toShortGermanFormat } from "../../../../utils";
import HighChartBase from "../../../../../../components/Charts/Base";
import styles from "./styles";
import {PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT} from "../../../../../../components/WarningTooltip";
import ExplanationTooltip from "../../../../../../components/ExplanationTooltip";


class PerformanceBarChart extends React.Component {

  renderChart() {
    const defaultChartColor = '#0596CE';
    const { classes, data, height } = this.props;
    let years = [], values = [];

    data.forEach((item) => {
      years.push(item['year']);
      values.push(parseFloat((item.data.calc || 0).toFixed(2)));
    })

    const series = [{
      name: 'performance',
      data: values,
      pointPadding: 0.15,
      visible: true,
      color: defaultChartColor
    }];

    return <HighChartBase
      className={classes.chart}
      language={'DE'}
      options={{
        chart: {
          type: 'column',
          height: height || 405,
          style: {
            fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
            fontSize: 14
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: years,
          tickLength: 0,
          labels: {
            style: {
              fontWeight: 'bold',
              fontSize: 14,
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function () {
              return toShortGermanFormat(this.value, '', ' %', 2, true);
            },
            style: {
              fontSize: 14,
            }
          },
          plotLines: [{
            color: '#D8DCDF',
            width: 2,
            value: 0,
            zIndex: 10,
            fontSize: 14,
          }]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          column: {
            grouping: true,
            shadow: false,
            borderWidth: 0
          },
          tooltip: {
            enabled: false
          },
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return toShortGermanFormat(this.y, '', ' %', 2, false);
              },
              style: {
                fontSize: 14,
                fontWeight: 'normal',
                color: '#202A38',
              },
            },
            enableMouseTracking: true
          }
        },
        series: series
      }} />
  }

  renderFootnote() {
    const { classes } = this.props;

    if(!this.props.endDate) return '';

    return <div className={classes.subline}>
      <p className={classes.description}>
        Datenstand: {this.props.endDate}
      </p>
    </div>
  }

  renderError() {
    return <div>{'Fehler. Die Graphik "Jährliche Wertentwicklung" konnte nicht geladen werden.'}</div>;
  }

  render() {
    const { classes, loading, error, data, cardClass } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: cardClass || classes.root
      }}
      title={(
        <>
          <span>Jährliche Wertentwicklung in %</span>
          <ExplanationTooltip
            tooltipKey={'yearly_performance_bar_chart'}
            customClasses={{helpIcon: this.props.classes.infoIcon}}
          />
        </>
      )}
      loading={loading}
      content={
        <div className={classes.container}>
          {error
            ? this.renderError()
            : data
              ? <>
                  {this.renderChart()}
                  {this.renderFootnote()}
                </>
              : 'k.A.'
          }
        </div>
      }
    />
  }
}

export default withStyles(styles)(PerformanceBarChart);
