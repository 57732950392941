import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  makeStyles,
  Grid
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getColorByValue,
  openMorningStartIsin,
  withEuroOrDash,
  withPercentOrDash,
  openFundShopInfosIsin,
  formatQty,
  formatDateOrDash, isSubDepotItem,
} from '../../../utils/utils';
import {
  DepotSubNumberCell,
  IsinCell, SustainabilityCell,
} from '../../../utils/commonTableColumns';
import { toGermanFormat } from '../../../utils/numberFormater';
import WarningTooltip from '../../WarningTooltip';
import { getHostRelatedSetting } from '../../../utils/sharedSettings';
import { HOST_RELATED_SHARED_SETTINGS_KEYS, NOT_FOUND } from '../../SharedSettingsProvider/constants';
import { InstrumentNameCell, SRRIColumn } from '../../../containers/Modelportfolios/components/InstrumentsList/table-data';
import {
  useAlertConfigurationModalContext,
} from '../../../containers/RiskDashboard/components/AlertConfigurationModal/AlertConfigurationModal';
import {
  ALERT_PRODUCT_TYPE,
} from '../../../containers/RiskDashboard/components/AlertConfigurationModal/utils';
import AlarmButton from '../../Buttons/AlarmButton/AlarmButton';
import VirtualPortfolioActionsManager from '../../../containers/VirtualPortfolioManager/ActionButtons';
import { getDisabledTradingOptions } from '../../../containers/RiskProfiling/utils';
import InputFormElement, {
  CurrencyNumberFormat,
  PercentageNumberFormat,
} from '../../../containers/RiskProfiling/components/StepContent/components/formElement/InputFormElement/InputFormElement';
import {
  getAssetInternalId,
  getAssetInternalIdForNewPaymentPlan,
  getInternalIdForNewPaymentPlan
} from '../../../containers/CustomerDashboard/utils';
import { getRiskMetricsValue } from './utlis';
import {
  TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH,
  TRADING_ACTIONS,
} from './constants';
import {
  useTooltipStyles,
  useVirtualTableStyles,
  useRebalancingTableStyles,
} from './styles';
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";

const getRebalancingWeightHeader = (data) => {
  const classes = useRebalancingTableStyles()
  return (
    <div className={classes.headerContainer}>
      <div className={classes.dataContainer}>
        <span className={classes.name}>{data.name}</span>
        <span>{data.categoryName}</span>
      </div>
      <span>Gewichtung</span>
    </div>
  )
}

const RebalancingDifferenceComponent = (props) => {
  const {
    item,
    value,
    displayTransactionType,
    renderInput
  } = props

  let style = {};
  if (item.transactionType === 'Verkauf') {
    style.color = '#F44638';
    style.fontFamily = 'Roboto-Bold';
  } else if (item.transactionType === 'Kauf') {
    style.color = '#5EC14E';
    style.fontFamily = 'Roboto-Bold';
  }
  return (
    <div style={style}>
      {renderInput ? renderInput() : (
        <span>{item.transactionType === 'Verkauf' && '-'}{value}</span>
      )}
      {displayTransactionType && (
        <>
          <br/>
          <span>{item.transactionType || ''}</span>
        </>
      )}
    </div>
  )
}

const getAssetQuantity = (quantity) => {
  try {

    let quantityStr = quantity && quantity.toString();
    let quanityParts = quantityStr.split('.');

    if (quanityParts.length > 1) {
      let decimals = quanityParts[1];

      let decimalsWithoutZero = decimals.replace(/^0+/, '');

      if (decimalsWithoutZero.length !== decimals.length) {

        let decimalPlaces = decimals.length - decimalsWithoutZero.length + 1;

        return toGermanFormat(quantity, '', '', decimalPlaces);
      }
    }
  } catch (err) {
    return toGermanFormat(quantity);
  }
  return toGermanFormat(quantity);
}

const useTradingActionStyles = makeStyles((theme) => ({
  tradingAction: {
    textAlign: 'center',
    height: 24,
    width: 24,
    border: '2px solid',
    borderRadius: 4,
    borderColor: (props) => props.color || theme.palette.primary.main,
    color: (props) => props.color || theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tradingActionSelected: {
    color: 'white !important',
    background: (props) => props.color || theme.palette.primary.main
  },
  tradingActionDisabled: {
    borderColor: '#AFAFAF !important',
    color: '#AFAFAF !important',
    '&$tradingActionSelected': {
      background: '#AFAFAF !important',
      color: 'white !important',
    }
  }
}))

export const TradingAction = ({title, onClick, selected, disabled, tooltip, color, ...props}) => {

  const classes = useTradingActionStyles({color});

  const content = (
    <div className={clsx(
      classes.tradingAction,
      props.classes.tradingAction,
      selected && classes.tradingActionSelected,
      selected && props.classes.tradingActionSelected,
      disabled && classes.tradingActionDisabled)}
       onClick={() => !disabled && onClick()}
    >{title}</div>
  );

  return !!tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content
};

TradingAction.defaultProps = {
  classes: {}
}

const TradingActions = (props) => {
  const {
    isModelportfolio,
    item,
    onChange,
    tradings,
    availableAssetClasses,
    custodiansInstrumentsData,
    tradeTransactions,
    disabled,
    brokerLicense,
    isVirtual,
    wholeDepotTradingAction
  } = props

  const handleTradingOptionChange = (event, deletePaymentPlanTradingType) => {
    if (onChange) {
      onChange(item, event.target.value, deletePaymentPlanTradingType);
    }
  }

  const tradingOption = _.find(tradings, instrument => instrument.instrumentId == getAssetInternalId(item))
  // tradingType info from existing trade session
  let value = _.get((tradeTransactions || {}), getAssetInternalId(item)) || (tradingOption && tradingOption.tradingType) || "default";

  const savingPlanInternalId = getAssetInternalIdForNewPaymentPlan(
    item, TRADING_ACTION_SAVINGS_PLAN);

  const savingPlantTradingOption = _.find(
    tradings, instrument => instrument.instrumentId == savingPlanInternalId);
  // tradingType info from existing trade session
  let savingPlanValue = !!_.get((tradeTransactions || {}), savingPlanInternalId) || !!savingPlantTradingOption;

  let disabledTradingOptions;
  let getFilteredTradingActions = (option) => option.isOrderAction;
  if (isVirtual){
    // for virtual portfolios banks are not selected -> no banks data -> there are no disabled options
    disabledTradingOptions = [];
    // switch out/sell allowed ONLY if qty > 0
    if(item.quantity <= 0) {
      disabledTradingOptions.push(TRADING_ACTION_SWITCH);
      disabledTradingOptions.push(TRADING_ACTION_SELL);
    }
  } else {
    disabledTradingOptions = getDisabledTradingOptions(item, custodiansInstrumentsData, availableAssetClasses, brokerLicense);
  }

  if (!item.isin) {
    return null;
  }

  const disabledByPortfolioLevelTrading = !!wholeDepotTradingAction && wholeDepotTradingAction != 'default';
  const isOptionDisabled = (option) => {
    return disabled || disabledByPortfolioLevelTrading || item.wholeDepotOrder || disabledTradingOptions.includes(option.value)
  }

  const getOptionTooltip = (option) => {
    if (disabled) {
      return 'Es existiert bereits ein offener Ordervorgang'
    }

    if (isModelportfolio || disabledByPortfolioLevelTrading) {
      return 'Nur für das Portfolio'
    }

    if (isOptionDisabled(option)) {
      return 'Orderart für dieses Produkt nicht verfügbar.'
    }

    return option.actionButtonTooltip;
  }

  const [, , , SAVING_PLAN_ACTION] = TRADING_ACTIONS

  return (
    <Grid container spacing={1}>
      {TRADING_ACTIONS.filter(option => getFilteredTradingActions(option)).map(option => (
        <Grid item>
          <TradingAction
            title={option.actionButtonText}
            onClick={() => handleTradingOptionChange({target: {value: value == option.value ? 'default' : option.value}})}
            selected={value==option.value}
            disabled={isOptionDisabled(option) || isModelportfolio}
            tooltip={getOptionTooltip(option)}
          />
        </Grid>
      ))}
      <Grid item>
        <TradingAction
          title={SAVING_PLAN_ACTION.actionButtonText}
          onClick={() => handleTradingOptionChange({target: {value: savingPlanValue ? 'default' : SAVING_PLAN_ACTION.value}}, TRADING_ACTION_SAVINGS_PLAN)}
          selected={savingPlanValue}
          disabled={isOptionDisabled(SAVING_PLAN_ACTION) || isModelportfolio}
          tooltip={getOptionTooltip(SAVING_PLAN_ACTION)}
        />
      </Grid>
    </Grid>
  )
}

export const RowNumberCell = {
  header: 'Nr.',
  body: {
    content: (item, options, index) => item.isSubItem ? '' : index + 1 ? `${index + 1}.` : '',
  },
  key: 'RowNumberCell'
};

const RowNumberCellWithFooterText = {
  ...RowNumberCell,
  footer: {
    content: (item, options) => {
      const footerText = _.get(options, 'footerData.text');
      if (!footerText) {
        return false;
      }

      if (['xs'].includes(options.width)) {
        return <WarningTooltip title={footerText}/>
      }

      return footerText
    }
  },
  colSpan: {footer: {default: 8, xs: 1}},
};

const openInstrumentInfo = (isin, link) => {
  if(link){
    openFundShopInfosIsin(isin, link)
  } else {
    openMorningStartIsin(isin)
  }
};

export const ProductNameCell = {
  header: 'Produktname',
  body: {
    content: (item, options) => {
      if(isSubDepotItem(item)) {
        return item.depot_sub_number;
      }

      const {isin} = item;

      if(!isin) {
        return item.name;
      }

      return (
        <span className={'link'} onClick={() => openInstrumentInfo(isin, options.virtualInstrumentLink)}>
          {item.name}
        </span>
      )
    },
    className: 'name bold',
    ellipsis: true
  },
  key: 'ProductNameCell'
};

export const MorningstarCategoryCell = {
  header: 'Morningstar-Kategorie',
  body: {
    content: (item) => isSubDepotItem(item) ? '' : item.ms_category
  },
  key: 'CategoryCell'
}

const getSharedSettings = (state) => state.get('sharedSettings')

const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
)


const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state)
});

const QuantityCellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    sharedSettings
  } = props;

  return (
    <>
      {item.error && <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.INSTRUMENT_TABLE_ERROR_MESSAGE_TEXT)}/>}
      {item.quantity || item.quantity === 0 ? getAssetQuantity(item.quantity) : '-'}
    </>
  )
});

const QuantityCell = {
  header: 'Stückzahl',
  body: {
    content: (item) => <QuantityCellContent item={item}/>
  },
  key: 'QuantityCell'
}

const PriceCell = {
  header: 'Kurs',
  body: {
    content: (item) => withEuroOrDash(item.price_eur)
  },
  key: 'PriceCell'
}

const DateCell = {
  header: 'Standdatum',
  body: {
    content: (item) => formatDateOrDash(item.price_date)
  },
  key: 'DateCell'
}

const ReturnPACellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    sharedSettings
  } = props;

  const classes = useTooltipStyles();
  if (item.pa_warning) {
    return (
      <span className={classes.warningWrapper}>
        -
        <div className={classes.warningAlignment}>
        <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, item.pa_warning)}/>
        </div>
      </span>
    )
  }

  if (item.profit_loss_percentage_pa || item.profit_loss_percentage_pa == 0) {
    return withPercentOrDash(item.profit_loss_percentage_pa, true);
  }

  if (!item.isin) {
    return (
      <span style={{padding: '8px 0 8px 16px'}}>
        -
      </span>
    )
  }

  return null

});

const HoldingPeriodCell = {
  header: 'Haltedauer',
  body: {
    content: (item) => (
      <span>
        {item.holding_period_days ? `${item.holding_period_days} d` : '-'}
      </span>
    )
  },
  key: 'HoldingPeriodCell'
}

const ReturnPACell = {

  header: 'p.a.',
  body: {
    content: (item) => {
      return (
        <span style={{color: getColorByValue(item.profit_loss_percentage_pa)}}>
          <ReturnPACellContent item={item} />
        </span>
      )
    }
  },
  key: 'ReturnPACell'
}

const MarketValueCell = {
  header: 'Wert',
  body: {
    content: (item) => withEuroOrDash(item.market_value)
  },
  key: 'MarketValueCell'
}

const MarketValueDifferenceCell = {
  header: '',
  body: {
    content: (item, options) => {
      const classes = useRebalancingInputsClasses()

      const itemChecked = !!(options.selected && options.selected.find(asset => asset.isin == item.isin))

      const valueInternal = React.useRef(item.newMarketValue)

      const onChange = (value) => {
        valueInternal.current = Math.abs(value)
      }

      const onBlur = () => options.onMarketValueChange && options.onMarketValueChange(item, valueInternal.current)

      return (
        <RebalancingDifferenceComponent
          item={item}
          value={withEuroOrDash(item.newMarketValue)}
          displayTransactionType
          renderInput={itemChecked && (() => (
            <InputFormElement
              value={valueInternal.current}
              inputComponent={CurrencyNumberFormat}
              inputProps={{inputProps: {allowNegative: false, prefix: item.transactionType === 'Verkauf' ? '-' : ''}}}
              custom_classes={classes}
              onChange={onChange}
              onBlur={onBlur}
            />
          ))}
        />
      )
    }
  },
  key: 'MarketValueDifferenceCell'
}

const StartValueCellHeader = connect(mapStateToProps)((props) => {
  const classes = useTooltipStyles()
  const {
    sharedSettings
  } = props;

  return(
      <span className={classes.warningWrapper}>
        {'Anfangsbestand'}
        <div className={classes.warningAlignment}>
          <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.START_VALUE_EXPLANATION) || NOT_FOUND}/>
        </div>
      </span>
    )
});

const BeginningValueCell = {
  header: {content: <StartValueCellHeader />},
  body: {
    content: (item) => withEuroOrDash(item.value_start)
  },
  key: 'BeginningValueCell'
}

const ReturnInvestedAmountCellHeader = connect(mapStateToProps)((props) => {
  const classes = useTooltipStyles()
  const {
    sharedSettings,
    options
  } = props;

  const tooltipKey = _.get(options, 'trans_saldo_explanation');

  return(
    <span className={classes.warningWrapper}>
      {'Kapitaleinsatz'}
      {!!tooltipKey &&
        <div className={classes.warningAlignment}>
          <WarningTooltip
            color={tooltipKey === 'TRANS_SALDO_WITH_BOOKINGS_EXPLANATION' ? '#c22c12': null}
            title={getHostRelatedSetting(sharedSettings.data,
            HOST_RELATED_SHARED_SETTINGS_KEYS[tooltipKey]) || NOT_FOUND}
          />
        </div>
      }
    </span>
  )
});

const InvestedAmountCell = {
  header: {
    content: (item, options) => {
      return <ReturnInvestedAmountCellHeader options={options.headerData} />
    }
  },
  body: {
    content: (item) => withEuroOrDash(item.invested_amount)
  },
  key: 'InvestedAmountCell'
}

const ReturnEuroCellExplanation = connect(mapStateToProps)((props) => {
  const {
    sharedSettings,
    valueToReplace,
    item,
  } = props;
  return (
    <WarningTooltip
      color={item && item.guv_bank_sell_some_quantity_explanation ? '#c22c12': null}
      title={
        <>
          {getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.GuV_VALUE_EXPLANATION,{
            "{{start_value}}": valueToReplace
          }) || NOT_FOUND}

          {item && item.guv_bank_sell_some_quantity_explanation &&
            <>
              <br/>
              {getHostRelatedSetting(sharedSettings.data,
                HOST_RELATED_SHARED_SETTINGS_KEYS[item.guv_bank_sell_some_quantity_explanation] || NOT_FOUND)}
            </>
          }
        </>
      }
    />
  )
});


const ReturnEuroCell = {
  header: 'GuV (€)',
  body: {
    content: (item) => (
      <span style={{color: getColorByValue(item.profit_loss_eur)}}>
        {item.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(item.profit_loss_eur)}
      </span>
    )
  },
  key: 'ReturnCellEuro'
}

const ReturnPercentageCell = {
  header: 'GuV (%)',
  body: {
    content: (item) => {
      const classes = useTooltipStyles()
      let value = formatQty(item.profit_loss_eur / item.profit_loss_perc, 2);

      return (
        <span style={{color: getColorByValue(item.profit_loss_perc)}} className={classes.warningWrapper}>
            {item.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(item.profit_loss_perc, true)}
          <div className={classes.warningAlignment}>
            <ReturnEuroCellExplanation item={item} valueToReplace={value}/>
          </div>
        </span>
    )}
  },
  key: 'ReturnCellPercentage'
}

const ReturnPercentagePACellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    options,
    sharedSettings
  } = props;

  const classes = useTooltipStyles();
  return(
    <>
      {options.footerData.portfolio_pa_warning
        ? (
          <span className={classes.warningWrapper}>
            -
            <div className={classes.warningAlignment}>
              <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, options.footerData.portfolio_pa_warning) || NOT_FOUND}/>
            </div>
          </span>
        )
        : <span style={{color: getColorByValue(options.footerData.profit_loss_percentage_pa)}} className={'percentagePA'}>
            {options.footerData.profit_loss_percentage_pa > 0 ? '+' : ''}{withPercentOrDash(options.footerData.profit_loss_percentage_pa || 0, true)}
          </span>
      }
    </>
  )
})

const ReturnPercentagePACell = {
  key: 'ReturnCellPercentagePA',
  expandedItemKey: 'ReturnPACell' // key to show/hide cell depending on custom report config
}

const WeightCell = {
  header: 'Gewichtung',
  body: {
    content: (item) => withPercentOrDash(item.weight, true)
  },
  key: 'WeightCell'
}

const PortfolioWeightCell = {
  header: {
    content: (item, options) => getRebalancingWeightHeader(options.portfolioData)
  },
  body: {
    content: (item) => withPercentOrDash(item.pWeight, true)
  },
  key: 'PortfolioWeightCell'
}

const ModelPortfolioWeightCell = {
  header: {
    content: (item, options) => getRebalancingWeightHeader(options.modelPortfolioData)
  },
  body: {
    content: (item) => withPercentOrDash(item.mpWeight, true)
  },
  key: 'ModelPortfolioWeightCell'
}

const useRebalancingInputsClasses = makeStyles(() => ({
  inputRoot: {
    height: 25,
    marginBottom: 5,
    fontSize: '0.875rem'
  }
}))

const WeightDifferenceCell = {
  header: 'Differenz',
  body: {
    content: (item, options) => {

      const classes = useRebalancingInputsClasses()

      const itemChecked = !!(options.selected && options.selected.find(asset => asset.isin == item.isin))

      const valueInternal = React.useRef((item.weightDifference || 0) * 100)

      const onChange = (value) => {
        valueInternal.current = Math.abs(value)
      }

      const onBlur = () => options.onWeightDifferenceChange && options.onWeightDifferenceChange(item, valueInternal.current && valueInternal.current / 100)

      return (
        <RebalancingDifferenceComponent
          item={item}
          value={withPercentOrDash(item.weightDifference)}
          renderInput={itemChecked && (() => (
            <InputFormElement
              value={valueInternal.current}
              inputComponent={PercentageNumberFormat}
              inputProps={{inputProps: {allowNegative: false, prefix: item.transactionType === 'Verkauf' ? '-' : ''}}}
              custom_classes={classes}
              onChange={onChange}
              onBlur={onBlur}
            />
          ))}
        />
      )
    }
  },
  key: 'WeightDifferenceCell'
}

const TradingActionsCell = {
  header: {
    content: (item, options) => {
      let { trading: {
        subDepotTradeEnabled,
        isModelportfolio,
        onAddTradingOption,
        portfolio,
        custodiansInstrumentsData,
        loading,
        tradeTransactions,
        disabled,
        bankMapping,
        brokerLicense,
        customerSrri,
        handleAddPortfolioTradingOption,
        handleWholeDepotOrderChange,
        isTradingAvailable,
        wholeDepotTradingAction,
        canTradePortfolios,
        portfolioId
      }} = options;

      const [BUY_ACTION, SELL_ACTION, SWITCH_ACTION, SAVINGS_PLAN_ACTION] = TRADING_ACTIONS;
      let value = "default";
      if (isModelportfolio) {
        if (!!tradeTransactions) {
          value = tradeTransactions[portfolioId];
        } else if (!!portfolio) {
          const tradingType = portfolio.tradingType;
          if (!!tradingType) {
            const tradingAction = TRADING_ACTIONS.find(o => o.code === tradingType);
            if (tradingAction){
              value = tradingAction.value;
            }
          }
        }
      } else {
        value = wholeDepotTradingAction
      }

      let savingsPlanValue = false;
      if (isModelportfolio) {
        const savingPlanInternalId = getInternalIdForNewPaymentPlan(
          portfolioId, TRADING_ACTION_SAVINGS_PLAN);

        const savingPlantTradingOption = _.find(
          _.get(portfolio, 'instruments') || [], instrument => instrument.instrumentId == savingPlanInternalId);
        // tradingType info from existing trade session
        // We could have multiple savings plans for MP. In that case instrumentId will be with savings plan index.
        // That is why we need to check if at least one savings plan exist (instrumentId.startsWith(savingPlanInternalId))
        savingsPlanValue = !!_.find(
          Object.keys(tradeTransactions || {}),
          (instrumentId) => instrumentId.startsWith(savingPlanInternalId))
          || !!savingPlantTradingOption;
      }

      const isOptionDisabled = (option) => {
        if (disabled) return true;

        if (isModelportfolio) return option.value == SWITCH_ACTION.value;

        return !canTradePortfolios || !option.isWholeDepotOrder;
      }

      const getOptionTooltip = (option) => {
        if (disabled) return 'Es existiert bereits ein offener Ordervorgang';

        if (isOptionDisabled(option)) {
          return 'Orderart für dieses Produkt nicht verfügbar.'
        }

        if (isModelportfolio) return option.actionButtonTooltip;

        return `Alle ${option.actionButtonTooltip}`;
      }

      const getOnClickHandler = (option) => {
        const handler = isModelportfolio
          ? handleAddPortfolioTradingOption
          : handleWholeDepotOrderChange;

        return () => handler({target: {value: value == option.value ? 'default' : option.value}})
      }

      return (
        <div>
          <span>Order-Aktionen</span>
          {!!isTradingAvailable && (
            <>
              {loading ? (<Skeleton />) : (
                <Grid container spacing={1} style={{marginTop: 5}}>
                  {[BUY_ACTION, SELL_ACTION, SWITCH_ACTION].map((action) => (
                    <Grid item>
                      <TradingAction
                        title={action.actionButtonText}
                        onClick={getOnClickHandler(action)}
                        selected={value==action.value}
                        disabled={isOptionDisabled(action)}
                        tooltip={getOptionTooltip(action)}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <TradingAction
                      title={SAVINGS_PLAN_ACTION.actionButtonText}
                      onClick={() => handleAddPortfolioTradingOption({target: {value: savingsPlanValue ? 'default' : SAVINGS_PLAN_ACTION.value}}, TRADING_ACTION_SAVINGS_PLAN)}
                      selected={savingsPlanValue}
                      disabled={isOptionDisabled(SAVINGS_PLAN_ACTION)}
                      tooltip={getOptionTooltip(SAVINGS_PLAN_ACTION)}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </div>
      );
    }
  },
  body: {
    content: (item, options) => {

      let { trading: {
        subDepotTradeEnabled,
        isModelportfolio,
        onAddTradingOption,
        portfolio,
        custodiansInstrumentsData,
        loading,
        tradeTransactions,
        disabled,
        bankMapping,
        brokerLicense,
        customerSrri,
        wholeDepotTradingAction
      }} = options;

      let isDisabled = disabled;
      if (!subDepotTradeEnabled) {
        // if sub depot trading is not enabled - do not render trading select for sub depots
        if (isSubDepotItem(item)){ return null; }
      } else if (!item.isSubItem && !_.isEmpty(item.component_sub_depots || [])){
        // if sub depot trading is enabled - do not render select for *combined* item with sub depots
        return null;
      }

      if(loading){
        return <Skeleton />
      }

      return (
        <TradingActions
          isModelportfolio={isModelportfolio}
          item={item}
          onChange={onAddTradingOption}
          value={undefined}
          custodiansInstrumentsData={custodiansInstrumentsData}
          tradings={portfolio ? portfolio.instruments : []}
          availableAssetClasses={bankMapping ? bankMapping.available_asset_classes : {}}
          tradeTransactions={tradeTransactions}
          disabled={isDisabled}
          brokerLicense={brokerLicense}
          customerSrri={customerSrri}
          isVirtual={options.isVirtual}
          wholeDepotTradingAction={wholeDepotTradingAction}
        />
      )
    }
  },
  key: 'TradingActionsCell'
}

const Volatility1y = {
  header: {
    content: () => (<span style={{whiteSpace: 'nowrap'}}>Volatilität<br />1 Jahr</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '1y')
  }
}

const Volatility3y = {
  header: {
    content: () => (<span>Volatilität<br />3 Jahre</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '3y')
  }
}

const Volatility5y = {
  header: {
    content: () => (<span>Volatilität<br />5 Jahre</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '5y')
  }
}

const MWVColumn = {
  header: {
    content: () => (<span>Maximaler Wertverlust</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, 'max', 'maximum_drawdown')
  }
}

const ActionsColumn = {
  header: {
    content: (item, options) => (_.get(options, 'trading.isVirtualOrderEnabled') ? 'Order' : '')
  },
  body: {
    content: (item, options) => {
      const classes = useVirtualTableStyles();
      return (
        <VirtualPortfolioActionsManager
          resource={options.resource}
          asset={item}
          classes={classes}
          portfolio_id={options.virtual.portfolioId}
          customer_id={options.customer_id}
          refresh={options.virtual.refresh}
          numberOfAssets={options.virtual.numberOfAssets}
          tradingActionsComponent={_.get(options, 'trading.isVirtualOrderEnabled') && TradingActionsCell.body.content(item, {...options, isVirtual: true})}
        />
      )}
  },
  key: 'ActionsColumn'
}

export const NOTIFICATION_TURNED_ON_ICON = 'fa fa-bell'
export const NOTIFICATION_TURNED_OFF_ICON = 'fa fa-bell-slash-o'


const AlarmColumn = {
  header: {
    content: () => (<span>ALARM</span>)
  },
  body: {
    content: (item, options) => {

      const {onSelectAsset} = useAlertConfigurationModalContext()

      const handleButtonClick = () => {
        onSelectAsset && onSelectAsset(
          {...item, depot_number: options.portfolio.number}, ALERT_PRODUCT_TYPE.ASSET, options.portfolio)
      }

      const alarmExists = !_.isEmpty(item.alerting_configuration)

      return (
        <AlarmButton
          alarmExists={alarmExists}
          alarmActive={alarmExists && _.find(item.alerting_configuration, (configuration) => configuration.active)}
          onClick={handleButtonClick}
        />
      )
    }
  }
}

export const alertsCustomersOverviewTableStructure = {
  default: [
    {
      content: [RowNumberCell]
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [AlarmColumn]
    }
  ]
}

const tableStructure = {
  default: [
    {
      content: [RowNumberCellWithFooterText],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCellWithFooterText],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
  ]
}

export const instrumentsTableStructure = tableStructure;

export const riskIndicatorsTableStructure = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [Volatility1y]
    },
    {
      content: [Volatility3y]
    },
    {
      content: [Volatility5y]
    },
    {
      content: [MWVColumn]
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell],
      align: 'right'
    },
    {
      content: [AlarmColumn]
    }
  ],
  xs: [
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell],
      align: 'right'
    },
    {
      content: [AlarmColumn]
    }
  ]
}

export const tableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
  ]
}

export const tradingTableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ]
}

export const tableStructureTrading = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    },
  ]
}

export const getTableStructureSwitch = (withSustainability) => {
  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [MorningstarCategoryCell]
      },
      {
        content: [QuantityCell, PriceCell, DateCell],
        align: 'right'
      },
      {
        content: [MarketValueCell, InvestedAmountCell],
        align: 'right'
      },
      {
        content: [ReturnEuroCell, ReturnPercentageCell, ReturnPACell, ReturnPercentagePACell],
        align: 'right'
      },
      {
        content: [WeightCell],
        align: 'right'
      }
    ],
      xs: [
      {
        content: [InstrumentNameCell, MarketValueCell]
      },
      {
        content: [ReturnEuroCell, ReturnPercentageCell, ReturnPercentagePACell],
        align: 'right'
      },
    ]
  };

  if (withSustainability) {
    structure.default.splice(1, 0, {content: [SustainabilityCell], cellClassName: 'sustainability-cell'})
  }

  return structure;
};


export const tableStructureVirtual = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ]
}

export const virtualTableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ActionsColumn],
      align: 'right'
    }
  ]
}

export const getTableStructureRebalancing = (withSustainability) =>  {

  const sustainabilityCellConfig =  {
    content: [SustainabilityCell],
    cellClassName: 'sustainability-cell'
  };

  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [MorningstarCategoryCell]
      },
      sustainabilityCellConfig,
      {
        content: [SRRIColumn],
        align: 'right'
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      md: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      sm: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      xs: [
      {
        content: [RowNumberCell],
        cellClassName: 'row-number'
      },
      {
        content: [InstrumentNameCell, SRRIColumn]
      },
      sustainabilityCellConfig,
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      },
    ]
  }

  if (withSustainability) {
    structure.md.splice(1, 0, sustainabilityCellConfig);
    structure.sm.splice(1, 0, sustainabilityCellConfig);
  }

  return structure;
};

export const tableStructureGrouping = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    }
  ],
}

export const tableStructureGroupingByProduct = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    }
  ],
}

export default tableStructure;
