import React from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";
import {AGGREGATED_PORTFOLIO_ID} from "../../../utils/utils";

export function useDepotFinancialData(customerId, portfolios, startDate, endDate, withHistorical, excludedDepotId, dataProvider) {

  const [data, setData] = React.useState({
    data: null,
    loading: true,
    error: null,
    updatedAt: +new Date(),
  });

  const fetch = async () => {

    setData({
      data: null,
      loading: true,
      error: null,
      updatedAt: +new Date()
    })

    try {
      const portfoliosIds = _.isEmpty(portfolios)
        ? [AGGREGATED_PORTFOLIO_ID] // Use aggregated depot id in case customer does not have active depots
        : portfolios.map((portfolio) => portfolio.depotNumber);
      const response = await dataProvider.getDepotFinancialData(
        customerId, portfoliosIds, startDate, endDate, withHistorical, undefined, undefined, excludedDepotId);
      parseResponse(response, 'financial', (data) => {
        setData({
          data: data,
          loading: false,
          error: null,
          updatedAt: +new Date()
        })
      }, (error) => {
        setData({
          data: null,
          loading: false,
          error: error,
          updatedAt: +new Date()
        })
      })
    } catch (error) {
      setData({
        data: null,
        loading: false,
        error: error,
        updatedAt: +new Date()
      })
    }
  }

  React.useEffect(() => {
    if (!!customerId && !_.isNil(portfolios)) {
      fetch();
    }
  }, [customerId, JSON.stringify(portfolios), startDate, endDate, withHistorical]);

  return [data, fetch]

}