import React from 'react';
import {withRouter} from "react-router-dom";
import _ from "lodash";

import DashboardTable from "../../DashboardTable";
import tableStructure, {
  tableStructureTrading,
  tableStructureDepotstatus,
  tradingTableStructureDepotstatus,
  tableStructureVirtual,
  virtualTableStructureDepotstatus,
  tableStructureGrouping, tableStructureGroupingByProduct
} from "./table-data";

import useStyles, { useTradingTableClasses, useDepotstatusClasses, useVirtualTableClasses, useVirtualTableDepotstatusClasses } from "./styles";

import {isTradingEnabled} from "../../TradingStore/utils";
import {GROUP_ACTION_DEPOT, GROUP_ACTION_PRODUCT} from "./constants";
import {getSubSystemConfigItem} from "../../../utils/utils";
import {
  REPORT_TYPES
} from "../../../containers/DashboardSettings/components/CustomersList/components/ReportType/constants";


export const buildComponentsWithSubItemsDataSource = (components, isMpOrPi, withCompletelySoldAssets=false, subComponentsFieldName='component_sub_depots') => {
  return _.flatten((components || []).map((component) => {
    if (withCompletelySoldAssets && component.is_profit_loss) {
      subComponentsFieldName = 'sub_components';
    }

    const subItems = (component[subComponentsFieldName] || []);
    const isHeader = !!subItems.length;
    return [{
      ...component,
      isMpOrPi,
      tableProps: {
        withoutHeader: !isHeader,
        isHeader
      }}, ...subItems.map((item) => ({...item, 'isSubItem': true}))]
  }))
}

const InstrumentsAllocationTable = (props) => {
  const classes = useStyles();
  const tradingClasses = useTradingTableClasses()
  const depotstatusClasses = useDepotstatusClasses()
  const virtualTableClasses = useVirtualTableClasses();
  const virtualDepotstatusClasses = useVirtualTableDepotstatusClasses();

  const {
    data,
    user,
    virtualInstrumentLink,
    expanded,
    expandedSubItems,
    onExpandedSubItems,
    match: {
      params: {
        customer_id,
        id,
      }
    },
    isCustomerApp,
    onAddTradingOption,
    tradings,
    tradeTransactions,
    reportType,
    isVirtual,
    refresh,
    isModelportfolio,
    isPtfTradingDisabled,
    custodiansInstrumentsData,
    loading,
    customerData,
    banksMapping,
    bankCode,
    instrumentsGroupBy,
    footerText,
    withCompletelySoldAssets
  } = props;

  const handleExpandedSubItemsChange = (elemId) => {
    onExpandedSubItems(data.id, {
      ...expandedSubItems,
      [elemId]: _.isNil(_.get(expandedSubItems, elemId)) ? true : !_.get(expandedSubItems, elemId)})
  };

  const dataSource =  React.useMemo(() => {
    const isMpOrPi = data.is_model_portfolio || data.is_private_investment;
    return buildComponentsWithSubItemsDataSource(data.components, isMpOrPi, withCompletelySoldAssets)
  }, [data, withCompletelySoldAssets]);

  let tradingEnabled = isTradingEnabled();

  const headerData = {
    trans_saldo_explanation: data.trans_saldo_explanation
  }

  let tableStructureResult;
  let tableClasses;
  let isDepotStatus = reportType == REPORT_TYPES.DEPOT_STATUS.value;

  if (instrumentsGroupBy && instrumentsGroupBy !== GROUP_ACTION_DEPOT) {
    tableStructureResult = instrumentsGroupBy === GROUP_ACTION_PRODUCT
      ? tableStructureGroupingByProduct
      : tableStructureGrouping;
    tableClasses = classes;
  } else if (tradingEnabled && !isCustomerApp && !isPtfTradingDisabled) {
    tableStructureResult = isDepotStatus ? tradingTableStructureDepotstatus : tableStructureTrading;
    tableClasses = isDepotStatus ? depotstatusClasses : tradingClasses
  } else {
    if(isVirtual){
      tableStructureResult = isDepotStatus ? virtualTableStructureDepotstatus : tableStructureVirtual;
      tableClasses = isDepotStatus ? virtualDepotstatusClasses : virtualTableClasses;
    } else {
      tableStructureResult = isDepotStatus ? tableStructureDepotstatus : tableStructure;
      tableClasses = isDepotStatus ? depotstatusClasses : classes;
    }
  }

  return <DashboardTable
    structure={tableStructureResult}
    dataSource={dataSource}
    expanded={expanded}
    withFooter={!!footerText}
    handleExpandedSubItemsChange={handleExpandedSubItemsChange}
    virtualInstrumentLink={virtualInstrumentLink}
    useInternalCollapse
    useItemColorAsBorder={instrumentsGroupBy != GROUP_ACTION_DEPOT}
    options={{
      resource: props.resource,
      customer_id: customer_id || id,
      headerData,
      footerData: {text: footerText},
      trading: {
        loading: loading,
        subDepotTradeEnabled: getSubSystemConfigItem('trading', 'sub_depots_trade_enabled'),
        isModelportfolio,
        isVirtualOrderEnabled: props.isVirtualOrderEnabled,
        onAddTradingOption,
        handleAddPortfolioTradingOption: props.handleAddPortfolioTradingOption,
        handleWholeDepotOrderChange: props.handleWholeDepotOrderChange,
        portfolio: tradings,
        portfolioId: data.id,
        isTradingAvailable: props.isTradingAvailable,
        canTradePortfolios: props.canTradePortfolios,
        wholeDepotTradingAction: props.wholeDepotTradingAction,
        tradeTransactions: _.get(tradeTransactions, data.id),
        disabled: !isVirtual && !!tradeTransactions,  // disabled in case there are Trade Session transactions
        custodiansInstrumentsData: custodiansInstrumentsData,
        bankMapping: banksMapping && banksMapping.hasOwnProperty(bankCode) && banksMapping[bankCode],
        brokerLicense: user && user.bca_level_id,
        customerSrri: customerData && customerData.last_srri && customerData.last_srri.srri,
      },
      virtual: {
        portfolioId: data.id,
        numberOfAssets: data.number_of_assets,
        refresh: refresh
      },
      skipIndexReset: true
    }}
    tableClasses={tableClasses}
    showCell={props.showCell}
  />
};


export default withRouter(InstrumentsAllocationTable);