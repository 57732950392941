import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {getBanksCompanyIdToCodeMapping, getTradingOption} from "../../containers/Trades/utils";
import {QuestionnairesHandlerResource} from '../../utils/api';
import {
  OBJECT_TYPES,
  PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_DASHBOARD_MSG,
} from "../../containers/RiskProfiling/constants";
  import {
  getCustomerTradings, isPaymentPlanType, isSavingsPlansEnabled,
  isTradingEnabled,
} from '../TradingStore/utils';
import {
  removeAllCombinedTradings,
  addPortfolioCombinedTradingOption,
  addCombinedTradingOption,
  removeCombinedTradingOption,
  removePortfolioCombinedTradingOption,
  updatePortfolioCombinedTradingOption,
} from '../TradingStore/actions';
import {
  TRADING_ACTION_PAYOUT_PLAN,
  TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SWITCH_PLAN,
  TRADING_ACTIONS,
} from "../Charts/InstrumentsAllocationTable/constants";
import {
  extendPaymentPlanWithInstrumentData,
  extendPortfoliosWithDataForTrading,
  extendPortfolioWithSavingsPlans,
  preparePortfoliosForCombinedTrading,
  hasUnusedPaymentPlans,
  paymentPlanHasPosition,
  portfolioIsPaymentPlansOnly,
  tradeDetailsRedirect,
  getAssetInternalId,
  getKeyFieldValue,
  getAssetInternalIdForNewPaymentPlan,
  getInternalIdForNewPaymentPlan
} from "../../containers/CustomerDashboard/utils";
import {createSelector} from "reselect";
import {useConfirmationModalContext} from "../ConfirmationModalContextProvider";
import {getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import {displaySuccessSnackBar} from "../SnackbarProvider/actions";

const getCombinedTrading = (state) => state.get('combinedTrading')

export const getCombinedTradingSelector = createSelector(
  [getCombinedTrading],
  (combinedTrading) => combinedTrading.toJS()
)

const mapStateToProps = (state) => ({
  combinedTrading: getCombinedTradingSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state)
});

const defaultDataBody = {
  data: {},
  loading: false,
};

  /**
   * HOC for retrieving tradings data
   */
const withTradingsData = (WrappedComponent, isV2=false) => {
  return connect(mapStateToProps)((props) => {
    const {
      customer,
      combinedTrading,
      payoutPlansData,
      switchPlansData,
      investmentPlatform,
      instrumentList,
      profitAndLoss,
      paymentPlans,
      isCustomerApp
    } = props;

  const [banksMapping, setBanksMapping] = React.useState({...defaultDataBody});
  const [banksData, setBanksData] = React.useState({...defaultDataBody});
  const [combinedTradingSession, setCombinedTradingSession] = React.useState({data: undefined, loading: false});

  const modalContext = useConfirmationModalContext();

  const customerCombinedTradings = () => {
    return customer && customer.data ? getCustomerTradings(combinedTrading.customers, customer.data.customer_id) : []
  };

  const fetchBanksData = async () => {
    // load bank data in case trading enabled and no data loaded before
    if(isTradingEnabled() || isSavingsPlansEnabled()) {
        if(_.isEmpty(banksMapping.data) && !banksMapping.loading){
            try {
              setBanksMapping({
                    ...banksMapping,
                    loading: true
                });
                let response = await getBanksCompanyIdToCodeMapping()
                setBanksMapping({
                    data: response,
                    loading: false
                });
            } catch (error) {
              setBanksMapping({
                    data : {},
                    loading: false
                });
            }

        }

      if(_.isEmpty(banksData.data) && !banksData.loading){
        try {
          setBanksData({
            ...banksData,
            loading: true
          });
          const banksCIOSData = await QuestionnairesHandlerResource.getBanksData();
          setBanksData({
            data: banksCIOSData.response,
            loading: false
          });
        } catch (error) {
          setBanksData({
            data: [],
            loading: false
          });
        }
      }
      if(_.isEmpty(banksMapping.data) && !banksMapping.loading){
        try {
          setBanksMapping({
                ...banksMapping,
                loading: true
            });
            let response = await getBanksCompanyIdToCodeMapping()
            setBanksMapping({
                data: response,
                loading: false
            });
        } catch (error) {
          setBanksMapping({
                data : {},
                loading: false
            });
        }
      }

      if(!customer.loading && customer.data) {
        if (_.isUndefined(combinedTradingSession.data) && !combinedTradingSession.loading) {
          try {
            setCombinedTradingSession({
              data: undefined,
              loading: true
            });

            const res = await QuestionnairesHandlerResource.getLatestSession(
              customer.data.customer_id, OBJECT_TYPES.COMBINED, false);
            let data = null;
            const sessionStarted = !!res.onboarding_answers;

            const _getTradingTypeValue = (tType) => {
              return _.get(TRADING_ACTIONS.find(o => o.code === tType), 'value');
            }

            if(sessionStarted) {
              data = {};

              // in case "continue" but there is memory data - clean it
              props.dispatch(removeAllCombinedTradings(customer.data.customer_id));

              // set transaction data from Trading session

              const transactionsQuestion = _.get(res, 'onboarding_answers.trade.trade-step', []).find(q => q.question_uid === 'transactions') || {};
              _.get(transactionsQuestion, 'answer[0]', []).map(portfolio => {
                Object.keys(portfolio.transactions).map(transaction_code => {
                  const tradingType = _getTradingTypeValue(transaction_code);
                  if (tradingType) {
                    portfolio.transactions[transaction_code].map((instrument, idx) => {

                      let instrumentId = (data) => {
                        return !data.isModelportfolio
                          ? getAssetInternalId(data)
                          : data.id;
                      }, transactionCode = tradingType;

                      if(isPaymentPlanType(tradingType)){
                        transactionCode = instrument.action;
                        if (transactionCode != 'create') {
                          // Use payment_id as instrumentId only for edit/delete (existing payment plans).
                          instrumentId = 'payment_id';
                        } else {
                          instrumentId = (data) => {
                            return !data.isModelportfolio
                              ? getAssetInternalId(data)
                              // For model portfolios we could have multiple payment plans
                              // so extend instrument id with index so we could count orders correctly
                              : `${getInternalIdForNewPaymentPlan(data.id, tradingType)}_${idx}`;
                          }
                        }
                      }
                      _.setWith(
                        data, `${portfolio.portfolioId}.${getKeyFieldValue(instrument.data, instrumentId)}`,
                        transactionCode, Object);
                    });
                  }
                });
              });

              // if no transactions - restore prev selected instruments
              if(_.isEmpty(data)){
                _.get(res, 'onboarding_answers.products_selection.portfolios', []).map(portfolio => {
                  portfolio.instruments.map(instrument => {
                    _.setWith(
                      data, `${portfolio.portfolioId}.${instrument.instrumentId}`,
                      isPaymentPlanType(instrument.tradingType)
                        ? instrument.action
                        : instrument.tradingType, Object);
                  });
                  // Portfolio.instruments has transactions only for normal instruments and paument plans for normal instruments/MP.
                  // If we want to know if buy/sell was selected for MP we need to check it separately by portfolio.tradingType
                  if (portfolio.isModelportfolio && !!portfolio.tradingType) {
                    _.setWith(
                      data, `${portfolio.portfolioId}.${portfolio.portfolioId}`,
                      _getTradingTypeValue(portfolio.tradingType), Object);
                  }
                });
              }
            }

            setCombinedTradingSession({
              data: data,
              loading: false
            });

          } catch (e) {
            setCombinedTradingSession({
              data: undefined,
              loading: false
            });
          }
        }
      }
    }
  };

  const goToTradeDetails = async (event) => {
    if (investmentPlatform.routes && customer.data.customer_id) {
      // start "new Buy" process: (re)build all data. ex: payment plans
      // in case no session or new transactions defined from dashboard
      let tradings = customerCombinedTradings(); // get tradings for current customer from all tradings in redux
      const tradingExists = Array.isArray(tradings) && tradings.length;
      // if there is no previously started order process (combinedTradingSession.data - transactions build from onboarding answers)
      // or there are tradings stored in redux
      if(!combinedTradingSession.data || tradingExists) {
        // prepares all portfolios for trading
        let portfolios = preparePortfoliosForCombinedTrading({
          instrumentListPortfolios: instrumentList.data,
          profitAndLossPortfolios: profitAndLoss.data,
          savingPlansPortfolios: paymentPlans.data,
          payoutPlansPortfolios: payoutPlansData.data,
          switchPlansPortfolios: switchPlansData.data,
          banksMappingData: banksMapping.data,
          banksData: banksData.data
        })

        let [portfoliosToAdd, portfoliosToUpdate] = [portfolios, []]

        // if there are transactions in store
        if (tradingExists) {
          // from all portfolios, remove those that has tradings in store ToDo check why do wee loop over portfolios twice
          portfoliosToAdd = portfolios
            .filter((portfolio) => !_.find(tradings, (tPortfolio) => tPortfolio.portfolioId === portfolio.id))
          // contains portfolios that already were in store
          portfoliosToUpdate = portfolios
            .filter((portfolio) => _.find(tradings, (tPortfolio) => tPortfolio.portfolioId === portfolio.id))
        }

        extendPortfoliosWithDataForTrading(portfolios, banksMapping.data, banksData.data);

        // TODO: Prepare better solution
        if (portfoliosToAdd.length) {
          await props.dispatch(addPortfolioCombinedTradingOption(customer.data, portfoliosToAdd, undefined, undefined, !tradingExists));
        }
        if (portfoliosToUpdate.length) {
          // is called to set payment plans for portfolio tradings in redux
          await Promise.all(portfolios.map((portfolio) => {
            return props.dispatch(updatePortfolioCombinedTradingOption(customer.data.customer_id, portfolio.id, {}, {
              "savings_plan": portfolio.savings_plan,
              "payout_plan": portfolio.payout_plan,
              "switch_plan": portfolio.switch_plan
            }))
          }))
        }
      }

      tradeDetailsRedirect(investmentPlatform.routes, 'TRADE_DETAILS', customer.data.customer_id, (url) => {
        props.history.push(url)
      })
    }
  };

  const displayTradingSnackbar = (tradingType) => {

    if (!isV2) {
      return;
    }

    const message = tradingType == 'default'
      ? 'Order erfolgreich abgewählt.'
      : 'Order erfolgreich hinzugefügt.';

    props.dispatch(displaySuccessSnackBar(message, 3));
  }

  // deletePaymentPlanTradingType used when we are deleting transaction because we are not able to detect
  // if transaction is payment plan from trading type because of "default" value
  const handleAddTradingOption = (portfolio, instrument, tradingType, deletePaymentPlanTradingType) => {

    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data);

    // In BCA-9202 possibility to create new payment plans from dashboard was added.
    const isPaymentPlan = isPaymentPlanType(tradingType);
    const paymentPlanAction = isPaymentPlan ? 'create' : undefined;

    if (tradingType != 'default') {
      props.dispatch(addCombinedTradingOption(customer.data, portfolio, instrument, tradingType, paymentPlanAction));
    } else {

      const isPaymentPlan = isPaymentPlanType(deletePaymentPlanTradingType);
      // Buy/Sell/Switch works via OR. Means we could create one of this transactions at the same time.
      // In BCA-9202 added possibility to create payment plans TOGETHER with other transaction so we need to build
      // uniq identifier for new payment plan transaction so it could be handled via AND statement.
      const instrumentInternalId = isPaymentPlan
        ? getAssetInternalIdForNewPaymentPlan(instrument, deletePaymentPlanTradingType)
        : getAssetInternalId(instrument);

      props.dispatch(removeCombinedTradingOption(customer.data.customer_id, portfolio.id, instrumentInternalId));
    }
    displayTradingSnackbar(tradingType);
  }

  const handleAddSavingPlanOption = async (savingPlan, instrument, action) => {

    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == savingPlan.portfolio_id);
    let paymentPlansPortfolio = _.find(paymentPlans.data, (p) => p.portfolio_id == savingPlan.portfolio_id);
    const portfolioTransactions = _.find(customerCombinedTradings(), (p) => {
      if (_.get(p, 'data.portfolio_id') == savingPlan.portfolio_id) {
        return _.get(p, 'instruments', []).filter(i => i.tradingType === TRADING_ACTION_SAVINGS_PLAN);
      }
    });

    const deleteConfirmationRequired = action === 'delete'
      && portfolioIsPaymentPlansOnly(portfolio.components, paymentPlansPortfolio && paymentPlansPortfolio.components)
      && paymentPlanHasPosition(instrument, portfolio.components)
      && !hasUnusedPaymentPlans(instrument.isin, instrument.payment_id,
        paymentPlansPortfolio && paymentPlansPortfolio.components,
        portfolioTransactions && portfolioTransactions.instruments || [])

    if (deleteConfirmationRequired && !await modalContext.confirm(PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_DASHBOARD_MSG, 'Bitte bestätigen Sie hier')) {
      return
    }

    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                       data for portfolio with id ${savingPlan.portfolio_id}`)
    }

    portfolio = _.cloneDeep(portfolio);
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data)
    extendPortfolioWithSavingsPlans(portfolio, savingPlan);

    if (action != 'default') {
      props.dispatch(
        addCombinedTradingOption(customer.data, portfolio, instrument, TRADING_ACTION_SAVINGS_PLAN, action)
      );
    } else {
      props.dispatch(removeCombinedTradingOption(customer.data.customer_id, portfolio.id, instrument.payment_id));
    }
    displayTradingSnackbar(action);
  }

  const handleAddPayoutPlanOption = (savingPlan, instrument, action) => {

    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == savingPlan.portfolio_id);
    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                     data for portfolio with id ${savingPlan.portfolio_id}`)
    }

    portfolio = _.cloneDeep(portfolio);
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data)
    extendPortfolioWithSavingsPlans(portfolio, savingPlan);

    if (action != 'default') {
      props.dispatch(
        addCombinedTradingOption(customer.data, portfolio, instrument, TRADING_ACTION_PAYOUT_PLAN, action)
      );
    } else {
      props.dispatch(removeCombinedTradingOption(customer.data.customer_id, portfolio.id, instrument.payment_id));
    }
    displayTradingSnackbar(action);
  };

  const handleAddSwitchPlanOption = (savingPlan, instrument, action) => {

    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == savingPlan.portfolio_id);
    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                   data for portfolio with id ${savingPlan.portfolio_id}`)
    }

    portfolio = _.cloneDeep(portfolio);
    const instrumentCopy = extendPaymentPlanWithInstrumentData(instrument, portfolio.components || [])
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data)
    extendPortfolioWithSavingsPlans(portfolio, savingPlan);

    if (action != 'default') {
      props.dispatch(
        addCombinedTradingOption(customer.data, portfolio, instrumentCopy, TRADING_ACTION_SWITCH_PLAN, action)
      );
    } else {
      props.dispatch(removeCombinedTradingOption(customer.data.customer_id, portfolio.id, instrumentCopy.payment_id));
    }
    displayTradingSnackbar(action);
  }

  const handleAddPortfolioTradingOption = (portfolio, tradingType, deletePaymentPlanTradingType) => {
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data);

    // In BCA-9202 possibility to create new payment plans from dashboard was added.
    const isPaymentPlan = isPaymentPlanType(tradingType);
    const paymentPlanAction = isPaymentPlan ? 'create' : undefined;

    if (tradingType != 'default') {
      if (!isPaymentPlan) {
        props.dispatch(addPortfolioCombinedTradingOption(
          customer.data, portfolio, getTradingOption(tradingType).code));
      } else {
        props.dispatch(
          addCombinedTradingOption(
            customer.data, portfolio, {...portfolio, isin: portfolio.id},
            tradingType, paymentPlanAction)
        );
      }
    } else {
      const isPaymentPlan = isPaymentPlanType(deletePaymentPlanTradingType);
      if (!isPaymentPlan) {
        props.dispatch(removePortfolioCombinedTradingOption(customer.data.customer_id, portfolio.id));
      } else {
        props.dispatch(removeCombinedTradingOption(
          customer.data.customer_id, portfolio.id,
          getInternalIdForNewPaymentPlan(portfolio.id, deletePaymentPlanTradingType)));
      }
    }
    displayTradingSnackbar(tradingType);
  }

  const handleAddPortfolioSavingPlanOption = (portfolioId, action) => {
    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == portfolioId);
    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                       data for portfolio with id ${portfolioId}`)
    }

    portfolio = _.cloneDeep(portfolio);
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data);

    if (action != 'default') {
      props.dispatch(
        addPortfolioCombinedTradingOption(customer.data, portfolio, TRADING_ACTION_SAVINGS_PLAN, action)
      );
    } else {
      props.dispatch(removePortfolioCombinedTradingOption(customer.data.customer_id, portfolioId));
    }
    displayTradingSnackbar(action);
  };

  const handleAddPortfolioPayoutPlanOption = (portfolioId, action) => {
    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == portfolioId);
    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                     data for portfolio with id ${portfolioId}`)
    }

    portfolio = _.cloneDeep(portfolio);
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data)

    if (action != 'default') {
      props.dispatch(
        addPortfolioCombinedTradingOption(customer.data, portfolio, TRADING_ACTION_PAYOUT_PLAN, action)
      );
    } else {
      props.dispatch(removePortfolioCombinedTradingOption(customer.data.customer_id, portfolioId));
    }
    displayTradingSnackbar(action);
  };

  const handleAddPortfolioSwitchPlanOption = (portfolioId, action) => {
    let portfolio = _.find(instrumentList.data, (p) => p.portfolio_id == portfolioId);
    if (!portfolio) {
      throw new Error(`Unable to find portfolio data in Instrument List 
                   data for portfolio with id ${portfolioId}`)
    }

    portfolio = _.cloneDeep(portfolio);
    extendPortfoliosWithDataForTrading(portfolio, banksMapping.data, banksData.data);

    if (action != 'default') {
      props.dispatch(
        addPortfolioCombinedTradingOption(customer.data, portfolio, TRADING_ACTION_SWITCH_PLAN, action)
      );
    } else {
      props.dispatch(removePortfolioCombinedTradingOption(customer.data.customer_id, portfolioId));
    }
    displayTradingSnackbar(action);
  };

  React.useEffect(() => {
    if(!isCustomerApp) {
      fetchBanksData();
    }
  }, [customer]);

  return (
    <WrappedComponent
      {...props}
      banksMappingData={banksMapping.data}
      banksData={banksData.data}
      combinedTradingSession={combinedTradingSession}
      goToTradeDetails={goToTradeDetails}
      handleAddTradingOption={handleAddTradingOption}
      handleAddSavingPlanOption={handleAddSavingPlanOption}
      handleAddPortfolioTradingOption={handleAddPortfolioTradingOption}
      handleAddPortfolioSavingPlanOption={handleAddPortfolioSavingPlanOption}
      handleAddPortfolioPayoutPlanOption={handleAddPortfolioPayoutPlanOption}
      handleAddPayoutPlanOption={handleAddPayoutPlanOption}
      handleAddPortfolioSwitchPlanOption={handleAddPortfolioSwitchPlanOption}
      handleAddSwitchPlanOption={handleAddSwitchPlanOption}
      combinedTradings={customerCombinedTradings()}
    />
  )
  })
};

export default withTradingsData;