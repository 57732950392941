import React from 'react';
import _ from 'lodash';

import useStyles, { benchmarkSwitchStyles } from './styles';
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Container, Divider, Grid, Paper, Tab } from "@material-ui/core";
import {
  COULD_NOT_GET_PRODUCT_DETAILS,
  EXISTING_COMPARISON_STORAGE_KEY,
  NEW_COMPARISON_STORAGE_KEY,
  PERFORMANCE_CHART_INFO_ICON_TEXT,
  PROCESS_TYPES,
  PRODUCT_COMPARISON_STEP,
  PRODUCT_TYPE
} from "./constants";
import { executeIfPathExist, getInvestmentDynamicPath } from "../InvestmentPlatform/utils";
import { connect } from "react-redux";
import Link from "../../components/Link";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import {
  FondsInformationTab,
  KeyFiguresTab,
  PerformanceTab,
  ProductStructureTab,
  RiskAnalyseTab,
  SaveComparisonModal
} from "./components";
import DownloadIcon from "../../images/DownloadIcon";
import clsx from "clsx";
import TimeFramePicker from "../CustomerDashboard/components/InvestmentDetailsNavigation/PortfolioTimeSelector";
import {
  BEGINNING,
  BEGINNING_PERFORMANCE_TIME_TYPE_VALUE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE,
  PERFORMANCE_TIME_TYPE,
  REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING
} from "../../utils/constants";
import { ComparisonProductsDropdown } from "../../components/PortfolioListDropdown/PortfolioListDropdown";
import ChartSection from "./components/ChartSection/ChartSection";
import WarningTooltip from "../../components/WarningTooltip";
import { PerformanceChart, PerformanceChartLegend } from "./components/charts";
import usePerformanceData from "./hooks/usePerformanceData";
import { createSelector } from "reselect";
import { useRouteMatch, withRouter } from "react-router-dom";
import { setProductComparison, setProductsComparisonTimeSelectorDateChange } from "./actions";
import { TabContext } from "@material-ui/lab";
import Tabs from "@material-ui/core/Tabs";
import {
  getPortfolioProductPortfolioIds, getProductBeginningDate,
  getProductColor,
  getProductName, getProductsBeginningDate,
  loadComparisonProductsDetails,
  redirectToProductsComparison
} from "./utils";
import SustainabilityMetricsTab from "./components/SustainabilityMetricsTab/SustainabilityMetricsTab";
import { displayErrorSnackBar } from "../../components/SnackbarProvider/actions";
import {
  getBaseReportGenerationSettings,
  setDefaultValue,
  updateHistoryStateValue,
  validateSelectedRangeBusinessDates
} from "../../utils/utils";
import {
  getExistingProductsComparisonInstanceSelector,
  getNewProductsComparisonInstanceSelector
} from "./components/TabsContent/Comparison";
import { PortfolioHandlerResource } from "../../utils/api";
import { useConfirmationModalContext } from "../../components/ConfirmationModalContextProvider";
import withNotification from "../../components/NotificationProvider";
import { getPreparedComparisonProducts } from "./components/SaveComparisonModal/SaveComparisonModal";
import { getUniqErrorMsgFromErrors } from "../CustomerDashboard/CustomerDashboard";
import ErrorModal from "../../components/ErrorModal";
import { getProductSri } from "./components/FondsKenzahlenTable/FondsKenzahlenTable";
import BenchmarkWithConfiguration
  from "../CustomerDashboard/components/Widgets/components/PerformanceLineChart/benchmark";
import BenchmarkModal from "../CustomerDashboard/components/InvestmentDetailsNavigation/BenchmarkSettingsModal";
import { modelPortfolioAccessible } from "../Modelportfolios/utils";
import moment from "moment";
import useEditorVariables from "./hooks/useEditorVariables";
import CustomersSelectionModal from './components/CustomerSelectionModal/ProductSourceSelectionModal';
import {getAuthSelector, getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";
import {
  EXPAND_ALL_SECTIONS_CHECKBOX_CONFIGURATION,
} from "../../components/DownloadPdfModal/components/Expanded/constants";

const onlyFundsSelected = (products) => _.some(products, (product) => _.get(product, 'data.is_fund'))

const PRODUCTS_COMPARISON_CONTENT_TABS = [{
  id: 1,
  title: 'Wertentwicklung',
  component: PerformanceTab,
  visible: true
}, {
  id: 2,
  title: 'Produktstruktur',
  component: ProductStructureTab,
  visible: true
}, {
  id: 3,
  title: 'Kennzahlen',
  component: KeyFiguresTab,
  visible: true,
}, {
  id: 4,
  title: 'Risikoanalyse',
  component: RiskAnalyseTab,
  visible: true
}, {
  id: 5,
  title: 'Fondsinformationen',
  component: FondsInformationTab,
  visible: onlyFundsSelected,
}, {
  id: 6,
  title: 'Nachhaltigkeitskennzahlen',
  component: SustainabilityMetricsTab,
  visible: true,
}];

const DEFAULT_TAB = PRODUCTS_COMPARISON_CONTENT_TABS[0]

const EXPANDED_COMPONENTS_STRUCTURE = {
  performanceTab: {
      'performance_chart': true, "peergroup_ranking_table": true, "performance_bar_chart": true,
    },
    structureTab: {
      'by_type': true, 'by_class': true, 'by_product': true, 'by_asset_categories': true, 'by_currency': true, 'by_sector': true, 'by_region': true,
      'by_srri': true, 'by_maturity': true, 'by_fixed_income_sub_type': true, 'by_credit_quality': true,
    },
    fondsInformationTab: {
      'fondsKeyFiguresMasterTable': true,
      'fondsKeyFiguresFeesTable': true,
    },
    keyFiguresTab: {
      "keyFiguresTable": true,
    },
    riskAnalysTab: {
      risk_return: true,
      stress_test: true,
      correlation: true,
    },
    sustainabilityMetricsTab: {
      'eco_sustainability': true,
      'esg': true,
      'exclusion_criteria': true,
      'pai': true,
      'sdg': true,
      'sustainability_metrics': true,
    }
}


// Creating separate component for charts, so it will not share state with ProductComparisonNew
function ProductsComparisonContent(props) {

  const classes = useStyles();

  const [currentTab, setCurrentTab] = React.useState(DEFAULT_TAB);

  const handleCurrentTabChange = (event, tabId) => setCurrentTab(PRODUCTS_COMPARISON_CONTENT_TABS.find((tab) => tab.id === tabId));

  return (
    <TabContext value={currentTab.id}>
      <Paper square={true} classes={{ root: classes.paperRoot }} style={{margin: '24px 0', overflow: 'visible'}}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Tabs
              value={currentTab.id}
              classes={{ indicator: classes.activeTabIndicator }}
              variant="scrollable"
              onChange={handleCurrentTabChange}
              TabIndicatorProps={{ children: <div /> }}
            >
              {PRODUCTS_COMPARISON_CONTENT_TABS.map((tab) => {
                if (!tab.visible || (_.isFunction(tab.visible) && !tab.visible(props.selectedProducts || []))) {
                  if(tab.id === currentTab.id ) setCurrentTab(DEFAULT_TAB);
                  return false
                }
                return (
                  <Tab
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                    label={tab.title}
                    value={tab.id}
                  />
                )
              })}
            </Tabs>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.tabContentContainer} style={{padding: 24}}>
            <currentTab.component {...props}/>
          </Grid>
        </Grid>
      </Paper>
    </TabContext>
  )
};

function ProductComparisonNew(props) {
  const classes = useStyles();
  const switchClasses = benchmarkSwitchStyles();

  const NEW_ID = "new";

  const [savingInProgress, setSavingInProgress] = React.useState(false);
  const [saveComparisonModalOpen, setSaveComparisonModalOpen] = React.useState(false);
  const [activeComparison, setActiveComparison] = React.useState({});
  const [selectedDates, setSelectedDates] = React.useState({})
  const [selectedDateType, setSelectedDateType] = React.useState({})
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [benchmarksSwitchEnabled, setBenchmarksSwitchEnabled] = React.useState(false);
  const [selectedBenchmarks, setSelectedBenchmarks] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const riskReturnSelectedRange = React.useRef()

  const [downloadPdfModalOpen, setDownloadPdfModalOpen] = React.useState(false);
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  const [customerSelectionModalOpen, setCustomerSelectionModalOpen] = React.useState(false);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [closeEditorTrigger, setCloseEditorTrigger] = React.useState(undefined);

  useEditorVariables(props.dispatch);

  const routeMatch = useRouteMatch()

  const modalContext = useConfirmationModalContext();

  const expandedSectionsRef = React.useRef(_.cloneDeep(EXPANDED_COMPONENTS_STRUCTURE))

  const [expandedSections, setExpandedSections] = React.useState({...expandedSectionsRef.current.performanceTab});
  React.useEffect(() => {
    setExpandedSections({...expandedSectionsRef.current.performanceTab})
  },[expandedSectionsRef.current])

  const [resultPageTitle, setResultPageTitle] = React.useState('')

  // state to store performance data of last benchmark
  const performanceData = usePerformanceData(selectedProducts, selectedDates, benchmarksSwitchEnabled, selectedBenchmarks);

  const onBenchmarksSave = (_, __, currentlySelectedBenchmarks) => {
    // set new currently selected benchmarks
    setSelectedBenchmarks(currentlySelectedBenchmarks)

    // if there are no selected benchmarks automatically disable switch
    if (!currentlySelectedBenchmarks.length){
      setBenchmarksSwitchEnabled(false);
    }
  }

  React.useEffect(() => {
    const pageTitle = activeComparison.name || 'Neuer Investmentvergleich';

    setResultPageTitle(pageTitle)

    updateHistoryStateValue(props.history, 'title', `Investmentvergleich - ${pageTitle}`);
  }, [activeComparison.name])

  React.useEffect(() => {
    if (!_.isEmpty(activeComparison.products)) {
      const _selectedProducts = activeComparison.products.filter((product) => product.configuration.active);
      setSelectedProducts(_selectedProducts);
      if (selectedDateType.value == BEGINNING_PERFORMANCE_TIME_TYPE_VALUE) {
        setSelectedDates((current) => {
          current.start = getProductsBeginningDate(_selectedProducts);
          return current;
        })
      }
    }
  }, [JSON.stringify(activeComparison.products)])

  const fetchProductComparisonDetails = async (comparisonId) => {
    try {
      const response = await PortfolioHandlerResource.at('customer/product-comparison/list/').get({id: comparisonId});
      const comparison = response[0];

      comparison.products = await loadComparisonProductsDetails(comparison, modalContext, props.displayNotification);

      return comparison

    } catch (error) {
      props.dispatch(displayErrorSnackBar(COULD_NOT_GET_PRODUCT_DETAILS));
    }
  }

  React.useEffect(() => {

    let comparison = {};

    (async () => {
      setLoading(true)
      let preloadedDateType = PERFORMANCE_TIME_TYPE.LAST_1_YEARS;
      let comparisonId = routeMatch.params.id;
      let comparisonIdInUrl = !_.isNil(comparisonId)
      let storageKey = comparisonIdInUrl ? EXISTING_COMPARISON_STORAGE_KEY : NEW_COMPARISON_STORAGE_KEY
      let comparison = props[storageKey];

      if (!comparison && comparisonId) {
        comparison = await fetchProductComparisonDetails(comparisonId);
      }

      if (_.isEmpty(_.get(comparison, 'products'))) {
        props.dispatch(displayErrorSnackBar(COULD_NOT_GET_PRODUCT_DETAILS));
        handleBackClick();
        return;
      }

      setActiveComparison({
        ...comparison,
        products: _.sortBy(comparison.products, (product) => _.get(product, 'configuration.order'))
          .map((product, index) => {

            // if there is no configuration in product - it is product comparison create process -> set default values
            if (!product.hasOwnProperty('configuration')) {
              product.configuration = {};
            }
            _.set(product.configuration, 'sri', getProductSri(product));
            _.set(product.configuration, 'model_portfolio_id', product.type == PRODUCT_TYPE.PRIVATE_INVESTMENT
              ? _.get(product, 'data.related_model_portfolio.id')
              : product.product_id);
            setDefaultValue(product.configuration, 'active', true);
            setDefaultValue(
              product.configuration, 'color',
              getProductColor(index, comparison.products
                .filter((p) => !!p.configuration.color)
                .map((p) => p.configuration.color)));
            setDefaultValue(product.configuration, 'order', index);
            setDefaultValue(product.configuration, 'name', getProductName(product));
            if (!!product.data) {
              if (product.type == PRODUCT_TYPE.CUSTOMER_PORTFOLIO) {
                setDefaultValue(product.configuration, 'customer_id', _.isArray(product.data)
                  ? product.data[0].customer_id
                  : product.data.customer_id);
                // Later will be used to know if "aggregate" option was selected;
                setDefaultValue(product.configuration, 'depot_name', getProductName(product));
                setDefaultValue(product.configuration, 'portfolio_id', getPortfolioProductPortfolioIds(product));
                setDefaultValue(product.configuration, 'depot_id', _.isArray(product.data)
                  ? product.data.map((depot) => depot.depotNumber)
                  : [product.data.depotNumber]);
              } else if (product.type == PRODUCT_TYPE.ASSET) {
                setDefaultValue(product.configuration, 'isin', product.data.isin);
                setDefaultValue(product.configuration, 'wkn', product.data.wkn);
              } else if ([PRODUCT_TYPE.MODEL_PORTFOLIO, PRODUCT_TYPE.PRIVATE_INVESTMENT].includes(product.type)) {
                setDefaultValue(product.configuration, 'mp_number', product.type == PRODUCT_TYPE.PRIVATE_INVESTMENT
                  ? product.data.portfolio_id
                  : product.data.mp_number);
              }
            }

            return product;
          })
      })

      // set time period from configuration
      if(!_.isNil(_.get(comparison, 'configuration.selected_date_type'))){
        let performanceTimeTypeKey = REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING[comparison.configuration.selected_date_type];
        preloadedDateType = PERFORMANCE_TIME_TYPE[performanceTimeTypeKey];
      }

      let dateRange = preloadedDateType.getDateRange();

      if (preloadedDateType.value === CUSTOM_PERFORMANCE_TIME_TYPE_VALUE) {
        dateRange.start = moment(comparison.configuration.start_date)
        dateRange.end = moment(comparison.configuration.end_date)
      } else if (preloadedDateType.value === BEGINNING_PERFORMANCE_TIME_TYPE_VALUE) {
        dateRange.start = getProductsBeginningDate(comparison.products.filter((product) => product.configuration.active));
        dateRange.end = moment();
      }

      validateSelectedRangeBusinessDates(dateRange, preloadedDateType.value, [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE]);
      setSelectedDates(dateRange)
      setSelectedDateType(preloadedDateType);
      setLoading(false);

      // load data from config
      // benchmarks
      setBenchmarksSwitchEnabled(_.get(comparison, 'configuration.benchmark_enabled', false));
      setSelectedBenchmarks(_.get(comparison, 'benchmarks', []));
      // expanded items
      let expandedItems = _.get(comparison, 'configuration.expanded_items')
      if (!_.isNil(expandedItems)){
        expandedSectionsRef.current = _.merge(_.cloneDeep(EXPANDED_COMPONENTS_STRUCTURE), expandedItems)
      }

    })();

    return () => {
      // Clear "date change" data from redux storage when page closed,
      // so it will not trigger dropdown to open when page opened for the first time
      props.dispatch(setProductsComparisonTimeSelectorDateChange({}));
    }
  }, [])

  const handleBackClick = (saveProcess) => {
    if (saveProcess){
      // if process type was not pass - it is new pc creation -> if it is saving process - redirect to list of saved pc
      redirectToProductsComparison(props.history, props.investmentPlatform.routes, {tab_id: PRODUCT_COMPARISON_STEP.COMPARISON})
    } else if (routeMatch.params.id) {
      // if there is comparison's id in the route - existing comparison opened,
      // so on go back you are redirected to products editing.
      redirectToProductsComparison(
        props.history, props.investmentPlatform.routes,
        {
          tab_id: PRODUCT_COMPARISON_STEP.COMPARISON,
          process_type: PROCESS_TYPES.EDIT,
          product_comparison_id: routeMatch.params.id,
        },
        false
      )
    } else {
      props.history.goBack();
    }
  };

  const toggleSaveComparisonModalOpen = () => {
    setSaveComparisonModalOpen((open) => !open);
  };

  const handleSave = (data, redirectToExistingComparisons) => {
    setActiveComparison(data);
    props.dispatch(setProductComparison(data, EXISTING_COMPARISON_STORAGE_KEY))
    if(redirectToExistingComparisons){
      handleBackClick(true)
    } else {
      const pageTitle = data.name || 'Neuer Investmentvergleich';
      !activeComparison.id && executeIfPathExist(props.investmentPlatform.routes, 'PRODUCTS_COMPARISON_NEW', path => {
        props.history.replace({pathname: `/${getInvestmentDynamicPath()}${path.replace(':id', data.id)}/`});
        updateHistoryStateValue(props.history, 'title', `Investmentvergleich - ${pageTitle}`);
      }, ':id')
    }
  }

  const handleCalculationDatesChanged = (dates, datesType) => {

    if (datesType.value == BEGINNING_PERFORMANCE_TIME_TYPE_VALUE) {
      dates.start = getProductsBeginningDate(selectedProducts);
      dates.end = moment();
    }

    setSelectedDates(dates);
    setSelectedDateType(datesType);
  };

  const handleSelectedProductsChange = (_selectedProducts) => {
    _selectedProducts = _.orderBy(_selectedProducts, [(item) => item.configuration.highlighted, (item) => item.configuration.order], ['desc', 'asc'])
    _selectedProducts.forEach((product, index) => {
      product.configuration.order = index
    })
    setActiveComparison({...activeComparison, products: [..._selectedProducts]})
  }

  const toggleExpandedSection = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey];
      expandedSectionsRef.current.performanceTab[sectionKey] = newState;
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  if (loading) {
    return (
      <Container className={`app-page-container`}>
        <Paper square={true} classes={{ root: classes.paperRoot }} style={{margin: '24px 0', overflow: 'visible'}}>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 6,  height: 'calc(100svh - 160px)'}}>
            <CircularProgress/>
          </Grid>
        </Paper>
      </Container>
    );
  }

  if(_.isEmpty(activeComparison)){
    return null
  }

  const fetchPdfFile = async(skip_expanded, _skip_sub_depot_expanded, _cover_text_enabled, _cover_message_content, extra_files, customers) => {
    setPdfLoading(true);

    try {
      const formData = getBaseReportGenerationSettings(extra_files, selectedDates, skip_expanded, expandedSectionsRef.current, EXPANDED_COMPONENTS_STRUCTURE)
      formData.append('name', activeComparison.name || 'Neuer Vergleich');

      if (!_.isEmpty(activeComparison.products)) {
        formData.append('products', JSON.stringify(getPreparedComparisonProducts(activeComparison, true)))
      }

      if(benchmarksSwitchEnabled) {
        formData.append('benchmarks', JSON.stringify(selectedBenchmarks));
      }

      formData.append('cover_text_enabled', _cover_text_enabled);
      riskReturnSelectedRange.current && formData.append('risk_return_selected_range', riskReturnSelectedRange.current);
      if(_cover_text_enabled){
        formData.append('cover_message_content', _cover_message_content || '<p></p>');
        if (customers) {
          formData.append('customer_ids', JSON.stringify(customers.map((customer) => customer.customer_id)));
        }
      }

      await PortfolioHandlerResource.at('customer/products-comparison/pdf/').post(formData);

      setDownloadPdfModalOpen(false)
    } catch (errors) {
      setErrorMessage(getUniqErrorMsgFromErrors(errors))
    }

    setPdfLoading(false)
  };

  const cancelCustomerSelection = () => {
    setCustomerSelectionModalOpen(false);
    setCloseEditorTrigger(+ new Date());
  };

  const productsCount = (activeComparison.products || []).length;
  const subHeaderTitle = `(${productsCount} Vergleichsinstrument${productsCount > 1 ? 'e' : ''})`;

  return (
    <Container className={`app-page-container`}>
      <Link
        title="Zurück zur Produktauswahl"
        icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
        underline
        onClick={handleBackClick}
      />
      <Paper square={true} classes={{ root: classes.paperHeader }}>
        <Grid item xs={12} className={classes.headerContainer}>
          <div className={classes.headerSection}>
            <Typography variant="h1" className={classes.header}>{resultPageTitle}</Typography>
            <div className={classes.subHeader}>{subHeaderTitle}</div>
          </div>
          {activeComparison && activeComparison.description &&
            <div className={classes.headerSection} style={{ display: "block", marginTop: 16 }}>
              <div className={classes.sectionHeader}>Beschreibung</div>
              <div className={classes.description}>
                {activeComparison.description}
              </div>
            </div>
          }
        </Grid>
      </Paper>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={7} className={classes.section} style={{justifyContent: 'flex-start', padding: '6px 0px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5} lg={5} className={classes.pickersSection}>
              <TimeFramePicker
                calculationDates={selectedDates}
                calculationDatesType={selectedDateType}
                handleCalculationDatesChanged={handleCalculationDatesChanged}
                optionsInfo={{
                  [BEGINNING]: 'Entspricht dem Auflegungsdatum des ältesten im Vergleich enthaltenen Produktes.'
                }}
                loading={performanceData.loading}
                dispatch={props.dispatch}
                timeSelectorDateChange={props.productsComparison.time_selector_date_change}
                isProductComparison
                sessionCustomerId={activeComparison.id || NEW_ID}
                defaultPerformanceTimeType={PERFORMANCE_TIME_TYPE.LAST_1_YEARS}
                businessDaysOnly
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={5}>
              <ComparisonProductsDropdown
                products={activeComparison.products}
                onSelectedProductsChange={handleSelectedProductsChange}
                disabled={performanceData.loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <BenchmarkWithConfiguration
                portfolio={activeComparison}
                selectedBenchmarks={selectedBenchmarks}

                benchmarkConfigurationEnabled
                isBenchmarkConfigurationEnabled
                requiredDataExist
                showInvestment={benchmarksSwitchEnabled}

                dispatch={props.dispatch}
                onChartSettingsChange={(_, __, newState) => {setBenchmarksSwitchEnabled(newState)}}
                customClasses={switchClasses}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} className={clsx(classes.section, classes.actionsSection)}>
          <PrimaryButton
            icon={<DownloadIcon color="white"/>}
            text="PDF herunterladen"
            onButtonClick={() => setDownloadPdfModalOpen(true)}
            disabled={_.isEmpty(selectedProducts)}
          />
          {(!activeComparison.id || modelPortfolioAccessible(activeComparison, props.auth)) && (
            <PrimaryButton
              text='Vergleich speichern'
              variant={'outlined'}
              customClasses={{root: classes.primaryButton}}
              icon={<i className="far fa-save" style={{fontSize: 18}} aria-hidden="true" />}
              onButtonClick={toggleSaveComparisonModalOpen}
              disabled={savingInProgress}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <ChartSection
            title={(
              <>
                <b>Rendite im Zeitverlauf in %</b>
                <WarningTooltip
                  title={PERFORMANCE_CHART_INFO_ICON_TEXT}
                  icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)} />}
                  width={600}
                />
              </>
            )}
            loading={performanceData.loading}
            error={performanceData.errors}
            displayError
            expanded={!!expandedSections.performance_chart}
            onExpanded={toggleExpandedSection('performance_chart')}
            content={
              <Grid container>
                <Grid item xs={12} md={8}>
                  <PerformanceChart
                    updatedAt={performanceData.updatedAt}
                    loading={performanceData.loading}
                    errors={performanceData.errors}
                    data={performanceData.data}
                    startDate={selectedDates.start}
                    endDate={selectedDates.end}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {performanceData.data && (
                    <PerformanceChartLegend data={performanceData.data} asLink/>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ProductsComparisonContent
            selectedProducts={selectedProducts}
            selectedBenchmarks={benchmarksSwitchEnabled ? selectedBenchmarks : []}
            allProducts={activeComparison.products}
            selectedDates={selectedDates}
            expandedSectionsRef={expandedSectionsRef}
            performanceData={performanceData}
            riskReturnSelectedPeriodRef={riskReturnSelectedRange}
          />
        </Grid>
      </Grid>
      <SaveComparisonModal
        open={saveComparisonModalOpen}
        selectedBenchmarks={selectedBenchmarks}
        onClose={toggleSaveComparisonModalOpen}
        comparison={activeComparison}
        onSave={handleSave}
        comparisonConfiguration={{
          start_date: selectedDates.start.format('YYYY-MM-DD'),
          end_date: selectedDates.end.format('YYYY-MM-DD'),
          selected_date_type: selectedDateType.value,
          benchmark_enabled: benchmarksSwitchEnabled,
          expanded_items: expandedSectionsRef.current,
        }}
      />
      <DownloadPdfDialog
        open={downloadPdfModalOpen}
        onClose={() => setDownloadPdfModalOpen(false)}
        onExport={fetchPdfFile}
        title='Investmentvergleich herunterladen'
        exportBtnDisabled={_.isEmpty(selectedProducts) || pdfLoading}
      >
        <DownloadPdfDialog.CoverPage
          compareContentForPreview={false}
          coverTitle={activeComparison.name || 'Neuer Vergleich'}
          withCustomers
        />
        <DownloadPdfDialog.Documents />
        <DownloadPdfDialog.Expanded
          items={[
            EXPAND_ALL_SECTIONS_CHECKBOX_CONFIGURATION
          ]}
        />
      </DownloadPdfDialog>
      <CustomersSelectionModal
        open={customerSelectionModalOpen}
        onClose={cancelCustomerSelection}
        onSelect={(customers) => {
          setSelectedCustomers(customers);
          setCustomerSelectionModalOpen(false);
        }}
      />

      <ErrorModal
        message={errorMessage}
        handleClose={() => setErrorMessage(undefined)}
      />

      {props.benchmarkConfiguration && props.benchmarkConfiguration.modal_opened && (
        <BenchmarkModal
          benchmarks={selectedBenchmarks}
          selectedPortfolio={activeComparison}

          onChartSettingsChange={onBenchmarksSave}
        />
      )}

    </Container>
  )
}

const getProductsComparison = (state) => state.get('productsComparison');

export const getProductsComparisonSelector = createSelector(
  [getProductsComparison],
  (productsComparison) => productsComparison.toJS()
);

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
  productsComparison: getProductsComparisonSelector(state),
  [NEW_COMPARISON_STORAGE_KEY]: getNewProductsComparisonInstanceSelector(state),
  [EXISTING_COMPARISON_STORAGE_KEY]: getExistingProductsComparisonInstanceSelector(state),
  benchmarkConfiguration: state.get('benchmarkConfiguration').toJS(),
});

export default connect(mapStateToProps)(withNotification(withRouter(ProductComparisonNew)));