import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 22,
    letterSpacing: -0.5,
    color: '#4D4F5C',
    fontWeight: 'normal',
    margin: 0,

    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '&:hover': {
        overflow: 'initial',
        wordBreak: 'break-all',
        whiteSpace: 'initial',
        textOverflow: 'initial'
      },
      fontSize: 18,
    }
  },
  subHeader: {
    margin: 0,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#4D4F5C',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  value: {
    margin: 0,
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#4D4F5C',

    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    }
  },
  subValue: {
    display: 'flex',
    alignItems: 'baseline',
    margin: 0,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#4D4F5C',

    '& > b': {
      fontWeight: 'bold',
      margin: '0 5px'
    }
  },
  container: {
    width: '100%'
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  subContent: {

  },
  dataWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  helpIcon: {
    color: theme.palette.primary.main,
    marginLeft: 10,

    '&:hover': {
      cursor: 'pointer'
    }
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    marginTop: 10,
    marginBottom: 0,
    display: 'block',

    '& > a': {
      color: `${theme.palette.primary.main} !important`,
    },

    [theme.breakpoints.down('md')]: {
      marginTop: 4,
    }
  },
  openTransactionLoadingContainer: {
    width: '50%'
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      marginRight: 5
    }
  }
}));