import React from 'react';

import {toGermanFormat} from "../../../../../../../utils/numberFormater";
import HighChartBase from "../../../../../../../components/Charts/Base";
import useStyles from './styles';

const RiskChart = props => {
  const {
    id,
    categories,
    min,
    max,
    benchmarkValue,
    benchmarkLabel,
    value,
    reversed
  } = props;

  const classes = useStyles();

  const getBenchmarkLabelAlign = () => {
    const maxValue = Math.abs(reversed ? min : max);
    const sector = Math.round(maxValue / 4);
    const value = Math.abs(benchmarkValue);

    if (value <= sector) {
      return reversed ? 'right' : 'left';
    }
    if (value >= maxValue - sector) {
      return reversed ? 'left' : 'right';
    }
    return 'center';
  };

  const getBenchmarkLabelText = () => {
    return `${benchmarkLabel} ${toGermanFormat(benchmarkValue)} %`;
  };

  const options = {
    chart: {
      height: 78,
      inverted: true,
      type: 'bar',
      spacingTop: 25,
      spacingBottom: 10,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: '#e1e3e9',
      style: {
        fontFamily: 'inherit'
      }
    },
    title: null,
    legend: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        useHTML: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#4d4f5c',
          width: '100px'
        },
      }
    },
    yAxis: {
      title: null,
      max: max,
      min: min,
      visible: true,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      maxPadding: 0.1,
      labels: {
        align: 'right',
        style: {
          color: '#b1b1b1',
          fontSize: '12px',
          width: '100px',
        },
        autoRotation: false,
        overflow: false,
        formatter: function formatter() {
          if (this.isFirst || this.isLast) {
            return `${this.value} %`;
          }
        },
      },
      plotLines: benchmarkValue ? [
        {
          value: Math.round(benchmarkValue),
          zIndex: 5,
          width: 5,
          color: '#d32323',
          label: {
            style: {
              color: '#4d4f5c',
              fontSize: '12px'
            },
            text: undefined,
            rotation: 0,
            align: getBenchmarkLabelAlign(),
            verticalAlign: 'top',
            y: -8,
            x: 0
          }
        }
      ] : []
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        color: '#0092E5'
      }
    },
    series: [
      {
        dataLabels: [
          {
            y: -1,
            style: {
              color: '#4d4f5c',
              fontWeight: 'bold',
              fontSize: '12px'
            },
            formatter: function formatter() {
              return toGermanFormat(this.point.y, '', ' %');
            },
          }
        ],
        data: [value]
      }
    ],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: () => {
        return `<b> Depot: ${toGermanFormat(value)} %<b/>` + (benchmarkValue ? ` im Vergleich zur Benchmark: ${toGermanFormat(benchmarkValue)} % ` : '');
      },
    }
  };

  return (
    <>
      {benchmarkLabel && (
        <span className={classes.chartLegendItem}>
          <span className={classes.chartLegendItemSymbol}>&#x25A0;</span>
          <span className={classes.chartLegendItemText}>{getBenchmarkLabelText()}</span>
        </span>
      )}
      <HighChartBase options={options} language={'DE'}/>
    </>
  )
};

export default RiskChart;
