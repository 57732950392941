import { makeStyles } from '@material-ui/core'

const styles = (theme) => ({
  dialogRoot: {
    zIndex: '1000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitleRoot: {
    paddingLeft: 24,
    paddingRight: 24,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },

    '& > h2': {
      fontFamily: 'Roboto-Regular',
      color: '#313D44',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
      }
    }
  },
  textEditorWrapper: {
    borderRadius: 0,
    '& .ql-editor': {
      minHeight: 173
    }
  },
  inputLabelRoot: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  dialogActions: {
    paddingLeft: 24,
    paddingRight: 24,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  }
})

export default makeStyles(styles)