import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  reportSettingsSection: {
    '& > div': {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px 0px`,
      [theme.breakpoints.down('xs')]: {
        '&': {
          padding: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
        }
      },

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        }
      },

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        },
        '&:last-child': {
          borderBottom: 'none' 
        }
      },
    },
    '& > div:last-child': {
      borderRight: 'none' 
    },

    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DADEE1' 
    }
  },
  reportSettingsSectionAdditional: {
    padding: 0,
    margin: 0,
    '& > div': {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px 0px`,
      [theme.breakpoints.down('md')]: {
        '&': {
          padding: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
        }
      },
    },
  },

  reportSettingsSectionAdditionalOne: {
    marginRight: 16,
    borderRight: '1px solid #DADEE1',
    [theme.breakpoints.down('sm')]: {
      '&': {
        marginRight: 0,
        borderRight: 'none'
      }
    },

    '& > div:first-child': {
      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        }
      },
    },
  },

  reportSettingsSectionAdditionalTwo: {
  },

  formLabel: {
    color: '#313D44',
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 8,
    whiteSpace: 'nowrap !important',
    width: '100%'
  },
  benchmarkLabel: {
    fontFamily: "'Roboto-Bold'",
    fontSize: 13,
    color: '#4D4F5C',
    margin: 0
  },
});

export default makeStyles(styles);