import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  title: {
    textDecoration: "underline",
    fontWeight: "bold"
  },
  text: {
    fontStyle: "italic"
  }
}));
