// msg: It is recommended to use the custodian's online portal to ensure that the selected product can be traded on the selected stock exchange.
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import {InfoIcon} from "../../../../../../images";
import React from "react";
import clsx from "clsx";

export const USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT = 'Es wird empfohlen sich im Onlineportal der Depotstelle zu vergewissern, dass für das gewählte Produkt ein Handel am ausgewählten Börsenplatz möglich ist.'
export const TableTooltip = withStyles(styles) ((props) => {

  return(
    <div className={props.classes && props.classes.switchInfoText}>
      <i className='far fa-info-circle' />
      <p>{props.text}</p>
    </div>
  )
})