import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  navigationContainer: {
    padding: '20px 30px',
    display: 'flex',
    position: 'relative',
    width: 'auto',
    height: 76,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: 'min-content',
    marginLeft: 'auto',
    '& > button': {
      maxWidth: 'min-content',
      color: '#0092e5',
      marginLeft: 20,
      border: '2px solid #0092e5',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: 4,
      opacity: 1,
      '&:hover': {
        backgroundColor: '#0092e5',
        '& > span': {
          '& > span, & > i': {
            color: '#FFFFFF',
          },
        }
      },
      '&:first-child': {
        marginLeft: 0,
        height: 35
      },
      '&:nth-child(2)': {
        height: 35
      },
      '& > span': {
        alignSelf: 'baseline',
        '& > span': {
          fontSize: 14,
          fontWeight: 'bold',
          color: '#0092E5',
        },
        '& > i': {
          color: '#0092E5',
        }
      }
    }
  },
  selectedDocumentsCount: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  helpTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& > p': {
      fontFamily: 'Roboto-Medium',
      fontSize: 20
    }
  },
  NavButtons: {
    width: '100%',
    color: '#6693B4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  expandIcon: {
    fill: '#7caed8',
  },
  expandInfo: {
    textAlign: 'center',
    marginLeft: 15,
    marginRight: 15,
    width: 'max-content'
  },
  expandActivePage: {
    width: '25px',
    fontWeight: 'bold',
    borderColor: '#6693B4',
    color: '#6693B4',
    textAlign: 'center',
    '& > input': {
      textAlign: 'center'
    }
  },
  document: {
    overflow: 'scroll',
    maxHeight: 1527
  }
});

export default makeStyles(styles);