import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Snackbar from '../Snackbar'
import { closeSnackbar } from './actions'


const mapStateToProps = (state) => ({
  snackbar: state.get('snackbar').toJS(),
});


function SnackbarProvider(props) {

  let {
    snackbar
  } = props;

  return (
    <Snackbar
      open={snackbar.open}
      variant={snackbar.variant}
      message={snackbar.message}
      handleClose={() => props.dispatch(closeSnackbar())}
      autoHideDuration={snackbar.duration || 4}
    />
  )
}

SnackbarProvider.propTypes = {

}

export default connect(mapStateToProps)(SnackbarProvider)

