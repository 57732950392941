import React, { useState } from 'react';
import moment from 'moment';
import clsx from "clsx";
import _ from "lodash";

/* Material-UI Components */
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

/* BCA modules */
import {
  PERFORMANCE_TIME_TYPE,
  DEFAULT_PERFORMANCE_TIME_TYPE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE, CUSTOM
} from '../../../../../utils/constants';
import DateRange from "../../../../../components/DateRange/DateRange";

import {setTimeSelectorDateChange} from "../../../actions";
import {useStyles, useFilterClasses} from "./styles";
import WarningTooltip from "../../../../../components/WarningTooltip";
import {
  datesEqual,
  formatDateShouldBeGraterThenErrorMessage,
  getNextBusinessDateForRange
} from "../../../../../utils/utils";
import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';
import Skeleton from "@material-ui/lab/Skeleton";
import Benchmark from '../../../components_v2/Benchmark/Benchmark';


require('moment/locale/de');
moment.locale('de');


const getCalculationDatesType = (calculationDatesType) => _.isString(calculationDatesType)
  ? PERFORMANCE_TIME_TYPE[calculationDatesType]
  : calculationDatesType


const PortfolioTimeSelector = React.memo((props) => {
  const {
    dispatch,
    calculationDates,
    calculationDatesType,
    handleCalculationDatesChanged,
    useFilterStyles,
    startOfInvestment,
    loading,
    stickyContainer,
    iconComponent,
    minDateErrorMessage,
    timeSelectorDateChange,
    customHeader,
    disabledOptions,
    businessDaysOnly,
    optionsInfo,
    isMounted={isMounted},
    BenchmarkProps,
  } = props;

  const classes = useFilterStyles ? useFilterClasses() : useStyles();
  const _DEFAULT_PERFORMANCE_TIME_TYPE = props.defaultPerformanceTimeType || DEFAULT_PERFORMANCE_TIME_TYPE;

  const timeSelectorChangeCallback = (value={}) => {
    dispatch && dispatch(setTimeSelectorDateChange(value))
  };

  React.useEffect(() => {
    timeSelectorChangeCallback();
  }, []);

  const buttonRef = React.useRef();
  const containerRef = React.useRef();
  const [selectedDates, setSelectedDates] = useState(calculationDates);
  const [expanded, setExpanded] = useState(false);
  const [performanceTimeType, setPerformanceTimeType] = useState(_DEFAULT_PERFORMANCE_TIME_TYPE);
  const [businessDatesUsed, setBusinessDatesUsed] = useState(false);

  /**
   * Data Validation. For now it only print in console. Could be extended later
   */
  React.useEffect(() => {

    if (!calculationDatesType) {
      return
    }

    const dateFormatted = (date) => date && date.format('YYYY-MM-DD')

    const _calculationDatesType = getCalculationDatesType(calculationDatesType)

    if (!calculationDates.start || !calculationDates.end || _calculationDatesType.value == CUSTOM_PERFORMANCE_TIME_TYPE_VALUE) {
      return
    }

    const datesTypeDates = _calculationDatesType.getDateRange();
    if (businessDaysOnly) {
      getNextBusinessDateForRange(datesTypeDates);
    }

    if (dateFormatted(datesTypeDates.start) != dateFormatted(calculationDates.start) || dateFormatted(datesTypeDates.end) != dateFormatted(calculationDates.end)) {
      console.warn('Calculation dates and calculation dates type mismatch!')
    }

  }, [calculationDates, calculationDatesType])

  React.useEffect(() => {
    setSelectedDates(calculationDates)
  }, [calculationDates])

  React.useEffect(() => {

    if (!calculationDatesType) {
      return
    }

    const _calculationDatesType = getCalculationDatesType(calculationDatesType)

    setPerformanceTimeType(_calculationDatesType)
  }, [calculationDatesType])

  React.useEffect(() => {
    if (!businessDaysOnly || !performanceTimeType
        || [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE].includes(performanceTimeType.value)
        || (!selectedDates.start && !selectedDates.end)) {
      setBusinessDatesUsed(false)
      return
    }

    const performanceTimeTypeRange = performanceTimeType.getDateRange();
    setBusinessDatesUsed(!datesEqual(performanceTimeTypeRange.start, selectedDates.start) || !datesEqual(performanceTimeTypeRange.end, selectedDates.end))

  }, [selectedDates, performanceTimeType, businessDaysOnly])

  React.useEffect(() => {
    // as we have two navigations (static and sticky) we should expand only currently visible one
    // offsetParent returns null in case The element or any ancestor has the display=none for sticky
    // check opacity for static container.
    const staticNavContainer = containerRef.current.closest('#sticky-trigger');
    if(!containerRef.current.offsetParent || (staticNavContainer && staticNavContainer.style.opacity === '0')) return;

    if(timeSelectorDateChange) {
      if (timeSelectorDateChange.startOfBenchmarkChanged) {
        setDates(timeSelectorDateChange, CUSTOM)
      } else if (timeSelectorDateChange.start && timeSelectorDateChange.end) {
        handleClickListItem(CUSTOM)
        setSelectedDates(timeSelectorDateChange)
        setExpanded(true)
      }
    } else {
      setExpanded(false)
    }
  }, [timeSelectorDateChange])

  const handleClickListItem = (key) => {
    // if clicked value is changed
    if (performanceTimeType.value !== PERFORMANCE_TIME_TYPE[key].value) {
      setPerformanceTimeType(PERFORMANCE_TIME_TYPE[key]);

      if (PERFORMANCE_TIME_TYPE[key].value !== PERFORMANCE_TIME_TYPE.CUSTOM.value) {
        setExpanded(false);
        setDates(PERFORMANCE_TIME_TYPE[key].getDateRange(), key);
      }
    }
  };

  const setDates = (dates, key) => {
    if (dates.start && dates.end && businessDaysOnly) {
      getNextBusinessDateForRange(dates);
    }
    timeSelectorChangeCallback();
    handleCalculationDatesChanged(dates, PERFORMANCE_TIME_TYPE[key]);
  };

  const [startOfInvestmentMoment, setStartOfInvestmentMoment] = useState(undefined)
  React.useEffect(() => {
    setStartOfInvestmentMoment(startOfInvestment && moment(startOfInvestment))
  }, [startOfInvestment])


  const renderPerformanceTimeTypesList = () => {
    const startDate = startOfInvestmentMoment;
    const selectedDateRange = performanceTimeType.getDateRange();
    const selectedStartDateRange = selectedDateRange && selectedDateRange.start;

    // if start of investment of portfolio is younger than currently selected date range
    if (startDate && selectedStartDateRange && startDate.isAfter(selectedStartDateRange)) {
      return handleClickListItem('BEGINNING'); // automatically select investment beginning
    }

    return Object.keys(PERFORMANCE_TIME_TYPE)
    .filter(key => {
      if (startDate) {
        const dateRange = PERFORMANCE_TIME_TYPE[key].getDateRange();
        const startDateRange = dateRange && dateRange.start || startDate;

        return startDateRange.isSameOrAfter(startDate);
      }
      return true;
    })
    .map(key => {
      if (disabledOptions.includes(PERFORMANCE_TIME_TYPE[key].value)) {
        return null
      }
      const isSelected = performanceTimeType.value === PERFORMANCE_TIME_TYPE[key].value;
      return (
        <ListItem
          key={PERFORMANCE_TIME_TYPE[key].value}
          className={clsx(classes.portfolioListItem, isSelected && classes.portfolioListItemSelected)}
          onClick={() => handleClickListItem(key)}
        >
          <span>{PERFORMANCE_TIME_TYPE[key].description}</span>
          {optionsInfo[key] && (
            <WarningTooltip title={optionsInfo[key]} />
          )}
        </ListItem>
      );
    });
  };

  const getMinDateErrorMessage = () => {
    if (minDateErrorMessage) return minDateErrorMessage;
    if (!!startOfInvestmentMoment) {
      return formatDateShouldBeGraterThenErrorMessage(startOfInvestmentMoment);
    }
  }

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const formatSelected = () => {

    if (!isMounted) {
      return <Skeleton style={{width: '50%', height: 35}}/>
    }

    return (calculationDates.start && calculationDates.end)
      ? `${calculationDates.start.format('DD.MM.YYYY')} - ${calculationDates.end.format('DD.MM.YYYY')}`
      : 'Seit Investmentbeginn';

  }

  const disabled = loading || !isMounted;
  const benchmarkEnabled = _.get(BenchmarkProps, 'enabled');

  return (
    <div id={stickyContainer ? "date-picker-dropdown-sticky" : "date-picker-dropdown"} ref={containerRef}>
      {!stickyContainer && (!!customHeader || benchmarkEnabled) && (
        <FormLabel className={clsx(classes.label, disabled && 'disabled')}>
          {customHeader}
          {benchmarkEnabled && (
            <Benchmark
              disabled={disabled}
              configuration={BenchmarkProps.configuration}
              value={BenchmarkProps.showBenchmark}
              onValueChanged={BenchmarkProps.onValueChanged}
              onConfigurationClick={BenchmarkProps.onConfigurationClick}
            />
          )}
        </FormLabel>
      )}
      <Button
        ref={buttonRef}
        id="date-picker"
        onClick={handleClick}
        className={classes.expansionPanelSummaryRoot}
        disabled={disabled}
        classes={{disabled: classes.disabled}}
        disableRipple
        fullWidth
      >
        <span className={classes.ellipsis}>
          {formatSelected()}
        </span>
        {businessDatesUsed && (
          <WarningTooltip title={"Da für handelsfreie Tage keine Preisdaten zur Verfügung stehen, wird automatisch der nächstmögliche Handelstag herangezogen, falls der gewählte Zeitraum an einem handelsfreien Tag beginnt oder endet."}/>
        )}
        <span style={{marginLeft: 'auto'}}>
          {iconComponent || <CalendarTodayIcon className={classes.calendarIcon} />}
        </span>
      </Button>
      <Popover open={expanded} anchorEl={buttonRef.current}
        onClose={handleClick}
        classes={{paper: classes.expansionPanelDetailsPaper }}
        anchorOrigin={{vertical: FORM_FIELD_HEIGHT, horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        elevation={0}
      >
        <div className={classes.portfolioListWrap}>
          <List className={classes.portfolioList}>
            {renderPerformanceTimeTypesList()}
          </List>
          {performanceTimeType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value && (
            <div className={classes.timePickerContainer}>
              <DateRange
                start={(selectedDates && selectedDates.start) || startOfInvestmentMoment}
                end={(selectedDates && selectedDates.end)}
                minDate={startOfInvestmentMoment}
                onChange={(dateRange) => {
                  handleClick();
                  setDates(dateRange, CUSTOM);
                }}
                minDateErrorMessage={getMinDateErrorMessage()}
                businessDaysOnly={businessDaysOnly}
                classes={{
                  textFieldUnderline: classes.textFieldUnderline
                }}
              />
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
});

PortfolioTimeSelector.defaultProps = {
  customHeader: 'Zeitraum',
  disabledOptions: [],
  optionsInfo: {}
}

export default PortfolioTimeSelector;