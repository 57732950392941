import React from 'react';

import moment from 'moment';
import {
  BEGINNING_PERFORMANCE_TIME_TYPE_VALUE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE,
  PERFORMANCE_TIME_TYPE
} from '../../utils/constants';

import DashboardSettingsWindow from './components/DashboardSettingsWindow';
import DashboardSettingsWindowV2 from './components/DasboardSettingWindowV2';
import { CustomerReportSettingResource } from '../../utils/api';
import connect from "react-redux/lib/connect/connect";
import {DASHBOARD_SETTINGS_TYPE} from './constants'
import SaveSettingsButton from "./components/SaveSettingsButton";
import {Grid} from "@material-ui/core";
import _ from 'lodash';
import {validateSelectedRangeBusinessDates} from "../../utils/utils";
import { REPORTS_AREAS } from './components/CustomersList/components/ReportsAreasVisibility/constants';

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

export default connect(mapStateToProps)((props) => {

  const {
    auth,
    defaultSettingsType,
    title,
    setHasUnsavedSettings,
    newDesign,
  } = props;

  const DashboardSettingsWindowComponent = newDesign ? DashboardSettingsWindowV2 : DashboardSettingsWindow;
  const [savedSettings, setSavedSettings] = React.useState(); // settings that come from db. Are updated on render and after save to db
  const [settings, setSettings] = React.useState({}); // settings that are currently changed on front
  const [dateRange, setDateRange] = React.useState({
    start: undefined,
    end: undefined
  });
  const [loading, setLoading] = React.useState(false);

  const [snackbarSettings, setSnackbarSettings] = React.useState({
    open: false,
    variant: 'success',
    message: ''
  });
  const handleSnackbarVisibility = (state) => {
    setSnackbarSettings({
      ...snackbarSettings,
      open: state
    })
  };

  React.useEffect(() => {
    if(!_.isNil(savedSettings) && !_.isNil(settings)){
      setHasUnsavedSettings(!_.isEqual(savedSettings, settings))
    }
  }, [settings, savedSettings])

  const handleSettingUpdate = (fieldname) => (value) => {
    if (fieldname === 'areas_visibility') {
        setSettings((prevState) => ({
        ...prevState,
        with_payment_plans: value.includes(REPORTS_AREAS.PAYMENT_PLANS.value),
        with_profit_loss: value.includes(REPORTS_AREAS.PROFIT_LOSS.value),
        include_historical_portfolios: value.includes(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value),
        with_transaction_saldo: value.includes(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value),
        with_transactions_monitor: value.includes(REPORTS_AREAS.TRANSACTIONS_MONITOR.value),
        with_other_assets: value.includes(REPORTS_AREAS.OTHER_ASSETS.value)
      }))
    } else if(fieldname === 'benchmark_updates') {
      setSettings((prevState) => ({
        ...prevState,
        benchmark_enabled: value.benchmark_enabled,
        benchmarks: value.benchmarks,
      }));
    } else {
        setSettings((prevState) => ({
        ...prevState,
        [fieldname]: value
      }))
    }

    // if save settings btn was clicked
    if(fieldname === 'id'){
      // cloneDeep used to prevent ref same object as settings
      let oldSettings = _.cloneDeep({...settings, [fieldname]: value}) // get currently selected settings with new field value
      setSavedSettings(oldSettings) // set it to savedSettings
    }

  }
  const handleDateRangeChanged = (dates, datesType) => {
    handleSettingUpdate('date_range')(datesType.value.toString())
    let newRange;
    if(datesType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value){
      newRange = {
        start: moment(dates.start),
        end: moment(dates.end)
      };
    } else {
      newRange = dates;
    }
    setDateRange(newRange)
    return newRange
  }
  const prepareDates = (response) => {
    let init_data;
    if(response && response.date_range === PERFORMANCE_TIME_TYPE.CUSTOM.value.toString()) {
       init_data = {
        start: moment(response.date_range_start_date),
        end: moment(response.date_range_end_date),
      }
    }
    else if (response && response.date_range) {
      const performanceTimeType = Object.values(PERFORMANCE_TIME_TYPE).find(item => item.value == response.date_range);
      init_data = performanceTimeType.getDateRange();
      validateSelectedRangeBusinessDates(
        init_data, performanceTimeType.value,
        [CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, BEGINNING_PERFORMANCE_TIME_TYPE_VALUE]);
    }
    else {
      init_data = {
        start: undefined,
        end: undefined
      }
    }
    setDateRange(init_data)
  }

  React.useEffect(() => {
    loadPageData();
  }, []);

  const loadPageData = async () => {
    let updatedSavedSettings = {}

    try {
      setLoading(true);
      let response =  await CustomerReportSettingResource.at('broker/default-dashboard-settings/').get(
        {broker_id: auth.user.broker_id,
        default_settings_type: props.defaultSettingsType});

      prepareDates(response);
      // cloneDepp is used to prevent 'custom_report_type_settings' key of settings and savedSettings to reference the same obj
      // destruction {...response} does not help, because nested dicts like 'custom_report_type_settings' will still ref the same obj
      setSettings(_.cloneDeep(response));
      updatedSavedSettings = _.cloneDeep(response)
      setLoading(false);
    }
    catch (e) {
      setSettings({});
      setLoading(false);
      setSnackbarSettings({
        open: true,
        variant: 'error',
        message: 'Es ist ein Fehler aufgetreten.'
      });
    }

    setSavedSettings(updatedSavedSettings)
  }

  const updateBenchmarkSwitchState = (forceShow) => {
    handleSettingUpdate('benchmark_updates')({
      benchmarks: settings.benchmarks,
      benchmark_enabled: !!forceShow
    })
  };

  const handleBenchmarkUpdate = (benchmarks, enabled) => {
    const preparedBenchmarksData = benchmarks && benchmarks.map(benchmark => ({
      ...benchmark,
      benchmark_id: benchmark.id,
      percentage: benchmark.weight
      }
    ));

    handleSettingUpdate('benchmark_updates')({
      benchmarks: preparedBenchmarksData,
      benchmark_enabled: !!enabled
    })
  };


  return (
      <Grid style={{ marginBottom: 15, background: "white", boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)', transform: 'translateZ(1px)', borderRadius: 2}}>
          <DashboardSettingsWindowComponent
            title={title}
            settings={settings}
            handleSettingUpdate={handleSettingUpdate}
            handleDateRangeChanged={handleDateRangeChanged}
            dateRange={dateRange}
            loading={loading}
            snackbarSettings={snackbarSettings}
            setSnackbarSettings={setSnackbarSettings}
            handleSnackbarVisibility={handleSnackbarVisibility}
            benchmarks={settings && settings.benchmarks}
            showBenchmark={settings && settings.benchmark_enabled}
            updateBenchmarkSwitchState={updateBenchmarkSwitchState}
            handleBenchmarkUpdate={handleBenchmarkUpdate}
            settingsType={DASHBOARD_SETTINGS_TYPE.GENERAL}
            defaultSettingsType={defaultSettingsType}
          />

          <Grid style={{display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #DADEE1',}}>
            <SaveSettingsButton
              settings={settings}
              dateRange={dateRange}
              setSnackbarSettings={setSnackbarSettings}
              broker_id={auth.user.broker_id}
              settingsType={DASHBOARD_SETTINGS_TYPE.GENERAL}
              handleSettingUpdate={handleSettingUpdate}
              btnWrapperStyles={{margin: '16px'}}
              disabled={_.isEqual(savedSettings, settings)}
            />
          </Grid>

      </Grid>
  )
});