import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import HighChartBase from "../../../../../../components/Charts/Base";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import Tooltip from "../../../../../../components/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import clsx from "clsx";
import _ from 'lodash';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

export class ChartRollingSharpeRatio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      benchmarkDetails: undefined,
      RollingSharpeRatioBMData: undefined,
      RollingSharpeRatioBMError: undefined,
      RollingSharpeRatioBMLoading: true,
      RollingSharpeRatioData: undefined,
      RollingSharpeRatioError: undefined,
      RollingSharpeRatioLoading: true,
      showBenchmark: true,
    }

    this.fetchRollingSharpeRatioData = this.fetchRollingSharpeRatioData.bind(this);
  }

  componentDidMount() {
    this.fetchRollingSharpeRatioData()
  }

  fetchRollingSharpeRatioData() {
    VirtualPortfolioHandlerResource.getRollingSharpeData(this.props.customerId, this.props.portfolioId)
      .then((response) => {

        parseResponse(response, 'rolling_sharpe_ratio',
          data => {
            this.setState({
              RollingSharpeRatioData: data['indicators'],
              RollingSharpeRatioError: undefined,
              RollingSharpeRatioLoading: false
            })
          },
          error => {
            this.setState({
              RollingSharpeRatioData: undefined,
              RollingSharpeRatioError: error,
              RollingSharpeRatioLoading: false
            })
          }
        )

      })

    VirtualPortfolioHandlerResource.getRollingSharpeBMData(this.props.customerId, this.props.portfolioId)
      .then((response) => {

        parseResponse(response, 'rolling_sharpe_ratio_bm',
          data => {
            this.setState({
              RollingSharpeRatioBMData: data['indicators'],
              RollingSharpeRatioBMError: undefined,
              RollingSharpeRatioBMLoading: false
            })
          },
          error => {
            this.setState({
              RollingSharpeRatioBMData: undefined,
              RollingSharpeRatioBMError: error,
              RollingSharpeRatioBMLoading: false
            })
          }
        )

        this.setState({
          benchmarkDetails: response['benchmark_details'],
        })

      })
  }

  renderBenchmarkToolTip() {
    let listItems = this.state.benchmarkDetails.map((item, index) => (
      <li key={index}>
        <span><b>{item.percentage}%</b>&nbsp;{item.benchmark_name}</span>
      </li>
    ))

    return <ul>
      {listItems}
    </ul>
  }

  renderChart() {
    const { classes } = this.props;
    const sharpeName = this.props.portfolioName;

    const defaultChartColor = '#0596CE';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };
    const benchmarkLegendStyle = {
      background: defaultBenchmarkColor
    };

    let series_data = [];

    if (this.state.RollingSharpeRatioData) {
      series_data.push({
        type: 'line',
        name: sharpeName,
        color: defaultChartColor,
        data: this.state.RollingSharpeRatioData.map(value => ([Date.parse(value.date), (value.sharpe_ratio_a_rolling || 0)]))
      })
    }

    if (this.state.showBenchmark && this.state.RollingSharpeRatioBMData) {
      series_data.push({
        type: 'line',
        name: 'benchmark',
        color: defaultBenchmarkColor,
        data: this.state.RollingSharpeRatioBMData.map(value => ([Date.parse(value.date), (value.sharpe_ratio_a_rolling || 0)]))
      })
    }

    return <div className={classes.chartContainer}>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{sharpeName}</label>
        </li>

        {!_.isEmpty(this.state.benchmarkDetails) && this.state.showBenchmark &&
          <li
            className={clsx(classes.legendItem, this.state.RollingSharpeRatioBMError || !this.state.showBenchmark ? classes.disabled : undefined)}>
            <div className={classes.bullet} style={benchmarkLegendStyle}/>
            <label>Benchmark</label>
            <Tooltip
              placement={'left'}
              disableHoverListener={this.state.RollingSharpeRatioBMError !== undefined}
              disableTouchListener={this.state.RollingSharpeRatioBMError !== undefined}
              disableFocusListener={this.state.RollingSharpeRatioBMError !== undefined}
              title={
                <div>
                  {this.state.RollingSharpeRatioBMError && 'Keine Benchmark erstellt'}
                  {this.renderBenchmarkToolTip()}
                </div>
              }
            >
              <ErrorIcon
                className={clsx(classes.helpIcon, this.state.RollingSharpeRatioBMError ? classes.disabledIcon : undefined)}/>
            </Tooltip>
          </li>
        }

      </ul>
      <HighChartBase className={classes.chart} options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          data: {
            dateFormat: "YYYY-mm-dd"
          },
          series: series_data,
          chart: {
            type: 'line',
            height: 300,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            }
          },
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: false,
            inputEnabled: false,
            verticalAlign: 'top',
            buttons: null,
          },
          navigator: {
            enabled: false
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            area: {
              fillColor: undefined
            },
            series: {
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              },
              type: 'line',
              fillColor: undefined,
              compareBase: 0,
              showInNavigator: true,
            }
          },
          tooltip: {
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            pointFormat: '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};" /><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">{point.y}</div></span><br/>',
            valueDecimals: 2,
            split: false,
            shared: true,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'square',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-weight: bold;">{point.key}</span><br/><br/>',
            style: {
              fontFamily: '"Roboto-Regular"'
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            title: {
              enabled: false
            },
            labels: {
              enabled: true,
              x: -2,
            }
          },
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              year: '%Y'
            },
            tickLength: 0,
            labels: {
              style: {
                fontSize: 14
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                tooltip: {
                  positioner: function (labelWidth, labelHeight, point) {
                    return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                  }
                }
              }
            }]
          }
      }} language={'DE'}/>
    </div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Rollierendes 1-Jahres Sharpe ratio'}
        loading={this.state.RollingSharpeRatioLoading && this.state.RollingSharpeRatioLoading}
        content={
          <div className={classes.container}>
            {this.state.RollingSharpeRatioError ? this.state.RollingSharpeRatioError : this.state.RollingSharpeRatioData ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}

export default withStyles(styles)(ChartRollingSharpeRatio)
