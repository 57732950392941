import { makeStyles } from '@material-ui/core';

const getStyles = (theme) => {
  return {
    container: {
      display: 'flex'
    },
    disabled: {
      pointerEvents: 'none',
      opacity: .7
    },
    label: {
      backgroundColor: '#EEF0F2',
      borderRight: '1px solid #ABB6BE',
      padding: '7px 12px',
      borderRadius: '2px 0 0px 2px',
      whiteSpace: 'nowrap',
      maxWidth: '50%',

      '& > span': {
        color: '#636A71',
        fontFamily: 'Roboto-Regular',
        fontSize: 12,
        fontWeight: 'bold'
      }
    },
    expansionPannelContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    expansionPanelRoot: {
      borderRadius: '0px 2px 2px 0px',
      height: 34,
      width: '100%',
      boxSizing: 'border-box',
      boxShadow: 'none',
      zIndex: 1
    },
    expansionPanelRootWithoutLabel: {
      borderRadius: 2,
      zIndex: 2
    },
    expansionPanelExpanded: {
      height: 'auto',
      zIndex: 2,
      transform: 'translate3d(0,0,1px)'
    },
    expandIcon: {
      padding: '0 10px'
    },
    expansionPanelSummaryRoot: {
      minHeight: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '0px 2px 2px 0px',
      padding: 10,
      display: 'flex'
    },
    expansionPanelSummaryExpanded: {
      minHeight: '34px !important',
      height: '34px !important',
    },
    expansionPanelSummaryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      fontFamily: 'Roboto-Regular',
      fontWeight: 'bold',
      color: '#636A71',
      fontSize: 12,
      padding: 0,
      margin: 0,
      width: 'calc(100% - 33px)'
    },
    expansionPanelDetailsRoot: {
      boxSizing: 'border-box',
      padding: 0,
      backgroundColor: 'white',
      borderTop: 'none',
      boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
    },
    list: {
      padding: 0,
      width: '100%',
      '& $listItem': {
        transition: 'all',
        transitionDuration: '0.2s',
        borderBottom: '1px solid #E1E3E9',
      },
      '& $listItem:hover': {
        cursor: 'pointer',
        backgroundColor: '#EFEFF2'
      },
      '& $listItem:nth-child(2n + 1)': {
        borderBottom: '1px solid #DADEE1'
      },
      '& $listItem:last-child': {
        borderBottom: 'none'
      },
      '& $listItem:first-child': {
        borderTop: '1px solid #ABB6BE'
      }
    },
    listItem: {
      padding: '6px 10px',
      '& span': {
        fontFamily: 'Roboto-Regular',
        color: '#303C43',
        fontSize: 14
      }
    },
    selectedListItem: {
      backgroundColor: 'transparent!important',
      '& span': {
          color: '#ABB6BE',
      }
    },
    ellipsis:{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    dropdownContainer: {
      position: 'relative',
      flex: 1
    },

    //#region Checkbox styles
    checkboxContainer: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#EEF0F2',
      borderRadius: 2,

      '& > $label': {
        borderRight: 'none',
        maxWidth: '100%',
        width: '100%'
      }
    },
    checkboxSection: {
      width: '100%',
      padding: 9,
      paddingTop: 0,
    },
    checkboxDoubleColumn: {
      '& > div': {
        display: 'flex',
        width: '100%',

        '& > div': {
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '50%',
        }
      }
    },
    checkboxFormControlLabel: {
      maxWidth: '100%'
    },
    checkboxLabel: {
      fontFamily: 'Roboto-Regular',
      fontSize: '12px !important',
      color: '#303C43',
      padding: '5px 0 !important',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%'
    },
    checkboxRoot: {
      padding: 0,
    },
    //#endregion
    //#region Range filter styles
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 12px 12px',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    inputs: {
      flex: 1,
      display: 'flex',
      alignItems: 'baseline',

      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    input: {
      height: 27,
      borderRadius: 2,
      border: '1px solid #ABB6BE',
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: '#636A71',
      padding: 4,
      flex: 1,
      outline: 'none',
      backgroundColor: 'white'
    },

    inputError: {
      border: '2px solid red'
    },

    //#endregion
    //#region Search filter styles
    searchContainer: {
      display: 'flex',
      position: 'relative'
    },
    searchButton: {
      fontFamily: 'Roboto-Medium',
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontSize: 14,
      textTransform: 'none',
      lineHeight: 0,
      boxShadow: 'none',
      borderRadius: '0px 2px 2px 0px',
      paddingLeft: 9,
      paddingRight: 9,
      minWidth: 30,

      '&:hover': {
        backgroundColor: 'white',
        boxShadow: 'none'
      }
    },
    searchInput: {
      'borderRadius': '2px 0px 0px 2px',
      border: 'none',
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: '#636A71',
      padding: '7px 10px',
      flex: 1,
      height: 34,
      paddingRight: 30,
      outline: 'none',
      minWidth: 170,

      '&::placeholder': {
        color: '#ABB6BE'
      },

      '&:focus': {
        border: 'none'
      }
    },
    expandIconSummary: {
      padding: '0px 8px !important'
    },
    checkboxFilterPanelRoot: {
      backgroundColor: '#EEF0F2',
      padding: 10,
      boxShadow: 'none',
      zIndex: 1
    },
    checkboxFilterSummaryRoot: {
      padding: 0,
      minHeight: '0px !important'
    },
    checkboxFilterSummaryContent: {
      margin: '0px !important'
    },
    checkboxFilterLabelMain: {
      fontFamily: 'Roboto-Regular',
      fontSize: 12,
      fontWeight: 'bold',
      color: '#636A71'
    },
    checkboxFilterDetailsRoot: {
      padding: 0
    },
    checkboxExpandIcon: {
      color: theme.palette.primary.main
    },
    //#endregion
    loadingIndicator: {
      color: theme.palette.primary.main
    },
    loadingContainer: {
      width: '100%',
      height: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}

export default makeStyles(getStyles);

export const filterStyles = makeStyles(getStyles);