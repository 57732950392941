const styles = theme => ({
  root: {
    marginBottom: 16,
    boxShadow: '0px 3px 15px #00000033',
    border: 'none',
    '&::before': {
      content: 'none',
    },
  },
  panelTitle: {
    position: 'relative',
    padding: '5.5px 16px',
    paddingLeft: 22,
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
  },
  panelTitleExpanded: {
    borderBottom: '1px solid #d8dcdf',
    '& > div': {
      borderBottom: 'none',
    },
    '& .custodian-name': {
      color: '#0092E5',
    },
    '& .left-border': {
      content: '',
      position: 'absolute',
      display: 'block',
      left: 0,
      top: 0,
      bottom: 0,
      width: 5,
      backgroundColor: '#0092E5',
    },
  },
  detailsRoot: {
    padding: '15px 40px 50px',
    justifyContent: 'space-between',
  },
  container: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '50%',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  headline: {
    fontWeight: 'bold',
    minHeight: 40,
    color: '#80858C',
    backgroundColor: '#F5F6F7',
    borderBottom: 'none!important',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '10px',
    borderBottom: 'solid 1px #D8DCDF',
  },
  itemLine: {
    minHeight: 44,
    alignItems: 'center'
  },
  itemMark: {
    minHeight: 44,
    alignItems: 'center',
    '& [class*="values"]': {
      paddingRight: 10,
    }
  },
  values: {
    width: '50%',
    textAlign: 'right'
  },
  leftPart: {
    width: '45%',
    float: 'left',
  },
  rightPart: {
    width: '45%',
    float: 'right',
  },
  circleRoot: {
    position: 'relative',
    top: 2,
  },
  textLink: {
    color: '#0092E5',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  linearProgress: {
    padding: '0.5rem 0.5rem 0 5rem',
  },
  textToRight: {
    textAlign: 'right',
  }
})

export default styles;