import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

/* Material-UI Icons */
import ErrorIcon from '@material-ui/icons/Error';

/* BCA Components */
import Tooltip from '../../../../components/Tooltip';
import LoadingContainer from '../LoadingContainer';

/* BCA modules */
import useStyles from '../styles';
import {UserUtils, withEuroOrDash} from "../../../../utils/utils";
import {
  buildCurrentCustomerDashboardPath, buildCurrentCustomerOtherAssetsListPath,
  buildCustomerDashboardLink,
  buildOtherAssetsListLink
} from "../../../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../../containers/InvestmentPlatform/utils'
import { newDesignUsed } from '../../../../utils/utils'
import ExpandButton
  , {
  ExpandIconButton
} from "../../../../containers/CustomerDashboard/components/Widgets/components/DashboardCard/components/ExpandButton";
import Grid from "@material-ui/core/Grid";
import WarningTooltip from "../../../WarningTooltip";

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const TotalInvestedAmount = (props) => {
  const classes = useStyles();

  const {
    value,
    loading,
    customerId,
    auth,
    otherAssetsLink,
    dashboardLink,
    expandable,
    expanded,
    onExpanded,
    withExpandButton
  } = props;

  const buildAssetLink = () => {
    let path = UserUtils.isBroker(auth) ? buildOtherAssetsListLink(customerId) : buildCurrentCustomerOtherAssetsListPath();

    if (UserUtils.isBroker(auth) && newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'OTHER_ASSETS', (dynamicPath) => {
        path = '/' + getInvestmentDynamicPath() + dynamicPath.replace(':customer_id', customerId)
      }, ':customer_id')
    }

    return path
  };

  const buildDashboardLink = () => {
    let path =  UserUtils.isBroker(auth) ? buildCustomerDashboardLink(customerId) : buildCurrentCustomerDashboardPath();

    if (UserUtils.isBroker(auth) && newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'DASHBOARD', (dynamicPath) => {
        path = '/' + getInvestmentDynamicPath() + dynamicPath.replace(':customer_id', customerId)
      }, ':customer_id')
    }

    return path
  };

  const Header = ({children}) => (
    <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 'auto', marginRight: 0}}>
      <Grid item>
        <Grid item className={classes.header} style={{
          display: 'flex',
          alignItems: 'center'
        }}>Gesamtvermögen
          <WarningTooltip
            title={'Weitere Vermögenswerte werden derzeit nicht zur Berechnung der Bestände oder der Rendite herangezogen. Das Gesamtvermögen wird immer mit dem aktuellen heute gültigen Wert angezeigt, unabhängig vom gewählten Enddatum des Reports.'}
            placement={"top"}
            iconClassName={classes.helpIcon}
          />
        </Grid>
        {children}
      </Grid>
      {!loading && withExpandButton && (
        <Grid item>
          <ExpandIconButton
            expanded={expanded}
            onChanged={() => onExpanded(!expanded)}
          />
        </Grid>
      )}
    </div>
  );

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          {props.loadingWithTitle && <Header />}
          {props.loadingContent || (<LoadingContainer/>)}
        </>
      ) : (
        <Header>
          <p style={{display: !expandable || expanded ? 'inline' : 'none'}}>
            <p className={classes.subHeader}>Bestände inkl. sonstige Vermögenswerte</p>
            <p className={classes.value}><span>{withEuroOrDash(value || 0)}</span></p>
            {customerId && otherAssetsLink && (
              <p className={classes.link} >
                <Link to={buildAssetLink()}>Sonstige Vermögenswerte anzeigen</Link>
              </p>
            )}
            {dashboardLink && (
              <p className={classes.link} >
                <Link to={buildDashboardLink()}>Vermögensübersicht anzeigen</Link>
              </p>
            )}
          </p>
        </Header>
      )}

    </div>
  )
};

export default connect(mapStateToProps)(TotalInvestedAmount);
