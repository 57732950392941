import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  highlightIcon: {
    color: theme.palette.primary.main,
    marginLeft: 5,
    fontSize: 16
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  chartLegendContainer: {
    flexDirection: (props) => props.flexDirection || 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      '& > span > span > span:last-child': {
        whiteSpace: 'nowrap'
      }
    },
  }
}));