import { makeStyles } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';

export const styles = theme => ({
  categoryItemContainer: {
    position: 'relative',
    borderRadius: 3,
    backgroundColor: hexToRgba(theme.palette.primary.main, 0.25),
    width: '100%',
    height: '100%',
    padding: '9px 10px',

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.08)'
    }
  },
  readOnly: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    '&:hover': {
      cursor: 'initial',
      boxShadow: 'none'
    },
  },
  categoryItemTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 22,
    color: '#4D4F5C',
    margin: 0,

    [theme.breakpoints.only('xs')]: {
      fontSize: 16
    }
  },
  categoryItemTotalAmount: {
    fontFamily: 'Roboto-Regular',
    fontSize: 18,
    color: '#4D4F5C',
    margin: 0,

    [theme.breakpoints.only('xs')]: {
      fontSize: 15
    }
  },
  categoryItemIcon: {
    position: 'absolute',
    bottom: 10,
    right: 10
  }
});

export default makeStyles(styles);