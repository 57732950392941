import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    width: '200px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  deleteHelperTitle: {
    fontSize: '13px',
    textAlign: 'left',
    padding: '15px 15px 15px 15px'
  },
});

export default makeStyles(styles);
