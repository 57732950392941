import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  container: {},
  formLabel: {
    color: '#313D44',
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 8,
    whiteSpace: 'nowrap !important',
  },
  focusedLabel: {
    color: '#313D44 !important'
  },
  formControlLabel: {
    fontSize: 14,
  },
  radio: {
    padding: 5,
    marginLeft: 4
  }
}))