import { makeStyles } from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    maxWidth: 240,

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4D4F5C',
    marginRight: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },
  expansionPannelContainer: {
    position: 'relative',
    height: FORM_FIELD_HEIGHT,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35
    },
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: FORM_FIELD_HEIGHT,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      height: 35
    },
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 100000,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    minHeight: '45px !important',
    height: '45px !important',
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 10,

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px !important',
    height: '45px !important',

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    fontSize: 18,
    padding: 0,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  },
  expansionPanelDetailsRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'white',
    borderTop: 'none',
    zIndex: 1000000
  },
  portfolioList: {
    padding: 0,
    width: '100%',
    '& $portfolioListItem': {
      transition: 'none',
      padding: '10'
    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    }
  },
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Bold',
      color: theme.palette.primary.main,
      fontSize: 14,
    }
  },
  portfolioListItemSelected: {
    backgroundColor: '#D8DCDF',

    '& > span': {
      color: '#4D4F5C'
    }
  },
  portfolioListCheckbox: {

  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expandArrow: {
    color: `${theme.palette.primary.main}`
  }
}))