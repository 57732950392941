import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { FORM_FIELD_HEIGHT } from '../../../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  container: {
    width: props => props.width || '100%',
    marginTop: props => !_.isNil(props.marginTop) ? props.marginTop : 16,
    marginBottom: props => !_.isNil(props.marginBottom) ? props.marginBottom : 8
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4D4F5C',
    marginRight: 20
  },
  expansionPannelContainer: {
    position: 'relative',
    height: FORM_FIELD_HEIGHT,
    width: '100%',
    marginBottom: 8
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: FORM_FIELD_HEIGHT,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 9999999,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryIcon: {
    color: theme.palette.primary.main
  },
  expansionPanelSummaryIconError: {
    color: 'red'
  },
  expansionPanelSummaryIconDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  expansionPanelSummaryRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    minHeight: '45px !important',
    height: '45px !important',
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 10,

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryExpandedDisabled: {

    borderColor: 'rgba(0, 0, 0, 0.38)',
    backgroundColor: 'transparent',

    '& $expansionPanelSummaryContent': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  expansionPanelSummaryRootError: {
    border: `2px solid red`,
    minHeight: '45px !important',
    height: '45px !important',
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 10
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px !important',
    height: '45px !important',

    // todo seems to be duplicate of expansionPanelSummaryExpanded in src/components/ListLinksDropdown/styles check if one style source could be used or make single component
    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontWaight: 'bold',
    fontSize: 18,
    padding: '0px',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  },
  expansionPanelDetailsRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'white',
    borderTop: 'none',
    width: '100%'
  },
  portfolioList: {
    padding: 0,
    width: '100%',
    '& $portfolioListItem': {
      transition: 'all',
      transitionDuration: '0.2s',
      padding: '10'
    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    }
  },
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Bold',
      color: theme.palette.primary.main,
      fontSize: 14,
    }
  },
  portfolioListItemSelected: {
    backgroundColor: '#D8DCDF',

    '& > span': {
      color: '#4D4F5C'
    }
  },
  portfolioListCheckbox: {

  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
  switchLabel: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    fontWeight: 40,
    color: '#B1B1B1',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    margin: 0,
  }
}))